import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton, PrimaryInput } from "common";
import { postCheckEmail } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { isLoading } from "utils/constants";
import { signinStep1Schema } from "validations";

import * as S from "./styled";

export const Step1 = () => {
  const { postCheckEmailStatus, checkEmailErrorMessage } = useSelector(clientSelectors);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signinStep1Schema),
  });

  const onSubmit = (data) => {
    dispatch(postCheckEmail(data));
  };

  return (
    <S.StepContainer onSubmit={handleSubmit(onSubmit)}>
      <S.StepHeader>sign in</S.StepHeader>
      <S.Description>To sign in to your account please provide your email address</S.Description>
      <S.InputWrapper>
        {checkEmailErrorMessage ? <S.ErrorMessageBlock>{checkEmailErrorMessage}</S.ErrorMessageBlock> : null}
        <PrimaryInput error={errors.email} placeholder="Email address" register={register("email")} />
      </S.InputWrapper>
      <S.ButtonWrapper>
        <PrimaryButton className="submit-button" label="sign in" loading={isLoading(postCheckEmailStatus)} />
      </S.ButtonWrapper>
    </S.StepContainer>
  );
};
