import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const DiscountWrapper = styled.div`
  margin-top: 20px;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 3px 1px -2px ${COLORS.overlay_200}, 0 2px 2px 0 ${COLORS.overlay_150}, 0 1px 5px 0 ${COLORS.overlay_150};

  svg {
    cursor: pointer;
  }

  .delete-modal {
    color: ${COLORS.red};
  }
`;

export const ContentTitle = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const RemoveContainer = styled.div`
  svg {
    cursor: pointer;
    width: 15px;
    fill: ${COLORS.dimgrey};
  }
`;
