import { ContentHeader, SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components";

export const ArchiveOwnerContent = styled(SinglePageContentShadow)`
  margin-block: 10px;

  .archive-owner-button {
    margin-block: 10px;
  }
`;

export const ContentTitle = styled(ContentHeader)``;
