import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";
import { v4 as uuid } from "uuid";

import {
  exportCSV,
  getHearingDatesList,
  getUpcomingHearings,
  postHearingsComplete,
  postUploadHearingComment,
  postUploadHearingFile,
} from "./actions";

const initialState = {
  hearingDatesList: [],
  per_page: 50,
  last_page: 0,
  upcomingHearingsList: [],
  paginationData: {
    first: 10,
    rows: 50,
    page: 1,
  },
  downloadCsvHref: null,
  isCommentsModalOpen: false,
  itemId: null,
  itemComment: null,
  allowScroll: false,
  exportCSVStatus: LOADING_STATUSES.idle,
  getHearingDatesListStatus: LOADING_STATUSES.idle,
  postUploadHearingFileStatus: LOADING_STATUSES.idle,
  postUploadHearingCommentStatus: LOADING_STATUSES.idle,
  getUpcomingHearingsStatus: LOADING_STATUSES.idle,
  postHearingsCompleteStatus: LOADING_STATUSES.idle,
};

export const hearingDatesList = createSlice({
  name: SLICE_NAME.hearingDatesSlice,
  initialState,
  reducers: {
    resetAllData: (state) => ({
      ...initialState,
      paginationData: state.paginationData,
    }),
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    setIsCommentsModalOpen: (state, { payload }) => {
      state.isCommentsModalOpen = payload;
    },
    setItemId: (state, { payload }) => {
      state.itemId = payload;
    },
    setItemComment: (state, { payload }) => {
      state.itemComment = payload;
    },
    setAllowScroll: (state, { payload }) => {
      state.allowScroll = payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getHearingDatesList.pending, (state) => {
      state.getHearingDatesListStatus = LOADING_STATUSES.pending;
    });
    addCase(getHearingDatesList.fulfilled, (state, { payload }) => {
      state.hearingDatesList = payload?.data.map((item) => ({
        ...item,
        uniqueId: uuid(),
      }));
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
      state.getHearingDatesListStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getHearingDatesList.rejected, (state) => {
      state.getHearingDatesListStatus = LOADING_STATUSES.failed;
    });

    // upload file
    addCase(postUploadHearingFile.pending, (state) => {
      state.postUploadHearingFileStatus = LOADING_STATUSES.pending;
    });
    addCase(postUploadHearingFile.fulfilled, (state) => {
      state.postUploadHearingFileStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postUploadHearingFile.rejected, (state) => {
      state.postUploadHearingFileStatus = LOADING_STATUSES.failed;
    });

    // export CSV
    addCase(exportCSV.pending, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCSV.fulfilled, (state, { payload }) => {
      state.downloadCsvHref = payload;
      state.exportCSVStatus = LOADING_STATUSES.succeeded;
    });
    addCase(exportCSV.rejected, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.failed;
    });

    // add comment
    addCase(postUploadHearingComment.pending, (state) => {
      state.postUploadHearingCommentStatus = LOADING_STATUSES.pending;
    });
    addCase(postUploadHearingComment.fulfilled, (state) => {
      state.postUploadHearingCommentStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postUploadHearingComment.rejected, (state) => {
      state.postUploadHearingCommentStatus = LOADING_STATUSES.failed;
    });

    // GET UPCOMING HEARINGS

    addCase(getUpcomingHearings.pending, (state) => {
      state.getHearingDatesListStatus = LOADING_STATUSES.pending;
    });
    addCase(getUpcomingHearings.fulfilled, (state, { payload }) => {
      state.upcomingHearingsList = payload.data.sort((a, b) => a.county.localeCompare(b.county));
      state.getHearingDatesListStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getUpcomingHearings.rejected, (state) => {
      state.getHearingDatesListStatus = LOADING_STATUSES.failed;
    });

    // POST HEARINGS COMPLETED
    addCase(postHearingsComplete.pending, (state) => {
      state.postHearingsCompleteStatus = LOADING_STATUSES.pending;
    });
    addCase(postHearingsComplete.fulfilled, (state) => {
      state.postHearingsCompleteStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postHearingsComplete.rejected, (state) => {
      state.postHearingsCompleteStatus = LOADING_STATUSES.failed;
    });
  },
});

export const hearingDatesReducer = hearingDatesList.reducer;

export const hearingDatesActions = {
  ...hearingDatesList.actions,
  exportCSV,
  getHearingDatesList,
  postUploadHearingComment,
  postUploadHearingFile,
  getUpcomingHearings,
  postHearingsComplete,
};
