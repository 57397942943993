import styled from "styled-components/macro";

export const Description = styled.div`
  color: black;
  position: absolute;
  top: 30px;
  display: flex;
  width: 100px;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
