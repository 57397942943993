import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { signupApi } from "./services";

export const signUp = createAsyncThunk(
  `${SLICE_NAME.signupSlice}/signup`,
  async ({ referral_code, ...body }, { rejectWithValue, dispatch }) => {
    try {
      const response = await signupApi.signUp({ ...body, referral_code });
      const { data, fields } = response.data;

      const { documents_yn, ...bodyWithoutDocumentsYn } = body;

      if (fields.is_owner_exist) {
        await signupApi.putOwner({
          body: bodyWithoutDocumentsYn,
          id: data.id,
        });
      }

      if (fields?.referral_flag_text !== "") {
        const [type, content] = fields.referral_flag_text.split("-");
        dispatch(
          postAccountLogs({
            owner_id: response.data.data.id,
            type,
            content,
            flag: 1,
          })
        );
      }

      body.referralCode = referral_code;
      response.data.data = { ...data, ...body };
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const propertySearch = createAsyncThunk(
  `${SLICE_NAME.signupSlice}/propertySearch`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await signupApi.propertySearch(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postProperty = createAsyncThunk(
  `${SLICE_NAME.signupSlice}/postProperty`,
  async ({ ownerId, ...body }, { rejectWithValue, dispatch }) => {
    try {
      const response = await signupApi.postProperty(body);

      const property_id = response.data.data.id;

      dispatch(
        connectOwnerProperty({
          owner_id: ownerId,
          property_id,
        })
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateOwner = createAsyncThunk(
  `${SLICE_NAME.signupSlice}/updateOwner`,
  async ({ body, id }, { rejectWithValue }) => {
    try {
      const response = await signupApi.putOwner({ body, id });
      response.data.data = body;
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postAccountLogs = createAsyncThunk(
  `${SLICE_NAME.signupSlice}/postAccountLogs`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await signupApi.postAccountLogs(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const connectOwnerProperty = createAsyncThunk(
  `${SLICE_NAME.signupSlice}/connectOwnerProperty`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await signupApi.connectOwnerProperty(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const propertySearchFromDb = createAsyncThunk(
  `${SLICE_NAME.signupSlice}/propertySearchFromDb`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await signupApi.propertySearchFromDb(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const completeSignUp = createAsyncThunk(
  `${SLICE_NAME.signupSlice}/completeSignUp`,
  async (body, { rejectWithValue }) => {
    try {
      await signupApi.completeSignUp(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addPropertyInLeads = createAsyncThunk(
  `${SLICE_NAME.signupSlice}/addPropertyInLeads`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await signupApi.postPropertyLeads(body);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deletePropertyLeads = createAsyncThunk(
  `${SLICE_NAME.signupSlice}/deletePropertyLeads`,
  async (body, { rejectWithValue }) => {
    try {
      await signupApi.deletePropertyLeads(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postPropertyId = createAsyncThunk(
  `${SLICE_NAME.signupSlice}/postPropertyId`,
  async (id, { rejectWithValue }) => {
    try {
      await signupApi.postPropertyId(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
