import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "assets/styles/colors";
import { Loader, PrimaryButton } from "common";
import { getMe } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { isLoading, NAV_LINKS } from "utils/constants";

import * as S from "./styled";

export const AccountPage = () => {
  const dispatch = useDispatch();
  const { getMeStatus, account } = useSelector(clientSelectors);

  useEffect(() => {
    dispatch(getMe());
  }, []);

  if (isLoading(getMeStatus)) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return (
    <S.AccountBlock>
      <S.AccountContainer>
        <S.AccountInfoTitle>Please contact us to change any of the following information.</S.AccountInfoTitle>
      </S.AccountContainer>
      <S.AccountContainer>
        <S.AccountInfoTitle>NAME</S.AccountInfoTitle>
        <S.AccountInfo>{account?.first_name}</S.AccountInfo>
      </S.AccountContainer>
      <S.AccountContainer>
        <S.AccountInfoTitle>SURNAME</S.AccountInfoTitle>
        <S.AccountInfo>{account?.last_name}</S.AccountInfo>
      </S.AccountContainer>
      <S.AccountContainer>
        <S.AccountInfoTitle>EMAIL</S.AccountInfoTitle>
        <S.AccountInfo>{account?.email}</S.AccountInfo>
      </S.AccountContainer>
      <S.AccountContainer>
        <S.AccountInfoTitle>PHONE NUMBER</S.AccountInfoTitle>
        <S.AccountInfo>{account?.phone_number}</S.AccountInfo>
      </S.AccountContainer>
      <S.AccountContainer>
        <S.AccountInfoTitle>MAILING ADDRESS</S.AccountInfoTitle>
        <S.AccountInfoBlock>
          <S.AccountInfo>{account?.county_owner_address} |</S.AccountInfo>
          <S.AccountInfo>{account?.county} | </S.AccountInfo>
          <S.AccountInfo>{account?.county_owner_name}</S.AccountInfo>
        </S.AccountInfoBlock>
      </S.AccountContainer>
      <S.ContactUsLink href={NAV_LINKS.contactUs} target="_blank">
        <PrimaryButton
          className="contact-us-button"
          label="CONTACT US"
          styles={{
            backgroundColor: `${COLORS.deepDark}`,
          }}
        />
      </S.ContactUsLink>
    </S.AccountBlock>
  );
};
