import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { singleDocumentsListApi } from "./services";

export const getSingleAoaDocuments = createAsyncThunk(
  `${SLICE_NAME.singleDocumentsSlice}/getSingleAoaDocuments`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleDocumentsListApi.getSingleAoaDocuments(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSingleSaDocuments = createAsyncThunk(
  `${SLICE_NAME.singleDocumentsSlice}/getSingleSaDocuments`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleDocumentsListApi.getSingleSaDocuments(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const putAoaFileName = createAsyncThunk(
  `${SLICE_NAME.singleDocumentsSlice}/putAoaFileName`,
  async (body, { rejectWithValue }) => {
    try {
      await singleDocumentsListApi.putAoaFileName(body.selectedId, body.value);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const putSaFileName = createAsyncThunk(
  `${SLICE_NAME.singleDocumentsSlice}/putSaFileName`,
  async (body, { rejectWithValue }) => {
    try {
      await singleDocumentsListApi.putSaFileName(body.selectedId, body.value);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadAoaDocument = createAsyncThunk(
  `${SLICE_NAME.singleDocumentsSlice}/uploadAoaDocument`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleDocumentsListApi.uploadAoaDoc(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadSaDocument = createAsyncThunk(
  `${SLICE_NAME.singleDocumentsSlice}/uploadSaDocument`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleDocumentsListApi.uploadSaDoc(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTracker = createAsyncThunk(
  `${SLICE_NAME.singleDocumentsSlice}/getTracker`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await singleDocumentsListApi.getTracker(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCSV = createAsyncThunk(
  `${SLICE_NAME.singleDocumentsSlice}/exportCSV`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleDocumentsListApi.exportCSV(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
