import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { protestedAccountsListApi } from "./services";

export const getProtestedAccountsList = createAsyncThunk(
  `${SLICE_NAME.protestedAccountsSlice}/getProtestedAccountsList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await protestedAccountsListApi.getProtestedAccounts(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postUploadFile = createAsyncThunk(
  `${SLICE_NAME.protestedAccountsSlice}/postUploadFile`,
  async (body, { rejectWithValue }) => {
    try {
      await protestedAccountsListApi.postUploadFile(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCSV = createAsyncThunk(
  `${SLICE_NAME.protestedAccountsSlice}/exportCSV`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await protestedAccountsListApi.exportCSV(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
