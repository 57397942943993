import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { agentsApi } from "./services";

export const createProfile = createAsyncThunk(
  `${SLICE_NAME.agentSlice}/createProfile`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await agentsApi.createProfile(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPermissions = createAsyncThunk(
  `${SLICE_NAME.agentSlice}/getPermissions`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await agentsApi.getPermissions();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAgents = createAsyncThunk(`${SLICE_NAME.agentSlice}/getAgents`, async (params, { rejectWithValue }) => {
  try {
    const response = await agentsApi.getAgents(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const exportCSV = createAsyncThunk(`${SLICE_NAME.agentSlice}/exportCSV`, async (params, { rejectWithValue }) => {
  try {
    const response = await agentsApi.exportCSV(params);
    const href = URL.createObjectURL(response.data);

    return href;
  } catch (err) {
    return rejectWithValue(err);
  }
});
