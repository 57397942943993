import {  useSelector } from "react-redux";
import { Loader } from "common";
import { singleArchivedSelectors } from "store/admin-slices/single-archived-slice/selectors";
import { isLoading } from "utils/constants";

import Flag from "./Flag";
import * as S from "./styled";

export const Flags = () => {
  const { singleArchived, postAccountsLogStatus } = useSelector(singleArchivedSelectors);

  return (
    <S.Container>
      <S.Content>
        <S.ContentTitle>Flags</S.ContentTitle>
          {isLoading(postAccountsLogStatus) ? (
            <Loader />
          ) : (
        <S.FlagsContent>
          {singleArchived.account_logs.map((accLog) => (
            <Flag key={accLog.id} accLog={accLog} />
          ))}
        </S.FlagsContent>
        )}
      </S.Content>
    </S.Container>
  );
};
