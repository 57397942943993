import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { discountApi } from "./services";

export const getOwnerDiscount = createAsyncThunk(
  `${SLICE_NAME.discount}/getOwnerDiscount`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await discountApi.getOwnerDiscount(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postDiscount = createAsyncThunk(
  `${SLICE_NAME.discount}/postDiscount`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await discountApi.postDiscount(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteDiscount = createAsyncThunk(
  `${SLICE_NAME.discount}/deleteDiscount`,
  async (id, { rejectWithValue }) => {
    try {
      await discountApi.deleteDiscount(id);
      return id;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateDiscount = createAsyncThunk(
  `${SLICE_NAME.discount}/updateDiscount`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await discountApi.putDiscount(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
