import { COLORS } from "assets/styles/colors";
import { ContentHeader, SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  width: 50%;
  border-radius: 4px;
  padding: 12px;
  box-shadow: 0 3px 1px -2px ${COLORS.overlay_200}, 0 2px 2px 0 ${COLORS.overlay_150}, 0 1px 5px 0 ${COLORS.overlay_150};
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-block: 24px;
  gap: 24px;
  width: 100%;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const Content = styled(SinglePageContentShadow)``;

export const ContentTitle = styled(ContentHeader)``;

export const AddAccountContent = styled(SinglePageContentShadow)`
  margin-block: 10px;

  .archive-owner-button {
    margin-block: 10px;
  }
`;

export const AddAccountForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .add-property-to-owner-button {
    width: 300px;
  }
`;

export const Box = styled.div`
  &.red {
    color: ${COLORS.red};
  }
`;

export const Text = styled.div`
  color: ${COLORS.green};

  &.error {
    color: ${COLORS.red};
  }
`;
