import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { COLORS } from "assets/styles/colors";

export const StepHeader = ({ label }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>{label}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.blue,
    paddingVertical: 2,
    paddingHorizontal: 4,
    marginVertical: 4,
  },
  label: {
    fontSize: 9,
    color: COLORS.white,
  },
});
