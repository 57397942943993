import { IMAGES } from "assets/images";
import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

export const ReferralWrapper = styled.div`
  min-height: calc(100vh - 75px);
  background-image: url(${IMAGES.home_1});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;

  .bottom-side-text {
    padding-top: 10px;
  }
`;
export const ReferralContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.primary};
  border-radius: 24px;
  background-color: ${COLORS.white};
  width: 86%;
  padding: 42px 36px;
  margin: 40px;

  @media screen and (${BREAKPOINTS.md}) {
    padding: 67px 46px;
    width: 792px;
  }

  .email-input {
    width: 200px;
    max-width: unset;

    @media screen and (${BREAKPOINTS.md}) {
      max-width: 300px;
      width: 300px;
    }
  }

  .email-button {
    width: 200px;
    max-height: 74px;
    margin-top: 10px;

    @media screen and (${BREAKPOINTS.md}) {
      width: 300px;
      height: 54px;
      margin-bottom: 20px;
    }
  }
`;

export const ReferralTitle = styled.div`
  color: ${COLORS.deepDark};
  font-family: Montserrat;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 32px;
    line-height: 60px;
  }
`;

export const ReferralText = styled.div`
  color: ${COLORS.deepDark};
  font-family: Montserrat;
  width: 90%;
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media screen and (${BREAKPOINTS.md}) {
    max-width: 654px;
    font-size: 16px;
  }
`;

export const ReferralForm = styled.form``;

export const SuccessMessageBlock = styled.div`
  color: ${COLORS.success};
`;

export const EmailErrorMessage = styled.div`
  width: 76%;
  color: ${COLORS.danger};
`;

export const ReferralEmailTitle = styled.div`
  padding-top: 10px;
  color: ${COLORS.deepDark};
  font-family: Montserrat;
  width: 80%;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  @media screen and (${BREAKPOINTS.md}) {
    max-width: 654px;
    font-size: 18px;
  }
`;

export const ShareViaEmailBlock = styled.div`
  margin-top: 20px;
  animation: 1s via-email;
  width: 100%;

  @keyframes via-email {
    0% {
      max-height: 0;
      overflow: hidden;
    }
    99% {
      overflow: hidden;
    }
    100% {
      max-height: 100vh;
      overflow: auto;
    }
  }
`;

export const ReferralSpan = styled.span`
  padding-top: 10px;
  font-size: 18px;
  width: 100%;
  word-wrap: break-word;
`;

export const ReferralCode = styled.div`
  color: ${COLORS.deepDark};
  height: 40px;
  display: flex;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;

  #referralCode {
    font-weight: bold;
  }

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 18px;
  }
`;
