export const API = {
  signup: "/owners",
  adminSignIn: "/oauth/token",
  propertiesSearch: "/properties-search",
  properties: "/properties",
  ownerPortalProperties: "/owner-portal/properties",
  ownerPortalAppeals: "/owner-portal/appeals",
  ownerPortalInvoices: "/owner-portal/invoices",
  getOwnersList: "/properties?documents_yn=1",
  sendReferralEmail: "/send-referral-email",
  ownersEmailList: "/owners/autocomplete/email",
  referrals: "/referrals",
  syncProperty: "/sync-property",
  accountLogs: "/account-logs",
  propertyOwner: "/property-owner",
  checkEmail: "/check-user",
  checkCode: "/verify-code",
  getProtestedAccountsList: "/protested-accounts",
  uploadFile: "/upload-file",
  getHearingDatesList: "/hearing-dates",
  notes: "/notes",
  me: "/owner-portal/me",
  ownerPortal: "/owner-portal",
  leads: "/leads",
  documents: "/documents",
  documentsAoa: "/documents/aoa",
  documentsSa: "/documents/sa",
  docs: "/docs",
  sendAoA: "/send-aoa",
  ownerReferrals: "/owner-referrals",
  exportCSV: "/export-csv",
  agentExportCSV: "/agent-export-csv",
  syncProperties: "/sync-properties",
  ownerReferredBy: "/owner-referred-by",
  propertiesSearchAddress: "/properties-search-address",
  getPropertyInfo: "/get-property-info",
  multipleCreate: "/multiple-create",
  archives: "/archives",
  contactAgain: "/contact-again",
  evidences: "/evidences",
  comps: "/comps",
  importAccounts: "/import-accounts",
  importArchiveProperties: "/import-archive-properties",
  getSaveComps: "/get-save-comps",
  leftBarValues: "/left-bar/values",
  saveComps: "/save-comps",
  equities: "/equities",
  sales: "/sales",
  massPrint: "/mass-print",
  print: "/print",
  archiveProperty: "/properties/archive",
  clientEvidence: "/client-evidences",
  changeEvidenceStatus: "/change-evidence-status",
  changeFileStatus: "/change-file-status",
  mergeEvidenceFiles: "/evidence-file-merge",
  changeFileType: "/change-file-type",
  changeFileName: "/change-file-name",
  adminUsers: "/admin/users",
  usersMe: "/users/me",
  addFile: "/add-file",
  getAllPermissions: "/get-all-permissions",
  admin: "/admin",
  aoaDocs: "/aoa-docs",
  saDocs: "/sa-docs",
  verifyAllAoa: "/sync-yes-properties",
  action: "/action",
  actions: "/actions",
  tracker: "/tracker",
  owners: "/owners",
  flags: "/flags",
  property: "/property",
  deleteProperty: "/delete-property",
  addReceiver: "/add-receiver",
  discounts: "/discounts",
  updateSender: "/update-sender",
  completeSignup: "/complete-signup",
  dashboard: "/v2properties",
  updateComment: "/update-comment",
  unarchiveProperty: "/unarchive",
  getFilters: "/get-filters",
  subject: "/subject",
  accountNumber: "/account-number",
  streetAddress: "/street-address",
  file: "/file",
  upcomingHearings: "/upcoming-hearings",
  complete: "/complete",
  getNotes: "/property-notes",
  notifications: "/notifications",
  read: "/read",
  updateEmail: "/update-email",
};
