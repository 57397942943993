import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "assets/images";
import { Icons } from "assets/svgs";
import { PrimaryButton } from "common";
import { Dropdown, Navbar } from "flowbite-react";
import { useScrollTo } from "hooks";
import { routes } from "router/routes";
import { signupSelectors } from "store/signup-slice/selectors";
import { APP_ENV_MODE, COUNTY_LINKS, NAV_LINKS, PTP_LINK } from "utils/constants";
import { getStorage } from "utils/helpers";
import { v4 as uuid } from "uuid";

import * as S from "./styled";

export const Header = () => {
  const { scrollTooErrorMessage } = useSelector(signupSelectors);

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const navigate = useNavigate();

  const isAuthorized = getStorage("owner_token");

  const ref = useRef(null);
  useScrollTo(ref, [scrollTooErrorMessage]);

  const handleSignUpClick = () => {
    navigate(routes.signup);
  };

  const handleSignInClick = () => {
    navigate(routes.signin);
  };

  const handleOpenNavbarClick = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <S.Navbar ref={ref}>
      <Navbar.Brand href={PTP_LINK}>
        <img alt="PTP Logo" className="mr-3 h-6 sm:h-9" src={IMAGES.logo} />
      </Navbar.Brand>
      <S.BurgerIconWrapper className="navbar-open-button" onClick={handleOpenNavbarClick}>
        <Icons.BurgerIcon />
      </S.BurgerIconWrapper>
      <S.NavbarItemsWrapper>
        <S.NavbarItems className={isOpenMenu ? "open" : ""}>
          <S.NavbarLink className="font-bold text-lg" to={NAV_LINKS.home}>
            Home
          </S.NavbarLink>
          <S.Dropdown>
            <Dropdown inline className="z-20" label="Counties">
              {Object.keys(COUNTY_LINKS).map((county) => (
                <Dropdown.Item key={uuid()}>
                  <a href={COUNTY_LINKS[county]}>{county}</a>
                </Dropdown.Item>
              ))}
            </Dropdown>
          </S.Dropdown>
          <S.NavbarLink className="font-bold text-lg" to={NAV_LINKS.blog}>
            Blog
          </S.NavbarLink>
          <S.NavbarLink className="font-bold text-lg" to={NAV_LINKS.faq}>
            FAQ
          </S.NavbarLink>
          <S.NavbarLink className="font-bold text-lg" to={NAV_LINKS.referrals}>
            Referrals
          </S.NavbarLink>
          <S.NavbarLink className="font-bold text-lg" to={NAV_LINKS.contactUs}>
            Contact Us
          </S.NavbarLink>
          <S.ButtonWrapper>
            <PrimaryButton
              eventHandler={handleSignUpClick}
              label="Sign Up"
              styles={{
                padding: "10px 20px",
              }}
            />
            {process.env.REACT_APP_ENV === APP_ENV_MODE.dev ? (
              <PrimaryButton
                eventHandler={handleSignInClick}
                label={isAuthorized ? "My Account" : "Sign In"}
                mode="gray"
                styles={{
                  padding: "10px 20px",
                }}
              />
            ) : null}
          </S.ButtonWrapper>
        </S.NavbarItems>
      </S.NavbarItemsWrapper>
    </S.Navbar>
  );
};
