import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME, TOAST_TYPES } from "utils/constants";
import { toastify } from "utils/helpers";

import { postSubmitEvidenceAction, propertySearch, propertySearchFromDb, uploadEvidenceProperties } from "./actions";

const initialState = {
  submitEvidenceStep: 0,
  propertyId: null,
  searchedProperties: [],
  step1Data: null,
  propertySearchData: null,
  isOpenSuccessModal: false,
  loading: false,
  showBio: false,
  success: false,
  disableConfirmButton: false,
  bioDataChanged: false,
  confirmDetailsClicked: false,
  areEvidenceTypesValid: true,
  submitEvidenceFileError: "",
  propertySearchStatus: LOADING_STATUSES.idle,
  searchedPropertiesStatus: LOADING_STATUSES.idle,
  UploadEvidenceStatus: LOADING_STATUSES.idle,
  postEvidenceStatus: LOADING_STATUSES.idle,
};

export const submitEvidenceSlice = createSlice({
  name: SLICE_NAME.submitEvidenceSlice,
  initialState,
  reducers: {
    setSuccessModalOpen: (state, { payload }) => {
      state.isOpenSuccessModal = payload;
    },
    setShowBio: (state, { payload }) => {
      state.showBio = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSuccess: (state, { payload }) => {
      state.success = payload;
    },
    setDisableConfirmButton: (state, { payload }) => {
      state.disableConfirmButton = payload;
    },
    setBioDataChanged: (state, { payload }) => {
      state.bioDataChanged = payload;
    },
    setConfirmDetailsClicked: (state, { payload }) => {
      state.confirmDetailsClicked = payload;
    },
    setAreEvidenceTypesValid: (state, { payload }) => {
      state.areEvidenceTypesValid = payload;
    },
    backStep: (state) => {
      state.submitEvidenceStep = state.submitEvidenceStep - 1;
    },
    nextStep: (state) => {
      state.submitEvidenceStep = state.submitEvidenceStep + 1;
    },
    setStep1Data: (state, { payload }) => {
      if (payload.county === "Fort Bend") {
        payload.county = "FortBend";
      }
      state.step1Data = payload;
    },

    setPropertyId: (state, { payload }) => {
      state.propertyId = payload;
    },
    addProperty: (state, { payload }) => {
      const showOtherCountyError =
        state.properties.length &&
        !state.properties.some((item) => item.county.toUpperCase() === payload.county.toUpperCase());
      if (state.properties.length === 8) {
        state.propertiesLimitError = "Please sign up with only 8 properties at a time.";
      } else if (showOtherCountyError) {
        state.otherCountyError = "Please sign up with properties for 1 county at a time.";
      } else {
        state.propertiesLimitError = null;
        state.otherCountyError = null;

        let index;
        if (state.properties.length) {
          index = state.properties[state.properties.length - 1].index + 1;
        } else {
          index = 1;
        }
        state.properties.push({ ...payload, index });

        state.propertySearchData = null;
      }
    },
    resetStatus: (state) => {
      state.searchedPropertiesStatus = LOADING_STATUSES.idle;
    },
    setSubmitEvidenceFileError: (state, { payload }) => {
      state.submitEvidenceFileError = payload;
    },
    resetPostEvidenceStatus: (state) => {
      state.postEvidenceStatus = LOADING_STATUSES.idle;
    },
    resetAllData: () => initialState,
  },
  extraReducers: ({ addCase }) => {
    // post submit evidence
    addCase(postSubmitEvidenceAction.pending, (state) => {
      state.postEvidenceStatus = LOADING_STATUSES.pending;
    });
    addCase(postSubmitEvidenceAction.fulfilled, (state, payload) => {
      state.postEvidenceResponse = payload.payload.data;
      state.postEvidenceStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postSubmitEvidenceAction.rejected, (state) => {
      state.postEvidenceStatus = LOADING_STATUSES.failed;
    });

    // property search
    addCase(propertySearch.pending, (state) => {
      state.otherCountyError = null;
      state.propertySearchStatus = LOADING_STATUSES.pending;
      state.propertySearchData = null;
    });
    addCase(propertySearch.fulfilled, (state, { payload }) => {
      state.propertySearchStatus = LOADING_STATUSES.succeeded;
      state.propertySearchData = payload.data;
    });
    addCase(propertySearch.rejected, (state) => {
      state.propertySearchStatus = LOADING_STATUSES.failed;
    });

    // search property in db
    addCase(propertySearchFromDb.pending, (state) => {
      state.searchedPropertiesStatus = LOADING_STATUSES.pending;
    });
    addCase(propertySearchFromDb.fulfilled, (state, { payload }) => {
      state.searchedProperties = payload.data;
      state.searchedPropertiesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(propertySearchFromDb.rejected, (state) => {
      state.searchedPropertiesStatus = LOADING_STATUSES.failed;
    });

    //Upload Evidence
    addCase(uploadEvidenceProperties.pending, (state) => {
      state.UploadEvidenceStatus = LOADING_STATUSES.pending;
    });
    addCase(uploadEvidenceProperties.fulfilled, (state) => {
      toastify(TOAST_TYPES.success, "Accounts successfully imported");
      state.UploadEvidenceStatus = LOADING_STATUSES.succeeded;
    });
    addCase(uploadEvidenceProperties.rejected, (state) => {
      state.UploadEvidenceStatus = LOADING_STATUSES.failed;
    });
  },
});

export const submitEvidenceReducer = submitEvidenceSlice.reducer;

export const submitEvidenceActions = {
  ...submitEvidenceSlice.actions,
  propertySearch,
  propertySearchFromDb,
  uploadEvidenceProperties,
  postSubmitEvidenceAction,
};
