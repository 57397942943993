import { Navigate, Outlet, useLocation } from "react-router-dom";
import { routes } from "router/routes";
import { getStorage } from "utils/helpers";

import { NavigationBar } from "components/Client/NavigationBar";

import * as S from "./styled";

export const ClientLayout = () => {
  const location = useLocation();
  const isAuthorized = getStorage("owner_token");

  if (!isAuthorized) {
    return <Navigate state={{ from: location }} to={routes.signin} />;
  }

  return (
    <S.ClientContainer>
      <NavigationBar />
      <S.Content>
        <S.OutletWrapper>
          <Outlet />
        </S.OutletWrapper>
      </S.Content>
    </S.ClientContainer>
  );
};
