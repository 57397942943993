import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { leadsListApi } from "./services";

export const getLeadsList = createAsyncThunk(
  `${SLICE_NAME.leadsSlice}/getLeadsList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await leadsListApi.getLeads(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const leadsExportCsv = createAsyncThunk(
  `${SLICE_NAME.leadsSlice}/leadsExportCsv`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await leadsListApi.exportCSV(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
