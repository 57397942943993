import { Image, Page, Text, View } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { PDF_ICONS } from "utils/constants";

import { styles } from "./AoAStyles";
import { CheckboxContainer } from "./CheckboxContainer";
import { StepHeader } from "./StepHeader";
import { UnderlineView } from "./UnderlineView";

export const AoAPage2 = ({ signType, signature, firstProp, owner }) => {
  return (
    <Page size="A4" style={styles.document}>
      <View style={styles.docHeader}>
        <Text style={styles.docHeaderText}>Texas Comptroller of Public Accounts</Text>
        <Text style={[styles.docHeaderText, styles.docHeaderText2]}>Form 50-162</Text>
      </View>
      <StepHeader label="STEP 3: Identify the Agent:" />
      <View style={styles.flexRow}>
        <UnderlineView flexGrow label="Name" value="Property Tax Protest" />
        <UnderlineView label="Telephone Number (include area code)" value="866-569-9707" />
      </View>
      <View style={styles.flexRow}>
        <UnderlineView flexGrow label="Address" value="3824 Cedar Springs Road, MB 361" />
      </View>
      <View style={styles.flexRow}>
        <UnderlineView flexGrow label="City, State, Zip Code" value="Dallas, TX 75219" />
      </View>
      <StepHeader label="STEP 4: Specify the Agent’s Authority" />
      <CheckboxContainer
        labels={[
          {
            content: "all property tax matters concerning the property identified",
            checked: true,
          },
          { content: "the following specific property tax matters: " },
        ]}
        title="The agent identi!ed above is authorized to represent me in"
      />
      <Text style={styles.description}>
        The agent identified above is authorized to receive confidential information pursuant to Tax Code Sections
        11.48(b)(2),
      </Text>
      <View style={[styles.flexRow]}>
        <Text style={[styles.description, { flexGrow: 1, alignItems: "flex-end" }]}>
          22.27(b)(2),23.123(c)(2), 23.126(c)(2) and
          23.45(b)(2):.........................................................................................................................
        </Text>
        <CheckboxContainer isHideTitle isRow labels={[{ content: "Yes", checked: true }, { content: "No" }]} />
      </View>
      <Text style={styles.description}>
        I hereby direct, as indicated below, the appraisal district, appraisal review board, and each taxing unit
        participating in the appraisal district to deliver the documents checked below to the agent identified above
        regarding the property identified. I acknowledge that such documents will be delivered only to the agent at the
        agent’s address indicated above and will not be delivered to me unless the affected offices choose to send me
        copies or are otherwise required by law. I understand that these documents can affect my legal rights and that
        the appraisal district, appraisal review board and the taxing units are not required to send me copies if I
        direct them to deliver the documents to my agent.
      </Text>
      <CheckboxContainer
        isHideTitle
        labels={[
          {
            content: "all communications from the chief appraiser",
            checked: true,
          },
          {
            content: "all communications from the appraisal review board",
            checked: true,
          },
          {
            content: "all communications from all taxing units participating in the appraisal district",
          },
        ]}
      />
      <StepHeader
        label={`STEP 5: Date the Agent’s Authority Ends. Pursuant to Tax Code Section 1.111(c), this designation remains in e"ect until the date
          indicated or until a written revocation is !led with the appraisal district by the property owner or the owner’s designated agent.
          A designation may be made to expire according to its own terms but is still subject to prior revocation by the property owner or
          designated agent. Pursuant to Tax Code Section 1.111(d), a property owner may not designate more than one agent to represent
          the property owner in connection with an item of property. The designation of an agent in connection with an item of property
          revokes any previous designation of an agent in connection with that item of property. By designating an agent on this form,
          previous designations of other agents in connection with the items of property shown on the form are revoked.`}
      />
      <View style={[styles.flexRow, { justifyContent: "flex-start" }]}>
        <Text style={styles.description}>Date Agent’s Authority Ends . . . . .</Text>
        <UnderlineView value="N/A" />
      </View>
      <StepHeader label="STEP 6: Identification, Signature, and Date:" />
      <View>
        <View style={styles.flexRow}>
          <Image
            src={PDF_ICONS.sign_here}
            style={{
              height: 20,
              objectFit: "contain",
            }}
          />
          <UnderlineView
            flexGrow
            label={`Signature of Property Owner, Property Manager or Other Person
              Authorized to Act on Behalf of the Property Owner*`}
            signType={signType}
            value={
              signature ? (
                <Image
                  src={signature}
                  style={{
                    height: `${signType === "type" ? 120 : 28}`,
                    objectFit: "contain",
                    maxWidth: 100,
                  }}
                />
              ) : null
            }
            valueType={signature ? "img" : "text"}
          />
          <UnderlineView
            label={`Date
            `}
            value={signature ? <Text style={{ height: 30 }}>{dayjs().format("MM/DD/YYYY")}</Text> : ""}
          />
        </View>
        <View style={styles.flexRow}>
          <Image
            src={PDF_ICONS.print_here}
            style={{
              height: 20,
              objectFit: "contain",
            }}
          />
          <UnderlineView
            flexGrow
            label={`Printed Name of Property Owner, Property Manager or Other Person Title
              Authorized to Act on Behalf of the Property Owner`}
            value={firstProp?.county_owner_name || owner?.full_name}
          />
          <UnderlineView
            label={`Title
            `}
          />
        </View>
      </View>
      <CheckboxContainer
        isBoldTitle
        labels={[
          {
            content: "the property owner",
            checked: true,
          },
          {
            content: "a property manager authorized to designate agents for the owner",
          },
          {
            content:
              "other person authorized to act on behalf of the owner other than the person being designated as agent",
          },
        ]}
        title="The individual signing this form is"
      />
      <View style={[styles.flexRow, { gap: 4, alignItems: "flex-start" }]}>
        <Text style={styles.description}>*</Text>
        <Text style={styles.description}>
          This form must be signed by the property owner, a property manager authorized to designate agents for the
          owner or other person authorized to act on behalf of the owner other than the person being designated as
          agent. If you are a person other than the property owner, the appraisal district may request a copy of the
          document(s) authorizing you to designate agents or act on behalf of the property owner.
        </Text>
      </View>
      <Text style={[styles.description, { marginTop: 4, fontWeight: "bold" }]}>
        If you make a false statement on this form, you could be found guilty of a Class A misdemeanor or a state jail
        felony under Penal Code Section 37.10.
      </Text>
      <View style={[styles.docHeader, { marginVertical: 10 }]}>
        <Text style={[styles.docHeaderText, { fontSize: 7 }]}>
          For more information, visit our website: comptroller.texas.gov/taxes/property-tax
        </Text>
        <Text style={[styles.docHeaderText, styles.docHeaderText2, { fontSize: 7 }]}>Page 2</Text>
      </View>
      <Text style={{ fontSize: 7 }}>50-162 • 12-16/13</Text>
    </Page>
  );
};
