import axios from "axios";
import { API } from "configs/api";

export const singleEvidenceSliceApi = {
  getEvidencesEquities: ({ property_id, ...params }) =>
    axios.get(`${API.comps + API.equities}/${property_id}`, {
      token: true,
      params,
    }),
  getEvidencesSales: ({ property_id, ...params }) =>
    axios.get(`${API.comps}${API.sales}/${property_id}`, {
      token: true,
      params,
    }),
  getSaveComps: ({ propertyId, ...params }) =>
    axios.get(`${API.comps + API.getSaveComps}/${propertyId}`, {
      token: true,
      params,
    }),
  getLeftBarValues: ({ propertyId, ...params }) =>
    axios.get(`${API.comps + API.leftBarValues}/${propertyId}`, {
      token: true,
      params,
    }),
  saveComps: ({ propertyId, ...body }) =>
    axios.post(`${API.comps + API.saveComps}/${propertyId}`, body, {
      token: true,
    }),
  exportCsv: ({ propertyId, ...params }) =>
    axios.get(`${API.comps + API.exportCSV}/${propertyId}`, {
      token: true,
      responseType: "blob",
      params,
    }),
  getCompsFilters: () =>
    axios.get(API.comps + API.getFilters, {
      token: true,
    }),
  getSubjectProperty: (propertyId) =>
    axios.get(`${API.comps + API.subject}/${propertyId}`, {
      token: true,
    }),
};
