export function generateDocSignUrl(data) {
  let pfAddress = "";
  let primaryAddress = [];
  let pfAccountNumber = "";
  let fullURL;
  let params = {};
  // Real powerform
  // let powerform =
  //   "https://www.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=a5a07096-9e90-409b-9e35-0b4390d0fe06&env=na1&acct=158935e4-871b-469b-9096-3024bbcb55cb&v=2&";
  // Demo powerform
  let powerform = process.env.REACT_APP_POWER_FORM;

  let pfListofAddresses = [
    "&Owner_Address1=",
    "&Owner_AddressTwo=",
    "&Owner_AddressThree=",
    "&Owner_AddressFour=",
    "&Owner_AddressFive=",
    "&Owner_AddressSix=",
    "&Owner_AddressSeven=",
    "&Owner_AddressEight=",
  ];

  let lengthProperties = data.properties.length;

  for (let i = 0; i < lengthProperties; i++) {
    if (data.properties[i].is_manually) {
      pfAddress =
        pfAddress +
        pfListofAddresses[i] +
        data.properties[i].street_number +
        " " +
        data.properties[i].street +
        (data.properties[i].unit_id ? " # " + data.properties[i].unit_id : "");
    } else {
      pfAddress = pfAddress + pfListofAddresses[i] + data.properties[i].street_address;
    }

    pfAddress = pfAddress.replace(/#/g, "%23");

    if (data.properties[i].county_owner_address === true) {
      primaryAddress = data.properties[i];
    }
  }

  let pfListofAccountNumbers = [
    "&Owner_AccountNumber=",
    "&Owner_AccountNumberTwo=",
    "&Owner_AccountNumberThree=",
    "&Owner_AccountNumberFour=",
    "&Owner_AccountNumberFive=",
    "&Owner_AccountNumberSix=",
    "&Owner_AccountNumberSeven=",
    "&Owner_AccountNumberEight=",
  ];

  for (let ii = 0; ii < lengthProperties; ii++) {
    pfAccountNumber = pfAccountNumber + pfListofAccountNumbers[ii] + (data.properties[ii].account_number || "");
  }

  // This changes the powerform URL if there needs to be more sheets for more properties
  if (lengthProperties > 4) {
    // Live URL
    // powerform =
    //   "https://www.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=bac560ca-877c-49c6-b2b6-15061a64788a&env=na1&acct=158935e4-871b-469b-9096-3024bbcb55cb&v=2&";
    // Demo URL
    powerform = process.env.REACT_APP_POWER_FORM_2;

    params.Owner_CountyTwo = data.properties[0].county;
  }

  if (primaryAddress.length < 1) {
    primaryAddress = data.properties[0];
  }

  params = {
    ...params,
    Owner_Email: data.email || "",
    Owner_County: data.properties[0].county || "",
    Owner_Name: primaryAddress.county_owner_name || "",
    Owner_Address: primaryAddress.county_owner_address || "",
    Owner_PhoneNumber: data.phone_number || "",
    Owner_PhoneNumber1: data.phone_number || "",
    Owner_CityStateZip:
      primaryAddress.county_owner_city +
      " " +
      primaryAddress.county_owner_state +
      " " +
      primaryAddress.county_owner_zip,
  };

  fullURL = powerform + new URLSearchParams(params).toString() + pfAddress + pfAccountNumber;

  // Just before sign up, send any logs needed. Push to the log list from the respective functions and then iterate through and send here

  // #uncomment

  // "https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=22fa308b-9ddb-4c87-b519-a0fd7f863397&env=demo&acct=bda00511-2db7-4ba7-ba57-d56a08240401&v=2Owner_Email=baghdasarov94%40gmail.com&Owner_County=Dallas&Owner_Name=undefined&Owner_Address=undefined&Owner_PhoneNumber=013-024-1485&Owner_CityStateZip=undefined+undefined+undefined&Owner_PhoneNumber1=013-024-1485&Owner_Address1=1927"
  return fullURL;
}

// /**
//  * @returns {string}
//  */
// export function generateDocSignUrl($this) {
//   let pfAddress = "";
//   let primaryAddress = [];
//   let pfAccountNumber = "";
//   let fullURL;
//   let params = {};
//   // Real powerform
//   // let powerform =
//   //   "https://www.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=a5a07096-9e90-409b-9e35-0b4390d0fe06&env=na1&acct=158935e4-871b-469b-9096-3024bbcb55cb&v=2&";
//   // Demo powerform
//   let powerform = process.env.VUE_APP_POWER_FORM;
//
//   let pfListofAddresses = [
//     "&Owner_Address1=",
//     "&Owner_AddressTwo=",
//     "&Owner_AddressThree=",
//     "&Owner_AddressFour=",
//     "&Owner_AddressFive=",
//     "&Owner_AddressSix=",
//     "&Owner_AddressSeven=",
//     "&Owner_AddressEight="
//   ];
//
//   let lengthProperties = $this.clientsProperties.length;
//
//   for (let i = 0; i < lengthProperties; i++) {
//     pfAddress =
//         pfAddress + pfListofAddresses[i] + $this.clientsProperties[i].address;
//
//     if ($this.clientsProperties[i].primaryAddress === true) {
//       primaryAddress = $this.clientsProperties[i];
//     }
//   }
//
//   let pfListofAccountNumbers = [
//     "&Owner_AccountNumber=",
//     "&Owner_AccountNumberTwo=",
//     "&Owner_AccountNumberThree=",
//     "&Owner_AccountNumberFour=",
//     "&Owner_AccountNumberFive=",
//     "&Owner_AccountNumberSix=",
//     "&Owner_AccountNumberSeven=",
//     "&Owner_AccountNumberEight="
//   ];
//
//   for (let ii = 0; ii < lengthProperties; ii++) {
//     pfAccountNumber =
//         pfAccountNumber +
//         pfListofAccountNumbers[ii] +
//         $this.clientsProperties[ii].accountNumber || "";
//   }
//
//   // This changes the powerform URL if there needs to be more sheets for more properties
//   if (lengthProperties > 4) {
//     // Live URL
//     // powerform =
//     //   "https://www.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=bac560ca-877c-49c6-b2b6-15061a64788a&env=na1&acct=158935e4-871b-469b-9096-3024bbcb55cb&v=2&";
//     // Demo URL
//     powerform = process.env.VUE_APP_POWER_FORM_2;
//
//     params.Owner_CountyTwo = $this.clientsProperties[0].county;
//   }
//
//   if (primaryAddress.length < 1) {
//     primaryAddress = $this.clientsProperties[0];
//   }
//
//   params = {
//     ...params,
//     Owner_Email: $this.email || "",
//     Owner_County: $this.clientsProperties[0].county || "",
//     Owner_Name: primaryAddress.primaryAddressData.countyOwnerName || "",
//     Owner_Address: primaryAddress.primaryAddressData.countyOwnerAddress || "",
//     Owner_PhoneNumber: $this.phoneNumber || "",
//     Owner_PhoneNumber1: $this.phoneNumber || "",
//     Owner_CityStateZip:
//         primaryAddress.primaryAddressData.countyOwnerCity +
//         " " +
//         primaryAddress.primaryAddressData.countyOwnerState +
//         " " +
//         primaryAddress.primaryAddressData.countyOwnerZip
//   };
//
//   fullURL =
//       powerform +
//       new URLSearchParams(params).toString() +
//       pfAddress +
//       pfAccountNumber;
//
//   // Just before sign up, send any logs needed. Push to the log list from the respective functions and then iterate through and send here
//   console.log("Run URL push");
//   // #uncomment
//
//   // "https://demo.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=22fa308b-9ddb-4c87-b519-a0fd7f863397&env=demo&acct=bda00511-2db7-4ba7-ba57-d56a08240401&v=2Owner_Email=baghdasarov94%40gmail.com&Owner_County=Dallas&Owner_Name=undefined&Owner_Address=undefined&Owner_PhoneNumber=013-024-1485&Owner_CityStateZip=undefined+undefined+undefined&Owner_PhoneNumber1=013-024-1485&Owner_Address1=1927"
//   return fullURL;
// }
