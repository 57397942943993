import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";
import { v4 as uuid } from "uuid";

import { exportCSV, getProtestedAccountsList, postUploadFile } from "./actions";

const initialState = {
  getProtestedAccountsListStatus: LOADING_STATUSES.idle,
  protestedAccountsList: [],
  allowScroll: false,
  per_page: 50,
  last_page: 0,
  downloadCsvHref: null,
  paginationData: {
    first: 10,
    rows: 50,
    page: 1,
  },
  postUploadFileStatus: LOADING_STATUSES.idle,
  exportCSVStatus: LOADING_STATUSES.idle,
};

export const protestedAccountsList = createSlice({
  name: SLICE_NAME.protestedAccountsSlice,
  initialState,
  reducers: {
    resetAllData: (state) => ({
      ...initialState,
      paginationData: state.paginationData,
    }),
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    setAllowScroll: (state, { payload }) => {
      state.allowScroll = payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getProtestedAccountsList.pending, (state) => {
      state.getProtestedAccountsListStatus = LOADING_STATUSES.pending;
    });
    addCase(getProtestedAccountsList.fulfilled, (state, { payload }) => {
      state.protestedAccountsList = payload?.data.map((item) => ({
        ...item,
        uniqueId: uuid(),
      }));
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
      state.getProtestedAccountsListStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getProtestedAccountsList.rejected, (state) => {
      state.getProtestedAccountsListStatus = LOADING_STATUSES.failed;
    });

    //   upload file

    addCase(postUploadFile.pending, (state) => {
      state.postUploadFileStatus = LOADING_STATUSES.pending;
    });
    addCase(postUploadFile.fulfilled, (state) => {
      state.postUploadFileStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postUploadFile.rejected, (state) => {
      state.postUploadFileStatus = LOADING_STATUSES.failed;
    });

    // export CSV
    addCase(exportCSV.pending, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCSV.fulfilled, (state, { payload }) => {
      state.downloadCsvHref = payload;
      state.exportCSVStatus = LOADING_STATUSES.succeeded;
    });
    addCase(exportCSV.rejected, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.failed;
    });
  },
});

export const protestedAccountsReducer = protestedAccountsList.reducer;

export const protestedAccountsActions = {
  ...protestedAccountsList.actions,
  exportCSV,
  getProtestedAccountsList,
  postUploadFile,
};
