import { Link } from "react-router-dom";
import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  background-color: ${COLORS.primary};
  display: flex;
  flex-direction: column;
  padding-block: 20px;

  @media screen and (${BREAKPOINTS.md}) {
    height: 100vh;
    min-width: 288px;
    padding-block: 60px;
    box-sizing: border-box;
  }
`;

export const NavContent = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 40px;

  @media screen and (${BREAKPOINTS.md}) {
    display: block;
    justify-content: center;
    margin: unset;
    margin-left: 35px;
  }
`;

export const AppLogo = styled.img`
  height: 40px;
  object-fit: contain;
  filter: invert(100%);

  @media screen and (${BREAKPOINTS.md}) {
    margin-bottom: 60px;
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${COLORS.white};

  svg {
    margin-right: 6px;
  }

  &.logout {
    svg {
      width: 20px;
      path {
        stroke: ${COLORS.white};
        stroke-width: 1.2;
      }
    }
  }
`;

export const NavLinkWrapper = styled.div`
  width: 100%;
  padding-inline: 40px;
  padding-block: 6px;
  display: flex;
  flex-grow: 1;
  justify-content: center;

  &.active {
    background-color: ${COLORS.white_170};
  }

  @media screen and (${BREAKPOINTS.md}) {
    display: flex;
    justify-content: unset;
  }
`;

export const BottomOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainOptions = styled.div`
  flex-grow: 1;
`;

export const BurgerMenuIcon = styled.div`
  border-radius: 10px;
  height: fit-content;
  display: block;

  svg path {
    stroke: white;
  }

  @media screen and (${BREAKPOINTS.md}) {
    display: none;
  }
`;

export const OptionsContent = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  height: 100%;

  &.open {
    max-height: 100vh;
    padding-bottom: 10px;
    transition: max-height 0.5s ease-in;
    overflow: hidden;
  }

  @media screen and (${BREAKPOINTS.md}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100vh;
  }
`;
