import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Icons } from "assets/svgs";
import { FilterMultiSelect, PrimaryButton, SearchInput, Table } from "common";
import { SimpleSelect } from "common/SimpleSelect";
import { useDebounce } from "hooks";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { referralsSelectors } from "store/referral-slice/selectors";
import { referralActions } from "store/referral-slice/slice";
import { isLoading, LOADING_STATUSES, YEARS_FILTER } from "utils/constants";

import { Modal } from "components/Modal";

import { Footer } from "./Footer";
import * as S from "./styled";

export const ReferredTo = () => {
  const {
    referrals,
    singleOwner,
    isAddModalOpen,
    postReferredToStatus,
    isDeleteReferralModalOpen,
    deleteReferredToStatus,
    postReferredToError,
  } = useSelector(singleOwnerSelectors);

  const { referralEmailList, getReferralEmailListStatus } = useSelector(referralsSelectors);

  const { id } = useParams();

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedYear, setSelectedYear] = useState(YEARS_FILTER.map((item) => item.value));
  const [selectedItemId, setSelectedItemId] = useState();
  const [wrongEmail, setWrongEmail] = useState("");

  const dispatch = useDispatch();

  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  const tableData = [
    { header: "Name", field: "full_name" },
    { header: "Email", field: "email" },
    {
      header: "Status",
      body: (item) => <>{item.documents_yn ? <S.Box>Approve</S.Box> : <S.Box className="red">Pending</S.Box>}</>,
    },
    { header: "Code", body: () => singleOwner.referral_code },
    { header: "Date", field: "updated_at" },
    { header: "Percent", body: () => "10%" },
    {
      header: "Remove",
      body: (item) => (
        <S.RemoveContainer>
          <Icons.TrashIcon onClick={() => handleOpenDeleteModal(item)} />
        </S.RemoveContainer>
      ),
    },
  ];

  const handleOpenDeleteModal = (item) => {
    setSelectedItemId(item.id);
    dispatch(singleOwnerActions.setIsDeleteReferralModalOpen(true));
  };

  const handleDeleteReferredTo = () => {
    dispatch(singleOwnerActions.deleteReferredTo(selectedItemId));
  };

  const handleOpenModal = () => {
    dispatch(singleOwnerActions.setIsAddModal(true));
    dispatch(referralActions.clearReferralEmailList());
    dispatch(singleOwnerActions.setIsAddModal(true));
  };

  const handleCloseModal = () => {
    dispatch(singleOwnerActions.setIsAddModal(false));
    dispatch(referralActions.clearReferralEmailList());
    dispatch(singleOwnerActions.resetReferredStatuses());
    setSearchQuery("");
  };

  const onSearch = (searchResult) => {
    dispatch(singleOwnerActions.resetReferredStatuses());
    dispatch(referralActions.clearReferralEmailList());
    setSearchQuery(searchResult);
    if (!searchQuery) {
      dispatch(singleOwnerActions.resetReferredStatuses());
    }
  };

  const handleSelectEmail = (value) => {
    setWrongEmail(value);
    dispatch(singleOwnerActions.postReferredTo({ id, email: value }));
    dispatch(referralActions.clearReferralEmailList());
  };

  const deSelectCounties = () => {
    if (selectedYear?.length > 0) {
      setSelectedYear([]);
    } else {
      setSelectedYear(YEARS_FILTER.map((item) => item.value));
    }
  };

  useEffect(() => {
    const handleSuccess = (actionType, modalAction) => {
      if (actionType === LOADING_STATUSES.succeeded) {
        dispatch(modalAction(false));
        if (actionType === postReferredToStatus) {
          dispatch(referralActions.clearReferralEmailList());
          setSearchQuery("");
        }
      }
    };

    handleSuccess(postReferredToStatus, singleOwnerActions.setIsAddModal);
    handleSuccess(deleteReferredToStatus, singleOwnerActions.setIsDeleteReferralModalOpen);
  }, [postReferredToStatus, deleteReferredToStatus]);

  useEffect(() => {
    if (debouncedSearchQuery) {
      dispatch(
        referralActions.getReferralEmailList({
          email: debouncedSearchQuery,
        })
      );
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (postReferredToStatus === LOADING_STATUSES.failed) {
      setSearchQuery(wrongEmail);
    }
  }, [postReferredToStatus]);

  return (
    <S.Container>
      <S.Content>
        <S.FilterBar>
          <S.ContentTitle>Referred to</S.ContentTitle>
          <S.FilterButtons>
            <PrimaryButton eventHandler={handleOpenModal} label="Add" mode="gray" />
            <FilterMultiSelect
              buttonLabel="YEAR FILTER"
              options={YEARS_FILTER}
              selectedOptions={selectedYear}
              setSelectedOptions={setSelectedYear}
            />
            <PrimaryButton
              label={(selectedYear.length ? "DE" : "") + "SELECT ALL YEARS"}
              mode="gray"
              onClick={deSelectCounties}
            />
          </S.FilterButtons>
        </S.FilterBar>
        <Modal isModalOpen={isAddModalOpen}>
          <SearchInput
            className="search-input"
            error={postReferredToError}
            placeholder="Search Email"
            value={searchQuery}
            onSearch={onSearch}
          />
          {!postReferredToError ? (
            <SimpleSelect
              handleSelect={handleSelectEmail}
              items={referralEmailList}
              loading={isLoading(getReferralEmailListStatus)}
              searchQuery={searchQuery}
            />
          ) : null}
          {!referralEmailList.length && getReferralEmailListStatus === LOADING_STATUSES.succeeded ? (
            <S.NoResult>No results found</S.NoResult>
          ) : null}
          <S.ButtonsContainer>
            <PrimaryButton eventHandler={handleCloseModal} label="Cancel" mode="gray" />
          </S.ButtonsContainer>
        </Modal>
        <Modal
          cancelButtonText="no"
          className="delete-modal"
          description="Are you sure ?"
          isModalOpen={isDeleteReferralModalOpen}
          successButtonText="yes"
          successLoadingStatus={isLoading(deleteReferredToStatus)}
          onCancel={() => dispatch(singleOwnerActions.setIsDeleteReferralModalOpen(false))}
          onSuccess={handleDeleteReferredTo}
        />
        <Table
          dataKey="id"
          footer={
            <Footer
              referralsLength={
                referrals?.filter((item) => selectedYear.some((year) => item?.updated_at?.includes(year))).length
              }
            />
          }
          loading={isLoading(postReferredToStatus)}
          tableData={tableData}
          value={referrals?.filter((item) => selectedYear.some((year) => item?.updated_at?.includes(year)))}
        />
      </S.Content>
    </S.Container>
  );
};
