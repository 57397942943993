import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { ownerTrackerApi } from "./services";

export const getOwnerTrackerList = createAsyncThunk(
  `${SLICE_NAME.ownerTrackerSlice}/getOwnerTrackerList`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await ownerTrackerApi.getOwnerTracker(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCSV = createAsyncThunk(
  `${SLICE_NAME.ownerTrackerSlice}/exportCSV`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await ownerTrackerApi.exportCSV(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
