import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Icons } from "assets/svgs";
import { PrimaryButton, Select } from "common";
import { singleClientEvidenceSelector } from "store/admin-slices/single-client-evidence-slice/selectors";
import { singleClientEvidenceActions } from "store/admin-slices/single-client-evidence-slice/slice";
import { submitEvidenceSelectors } from "store/submit-evidence-slice/selectors";
import { submitEvidenceActions } from "store/submit-evidence-slice/slice";
import { EVIDENCE_TYPES_ITEMS, isLoading, LOADING_STATUSES } from "utils/constants";
import { getStorage } from "utils/helpers";

import * as S from "./styled";

export const UploadClientEvidenceFile = () => {
  const { postAddFileStatus, selectedOptions, fileName, isFileSelected, isModalOpen } =
    useSelector(singleClientEvidenceSelector);
  const { postEvidenceStatus } = useSelector(submitEvidenceSelectors);

  const [selectedFile, setSelectedFile] = useState(null);
  const propertyInfo = getStorage("propertyInfo");

  const pdfInputRef = useRef();

  const { id } = useParams();

  const dispatch = useDispatch();

  const onSelectChange = (value) =>
    dispatch(
      singleClientEvidenceActions.setSelectedOptions(EVIDENCE_TYPES_ITEMS.find((item) => item.label === value).value)
    );

  const handleSelectPdf = () => pdfInputRef.current?.click();

  const reset = () => {
    dispatch(singleClientEvidenceActions.setSelectedOptions(null));
    setSelectedFile(null);
    dispatch(singleClientEvidenceActions.setFileName(""));
    dispatch(singleClientEvidenceActions.setIsFileSelected(false));
  };

  const handlePdfChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setSelectedFile(null);
      dispatch(singleClientEvidenceActions.setIsFileSelected(false));
      return;
    }
    dispatch(singleClientEvidenceActions.setFileName(file.name));
    setSelectedFile(file);
    dispatch(singleClientEvidenceActions.setIsFileSelected(true));
  };

  const deleteFile = () => {
    setSelectedFile(null);
    dispatch(singleClientEvidenceActions.setFileName(""));
    dispatch(singleClientEvidenceActions.setIsFileSelected(false));
  };

  const closeModal = () => {
    dispatch(singleClientEvidenceActions.setIsModalOpen(false));
    reset();
  };

  const handleConfirm = () => {
    const formData = new FormData();

    if (window.location.href.includes("create")) {
      formData.append("files[0][file]", selectedFile);
      if (selectedOptions) {
        formData.append("files[0][evidence_type]", selectedOptions);
      }
      const [firstName, ...lastNameParts] = propertyInfo.full_name.split(" ");
      const lastName = lastNameParts.join(" ");

      formData.append("property_id", propertyInfo.property_id);
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("address", propertyInfo.street_address);
      formData.append("client_email", propertyInfo.email);
      formData.append("county", propertyInfo.county);
      formData.append("description", "manually uploaded");
      dispatch(submitEvidenceActions.postSubmitEvidenceAction(formData));
    } else {
      formData.append("file", selectedFile);
      if (selectedOptions) {
        formData.append("evidence_type", selectedOptions);
      }
      dispatch(singleClientEvidenceActions.postAddFile({ id, body: formData }));
    }
  };

  useEffect(() => {
    if (postAddFileStatus === LOADING_STATUSES.succeeded || postEvidenceStatus === LOADING_STATUSES.succeeded) {
      dispatch(singleClientEvidenceActions.setIsModalOpen(false));
      reset();
      dispatch(submitEvidenceActions.setSubmitEvidenceFileError(""));
      dispatch(submitEvidenceActions.resetPostEvidenceStatus());
    }
  }, [postAddFileStatus, postEvidenceStatus]);

  useEffect(() => {
    if (postAddFileStatus === LOADING_STATUSES.failed || postEvidenceStatus === LOADING_STATUSES.failed) {
      if (!selectedFile || !selectedOptions) {
        if (!selectedFile) {
          toast.error("Please select a file");
        }
        if (!selectedOptions) {
          toast.error("Please select a file type");
        }
      } else {
        if (selectedFile?.size > 16 * 1024 * 1024) {
          toast.error("The file is larger than 15mb");
        }
      }
    }
  }, [postAddFileStatus, postEvidenceStatus]);

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(submitEvidenceActions.setSubmitEvidenceFileError(""));
    }
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && (
        <S.PdfModalContainer>
          <S.PdfModalContent>
            <Icons.CloseIcon className="close-button" onClick={closeModal} />
            <Select itemKey="label" items={EVIDENCE_TYPES_ITEMS} label="Select file type" onChange={onSelectChange} />
            <S.PdfUploadButton>
              <S.PdfInput ref={pdfInputRef} accept=".pdf" type="file" onChange={handlePdfChange} />
              <PrimaryButton eventHandler={handleSelectPdf} label="upload file" mode="gray" styles={{ width: 200 }} />
            </S.PdfUploadButton>
            {isFileSelected && selectedFile ? (
              <S.File>
                <S.FileName>{fileName}</S.FileName>
                <Icons.CloseIcon className="delete-file" onClick={deleteFile} />
              </S.File>
            ) : null}
            <PrimaryButton
              eventHandler={handleConfirm}
              label="Confirm"
              loading={isLoading(postAddFileStatus) || isLoading(postEvidenceStatus)}
            />
          </S.PdfModalContent>
        </S.PdfModalContainer>
      )}
    </>
  );
};
