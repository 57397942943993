import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Icons } from "assets/svgs";
import { PrimaryButton, Select } from "common";
import { COUNTIES_FILTER, isLoading, LOADING_STATUSES } from "utils/constants";

import * as S from "./styled";

export const UploadFileModal = ({ isOpenModal, setIsModalOpen, uploadFile, status }) => {
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isFileSelected, setIsFileSelected] = useState(false);

  const fileInputRef = useRef();
  const onSelectChange = (value) =>
    setSelectedOptions(COUNTIES_FILTER.find((item) => item.description === value).value);

  const handleSelectFile = () => fileInputRef.current?.click();

  const reset = () => {
    setSelectedOptions(null);
    setSelectedFile(null);
    setFileName("");
    setIsFileSelected(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setSelectedFile(null);
      setIsFileSelected(false);
      return;
    }
    setFileName(file.name);
    setSelectedFile(file);
    setIsFileSelected(true);
  };

  const deleteFile = () => {
    setSelectedFile(null);
    setFileName("");
    setIsFileSelected(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    reset();
  };

  const handleConfirm = () => {
    const body = new FormData();
    if (selectedOptions) {
      body.append("county", selectedOptions);
    }
    if (selectedOptions !== "Collin" && selectedOptions !== "Williamson") {
      body.append("file", selectedFile);
    }
    uploadFile(body);
  };

  useEffect(() => {
    if (status === LOADING_STATUSES.succeeded) {
      setIsModalOpen(false);
      reset();
    }
  }, [status]);

  useEffect(() => {
    if (status === LOADING_STATUSES.failed) {
      if (!selectedFile) {
        toast.error("Please select a file");
      }

      if (!selectedOptions) {
        toast.error("Please select a file type");
      }
    }
  }, [status]);

  return (
    <>
      {isOpenModal && (
        <S.ModalContainer>
          <S.ModalContent>
            <Icons.CloseIcon className="close-button" onClick={closeModal} />
            <Select itemKey="description" items={COUNTIES_FILTER} label="Select county" onChange={onSelectChange} />
            {selectedOptions === "Collin" || selectedOptions === "Williamson" ? null : (
              <S.UploadButton>
                <S.FileInput ref={fileInputRef} accept=".xlx, .xls, .xlsx" type="file" onChange={handleFileChange} />
                <PrimaryButton
                  eventHandler={handleSelectFile}
                  label="upload file"
                  mode="gray"
                  styles={{ width: 200 }}
                />
              </S.UploadButton>
            )}
            {isFileSelected && selectedFile ? (
              <S.File>
                <S.FileName>{fileName}</S.FileName>
                <Icons.CloseIcon className="delete-file" onClick={deleteFile} />
              </S.File>
            ) : null}
            <PrimaryButton eventHandler={handleConfirm} label="Confirm" loading={isLoading(status)} />
          </S.ModalContent>
        </S.ModalContainer>
      )}
    </>
  );
};
