import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const HearingDatesContainer = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .green {
    color: ${COLORS.green};
  }

  .red {
    color: ${COLORS.red};
  }
`;

export const HearingDatesListTitle = styled.div`
  font-size: 24px;
  color: ${COLORS.deepDark};
  margin: 10px;
`;

export const PaginatorWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
  justify-content: center;
`;

export const Box = styled.div`
  &.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Comments = styled.div`
  &.flex-center {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    flex-wrap: wrap;
  }

  &.reason-content {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .edit-icon {
    height: 18px;
  }
`;

export const Form = styled.form`
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;

  textarea {
    height: 300px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const CountryAuth = styled.div``;

export const OwnerStatus = styled.div``;

export const Text = styled.span``;

export const TextRed = styled.span`
  color: ${COLORS.red};
`;

export const CountriesCount = styled.div`
  width: 600px;
  min-height: 60px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ReloadAndCountriesSection = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Status = styled.div`
  button {
    padding: 6px 8px;
    font-size: 12px;
    white-space: nowrap;
  }
`;
