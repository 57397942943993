import { COLORS } from "assets/styles/colors";
import { SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components/macro";

export const Container = styled.div``;

export const Content = styled(SinglePageContentShadow)`
  min-width: 400px;
  height: 100%;
`;

export const ClientData = styled.div`
  margin-block: 16px;
  display: flex;
  width: max-content;
  align-items: center;
`;

export const EmailText = styled.span`
  margin-left: 8px;
  margin-right: 8px;
`;

export const EmailIconContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 10px;

  .email-input {
    margin-bottom: 1rem;
    margin-left: 6px;
  }

  svg {
    cursor: pointer;
    height: 18px;
    fill: ${COLORS.dimgrey};
  }
`;
