import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: calc(100vh - 70px);
  min-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 10px;

  .back-button {
    margin-bottom: 20px;
  }

  .delete-button {
    margin-top: 20px;
  }

  .delete-modal {
    color: ${COLORS.red};
  }
`;

export const LoaderBox = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
`;
