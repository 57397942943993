import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icons } from "assets/svgs";
import clsx from "clsx";
import { PrimaryButton, PrimaryTextarea, Select } from "common";
import { submitEvidenceSelectors } from "store/submit-evidence-slice/selectors";
import { submitEvidenceActions } from "store/submit-evidence-slice/slice";
import { EVIDENCE_TYPES_ITEMS, isLoading, LOADING_STATUSES, PTP_CONTACT_US_LINK } from "utils/constants";
import { toggleClassOnRoot } from "utils/helpers";
import { v4 as uuid } from "uuid";
import { submitEvidenceStep2Schema } from "validations";

import * as S from "./styled";
import { SuccessModal } from "./SuccessModal";

const Step2 = () => {
  const {
    postEvidenceStatus,
    confirmDetailsClicked,
    areEvidenceTypesValid,
    step1Data,
    isOpenSuccessModal,
    propertyId,
  } = useSelector(submitEvidenceSelectors);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const dispatch = useDispatch();

  const {
    register,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(submitEvidenceStep2Schema),
  });

  const navigateContactUs = () => {
    window.open(PTP_CONTACT_US_LINK, "_blank");
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    dispatch(submitEvidenceActions.backStep());
  };

  const handleEvidenceTypeChange = (label, fileItemId) => {
    const updatedEvidenceTypes = uploadedFiles.map((item) => {
      if (item.id === fileItemId) {
        return { id: item.id, file: item.file, evidence_type: label };
      }
      return item;
    });
    setUploadedFiles(updatedEvidenceTypes);
  };

  const handleFileDelete = (id) => {
    const updatedFiles = [...uploadedFiles];
    const newFiles = updatedFiles.filter((item) => item.id !== id);
    setUploadedFiles(newFiles);
    const filesObject = {};
    newFiles.forEach((item, index) => {
      filesObject[index] = item.file;
    });
    setValue("files", filesObject);
    const fileInput = document.getElementById("file-upload");
    fileInput.value = null;
  };

  const setFiles = (files) => {
    if (Object.keys(files)?.length) {
      Object.keys(files)?.forEach((key) => {
        setUploadedFiles((prevState) => [
          ...prevState,
          { id: uuid(), evidence_type: "", error: { message: "" }, file: files[`${key}`] },
        ]);
      });
    }
  };

  const onSubmit = (data) => {
    const formData = new FormData();

    for (const key in step1Data) {
      formData.append(key, step1Data[key]);
    }
    formData.append("description", data.description);
    formData.append("property_id", propertyId);
    uploadedFiles.forEach((item, index) => {
      formData.append(`files[${index}][evidence_type]`, item.evidence_type);
    });
    uploadedFiles.forEach((item, index) => {
      formData.append(`files[${index}][file]`, item.file);
    });

    dispatch(submitEvidenceActions.postSubmitEvidenceAction(formData));
  };

  useEffect(() => {
    if (postEvidenceStatus === LOADING_STATUSES.succeeded) {
      toggleClassOnRoot();
      reset();
      dispatch(submitEvidenceActions.setSuccessModalOpen(true));
    }
  }, [postEvidenceStatus]);

  useEffect(() => {
    if (uploadedFiles.length) {
      const isEvidenceTypesValid = uploadedFiles.every((file) => file.evidence_type);

      const sizeCheckedFiles = uploadedFiles.map((file) => {
        // TODO: move to constant and replace with validation check in schema
        // 12mb
        if (file.file.size > 15 * 1024 * 1024) {
          return { ...file, isTooLarge: true };
        }
        return file;
      });

      if (JSON.stringify(sizeCheckedFiles) !== JSON.stringify(uploadedFiles)) {
        setUploadedFiles(sizeCheckedFiles);
      }

      dispatch(submitEvidenceActions.setAreEvidenceTypesValid(isEvidenceTypesValid));
    } else {
      dispatch(submitEvidenceActions.setAreEvidenceTypesValid(false));
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (postEvidenceStatus === LOADING_STATUSES.failed) {
      toast.error("The file content is too large");
    }
  }, [postEvidenceStatus]);

  return (
    <S.SubmitEvidenceForm onSubmit={handleSubmit(onSubmit)}>
      <PrimaryTextarea error={errors.description} label="Message*" register={register("description")} />
      <S.UploadSection>
        <S.ChooseEvidenceInfo>
          Upload your evidence (max size 15 MB per file. Supported file formats : pdf, xlsx, png, jpeg, jpg, doc, docx,
          csv, txt )
        </S.ChooseEvidenceInfo>
        <S.FileUploadButton htmlFor="file-upload">
          <S.UploadButton className={errors.files ? "invalid" : ""}>
            <Icons.UploadIcon />
            UPLOAD FILE
            {errors.files ? <S.FileErrorMessage>The field is required</S.FileErrorMessage> : null}
          </S.UploadButton>
        </S.FileUploadButton>
        <Controller
          control={control}
          name="files"
          render={({ field: { onChange, onBlur, ref }, fieldState: { error } }) => (
            <S.FileInput
              ref={ref}
              multiple
              accept=".pdf, .xlsx, .png, .jpeg, .jpg, .doc, .docx, .csv, .txt"
              error={error}
              id="file-upload"
              type="file"
              onBlur={onBlur}
              onChange={(e) => {
                if (e.target.files[0]) {
                  onChange(e.target.files);
                  setFiles(e.target.files);
                }
              }}
            />
          )}
        />
      </S.UploadSection>
      <S.EvidenceFiles>
        <S.ChooseEvidenceInfo>Choose evidence type for each file</S.ChooseEvidenceInfo>
        {uploadedFiles
          ? uploadedFiles.map((fileItem) => (
              <S.UploadedFiles key={fileItem.id}>
                <Select
                  className="evidence-type w-full"
                  error={fileItem?.error}
                  itemKey="label"
                  items={EVIDENCE_TYPES_ITEMS}
                  label="Evidence Type*"
                  value={fileItem.evidence_type}
                  onChange={(selectedLabel) => handleEvidenceTypeChange(selectedLabel, fileItem.id)}
                />
                <div className="flex flex-col items-center w-full">
                  <S.File>
                    <S.FileText>
                      {fileItem.file.name.length > 30
                        ? `${fileItem.file.name.substring(0, 30)}...`
                        : fileItem.file.name}
                    </S.FileText>
                    <Icons.CloseIcon className="close-button" onClick={() => handleFileDelete(fileItem.id)} />
                  </S.File>
                  <div
                    className={clsx("text-red-500 text-xs mt-1 text-center", {
                      hidden: !fileItem.isTooLarge,
                    })}
                  >
                    The file is larger than 15MB. You can send this file to:
                    <a
                      className="text-[#437f55] pl-1"
                      href="mailto:info@propertytaxprotest.com"
                      rel="noreferrer"
                      target="_blank"
                    >
                      info@propertytaxprotest.com
                    </a>
                    .
                  </div>
                </div>
              </S.UploadedFiles>
            ))
          : null}
      </S.EvidenceFiles>
      <S.ButtonsWrapper>
        <PrimaryButton eventHandler={handleBackClick} label="back" mode="gray" />
        <PrimaryButton
          className="submit-button"
          disabled={
            errors.description ||
            !areEvidenceTypesValid ||
            !confirmDetailsClicked ||
            uploadedFiles?.some((item) => item?.isTooLarge)
          }
          label="Submit Evidence"
          loading={isLoading(postEvidenceStatus)}
          type="submit"
        />
      </S.ButtonsWrapper>
      <S.ContactUsText>
        Have questions or trouble while submitting evidence?
        <S.ContactUs onClick={navigateContactUs}> Contact Us</S.ContactUs>
      </S.ContactUsText>
      {isOpenSuccessModal && <SuccessModal setUploadedFiles={setUploadedFiles} />}
    </S.SubmitEvidenceForm>
  );
};

export default Step2;
