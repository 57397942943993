import { NavLink } from "react-router-dom";

import * as S from "./styled";

export const AdminHomePage = () => {
  return (
    <S.HomeContainer>
      <S.HomeTitle>Welcome, this is for Property Tax Protest Staff.</S.HomeTitle>
      <S.HomeDescription>Please return to the</S.HomeDescription>
      <NavLink to="/">webSite</NavLink>
    </S.HomeContainer>
  );
};
