import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { evidencesSliceApi } from "./services";

export const getEvidences = createAsyncThunk(
  `${SLICE_NAME.evidenceSlice}/getEvidences`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await evidencesSliceApi.getEvidences(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCsv = createAsyncThunk(
  `${SLICE_NAME.evidenceSlice}/exportCsv`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await evidencesSliceApi.exportCsv(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const importAccounts = createAsyncThunk(
  `${SLICE_NAME.evidenceSlice}/importAccounts`,
  async (file, { rejectWithValue }) => {
    try {
      const response = await evidencesSliceApi.importAccounts(file);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const massPrint = createAsyncThunk(
  `${SLICE_NAME.evidenceSlice}/massPrint`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await evidencesSliceApi.massPrint(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const printProperty = createAsyncThunk(
  `${SLICE_NAME.evidenceSlice}/printProperty`,
  async (propertyId, { rejectWithValue }) => {
    try {
      const response = await evidencesSliceApi.printProperty(propertyId);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
