import { Outlet } from "react-router-dom";

import { Footer } from "components/Footer";
import { Header } from "components/Header";

import * as S from "./styled";

export const UserLayout = () => {
  return (
    <>
      <Header />
      <S.UserLayoutContent>
        <Outlet />
      </S.UserLayoutContent>
      <Footer />
    </>
  );
};
