import { NavLink } from "react-router-dom";
import { Tooltip, Typography } from "@material-tailwind/react";
import { Icons } from "assets/svgs";
import { PrimaryButton, PrimaryInput, Select } from "common";
import { Dropdown } from "primereact/dropdown";
import { adminRoutes } from "router/routes";
import { EVIDENCE_TYPES_ITEMS, isLoading, LOADING_STATUSES } from "utils/constants";
import { generateAddressLink, getStatusColor } from "utils/helpers";

import {
  ApprovedSelectContainer,
  ButtonContainer,
  ColumnContent,
  ColumnText,
  DescriptionContainer,
  FileTypesSelect,
  Link,
  TooltipContent,
} from "./styled";

const FlagsColumn = (item) => (
  <ColumnContent>
    {item.account_logs === 0 ? (
      <Icons.LikeIcon />
    ) : (
      <ColumnText className="text-red-600 text-xl">{item.account_logs}</ColumnText>
    )}
  </ColumnContent>
);

const FlagsColumnLeads = (item) => (
  <ColumnContent>
    {item.account_logs === 0 ? (
      <Icons.LikeIcon />
    ) : (
      <ColumnText className="text-red-600 text-xl">{item.account_logs}</ColumnText>
    )}
  </ColumnContent>
);

const SignUpStep = (item) => <ColumnText className="text-red-600 text-xl">{item.sign_up_step_number}</ColumnText>;

const ReferralColumn = (item) => (
  <ColumnContent>
    {item.referral_code ? (
      <ColumnText className="text-green-600">{item.referral_code}</ColumnText>
    ) : (
      <ColumnText className="text-red-600 text-base">NO</ColumnText>
    )}
  </ColumnContent>
);

const HearingDatesColumn = (item) => (
  <ColumnContent>
    {item.hearing_dates === "YES" ? (
      <ColumnText className="text-green-600">{item.hearing_dates}</ColumnText>
    ) : (
      <ColumnText className="text-red-600 text-base">NO</ColumnText>
    )}
  </ColumnContent>
);

const ItemStreetAddress = (item, selectSyncProperty) => (
  <ColumnContent>
    <a
      className="mx-3 text-green-700 text-base underline"
      href={generateAddressLink(item)}
      rel="noreferrer"
      target="_blank"
      onClick={() => selectSyncProperty(item)}
    >
      {item.street_address}
    </a>
  </ColumnContent>
);

const AuthColumn = (item) => (
  <ColumnText className={item?.authed_or_not.toLowerCase() === "yes" ? "green" : "red"}>
    {item?.authed_or_not.toUpperCase()}
  </ColumnText>
);

const EditButton = (item) => (
  <NavLink to={`${adminRoutes.prefix}/${adminRoutes.owners}/${item.owner_id}/${item.property_id}`}>
    <Icons.EditIcon />
  </NavLink>
);

const ClientEvidenceButton = () => {
  return (
    <NavLink>
      <Icons.ClientEvidenceIcon />
    </NavLink>
  );
};

const SendNoSend = (item) => (
  <ColumnContent>
    {item.sent_or_not !== "NOT SENT" ? (
      <ColumnText className="text-green-500 text-base">{item?.sent_or_not}</ColumnText>
    ) : (
      <ColumnText className="text-red-500 text-base">Not Sent</ColumnText>
    )}
  </ColumnContent>
);

const VerifyAoa = (item, onClick, syncPropertyId) => (
  <ColumnContent className="flex justify-center	align-center">
    <PrimaryButton
      eventHandler={() => onClick(item)}
      label="Verify AoA"
      loading={syncPropertyId === item.property_id}
      styles={{
        padding: "6px 8px",
        fontSize: 12,
        whiteSpace: "nowrap",
      }}
    />
  </ColumnContent>
);

const DocumentsName = (item, activeId, register, errors) => {
  return (
    <>
      {item?.id === activeId ? (
        <PrimaryInput
          error={errors.document}
          register={register("document")}
          style={{
            fontSize: 14,
            textAlign: "center",
          }}
          type="text"
        />
      ) : (
        <Link href={item?.preview_url} target="_blank">
          {item?.filename ?? item?.download_url}
        </Link>
      )}
    </>
  );
};

const PaginationTemplate = {
  layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
  RowsPerPageDropdown: (options) => {
    const dropdownOptions = [
      { label: 50, value: 50 },
      { label: 100, value: 100 },
      { label: 200, value: 200 },
      { label: "All", value: "" },
    ];

    return (
      <>
        <span className="mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}>
          Items per page:{" "}
        </span>
        <Dropdown options={dropdownOptions} value={options.value} onChange={options.onChange} />
      </>
    );
  },
  CurrentPageReport: (options) => {
    return (
      <span
        style={{
          color: "var(--text-color)",
          userSelect: "none",
          width: "120px",
          textAlign: "center",
        }}
      >
        {options.first} - {options.last} of {options.totalRecords}
      </span>
    );
  },
};

const EvidenceStatus = (item) => (
  <ButtonContainer>
    <PrimaryButton
      label={item.comparison_status}
      mode={getStatusColor(item.comparison_status)}
      styles={{ textTransform: "capitalize", fontSize: "14px" }}
    />
  </ButtonContainer>
);

const PrintProperty = (item, printPropertyStatus, handlePrintClick, propertyId) => (
  <ButtonContainer>
    <PrimaryButton
      eventHandler={() => handlePrintClick(item.property_id, item)}
      label="Print Packet"
      loading={isLoading(printPropertyStatus) && item.property_id === propertyId}
      mode="purple"
      styles={{ fontSize: "14px", whiteSpace: "nowrap" }}
    />
  </ButtonContainer>
);

const DescriptionTemplate = (description, heading) => (
  <>
    {description ? (
      <Tooltip
        className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/30"
        content={
          <TooltipContent>
            <Typography className="font-medium" color="blue-gray">
              {heading}
            </Typography>

            <Typography className="font-normal opacity-80" color="blue-gray" variant="small">
              {description}
            </Typography>
          </TooltipContent>
        }
        placement="bottom"
      >
        <DescriptionContainer>
          {description?.length > 30 ? description?.slice(0, 30) + "..." : description}
        </DescriptionContainer>
      </Tooltip>
    ) : null}
  </>
);

const ReviewedStatus = (status) => (
  <ButtonContainer>
    <PrimaryButton
      label={status}
      mode={status === "Reviewed" ? "green" : "gray"}
      styles={{ textTransform: "capitalize", fontSize: "14px", whiteSpace: "nowrap" }}
    />
  </ButtonContainer>
);

const MergeStatus = (is_merged) => (
  <ButtonContainer>
    <PrimaryButton
      label={is_merged === 1 ? "Merged" : "Not Merged"}
      mode={is_merged === 1 ? "green" : "gray"}
      styles={{ textTransform: "capitalize", fontSize: "14px", whiteSpace: "nowrap" }}
    />
  </ButtonContainer>
);
const MergeEvidenceFiles = (id, handleMergeEvidenceFiles, mergeEvidenceFilesStatus, evidenceId) => (
  <ButtonContainer>
    <PrimaryButton
      eventHandler={() => handleMergeEvidenceFiles(id)}
      label="Merge"
      loading={isLoading(mergeEvidenceFilesStatus) && id === evidenceId}
      styles={{ fontSize: "14px", whiteSpace: "nowrap" }}
    />
  </ButtonContainer>
);

const ApprovedOrNotFiles = (item, approvedSelectItems, handleApprovedChange) => {
  const selectedLabel =
    item.status !== null ? approvedSelectItems.find((selectItem) => selectItem.value === item.status).label : "";
  return (
    <ApprovedSelectContainer>
      <Select
        className={`select ${item.status ? "color-green" : ""}`}
        containerProps={{
          style: {
            position: "static",
            minWidth: "unset",
            width: 80,
          },
        }}
        itemKey="label"
        items={approvedSelectItems}
        labelProps={{ style: { display: "none" } }}
        value={selectedLabel}
        onChange={(selectedLabel) => handleApprovedChange(item.id, selectedLabel)}
      />
    </ApprovedSelectContainer>
  );
};

const EditClientEvidenceFiles = (item, selectedId, register, errors) => (
  <>
    {item?.id === selectedId ? (
      <PrimaryInput
        error={errors.document}
        register={register("document")}
        style={{
          fontSize: 14,
          textAlign: "center",
        }}
        type="text"
      />
    ) : (
      <Link href={item?.path} target="_blank">
        {item?.name}
      </Link>
    )}
  </>
);

const FileTypeSelect = (item, selectedTypeId, handleFileTypeChange, postChangeFileTypeStatus) => (
  <FileTypesSelect>
    <Select
      className="select"
      containerProps={{
        style: {
          position: "static",
        },
      }}
      error={postChangeFileTypeStatus === LOADING_STATUSES.failed && item.id === selectedTypeId}
      itemKey="label"
      items={EVIDENCE_TYPES_ITEMS}
      labelProps={{ style: { display: "none" } }}
      value={item.type}
      onChange={(newValue) => handleFileTypeChange(item.id, newValue)}
    />
  </FileTypesSelect>
);
export const Template = {
  FlagsColumn,
  ReferralColumn,
  ItemStreetAddress,
  AuthColumn,
  EditButton,
  SendNoSend,
  VerifyAoa,
  FlagsColumnLeads,
  SignUpStep,
  PaginationTemplate,
  DocumentsName,
  EvidenceStatus,
  PrintProperty,
  DescriptionTemplate,
  ReviewedStatus,
  MergeStatus,
  MergeEvidenceFiles,
  ApprovedOrNotFiles,
  EditClientEvidenceFiles,
  FileTypeSelect,
  ClientEvidenceButton,
  HearingDatesColumn,
};
