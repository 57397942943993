import { Icons } from "assets/svgs";
import { PrimaryButton } from "common";

import * as S from "./styled";

export const AdminReloadButton = ({ onClick }) => {
  return (
    <S.ReloadButton>
      <PrimaryButton
        label={<Icons.ReloadIcon />}
        mode="gray"
        styles={{
          width: "70px",
          margin: "10px",
        }}
        onClick={onClick}
      />
    </S.ReloadButton>
  );
};
