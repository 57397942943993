import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "assets/svgs";
import { Loader } from "common";
import Stepper from "common/Stepper";
import { getAppeals } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { isLoading } from "utils/constants";
import { v4 as uuid } from "uuid";

import * as S from "./styled";

export const AppealsPage = () => {
  const { getAppealsStatus, appeals } = useSelector(clientSelectors);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAppeals());
  }, []);

  if (isLoading(getAppealsStatus)) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return (
    <S.AppealsScrollBlock>
      {appeals.map((item) => {
        return (
          <S.AppealsContainer key={uuid()}>
            <S.AppealsContent>
              <Icons.PropertiesIcon />
              <S.AppealsTitle>
                {item.street_address} | {item.county} | {item.county_owner_name}
              </S.AppealsTitle>
            </S.AppealsContent>
            <Stepper
              isShowDescription
              descriptions={[
                "Forms sent to CAD",
                "Protest Filed",
                "Hearing Date Scheduled",
                "Protest Results/Link to Pay Invoice",
              ]}
              maxStep={4}
              step={item.completed_step}
            />
          </S.AppealsContainer>
        );
      })}
    </S.AppealsScrollBlock>
  );
};
