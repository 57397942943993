import axios from "axios";
import { API } from "configs/api";

export const ownersListApi = {
  getOwners: (params) => axios.get(API.getOwnersList, { token: true, params }),
  syncProperty: (params) =>
    axios.get(
      `${API.syncProperty}/${params.property_id}?county=${params.county}&account_number=${params.accountNumber}`,
      { token: true }
    ),
  exportCSV: (params) =>
    axios.get(API.properties + API.exportCSV, {
      token: true,
      responseType: "blob",
      params,
    }),
  verifyAllAoA: (params) => axios.get(API.verifyAllAoa, { token: true, params }),
};
