import { IMAGES } from "assets/images";
import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

export const NotFoundBackground = styled.div`
  height: 100vh;
  background-image: url(${IMAGES.not_found});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
`;

export const PropertiesButtonBlock = styled.div`
  display: flex;
  justify-content: center;

  .go-back {
    width: 100%;
    height: 60px;
    background-color: ${COLORS.green};
    color: ${COLORS.white};
    border-radius: 10px;
    margin-bottom: 20px;

    &:hover {
      box-shadow: 0 5px 14px 0 ${COLORS.green};
      cursor: pointer;
    }

    @media screen and (${BREAKPOINTS.sm}) {
      width: 90%;
    }

    @media screen and (${BREAKPOINTS.md}) {
      width: 160px;
    }

    @media screen and (${BREAKPOINTS.lg}) {
      width: 200px;
    }

    @media screen and (${BREAKPOINTS.xl}) {
      width: 240px;
    }
  }
`;

export const IconBlock = styled.div`
  padding-bottom: 100px;
`;

export const PageNotFound = styled.div`
  position: absolute;
  bottom: 0px;
`;

export const Text = styled.div`
  color: ${COLORS.dark};
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;

  @media screen and (${BREAKPOINTS.md}) {
    font-size: 56px;
  }
`;
