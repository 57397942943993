import axios from "axios";
import { API } from "configs/api";

export const ownerTrackerApi = {
  getOwnerTracker: ({ id, params }) => axios.get(`${API.signup}${API.actions}/${id}`, { token: true, params }),
  exportCSV: (params) =>
    axios.get(API.signup + API.action + API.exportCSV, {
      token: true,
      responseType: "blob",
      params,
    }),
};
