import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FilterMultiSelect, PrimaryButton, SearchInput, Table } from "common";
import { useDebounce } from "hooks";
import { Paginator } from "primereact/paginator";
import { adminRoutes } from "router/routes";
import { dashboardSelector } from "store/admin-slices/dashboard-slice/selectors";
import { dashboardActions } from "store/admin-slices/dashboard-slice/slice";
import { COUNTIES_FILTER_NEW_DATA, LOADING_STATUSES } from "utils/constants";
import { getPaginationDescription } from "utils/helpers";

import { Filter } from "components/Filter";

import * as S from "./styled";

export const DashboardPage = () => {
  const { dashboardList, last_page, paginationData, getDashboardListStatus } = useSelector(dashboardSelector);

  const [selectedCountyOptions, setSelectedCountyOptions] = useState([]);
  const [sortData, setSortData] = useState({
    field: "updated_at",
    direction: -1,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const getDashboard = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      counties: selectedCountyOptions,
      search: debouncedSearchQuery,
      direction: sortData.direction === 1 ? "asc" : "desc",
    };
    dispatch(dashboardActions.getDashboardList(params));
  };

  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      dashboardActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getDashboard({ page: page + 1, rows });
  };

  const deSelectCounties = () => {
    if (selectedCountyOptions?.length > 0) {
      setSelectedCountyOptions([]);
    } else {
      setSelectedCountyOptions(COUNTIES_FILTER_NEW_DATA.map((item) => item.value));
    }
  };

  const onSort = (e) => {
    setSortData({ field: e.sortField, direction: e.sortOrder });
  };

  const onSearch = (searchResult) => {
    setSearchQuery(searchResult);
  };

  const handleRowClick = (item) => {
    navigate(`${adminRoutes.prefix}/${adminRoutes.dashboard}/${item.data.id}`);
  };

  const tableData = useMemo(
    () => [
      { field: "owner_name", header: "Owner Name", sortable: true },
      { field: "account_number", header: "Account Number", sortable: true },
      { field: "account_number_county", header: "Account Number County", sortable: true },
      { field: "county", header: "County", sortable: true },
      { field: "situs_combined", header: "Situs Combined", sortable: true },
      { field: "unit_id", header: "Unit id", sortable: true },
      { field: "commercial_flag", header: "Commercial Flag", sortable: true },
    ],
    []
  );

  useEffect(() => {
    getDashboard();
  }, [debouncedSearchQuery, sortData, selectedCountyOptions]);

  useEffect(() => {
    return () => dispatch(dashboardActions.resetAllData());
  }, []);

  return (
    <S.DashboardContainer>
      <S.DashboardTitle>New Data</S.DashboardTitle>
      <Filter>
        <SearchInput
          className="search-input"
          placeholder="Search Client List"
          value={searchQuery}
          onSearch={onSearch}
        />
        <FilterMultiSelect
          buttonLabel="COUNTY FILTER"
          options={COUNTIES_FILTER_NEW_DATA}
          selectedOptions={selectedCountyOptions}
          setSelectedOptions={setSelectedCountyOptions}
        />
        <PrimaryButton
          label={(selectedCountyOptions.length ? "DE" : "") + "SELECT ALL COUNTIES"}
          mode="gray"
          onClick={deSelectCounties}
        />
      </Filter>
      <Table
        dataKey="id"
        loading={getDashboardListStatus !== LOADING_STATUSES.succeeded}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={dashboardList}
        onRowClick={handleRowClick}
        onSort={onSort}
      />
      <S.PaginatorWrapper>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[50, 100, 200]}
          totalRecords={last_page}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.DashboardContainer>
  );
};
