import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Icons } from "assets/svgs";
import { AdminReloadButton, PrimaryButton, Table } from "common";
import { Filter } from "pages/admin/components/Filter";
import { Paginator } from "primereact/paginator";
import { evidenceSelectors } from "store/admin-slices/evidence-slice/selectors";
import { evidenceActions } from "store/admin-slices/evidence-slice/slice";
import { singleEvidenceSelectors } from "store/admin-slices/single-evidence-slice/selectors";
import { singleEvidenceActions } from "store/admin-slices/single-evidence-slice/slice";
import { isLoading, LOADING_STATUSES, RESIDENT_TYPES, RESIDENT_TYPES as RESIDENTS_TYPES } from "utils/constants";
import { downloadFileByLink, getPaginationDescription } from "utils/helpers";

import * as S from "./styled";

export const EvidenceTable = () => {
  const {
    isSideBarOpen,
    residentType,
    tableData,
    sales,
    equities,
    savedComps,
    downloadCompsCsvHref,
    getEvidencesEquitiesStatus,
    getEvidencesSalesStatus,
    saveCompsStatus,
    exportCompsCsvStatus,
    subjectProperty,
    last_page,
    last_page_sales,
    paginationData,
    filteredBody,
    sortData,
    getSaveCompsStatus,
    selectedProperties,
  } = useSelector(singleEvidenceSelectors);
  const { printPropertyStatus, printPropertyDownloadHref } = useSelector(evidenceSelectors);

  const [selected, setSelected] = useState([]);
  const [selectedSales, setSelectedSales] = useState([]);
  const [selectedPropertiesIds, setSelectedPropertiesIds] = useState([]);
  const [selectedPropertiesIdsSales, setSelectedPropertiesIdsSales] = useState([]);
  const [allListIds, setAllListIds] = useState([]);
  const [allListIdsSales, setAllListIdsSales] = useState([]);
  const [isGotValuesById, setIsGotValuesById] = useState(false);
  const [isPageChanged, setIsPageChanged] = useState(false);

  const { propertyId } = useParams();

  const dispatch = useDispatch();

  const location = useLocation();

  const filterSelectedData = (data = [], isPageChanged) => {
    if (residentType === RESIDENT_TYPES.equity) {
      if (!selectedPropertiesIds.length && !isPageChanged) {
        setSelected(data.filter((item) => savedComps.includes(item.property_id)));
        setSelectedPropertiesIds(savedComps);
      } else {
        setSelected(data.filter((item) => selectedPropertiesIds.includes(item.property_id)));
      }
    } else if (residentType === RESIDENT_TYPES.sales) {
      if (!selectedPropertiesIdsSales.length && !isPageChanged) {
        setSelectedSales(data.filter((item) => savedComps.includes(item.property_id)));
        setSelectedPropertiesIdsSales(savedComps);
      } else {
        setSelectedSales(data.filter((item) => selectedPropertiesIdsSales.includes(item.property_id)));
      }
    }
  };

  const handleSettingsClick = () => dispatch(singleEvidenceActions.toggleSettingsModal());

  const changeResidentType = (type) => dispatch(singleEvidenceActions.selectResidentType(type));

  const handleSaveClick = () => {
    dispatch(
      singleEvidenceActions.saveComps({
        propertyId,
        sales: Number(residentType === RESIDENT_TYPES.sales),
        property_ids: residentType === RESIDENT_TYPES.equity ? selectedPropertiesIds : selectedPropertiesIdsSales,
      })
    );
  };

  const handleExportClick = () => {
    const params = {
      propertyId,
      property_ids: selectedProperties.map((item) => item.property_id),
      sales: Number(residentType === RESIDENT_TYPES.sales),
    };
    dispatch(singleEvidenceActions.exportCompsCsv(params));
  };

  const handleResetDataClick = () => {
    dispatch(singleEvidenceActions.resetFilters());
    dispatch(
      singleEvidenceActions.setSortData({
        direction: -1,
      })
    );
    if (residentType === RESIDENT_TYPES.equity) {
      filterSelectedData(equities);
    } else if (residentType === RESIDENT_TYPES.sales) {
      filterSelectedData(sales);
      dispatch(singleEvidenceActions.getEvidencesSales({ propertyId }));
    }
  };

  const handlePrintClick = () => {
    dispatch(evidenceActions.printProperty(propertyId));
  };

  const onSelectionChange = (event) => {
    if (residentType === RESIDENT_TYPES.equity) {
      setSelected(event.value);

      if (event.type === "all") {
        if (event.value?.length) {
          const mapedIds = event.value.map((item) => item.property_id);
          setAllListIds(mapedIds);
          setSelectedPropertiesIds((prev) => [...prev, ...mapedIds.filter((item) => !prev.includes(item))]);
        } else {
          if (paginationData.page === 1) {
            setSelectedPropertiesIds([]);
          } else {
            setSelectedPropertiesIds((prev) => [...prev.filter((item) => !allListIds.includes(item))]);
            setAllListIds([]);
          }
        }
        setSelected(event.value);
      }
    } else if (residentType === RESIDENT_TYPES.sales) {
      setSelectedSales(event.value);

      if (event.type === "all") {
        if (event.value?.length) {
          const mapedIdsSales = event.value.map((item) => item.property_id);
          setAllListIdsSales(mapedIdsSales);
          setSelectedPropertiesIdsSales((prev) => [...prev, ...mapedIdsSales.filter((item) => !prev.includes(item))]);
        } else {
          if (paginationData.page === 1) {
            setSelectedPropertiesIdsSales([]);
          } else {
            setSelectedPropertiesIdsSales((prev) => [...prev.filter((item) => !allListIdsSales.includes(item))]);
            setAllListIdsSales([]);
          }
        }
        setSelectedSales(event.value);
      }
    }
  };

  const rowSelect = (event, isSelected) => {
    if (residentType === RESIDENT_TYPES.equity) {
      if (isSelected) {
        setSelectedPropertiesIds((prevent) => [...prevent, event.data.property_id]);
      } else {
        setSelectedPropertiesIds((prevent) => prevent.filter((item) => item !== event.data.property_id));
      }
    } else if (residentType === RESIDENT_TYPES.sales) {
      if (isSelected) {
        setSelectedPropertiesIdsSales((prevent) => [...prevent, event.data.property_id]);
      } else {
        setSelectedPropertiesIdsSales((prevent) => prevent.filter((item) => item !== event.data.property_id));
      }
    }
  };

  const onSort = (e) => {
    dispatch(singleEvidenceActions.setSortData({ field: e.sortField, direction: e.sortOrder }));
  };

  const isRowSelectable = (event) => {
    return event.data.property_id !== subjectProperty.property_id;
  };

  const onPageChange = ({ first, rows, page }) => {
    setIsPageChanged(true);
    dispatch(
      singleEvidenceActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    const params = {
      ...filteredBody,
      page: page + 1,
      limit: rows,
      is_filtered: 1,
      property_id: propertyId,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "DESC" : "ASC",
    };

    if (residentType === RESIDENTS_TYPES.equity) {
      dispatch(singleEvidenceActions.getEvidencesEquities(params));
    } else if (residentType === RESIDENTS_TYPES.sales) {
      dispatch(singleEvidenceActions.getEvidencesSales(params));
    }
  };

  useEffect(() => {
    downloadFileByLink(exportCompsCsvStatus, downloadCompsCsvHref, "comps.csv");
  }, [downloadCompsCsvHref]);

  useEffect(() => {
    downloadFileByLink(
      printPropertyStatus,
      printPropertyDownloadHref,
      `${location.state?.accountNumber}_${new Date().toISOString().split("T")[0]}_${location.state?.packetCounty}.pdf`
    );
  }, [printPropertyDownloadHref]);

  useEffect(() => {
    if ((sales.length || equities.length) && savedComps.length) {
      if (residentType === RESIDENT_TYPES.sales) {
        filterSelectedData(sales, isPageChanged);
      }

      if (residentType === RESIDENT_TYPES.equity) {
        filterSelectedData(equities, isPageChanged);
      }
    }
  }, [sales, equities]);

  useEffect(() => {
    if (residentType === RESIDENT_TYPES.equity) {
      dispatch(
        singleEvidenceActions.setSortData({
          direction: -1,
        })
      );
    } else if (residentType === RESIDENT_TYPES.sales) {
      dispatch(
        singleEvidenceActions.setSortData({
          direction: -1,
        })
      );
    }
  }, [residentType]);

  useEffect(() => {
    if (printPropertyStatus === LOADING_STATUSES.succeeded) {
      dispatch(evidenceActions.resetPrintProperty());
    }
  }, [printPropertyDownloadHref, printPropertyStatus]);

  useEffect(() => {
    dispatch(singleEvidenceActions.getSubjectProperty(propertyId));

    return () => dispatch(singleEvidenceActions.resetAllData());
  }, []);

  useEffect(() => {
    if (residentType === RESIDENT_TYPES.equity) {
      dispatch(
        singleEvidenceActions.getSaveComps({
          sales: 0,
          propertyId,
        })
      );
    } else if (residentType === RESIDENT_TYPES.sales) {
      dispatch(
        singleEvidenceActions.getSaveComps({
          sales: 1,
          propertyId,
        })
      );
    }
  }, [residentType]);

  useEffect(() => {
    if (residentType === RESIDENT_TYPES.equity) {
      if (
        equities.length &&
        savedComps.length === 0 &&
        getSaveCompsStatus === LOADING_STATUSES.succeeded &&
        paginationData.page === 1
      ) {
        const firstNineItems = equities.slice(0, 9);
        setSelected(firstNineItems);
        setSelectedPropertiesIds(firstNineItems.map((property) => property.property_id));
      }
    } else if (residentType === RESIDENT_TYPES.sales) {
      if (
        sales.length &&
        savedComps.length === 0 &&
        getSaveCompsStatus === LOADING_STATUSES.succeeded &&
        paginationData.page === 1
      ) {
        const firstNineItemsSales = sales.slice(0, 9);
        setSelectedSales(firstNineItemsSales);
        setSelectedPropertiesIdsSales(firstNineItemsSales.map((property) => property.property_id));
      }
    }
  }, [equities.length, sales.length, savedComps.length]);

  useEffect(() => {
    if (selectedPropertiesIds.length || selectedPropertiesIdsSales.length) {
      if (
        (selectedPropertiesIds.length > 2 && selectedPropertiesIds.length < 10) ||
        (selectedPropertiesIdsSales.length > 2 && selectedPropertiesIdsSales.length < 10)
      ) {
        dispatch(
          singleEvidenceActions.getLeftBarValues({
            propertyId,
            is_sale: residentType === RESIDENT_TYPES.equity ? 0 : 1,
            property_ids: residentType === RESIDENT_TYPES.equity ? selectedPropertiesIds : selectedPropertiesIdsSales,
          })
        );
        setIsGotValuesById(true);
      } else {
        if (isGotValuesById) {
          dispatch(
            singleEvidenceActions.getLeftBarValues({
              propertyId,
              is_sale: residentType === RESIDENT_TYPES.equity ? 0 : 1,
            })
          );
          setIsGotValuesById(false);
        }
      }
    } else {
      dispatch(
        singleEvidenceActions.getLeftBarValues({
          propertyId,
          is_sale: residentType === RESIDENT_TYPES.equity ? 0 : 1,
        })
      );
      setIsGotValuesById(false);
    }
  }, [selectedPropertiesIds?.length, selectedPropertiesIdsSales?.length, residentType]);

  return (
    <S.EvidenceTableContainer>
      <S.ResidentalContainer>
        <S.Box
          className={isSideBarOpen ? "arrowRight" : "arrowLeft"}
          onClick={() => dispatch(singleEvidenceActions.toggleSideBar())}
        >
          <Icons.ArrowRightIcon />
        </S.Box>
        <S.Box>
          <PrimaryButton
            eventHandler={() => changeResidentType(RESIDENT_TYPES.equity)}
            label="Residential  Equity"
            mode={residentType === RESIDENT_TYPES.equity ? "green" : "gray"}
          />
          <PrimaryButton
            eventHandler={() => changeResidentType(RESIDENT_TYPES.sales)}
            label="Residential Sales"
            mode={residentType === RESIDENT_TYPES.sales ? "green" : "gray"}
          />
        </S.Box>
        <S.SettingsBlock>
          <PrimaryButton
            className="settingButton"
            eventHandler={handleSettingsClick}
            iconStart={<Icons.SettingsIcon />}
            label="Settings"
            mode="gray"
          />
        </S.SettingsBlock>
      </S.ResidentalContainer>
      <Filter>
        <AdminReloadButton onClick={handleResetDataClick} />
        <S.FilterRightButtons>
          <PrimaryButton eventHandler={handleSaveClick} label="Save" loading={isLoading(saveCompsStatus)} mode="gray" />
          <PrimaryButton
            eventHandler={handleExportClick}
            label="Export"
            loading={isLoading(exportCompsCsvStatus)}
            mode="gray"
          />
          <PrimaryButton
            eventHandler={handlePrintClick}
            label="Print"
            loading={isLoading(printPropertyStatus)}
            mode="gray"
          />
          {residentType === RESIDENT_TYPES.equity
            ? selectedPropertiesIds?.length
              ? selectedPropertiesIds.length + " Comps selected"
              : "0 Comps selected"
            : selectedPropertiesIdsSales?.length
            ? selectedPropertiesIdsSales.length + " Comps selected"
            : "0 Comps selected"}
        </S.FilterRightButtons>
      </Filter>
      <S.TableContainer>
        <Table
          scrollable
          showGridlines
          frozenValue={subjectProperty && [subjectProperty]}
          isDataSelectable={isRowSelectable}
          loading={
            residentType === RESIDENT_TYPES.equity
              ? getEvidencesEquitiesStatus !== LOADING_STATUSES.succeeded
              : getEvidencesSalesStatus !== LOADING_STATUSES.succeeded
          }
          scrollHeight="calc(100vh - 280px)"
          selection={residentType === RESIDENT_TYPES.equity ? selected : selectedSales}
          sortField={sortData.field}
          sortOrder={sortData.direction}
          tableData={[
            {
              headerClassName: "column-header",
              selectionMode: "multiple",
            },
            ...tableData.filter((item) => item.isShow),
          ]}
          value={residentType === RESIDENT_TYPES.equity ? equities : sales}
          onRowSelect={(e) => rowSelect(e, true)}
          onRowUnselect={(e) => rowSelect(e, false)}
          onSelectionChange={onSelectionChange}
          onSort={onSort}
        />
      </S.TableContainer>
      <S.PaginatorWrapper>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[50, 100, 200]}
          totalRecords={residentType === RESIDENT_TYPES.equity ? last_page : last_page_sales}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">
          {getPaginationDescription(
            paginationData,
            residentType === RESIDENT_TYPES.equity ? last_page : last_page_sales
          )}
        </S.Box>
      </S.PaginatorWrapper>
    </S.EvidenceTableContainer>
  );
};
