import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import { deleteDiscount, getOwnerDiscount, postDiscount, updateDiscount } from "./actions";

const initialState = {
  getDiscountList: [],
  isModalOpen: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  selectedDiscount: null,
  selectedDiscountId: null,
  getDiscountStatus: LOADING_STATUSES.idle,
  postDiscountStatus: LOADING_STATUSES.idle,
  deleteDiscountStatus: LOADING_STATUSES.idle,
  updateDiscountStatus: LOADING_STATUSES.idle,
};
export const discountSlice = createSlice({
  name: SLICE_NAME.discount,
  initialState,
  reducers: {
    setIsModalOpen: (state, { payload }) => {
      state.isModalOpen = payload;
    },
    setIsEditModalOpen: (state, { payload }) => {
      state.isEditModalOpen = payload;
    },
    setIsDeleteModalOpen: (state, { payload }) => {
      state.isDeleteModalOpen = payload;
    },
    setSelectedDiscountId: (state, { payload }) => {
      state.selectedDiscountId = payload;
    },
    setSelectedDiscount: (state, { payload }) => {
      state.selectedDiscount = payload;
    },
    resetAllData: () => initialState,
  },
  extraReducers: ({ addCase }) => {
    // get owner discount
    addCase(getOwnerDiscount.pending, (state) => {
      state.getDiscountStatus = LOADING_STATUSES.pending;
    });
    addCase(getOwnerDiscount.fulfilled, (state, { payload }) => {
      state.getDiscountStatus = LOADING_STATUSES.succeeded;
      state.getDiscountList = payload.data;
    });
    addCase(getOwnerDiscount.rejected, (state) => {
      state.getDiscountStatus = LOADING_STATUSES.failed;
    });

    //post discount
    addCase(postDiscount.pending, (state) => {
      state.postDiscountStatus = LOADING_STATUSES.pending;
    });
    addCase(postDiscount.fulfilled, (state, { payload }) => {
      state.postDiscountStatus = LOADING_STATUSES.succeeded;
      state?.getDiscountList.push(payload.data);
    });
    addCase(postDiscount.rejected, (state) => {
      state.postDiscountStatus = LOADING_STATUSES.failed;
    });

    //delete discount
    addCase(deleteDiscount.pending, (state) => {
      state.deleteDiscountStatus = LOADING_STATUSES.pending;
    });
    addCase(deleteDiscount.fulfilled, (state, { payload }) => {
      state.getDiscountList = state.getDiscountList.filter((item) => item.id !== payload);
      state.deleteDiscountStatus = LOADING_STATUSES.succeeded;
    });
    addCase(deleteDiscount.rejected, (state) => {
      state.deleteDiscountStatus = LOADING_STATUSES.failed;
    });

    //update discount
    addCase(updateDiscount.pending, (state) => {
      state.updateDiscountStatus = LOADING_STATUSES.pending;
    });
    addCase(updateDiscount.fulfilled, (state, { payload }) => {
      state.getDiscountList = state.getDiscountList.map((item) => {
        if (item.id === payload.data.id) {
          return payload.data;
        }
        return item;
      });
      state.updateDiscountStatus = LOADING_STATUSES.succeeded;
    });
    addCase(updateDiscount.rejected, (state) => {
      state.updateDiscountStatus = LOADING_STATUSES.failed;
    });
  },
});

export const discountReducer = discountSlice.reducer;

export const discountActions = {
  ...discountSlice.actions,
  deleteDiscount,
  getOwnerDiscount,
  postDiscount,
  updateDiscount,
};
