import axios from "axios";
import { API } from "configs/api";

export const singleAgentApi = {
  getAgent: (id) => axios.get(`${API.adminUsers}/${id}`, { token: true }),
  editAgent: ({ id, ...body }) => axios.put(`${API.adminUsers}/${id}`, body, { token: true }),
  getTracker: ({ id, params }) => axios.get(`${API.adminUsers}${API.tracker}/${id}`, { token: true, params }),
  deleteAgent: (id) => axios.delete(`${API.adminUsers}/${id}`, { token: true }),
  exportCSV: (params) =>
    axios.get(`${API.adminUsers}${API.tracker}${API.action}${API.exportCSV}`, {
      token: true,
      params,
      responseType: "blob",
    }),
};
