import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton, PrimaryInput } from "common";
import { referralsSelectors } from "store/referral-slice/selectors";
import { referralActions } from "store/referral-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";
import { referralSchema } from "validations";

import * as S from "./styled";

const SendReferralEmailSection = () => {
  const { referralCode, postReferralEmailStatus } = useSelector(referralsSelectors);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(referralSchema),
  });

  const onSubmit = (data) => {
    dispatch(
      referralActions.postReferralEmail({
        email: data.email,
        referral_code: referralCode,
      })
    );
  };

  useEffect(() => {
    setValue("email", "");
  }, [postReferralEmailStatus]);

  return (
    <S.ReferralForm onSubmit={handleSubmit(onSubmit)}>
      <PrimaryInput
        className="email-input"
        error={errors.email}
        placeholder="Referee's Email"
        register={register("email")}
      />
      {postReferralEmailStatus === LOADING_STATUSES.succeeded ? (
        <S.SuccessMessageBlock>Referral Email Sent! Ready to send another one.</S.SuccessMessageBlock>
      ) : null}
      <PrimaryButton
        className="email-button"
        label="SEND REFERRAL EMAIL"
        loading={isLoading(postReferralEmailStatus)}
      />
    </S.ReferralForm>
  );
};

export default SendReferralEmailSection;
