import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import { getSingleDashboardList } from "./actions";

const initialState = {
  singleDashboardList: [],
  getSingleDashboardListStatus: LOADING_STATUSES.idle,
};

export const singleDashboardSlice = createSlice({
  name: SLICE_NAME.singleDashboardSlice,
  initialState,
  reducers: {
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    resetAllData: () => initialState,
  },
  extraReducers: ({ addCase }) => {
    // get documents list AOA
    addCase(getSingleDashboardList.pending, (state) => {
      state.getSingleDashboardListStatus = LOADING_STATUSES.pending;
    });
    addCase(getSingleDashboardList.fulfilled, (state, { payload }) => {
      state.singleDashboardList = payload;
      state.getSingleDashboardListStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getSingleDashboardList.rejected, (state) => {
      state.getSingleDashboardListStatus = LOADING_STATUSES.failed;
    });
  },
});

export const singleDashboardReducer = singleDashboardSlice.reducer;

export const dashboardSingleActions = {
  ...singleDashboardSlice.actions,
  getSingleDashboardList,
};
