import axios from "axios";
import { API } from "configs/api";

export const hearingDatesListApi = {
  getHearingDates: (params) => axios.get(API.getHearingDatesList, { token: true, params }),
  postUploadHearingFile: (body) =>
    axios.post(`${API.getHearingDatesList}${API.uploadFile}`, body, { token: true, formData: true }),
  exportCSV: (params) =>
    axios.get(API.getHearingDatesList + API.exportCSV, {
      token: true,
      responseType: "blob",
      params,
    }),
  postUploadHearingComment: ({ id, ...body }) =>
    axios.post(`${API.getHearingDatesList}${API.updateComment}/${id}`, body, { token: true }),
  getUpcomingHearings: () => axios.get(`${API.getHearingDatesList}${API.upcomingHearings}`, { token: true }),
  postHearingsComplete: (body) =>
    axios.post(`${API.getHearingDatesList}${API.complete}`, body, { token: true, formData: true }),
};
