import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import clsx from "clsx";
import { AutocompleteInput, Checkbox, ErrorMessage, Loader, PrimaryButton, PrimaryInput, Select } from "common";
import { useDebounce } from "hooks";
import { signupSelectors } from "store/signup-slice/selectors";
import { signupActions } from "store/signup-slice/slice";
import { COUNTIES_FILTER, isLoading, LOADING_STATUSES } from "utils/constants";
import { getStorage, isEmpty } from "utils/helpers";
import { v4 as uuid } from "uuid";
import { signUpAddPropertySchema } from "validations";

import * as S from "./styled";

export const PropertyInformation = () => {
  const {
    propertySearchData,
    propertySearchStatus,
    searchedProperties,
    properties,
    searchedPropertiesStatus,
    duplicateIdError,
    addPropertyInLeadsStatus,
    mailingInformation,
  } = useSelector(signupSelectors);

  const [searchAddressError, setSearchAddressError] = useState(null);
  const [otherCountyError, setOtherCountyError] = useState("");
  const [propertiesLimitError, setPropertiesLimitError] = useState("");
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [isAddedManually, setIsAddedManually] = useState(false);
  const firstName = sessionStorage.getItem("first_name");
  const lastName = sessionStorage.getItem("last_name");
  const owner_id = getStorage("owner_id");
  const [isMailingAddressTheSame, setIsMailingAddressTheSame] = useState(false);
  const [mailingInfoErrors, setMailingInfoErrors] = useState({});
  const [firstClickAddProperty, setFirstClickAddProperty] = useState(false);

  const searchAddressInputRef = useRef();

  const dispatch = useDispatch();

  const {
    watch,
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(signUpAddPropertySchema),
  });

  const {
    cityStateZip,
    county,
    address,
    searchAddress,
    unit,
    city,
    state,
    zip,
    streetName,
    addressNumber,
    ownerName,
    ownerAddress,
    ownerCity,
    ownerState,
    ownerZip,
  } = watch();
  const debouncedSearchAddress = useDebounce(searchAddress);

  const disableAddPropertyButtonManually =
    isAddedManually &&
    (!county ||
      !state ||
      !city ||
      !zip ||
      !streetName ||
      !addressNumber ||
      !ownerName ||
      !ownerAddress ||
      !ownerCity ||
      !ownerState ||
      !ownerZip);

  const disableAddPropertyButton =
    (!isAddedManually && (!ownerAddress || !ownerCity || !ownerState || !ownerZip || !ownerName)) ||
    !propertySearchData;

  const toggleIsMailingAddressTheSame = () => setIsMailingAddressTheSame((p) => !p);

  const handleSetAddressErrorCheck = () => {
    setFirstClickAddProperty(true);
    if (!ownerName || !ownerAddress || !ownerCity || !ownerState || !ownerZip) {
      setIsMailingAddressTheSame(false);
    }
    if (properties.length >= 1 && county && !properties.some((item) => item.county === county)) {
      setOtherCountyError("Please sign up with properties for 1 county at a time.");
    }
    if (otherCountyError || duplicateIdError || propertiesLimitError) {
      dispatch(signupActions.setScrollTooErrorMessage());
    }
    if (!county?.trim() && !address?.trim() && !cityStateZip?.trim() && !ownerName?.trim()) {
      setSearchAddressError("Please search for your property first.");
      return;
    }
    let selectedCounty = county;
    if (selectedCounty?.toLowerCase() === "fort bend") {
      selectedCounty = "FortBend";
    }
    const body = {
      is_completed: 0,
      owner_id: owner_id,
      ...mailingInformation,
    };
    if (unit) {
      body.unit_id = unit;
      dispatch(signupActions.resetErrorMessages());
    }
    if (isAddedManually) {
      body.is_manually = 1;
      body.street_name = streetName;
      body.address_number = addressNumber;
      body.city = city;
      body.county = selectedCounty;
      body.state = state;
      body.zip = zip;
    } else {
      body.property_id = propertySearchData?.id;
      body.is_manually = 0;
    }
    if (!properties.length) {
      if ((!isAddedManually && !disableAddPropertyButton) || (isAddedManually && !disableAddPropertyButtonManually)) {
        dispatch(signupActions.addPropertyInLeads(body));
      }
    }
    if (
      properties.length >= 0 &&
      properties.some((item) => {
        const concatenatedCounty = item?.county?.replace(/\s+/g, "");
        const selectedCounty = county?.replace(/\s+/g, "");

        return concatenatedCounty?.toUpperCase() === selectedCounty?.toUpperCase();
      })
    ) {
      if (properties.length < 8) {
        dispatch(signupActions.addPropertyInLeads(body));
      } else {
        setPropertiesLimitError("Please sign up with only 8 properties at a time.");
      }
    }
    setValue("searchAddress", "");
  };

  const handleSearchedPropertyClick = (data) => {
    setIsMailingAddressTheSame(false);
    setIsAddressSelected(true);
    setOtherCountyError("");
    setPropertiesLimitError("");
    setSearchAddressError(null);
    dispatch(signupActions.propertySearch(data.id));
    setValue("searchAddress", `${data.street_address}`);
    dispatch(signupActions.resetErrorMessages());
  };

  const onSubmit = () => {
    if (searchAddressInputRef.current) {
      searchAddressInputRef.current.value = "";
    }
  };

  const fillPropertyInfo = () => {
    if (isAddedManually) {
      setValue("ownerAddress", `${addressNumber} ${streetName}${unit ? " # " + unit : ""}`);
      setValue("ownerCity", city);
      setValue("ownerState", "TX");
      setValue("ownerZip", zip);
    } else {
      setValue("ownerAddress", `${propertySearchData.street_address} ${unit ? " # " + unit : ""}`);
      setValue("ownerCity", propertySearchData?.city);
      setValue("ownerState", "TX");
      setValue("ownerZip", propertySearchData?.zip);
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    dispatch(signupActions.backStep());
  };

  const toggleIsAddedManually = () => {
    reset();
    setMailingInfoErrors({});
    setOtherCountyError("");
    setPropertiesLimitError("");
    setIsAddedManually((prev) => !prev);
    setFirstClickAddProperty(false);
    dispatch(signupActions.resetErrorMessages());
  };

  useEffect(() => {
    if (!isEmpty(propertySearchData)) {
      const {
        street_address = "",
        city = "",
        state = "",
        zip = "",
        county = "",
        unit_id = "",
        county_owner_city = "",
        county_owner_state = "",
        county_owner_zip = "",
        county_owner_address = "",
        county_owner_name = "",
      } = propertySearchData;

      setValue("address", street_address);
      setValue("cityStateZip", `${city ?? ""} ${state ?? ""} ${zip ?? ""}`);
      setValue("county", county === "FortBend" ? "Fort Bend" : county);
      setValue("unit", unit_id);
      if (properties.length < 1) {
        setValue("ownerName", county_owner_name ? county_owner_name : `${firstName} ${lastName}`);
        setValue("ownerAddress", county_owner_address);
        setValue("ownerCity", county_owner_city);
        setValue("ownerState", county_owner_state);
        setValue("ownerZip", county_owner_zip);
      }
    }
  }, [propertySearchData, properties.length]);

  useEffect(() => {
    if (properties.length < 1) {
      dispatch(
        signupActions.setMailingInformation({
          county_owner_name: ownerName,
          county_owner_address: ownerAddress,
          county_owner_city: ownerCity,
          county_owner_state: ownerState,
          county_owner_zip: ownerZip,
        })
      );
    }
  }, [ownerName, ownerAddress, ownerCity, ownerState, ownerZip, properties.length]);

  useEffect(() => {
    if (debouncedSearchAddress && !isAddressSelected) {
      dispatch(signupActions.propertySearchFromDb({ search: debouncedSearchAddress }));
    }
  }, [debouncedSearchAddress]);

  useEffect(() => {
    if (!searchedProperties.length) {
      setSearchAddressError("No results found");
    }
  }, [searchedProperties.length, searchedPropertiesStatus]);

  useEffect(() => {
    if (propertySearchStatus === LOADING_STATUSES.succeeded) {
      setIsAddressSelected(false);
    }
  }, [propertySearchStatus]);

  useEffect(() => {
    if (addPropertyInLeadsStatus === LOADING_STATUSES.succeeded) {
      setMailingInfoErrors({});
      setIsAddedManually(false);
      setOtherCountyError("");
      setPropertiesLimitError("");
    }
  }, [addPropertyInLeadsStatus]);

  useEffect(() => {
    setIsMailingAddressTheSame(false);
    dispatch(signupActions.setIsAddedManually(isAddedManually));
    setValue("ownerName", `${firstName} ${lastName}`);
  }, [isAddedManually]);

  useEffect(() => {
    dispatch(signupActions.setScrollTooErrorMessage());
  }, [otherCountyError, duplicateIdError, propertiesLimitError]);

  useEffect(() => {
    if (isMailingAddressTheSame && (propertySearchData || isAddedManually)) {
      fillPropertyInfo();
    }
  }, [isMailingAddressTheSame, addressNumber, streetName, unit, city, zip]);

  useEffect(() => {
    if (firstClickAddProperty) {
      setMailingInfoErrors({
        ownerAddress: ownerAddress ? null : "The field is required.",
        ownerCity: ownerCity ? null : "The field is required.",
        ownerState: ownerState ? null : "The field is required.",
        ownerZip: ownerZip ? null : "The field is required.",
        ownerName: ownerName ? null : "The field is required.",
        county: county ? null : "Please select a county",
        addressNumber: addressNumber ? null : "The field is required",
        streetName: streetName ? null : "The field is required",
        city: city ? null : "The field is required",
        state: state ? null : "The field is required",
        zip: zip ? null : "The field is required",
      });
    }
  }, [
    firstClickAddProperty,
    ownerAddress,
    ownerCity,
    ownerState,
    ownerName,
    ownerZip,
    county,
    addressNumber,
    streetName,
    city,
    state,
    zip,
  ]);

  const SearchDataLayout = propertySearchData ? S.Form : S.Box;

  return (
    <S.PropertyInFormationContainer>
      <S.PropertyInformation className={`${propertySearchData || isAddedManually ? "show" : ""}`}>
        <S.Box>
          <S.SubTitle>Property Information</S.SubTitle>
          <S.Description>
            Next, look up your address and add your property. You can add properties as needed.
          </S.Description>
        </S.Box>
        <S.Box>
          {isAddedManually ? (
            <PrimaryButton
              eventHandler={toggleIsAddedManually}
              label="Return to address search"
              styles={{ marginBlock: "10px" }}
            />
          ) : (
            <AutocompleteInput
              autoFocus
              autoComplete="off"
              placeholder="Search For Your Property"
              readOnly={isLoading(propertySearchStatus)}
              register={register("searchAddress")}
              value={searchAddress}
            >
              <S.SearchBoxContainer>
                {searchedPropertiesStatus !== LOADING_STATUSES.succeeded ? (
                  <S.LoaderBox>
                    <Loader size={40} />
                  </S.LoaderBox>
                ) : (
                  searchedProperties.slice(0, 6).map((data) => (
                    <S.SearchBox key={uuid()} className="pac-item" onClick={() => handleSearchedPropertyClick(data)}>
                      <Icons.LocationIcon />
                      <S.Span className="street-address">{data.street_address}</S.Span>
                      <S.Span>{data.city} TX USA</S.Span>
                    </S.SearchBox>
                  ))
                )}
                {!searchedProperties.length && searchedPropertiesStatus === LOADING_STATUSES.succeeded ? (
                  <S.NoResultBlock>
                    <S.NoResult>{searchAddressError}</S.NoResult>
                    <PrimaryButton eventHandler={toggleIsAddedManually} label="Add your address manually" />
                  </S.NoResultBlock>
                ) : null}
              </S.SearchBoxContainer>
            </AutocompleteInput>
          )}
        </S.Box>

        {otherCountyError || duplicateIdError || (properties.length >= 8 && propertiesLimitError) ? (
          <S.ErrorContent>
            <ErrorMessage fontSize={16}>{otherCountyError || duplicateIdError || propertiesLimitError}</ErrorMessage>
          </S.ErrorContent>
        ) : null}
        <SearchDataLayout className="flex w-full flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
          {propertySearchData && !isAddedManually ? (
            <>
              <PrimaryInput readOnly error={errors.address} placeholder="Address" register={register("address")} />
              <PrimaryInput
                error={errors.unit}
                placeholder="Unit # if needed"
                readOnly={propertySearchData?.unit_id}
                register={register("unit")}
              />
              <PrimaryInput
                readOnly
                error={errors.cityStateZip}
                placeholder="City State Zip"
                register={register("cityStateZip")}
              />
              <PrimaryInput readOnly error={errors.county} placeholder="County" register={register("county")} />
            </>
          ) : null}
          {isAddedManually ? (
            <>
              <PrimaryInput
                error={mailingInfoErrors.addressNumber}
                placeholder="Address Number"
                readOnly={false}
                register={register("addressNumber")}
              />
              <PrimaryInput
                error={mailingInfoErrors.streetName}
                placeholder="Street Name"
                readOnly={false}
                register={register("streetName")}
              />
              <PrimaryInput error={errors.unit} placeholder="Unit # if needed" register={register("unit")} />
              <Controller
                control={control}
                name="county"
                render={({ field: { onChange } }) => (
                  <Select
                    error={mailingInfoErrors.county}
                    items={COUNTIES_FILTER.map((item) => item.description)}
                    label="County"
                    onChange={(value) => onChange({ target: { value } })}
                  />
                )}
              />
              <PrimaryInput error={mailingInfoErrors.city} placeholder="City" register={register("city")} />
              <PrimaryInput
                readOnly
                error={mailingInfoErrors.state}
                placeholder="State"
                register={register("state")}
                value="TX"
              />
              <PrimaryInput error={mailingInfoErrors.zip} placeholder="Zip" register={register("zip")} />
            </>
          ) : null}
          {isLoading(propertySearchStatus) && (
            <S.Box className="flex justify-center">
              <Loader size={60} />
            </S.Box>
          )}
        </SearchDataLayout>
      </S.PropertyInformation>
      {propertySearchData && properties.length < 1 && !isAddedManually ? (
        <>
          <S.OwnerInformation className={`${propertySearchData ? "show" : ""}`}>
            <S.HeaderInfoBlock>
              <S.SubTitle className="!mb-0">Mailing Information</S.SubTitle>
              <S.SubTitleMessage>(Required for Tax Forms)</S.SubTitleMessage>
            </S.HeaderInfoBlock>
            <S.Description>Please check your information, if it is not correct, you can easily edit it.</S.Description>
            <S.Box>
              <Checkbox
                checked={isMailingAddressTheSame}
                description="Mailing address is the same as property information"
                onChange={toggleIsMailingAddressTheSame}
              />
            </S.Box>
            <div
              className={clsx({
                hidden: isMailingAddressTheSame,
              })}
            >
              <PrimaryInput
                error={mailingInfoErrors.ownerAddress}
                placeholder="Mailing address"
                register={register("ownerAddress")}
              />
              <PrimaryInput
                error={mailingInfoErrors.ownerCity}
                placeholder="Mailing city"
                register={register("ownerCity")}
              />
              <PrimaryInput
                error={mailingInfoErrors.ownerState}
                placeholder="Mailing state"
                register={register("ownerState")}
              />
              <PrimaryInput
                error={mailingInfoErrors.ownerZip}
                placeholder="Mailing zip"
                register={register("ownerZip")}
              />
            </div>
            <PrimaryInput
              error={mailingInfoErrors.ownerName}
              placeholder="Property owner name"
              register={register("ownerName")}
            />
          </S.OwnerInformation>
        </>
      ) : null}
      {properties.length < 1 && isAddedManually ? (
        <>
          <S.OwnerInformation className={`${propertySearchData || isAddedManually ? "show" : ""}`}>
            <S.HeaderInfoBlock>
              <S.SubTitle className="!mb-0">Mailing Information</S.SubTitle>
              <S.SubTitleMessage>(Required for Tax Forms)</S.SubTitleMessage>
            </S.HeaderInfoBlock>
            <S.Box>
              <Checkbox
                checked={isMailingAddressTheSame}
                description="Mailing address is the same as property information"
                onChange={toggleIsMailingAddressTheSame}
              />
            </S.Box>
            <div
              className={clsx({
                hidden: isMailingAddressTheSame,
              })}
            >
              <PrimaryInput
                error={mailingInfoErrors.ownerAddress}
                placeholder="Mailing address"
                register={register("ownerAddress")}
              />
              <PrimaryInput
                error={mailingInfoErrors.ownerCity}
                placeholder="Mailing city"
                register={register("ownerCity")}
              />
              <PrimaryInput
                error={mailingInfoErrors.ownerState}
                placeholder="Mailing state"
                register={register("ownerState")}
              />
              <PrimaryInput
                error={mailingInfoErrors.ownerZip}
                placeholder="Mailing zip"
                register={register("ownerZip")}
              />
            </div>
            <PrimaryInput
              error={mailingInfoErrors.ownerName}
              placeholder="Property owner name"
              register={register("ownerName")}
            />
          </S.OwnerInformation>
        </>
      ) : null}
      <S.ButtonsContainer>
        <PrimaryButton
          className="w-fit"
          eventHandler={handleSetAddressErrorCheck}
          label={properties.length < 1 ? "Continue" : "Add Property"}
          minwidth={200}
          styles={
            properties.length < 1
              ? null
              : {
                  backgroundColor: COLORS.deepDark,
                  width: "fit-content",
                }
          }
        />
        {properties.length < 1 ? <PrimaryButton eventHandler={handleBackClick} label="back" mode="gray" /> : null}
      </S.ButtonsContainer>
    </S.PropertyInFormationContainer>
  );
};
