import * as yup from "yup";

export const addPropertyManuallySchema = yup.object().shape({
  manuallyCounty: yup.string().max(255).required("The field is required."),
  unit: yup.string(),
  addressNumber: yup.string().required("The field is required."),
  streetName: yup.string().required("The field is required."),
  city: yup.string().required("The field is required."),
  state: yup.string().required("The field is required."),
  zip: yup.string().required("The field is required."),
});
