import { Loader } from "../Loader";

import * as S from "./styled";

export const SimpleSelect = ({ items = [], handleSelect, loading, searchQuery, error, ...rest }) => {
  return searchQuery ? (
    <S.Container {...rest}>
      <S.List className={loading ? "loading" : ""}>
        {loading ? (
          <Loader size={60} />
        ) : (
          items.map((item) => (
            <S.ListItem key={item} onClick={() => handleSelect(item)}>
              {item}
            </S.ListItem>
          ))
        )}
      </S.List>
      {error && <S.ErrorText>{error.message}</S.ErrorText>}
    </S.Container>
  ) : null;
};
