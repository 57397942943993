import { styled } from "styled-components/macro";

export const Container = styled.div`
  position: relative;
`;

export const Box = styled.div`
  width: 100%;
  top: 45px;
`;
