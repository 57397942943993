import { useDispatch, useSelector } from "react-redux";
import { Icons } from "assets/svgs";
import { HomeGreyIcon } from "assets/svgs/HomeGreyIcon";
import { signupSelectors } from "store/signup-slice/selectors";
import { signupActions } from "store/signup-slice/slice";
import { v4 as uuid } from "uuid";

import * as S from "./styled";

const Properties = ({ isDelete = false }) => {
  const { properties } = useSelector(signupSelectors);

  const dispatch = useDispatch();
  const handleAcceptModalOpen = (key, id, owner_id) => {
    dispatch(signupActions.toggleAcceptModal({ deletePropertyKey: key, id, owner_id }));
  };

  return (
    <>
      {properties.map((property) => (
        <S.PropertyBox key={uuid()}>
          <HomeGreyIcon />
          <S.PropertyInfoBox>
            {property.street_address || property.address} |{" "}
            {property.county === "FortBend" ? "Fort Bend" : property.county}
          </S.PropertyInfoBox>
          {!!isDelete && (
            <S.RemoveIconBlock>
              <Icons.RemoveIcon onClick={() => handleAcceptModalOpen(property.index, property.id, property.owner_id)} />
            </S.RemoveIconBlock>
          )}
        </S.PropertyBox>
      ))}
    </>
  );
};

export default Properties;
