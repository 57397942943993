import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { documentsListApi } from "./services";

export const getDocumentsList = createAsyncThunk(
  `${SLICE_NAME.documentsSlice}/getDocumentsList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await documentsListApi.getDocuments(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCSV = createAsyncThunk(
  `${SLICE_NAME.documentsSlice}/exportCSV`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await documentsListApi.exportCSV(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
