import { configureStore } from "@reduxjs/toolkit";

import { agentsReducer } from "./admin-slices/agents-slice/slice";
import { archivesListReducer } from "./admin-slices/archived-slice/slice";
import { clientEvidenceReducer } from "./admin-slices/client-evidence-slice/slice";
import { singleDashboardReducer } from "./admin-slices/dashboard-single-slice/slice";
import { dashboardReducer } from "./admin-slices/dashboard-slice/slice";
import { discountReducer } from "./admin-slices/discount-slice/slice";
import { singleDocumentsListReducer } from "./admin-slices/documents-single-slice/slice";
import { documentsListReducer } from "./admin-slices/documents-slice/slice";
import { evidenceReducer } from "./admin-slices/evidence-slice/slice";
import { hearingDatesReducer } from "./admin-slices/hearing-dates-slice/slice";
import { leadsListReducer } from "./admin-slices/leads-slice/slice";
import { ownersListReducer } from "./admin-slices/owner-slice/slice";
import { ownerTrackerReducer } from "./admin-slices/owner-tracker-slice/slice";
import { protestedAccountsReducer } from "./admin-slices/protested-accounts-slice/slice";
import { adminSignInReducer } from "./admin-slices/signin-slice/slice";
import { singleAgentReducer } from "./admin-slices/single-agent-slice/slice";
import { singleArchivedReducer } from "./admin-slices/single-archived-slice/slice";
import { singleClientEvidenceReducer } from "./admin-slices/single-client-evidence-slice/slice";
import { singleEvidenceReducer } from "./admin-slices/single-evidence-slice/slice";
import { singleOwnerReducer } from "./admin-slices/single-property-slice/slice";
import { clientReducer } from "./client-slice/slice";
import { referralPageReducer } from "./referral-slice/slice";
import { signupReducer } from "./signup-slice/slice";
import { submitEvidenceReducer } from "./submit-evidence-slice/slice";

export const store = configureStore({
  reducer: {
    signup: signupReducer,
    adminSignIn: adminSignInReducer,
    ownersList: ownersListReducer,
    protestedAccountsList: protestedAccountsReducer,
    hearingDatesList: hearingDatesReducer,
    client: clientReducer,
    singleOwner: singleOwnerReducer,
    leadsList: leadsListReducer,
    referrals: referralPageReducer,
    documentsList: documentsListReducer,
    singleDocuments: singleDocumentsListReducer,
    singleEvidence: singleEvidenceReducer,
    archivesList: archivesListReducer,
    evidence: evidenceReducer,
    clientEvidence: clientEvidenceReducer,
    submitEvidenceSlice: submitEvidenceReducer,
    singleClientEvidence: singleClientEvidenceReducer,
    agents: agentsReducer,
    singleAgent: singleAgentReducer,
    ownerTrackerReducer: ownerTrackerReducer,
    discount: discountReducer,
    dashboard: dashboardReducer,
    singleDashboard: singleDashboardReducer,
    singleArchived: singleArchivedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
