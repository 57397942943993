import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { referralsPageApi } from "./services";

export const postReferralCode = createAsyncThunk(
  `${SLICE_NAME.referralSlice}/postReferralCode`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await referralsPageApi.postReferralCode(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postReferralEmail = createAsyncThunk(
  `${SLICE_NAME.referralSlice}/postReferralEmail`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await referralsPageApi.postReferralEmail(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReferralEmailList = createAsyncThunk(
  `${SLICE_NAME.referralSlice}/getReferralEmailList`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await referralsPageApi.getReferralEmailList(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
