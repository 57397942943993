import { styled } from "styled-components";

export const FilterBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding: 10px;

  @media (max-width: 1500px) {
    justify-content: center;
  }
`;
