import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const Box = styled.div`
  &.red {
    color: ${COLORS.red};
  }
`;

export const Text = styled.div`
  color: ${COLORS.green};

  &.error {
    color: ${COLORS.red};
  }
`;

export const ConfirmContent = styled.div`
  display: flex;
  gap: 24px;

  &.reason-content-buttons {
    justify-content: center;
  }
`;

export const ArchiveModalContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.overlay_200};
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;

  .archive-owner-button {
    margin-block: 10px;
  }

  .reason-select {
    width: 360px;
  }
`;

export const ArchiveModalContent = styled.div`
  background-color: ${COLORS.white};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 60px;
  border-radius: 6px;

  &.open-reason-content {
    align-items: unset;
  }
`;
