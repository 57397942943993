import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";
import { Icons } from "assets/svgs";
import { Checkbox } from "common/Checkbox";

import * as S from "./styled";

export const PrimaryAccordion = ({
  headerContent,
  bodyContent,
  isOpen,
  onToggle,
  checkboxChecked,
  checkboxId,
  checkboxOnChange,
  className,
}) => {
  return (
    <S.AccordionContainer className={className}>
      <Accordion icon={<Icons.ArrowDownIcon className={isOpen ? "rotate" : ""} />} open={isOpen}>
        <S.AccordionHeaderContainer>
          <Checkbox checked={checkboxChecked} id={checkboxId} onChange={checkboxOnChange} />
          <AccordionHeader onClick={onToggle}>{headerContent}</AccordionHeader>
        </S.AccordionHeaderContainer>
        <AccordionBody>{bodyContent}</AccordionBody>
      </Accordion>
    </S.AccordionContainer>
  );
};
