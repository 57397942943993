import { Document } from "@react-pdf/renderer";

import { AoAPage } from "./AoAPage";
import { AoAPage2 } from "./AoAPage2";
import { SaPage } from "./SAPage";

export const AoADocument = ({ owner, isDownloadCase, properties = [], signature, signType }) => {
  const firstPropIndex = properties.findIndex(
    (obj) => Object.prototype.hasOwnProperty.call(obj, "county_owner_name") && obj["county_owner_name"] !== null
  );

  const firstProp = firstPropIndex !== -1 ? properties[firstPropIndex] : null;

  return (
    <Document>
      <AoAPage firstProp={firstProp} owner={owner} properties={properties.slice(0, 4)} />
      {properties.length > 4 && <AoAPage firstProp={firstProp} owner={owner} properties={properties.slice(4)} />}
      <AoAPage2 firstProp={firstProp} owner={owner} signature={signature} signType={signType} />
      {isDownloadCase && <SaPage owner={owner} signature={signature} signType={signType} />}
    </Document>
  );
};
