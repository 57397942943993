import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "common";
import { useDebounce } from "hooks";
import { adminRoutes } from "router/routes";
import { singleDocumentSelectors } from "store/admin-slices/documents-single-slice/selectors";
import { documentsSingleActions } from "store/admin-slices/documents-single-slice/slice";
import { DOCUMENT_TYPE } from "utils/constants";

import { SingleDocumentsTable } from "./SingleDocumentsTable";
import * as S from "./styled";
import { TrackerTable } from "./TrackerTable";

export const DocumentsSinglePage = () => {
  const {
    AoaDocs,
    SaDocs,
    getSingleAoaDocumentsStatus,
    getSingleSaDocumentsStatus,
    putAoaFileNameStatus,
    putSaFileNameStatus,
    paginationData,
  } = useSelector(singleDocumentSelectors);

  const [sortData, setSortData] = useState({
    field: "created_at",
    direction: -1,
  });
  const [startDate, setStartDate] = useState("");
  const debouncedStartDate = useDebounce(startDate, 500);

  const [endDate, setEndDate] = useState("");
  const debouncedEndDate = useDebounce(endDate, 500);

  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const goBack = () => {
    navigate(`${adminRoutes.prefix}/${adminRoutes.documents}`);
  };
  const getTrackerList = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      order_column: sortData.field,
      start_date: debouncedStartDate,
      end_date: debouncedEndDate,
      direction: sortData.direction === 1 ? "asc" : "desc",
    };

    dispatch(documentsSingleActions.getTracker({ id, params }));
  };

  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      documentsSingleActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getTrackerList({ page: page + 1, rows });
  };

  useEffect(() => {
    getTrackerList();
  }, [sortData, debouncedStartDate, debouncedEndDate]);

  useEffect(() => {
    dispatch(documentsSingleActions.getSingleAoaDocuments({ id }));
  }, []);

  useEffect(() => {
    dispatch(documentsSingleActions.getSingleSaDocuments({ id }));
  }, []);

  useEffect(() => {
    return () => dispatch(documentsSingleActions.resetAllData());
  }, []);

  return (
    <S.Container>
      <PrimaryButton className="back-button" eventHandler={goBack} label="Back" />
      <SingleDocumentsTable
        permissions
        documents={AoaDocs}
        getSingleDocumentsStatus={getSingleAoaDocumentsStatus}
        putDocumentsFileName={documentsSingleActions.putAoaFileName}
        putFileNameStatus={putAoaFileNameStatus}
        tableHeader="AOA name"
        type={DOCUMENT_TYPE.AOA}
        uploadButtonLabel="upload new aoa"
      />
      <SingleDocumentsTable
        documents={SaDocs}
        getSingleDocumentsStatus={getSingleSaDocumentsStatus}
        putDocumentsFileName={documentsSingleActions.putSaFileName}
        putFileNameStatus={putSaFileNameStatus}
        tableHeader="SA name"
        type={DOCUMENT_TYPE.SA}
        uploadButtonLabel="upload new sa"
      />
      <TrackerTable
        debouncedEndDate={debouncedEndDate}
        debouncedStartDate={debouncedStartDate}
        setEndDate={setEndDate}
        setSortData={setSortData}
        setStartDate={setStartDate}
        sortData={sortData}
        onPageChange={onPageChange}
      />
    </S.Container>
  );
};
