import { useEffect } from "react";

export const useOutsideClick = (callback) => {
  useEffect(() => {
    document.addEventListener("click", callback);

    return () => {
      document.removeEventListener("click", callback);
    };
  }, []);
};
