import * as yup from "yup";

export const signUpStep1Schema = yup.object().shape({
  firstName: yup.string().required("The field is required."),
  lastName: yup.string().required("The field is required."),
  email: yup.string().email("E-mail address must be a valid email").required("The Email is required."),
  phoneNumber: yup
    .string()
    .matches(/^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$/, "Phone Number must be valid")
    .required("The phone number is required."),

  referral: yup.string().nullable(),
});
