import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { COLORS } from "assets/styles/colors";
import { PrimaryButton, PrimaryInput } from "common";
import { LazyLoadSelect } from "common/LazyLoadSelect";
import { adminRoutes } from "router/routes";
import { singleEvidenceSelectors } from "store/admin-slices/single-evidence-slice/selectors";
import { singleEvidenceActions } from "store/admin-slices/single-evidence-slice/slice";
import { RESIDENT_TYPES } from "utils/constants";
import { numberToDollar } from "utils/helpers";

import * as S from "./styled";

const filterKeys = {
  salePrice: "sale_price",
  finalMarketValue: "final_market_value",
  totalArea: "total_area",
  mainArea: "main_area",
  effectiveYearBuilt: "effective_year_built",
  yearBuilt: "year_built",
};

export const EvidenceSideBar = () => {
  const {
    isSideBarOpen,
    leftBarValues,
    filters: tableFilters,
    compsFilters,
    haveLeftBarValues,
    subjectProperty,
    sortData,
    residentType,
    equities,
    sales,
  } = useSelector(singleEvidenceSelectors);

  const [filters, setFilters] = useState(tableFilters);
  const [propertyClass, setPropertyClass] = useState([]);
  const [condition, setCondition] = useState([]);
  const [neighborhoodCode, setNeighborhoodCode] = useState([]);
  const [quality, setQuality] = useState([]);
  const [radius, setRadius] = useState();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const property_id = useParams().propertyId;

  const goBack = () => navigate(`${adminRoutes.prefix}/${adminRoutes.evidence}`);

  const generateParams = () => {
    const params = {
      class_code: propertyClass,
      condition: condition,
      neighborhood_code: neighborhoodCode,
      quality: quality,
      is_sale: residentType === RESIDENT_TYPES.equity ? 0 : 1,
      property_id,
      is_filtered: 1,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "DESC" : "ASC",
      limit: 50,
      page: 1,
      year_build: {
        start: filters["year_built"][0],
        end: filters["year_built"][1],
      },
      effective_year_build: {
        start: filters["effective_year_built"][0],
        end: filters["effective_year_built"][1],
      },
      main_area: {
        start: filters["main_area"][0],
        end: filters["main_area"][1],
      },
      final_market_value: {
        start: filters["final_market_value"][0],
        end: filters["final_market_value"][1],
      },
    };

    Object.keys(params).forEach((key) => {
      if (Array.isArray(params[key])) {
        //todo
      } else if (!Array.isArray(params[key]) && typeof params[key] === "object") {
        if (params[key]?.start === -Infinity) {
          delete params[key].start;
        }
        if (params[key]?.end === Infinity) {
          delete params[key].end;
        }
      }
    });

    return params;
  };

  const handleCompsFiltersClick = () => {
    const params = generateParams();

    if (residentType === RESIDENT_TYPES.equity) {
      dispatch(singleEvidenceActions.getEvidencesEquities({ property_id, ...params }));
    } else if (residentType === RESIDENT_TYPES.sales) {
      dispatch(singleEvidenceActions.getEvidencesSales({ property_id, ...params }));
    }
  };

  const handleFirstFilterChange = (e, key) =>
    setFilters((prevState) => ({
      ...prevState,
      [key]: [Number(e.target.value) || -Infinity, prevState[key][1]],
    }));

  const handleSecondFilterChange = (e, key) =>
    setFilters((prevState) => ({
      ...prevState,
      [key]: [prevState[key][0], Number(e.target.value) || Infinity],
    }));

  const propertyData = [
    {
      id: 1,
      label: "Account:",
      value: leftBarValues?.account_number,
    },
    {
      id: 2,
      label: "Situs:",
      value: leftBarValues?.street_address,
    },
    {
      id: 3,
      label: "Owner:",
      value: leftBarValues?.county_owner_name,
    },
    {
      id: 4,
      label: "County:",
      value: leftBarValues?.county,
    },
  ];

  const propertyInfo = [
    {
      label: "2023 Certified Value",
      value: leftBarValues?.certified_value,
      isMoney: true,
    },
    {
      label: "+/-",
      value: leftBarValues?.sales,
      isMoney: true,
    },
    {
      label: `Adjusted ${residentType === RESIDENT_TYPES.sales ? "Sales" : "Equity"} Value`,
      value: leftBarValues?.adjusted_sales_value,
      isMoney: true,
    },
  ];

  const filterData = [
    {
      title: "Sale Price",
      firstOnChange: (e) => handleFirstFilterChange(e, filterKeys.salePrice),
      secondOnChange: (e) => handleSecondFilterChange(e, filterKeys.salePrice),
      isShow: residentType === RESIDENT_TYPES.sales,
    },
    {
      title: "Year Built",
      description: subjectProperty?.year_build,
      firstOnChange: (e) => handleFirstFilterChange(e, filterKeys.yearBuilt),
      secondOnChange: (e) => handleSecondFilterChange(e, filterKeys.yearBuilt),
      isShow: true,
    },
    {
      title: "Eff. Year Built",
      description: subjectProperty?.effective_year_build,
      firstOnChange: (e) => handleFirstFilterChange(e, filterKeys.effectiveYearBuilt),
      secondOnChange: (e) => handleSecondFilterChange(e, filterKeys.effectiveYearBuilt),
      isShow: true,
    },
    {
      title: "Main Area",
      description: subjectProperty?.land_area,
      firstOnChange: (e) => handleFirstFilterChange(e, filterKeys.mainArea),
      secondOnChange: (e) => handleSecondFilterChange(e, filterKeys.mainArea),
      isShow: true,
    },
    {
      title: "Market Value",
      description: subjectProperty?.current_year_market_value,
      firstOnChange: (e) => handleFirstFilterChange(e, filterKeys.finalMarketValue),
      secondOnChange: (e) => handleSecondFilterChange(e, filterKeys.finalMarketValue),
      isShow: true,
    },
  ];

  const compsData = [
    {
      label: "Neighborhood",
      description: subjectProperty?.neighborhood_code.trim(),
      options: compsFilters?.neighborhoodCode,
      selectedOptions: neighborhoodCode,
      setSelectedOptions: setNeighborhoodCode,
    },
    {
      label: "Condition",
      description: subjectProperty?.condition.trim(),
      options: compsFilters?.condition,
      selectedOptions: condition,
      setSelectedOptions: setCondition,
    },
    {
      label: "Quality",
      description: subjectProperty?.quality.trim(),
      options: compsFilters?.quality,
      selectedOptions: quality,
      setSelectedOptions: setQuality,
    },
    {
      label: "Property Class",
      description: subjectProperty?.class_code.trim(),
      options: compsFilters?.propertyClass,
      selectedOptions: propertyClass,
      setSelectedOptions: setPropertyClass,
    },
  ];

  useEffect(() => {
    dispatch(singleEvidenceActions.getCompsFilters());
  }, []);

  useEffect(() => {
    const params = generateParams();

    if (haveLeftBarValues) {
      if (residentType === RESIDENT_TYPES.equity && !equities?.length) {
        dispatch(singleEvidenceActions.getEvidencesEquities(params));
      } else if (residentType === RESIDENT_TYPES.sales && !sales?.length) {
        dispatch(singleEvidenceActions.getEvidencesSales(params));
      }
    }
  }, [haveLeftBarValues, sortData, residentType]);

  useEffect(() => {
    setPropertyClass([subjectProperty?.class_code]);
    setCondition([subjectProperty?.condition]);
    setNeighborhoodCode([subjectProperty?.neighborhood_code]);
    setQuality([subjectProperty?.quality]);
  }, [subjectProperty]);

  useEffect(() => {
    if (leftBarValues) {
      dispatch(singleEvidenceActions.setHaveLeftBarValues(true));
    }
  }, [leftBarValues]);

  return (
    <S.SlidebarContainer className={isSideBarOpen ? "" : "closed"}>
      <S.SiteBarFixedContainer>
        <PrimaryButton className="back-button" eventHandler={goBack} label="Back" />
        <S.Border />
        <S.PropertyParentContainer>
          {propertyData.map((item) => (
            <S.PropertyContainer key={item.id}>
              <S.Label>{item?.label}</S.Label>
              <S.Value>{item?.value}</S.Value>
            </S.PropertyContainer>
          ))}
        </S.PropertyParentContainer>
        <S.Border />
        <S.PropertyParentContainer>
          {propertyInfo.map((item) => (
            <S.PropertyContainer key={item.label}>
              <S.Label>{item.label}</S.Label>
              {leftBarValues ? (
                <S.Value
                  style={
                    item.label === "+/-"
                      ? { color: COLORS.green_300 }
                      : item.label === "Adjusted Value"
                      ? { color: COLORS.light_blue }
                      : {}
                  }
                >
                  {item.isMoney ? numberToDollar(item.value) : item.value}
                </S.Value>
              ) : null}
            </S.PropertyContainer>
          ))}
        </S.PropertyParentContainer>
        <S.Border />
        <S.FilterContainer>
          <S.Heading>
            <S.HeadingText>SELECT COMPS BY:</S.HeadingText>
            <PrimaryButton eventHandler={handleCompsFiltersClick} label="Apply" />
          </S.Heading>
          <S.InputsContainer>
            <S.MultiSelectWrapper>
              {compsData.map((selectItem) => (
                <LazyLoadSelect
                  key={selectItem.label}
                  floatingLabel
                  description={selectItem.description}
                  options={selectItem.options}
                  placeholder={selectItem.label}
                  selectedOptions={subjectProperty ? selectItem.selectedOptions : []}
                  setSelectedOptions={selectItem.setSelectedOptions}
                />
              ))}
            </S.MultiSelectWrapper>
            <S.Block>
              <S.Title>Radius</S.Title>
              <PrimaryInput readOnly type="number" value={radius} onChange={(e) => setRadius(e.target.value)} />
            </S.Block>
          </S.InputsContainer>
        </S.FilterContainer>
        <S.Border />
        <S.FilterContainer>
          <S.Heading>
            <S.HeadingText>FILTER</S.HeadingText>
            <PrimaryButton eventHandler={handleCompsFiltersClick} label="Apply" />
          </S.Heading>
          <S.InputsContainer>
            {filterData
              .filter((item) => item.isShow)
              .map((item) => (
                <S.InputsBlock key={item.title}>
                  <S.Div>
                    <S.Title>{item.title} &nbsp;</S.Title>
                    <S.Description>({item.description})</S.Description>
                  </S.Div>
                  <S.FilterInput>
                    <PrimaryInput className="compareInput" type="number" onChange={item.firstOnChange} />
                    <S.Span>to</S.Span>
                    <PrimaryInput className="compareInput" type="number" onChange={item.secondOnChange} />
                  </S.FilterInput>
                </S.InputsBlock>
              ))}
          </S.InputsContainer>
        </S.FilterContainer>
      </S.SiteBarFixedContainer>
    </S.SlidebarContainer>
  );
};
