export const HidePassword = (props) => (
  <svg
    {...props}
    aria-hidden="true"
    className="w-[25px] h-[20px] text-gray-800 dark:text-white"
    fill="currentColor"
    viewBox="0 0 20 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
  </svg>
);
