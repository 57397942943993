import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { PrimaryInput } from "common";
import { ContentHeader } from "pages/admin/OwnersSinglePage/styled";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";

import * as S from "./styled";

export const ClientDetails = () => {
  const { singleOwner } = useSelector(singleOwnerSelectors);

  const { full_name, email, phone_number, created_at, referral_code, id } = singleOwner;

  const [editingEmail, setEditingEmail] = useState(false);
  const [emailValue, setEmailValue] = useState(email);
  const [lastUpdatedEmail, setLastUpdatedEmail] = useState(email);

  const dispatch = useDispatch();

  const handleEditEmail = () => {
    setEditingEmail(true);
  };

  const handleSaveEmail = () => {
    if (!emailValue.trim()) {
      setEmailValue(lastUpdatedEmail);
      setEditingEmail(false);
      return;
    }

    dispatch(singleOwnerActions.updateEmail({ owner_id: id, email: emailValue }));
    setEditingEmail(false);
    setLastUpdatedEmail(emailValue);
  };

  const handleCancelEdit = () => {
    setEmailValue(lastUpdatedEmail);
    setEditingEmail(false);
  };

  return (
    <S.Container>
      <S.Content>
        <ContentHeader>Client Details</ContentHeader>
        <S.ClientData>Full Name: {full_name}</S.ClientData>
        <S.ClientData>
          E-Mail Address:
          {editingEmail ? (
            <S.EmailIconContainer>
              <PrimaryInput
                value={emailValue}
                wrapperClassName="email-input"
                onChange={(e) => setEmailValue(e.target.value)}
              />
              {emailValue.trim() ? (
                <Icons.CheckIcon color={COLORS.green} onClick={handleSaveEmail} />
              ) : (
                <Icons.CloseIcon color={COLORS.red} onClick={handleCancelEdit} />
              )}
            </S.EmailIconContainer>
          ) : (
            <S.EmailIconContainer>
              <S.EmailText>{emailValue}</S.EmailText>
              <Icons.EditIcon onClick={handleEditEmail} />
            </S.EmailIconContainer>
          )}
        </S.ClientData>
        <S.ClientData>Phone: {phone_number}</S.ClientData>
        <S.ClientData>Referral Code: {referral_code}</S.ClientData>
        <S.ClientData>Sign Up Date: {created_at}</S.ClientData>
      </S.Content>
    </S.Container>
  );
};
