import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import { exportCSV, getDocumentsList } from "./actions";

const initialState = {
  documentsList: [],
  last_page: 0,
  downloadCsvHref: null,
  allowScroll: false,
  paginationData: {
    first: 10,
    rows: 50,
    page: 1,
  },
  documentsListFilters: {},
  getDocumentsListStatus: LOADING_STATUSES.idle,
  exportCSVStatus: LOADING_STATUSES.idle,
};

export const documentsList = createSlice({
  name: SLICE_NAME.documentsSlice,
  initialState,
  reducers: {
    setDocumentsListFilters: (state, { payload }) => {
      state.documentsListFilters = payload;
    },
    resetAllData: (state, { payload }) => {
      if (!payload) {
        return {
          ...initialState,
          documentsListFilters: state.documentsListFilters,
        };
      } else {
        return initialState;
      }
    },
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    setAllowScroll: (state, { payload }) => {
      state.allowScroll = payload;
    },
  },
  extraReducers: ({ addCase }) => {
    // get documents list
    addCase(getDocumentsList.pending, (state) => {
      state.getDocumentsListStatus = LOADING_STATUSES.pending;
    });
    addCase(getDocumentsList.fulfilled, (state, { payload }) => {
      state.getDocumentsListStatus = LOADING_STATUSES.succeeded;
      state.documentsList = payload?.data;
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
    });
    addCase(getDocumentsList.rejected, (state) => {
      state.getDocumentsListStatus = LOADING_STATUSES.failed;
    });

    // export CSV
    addCase(exportCSV.pending, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCSV.fulfilled, (state, { payload }) => {
      state.downloadCsvHref = payload;
      state.exportCSVStatus = LOADING_STATUSES.succeeded;
    });
    addCase(exportCSV.rejected, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.failed;
    });
  },
});

export const documentsListReducer = documentsList.reducer;

export const documentsActions = {
  ...documentsList.actions,
  getDocumentsList,
  exportCSV,
};
