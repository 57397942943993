import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const ModalContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.overlay_200};
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: ${COLORS.white};
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 15px;
  padding: 40px 60px;
  border-radius: 6px;
  position: relative;

  .close-button {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;

export const UploadButton = styled.div`
  height: 44px;
`;

export const FileInput = styled.input`
  display: none;
`;

export const File = styled.div`
  display: flex;
  max-width: 200px;
  border: 1px solid ${COLORS.green};
  border-radius: 4px;
  padding: 6px;

  .delete-file {
    cursor: pointer;
  }
`;

export const FileName = styled.div`
  width: 90%;
  font-size: 12px;
`;
