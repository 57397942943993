import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "common";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";

import { ArchiveModal } from "components/ArchiveModal";

import * as S from "./styled";

export const ArchiveOwner = () => {
  const { archiveOwnerStatus, singleOwner } = useSelector(singleOwnerSelectors);

  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleArchiveOwnerDispatch = (params) => {
    dispatch(singleOwnerActions.archiveOwner({ navigate, params }));
  };

  return (
    <S.ArchiveOwnerContent>
      <S.ContentTitle>Archive Owner</S.ContentTitle>
      <ArchiveModal
        handleArchiveDispatch={handleArchiveOwnerDispatch}
        isOpenModal={isOpenArchiveModal}
        setIsOpenModal={setIsOpenArchiveModal}
        singleArchiveId={singleOwner.id}
        singleArchiveStatus={archiveOwnerStatus}
      />
      <PrimaryButton
        className="archive-owner-button"
        eventHandler={() => setIsOpenArchiveModal(true)}
        label="archive owner"
        mode="gray"
      />
    </S.ArchiveOwnerContent>
  );
};
