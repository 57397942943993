import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const AdminLayoutContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  overflow-y: hidden;

  .pagination button {
    &.p-highlight,
    &:hover {
      background-color: ${COLORS.light_green} !important;
      border: none !important;
      color: ${COLORS.deepDark} !important;
    }
  }

  // primereact styles
  .p-highlight {
    background-color: ${COLORS.light_green} !important;
    color: ${COLORS.dark} !important;
    box-shadow: none !important;

    span svg {
    }
  }

  .p-checkbox-box.p-highlight {
    background-color: ${COLORS.green} !important;
    border-color: ${COLORS.green} !important;
  }

  .p-checkbox-box {
    &:hover {
      border-color: ${COLORS.green} !important;
    }
  }

  .p-checkbox-box.p-highlight.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px ${COLORS.light_green} !important;
    border-color: ${COLORS.green} !important;
  }

  .p-checkbox-focused {
    box-shadow: none !important;
  }

  .p-component-overlay {
    background-color: ${COLORS.light_green}95 !important;
  }

  .p-sortable-column-icon {
    color: ${COLORS.dark} !important;
  }
  .p-inputwrapper {
    &:hover {
      border-color: ${COLORS.green} !important;
    }
  }
  .p-inputwrapper.p-inputwrapper-focus {
    box-shadow: none !important;
    border-color: ${COLORS.green} !important;
  }
`;

export const AdminNavigationBar = styled.div`
  min-width: 102px;
  border-radius: 1px solid ${COLORS.dark};
  display: flex;
  height: auto;
  position: relative;
  z-index: 20;
  .navBarOpenClose {
    width: 100px;
    .active {
      background-color: ${COLORS.green};
    }
    &:hover {
      width: 400px;
      .hideTitle {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  .hideTitle {
    opacity: 0;
    pointer-events: none;
    min-width: max-content;
  }
`;

export const RightSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 102px);
`;

export const AdminHeader = styled.div`
  height: 70px;
  background-color: ${COLORS.green};
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    color: ${COLORS.white};
    font-size: 24px;
    margin-left: 50px;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-right: 50px;
`;

export const UserName = styled.p`
  font-size: 12px;
  color: ${COLORS.white};
`;

export const AvatarIconContainer = styled.span`
  display: inline-block;
  height: 35px;
  width: 35px;
  background-color: #f0f0f0;
  border-radius: 50%;
  overflow: hidden;
`;

export const UserLayoutContent = styled.div`
  min-height: calc(100vh - 300px - 90px);
`;

export const ClientContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (${BREAKPOINTS.md}) {
    display: flex;
    flex-direction: row;
  }
`;

export const Content = styled.div`
  width: 100%;
  background-color: ${COLORS.overlay_150};
  padding: 60px 180px 0;
  max-height: 100vh;
  overflow-y: hidden;

  @media screen and (${BREAKPOINTS.sm}) {
    padding: 16px;
  }

  @media screen and (${BREAKPOINTS.md}) {
    padding: 60px 46px 0;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    padding: 60px 100px 0;
  }
`;

export const OutletWrapper = styled.div`
  overflow-y: hidden;
  min-height: 80vh;
`;
