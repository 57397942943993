import axios from "axios";
import { API } from "configs/api";

export const clientApi = {
  checkEmail: (body) => axios.post(API.checkEmail, body),
  checkCode: (body) => axios.post(API.checkCode, body),
  getMe: () => axios.get(API.me, { owner_token: true }),
  appeals: () => axios.get(API.ownerPortalAppeals, { owner_token: true }),
  properties: () => axios.get(API.ownerPortalProperties, { owner_token: true }),
  invoices: () => axios.get(API.ownerPortalInvoices, { owner_token: true }),
};
