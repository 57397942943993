import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import {
  exportCSV,
  getSingleAoaDocuments,
  getSingleSaDocuments,
  getTracker,
  putAoaFileName,
  putSaFileName,
  uploadAoaDocument,
  uploadSaDocument,
} from "./actions";

const initialState = {
  AoaDocs: [],
  SaDocs: [],
  trackerData: [],
  per_page: 10,
  last_page: 0,
  isModalOpen: false,
  paginationData: {
    first: 0,
    rows: 10,
    page: 1,
  },
  downloadCsvHref: null,
  getSingleAoaDocumentsStatus: LOADING_STATUSES.idle,
  getSingleSaDocumentsStatus: LOADING_STATUSES.idle,
  putAoaFileNameStatus: LOADING_STATUSES.idle,
  putSaFileNameStatus: LOADING_STATUSES.idle,
  uploadAoaDocumentStatus: LOADING_STATUSES.idle,
  uploadSaDocumentStatus: LOADING_STATUSES.idle,
  getTrackerDataStatus: LOADING_STATUSES.idle,
  exportCsvStatus: LOADING_STATUSES.idle,
};

export const singleDocumentsList = createSlice({
  name: SLICE_NAME.singleDocumentsSlice,
  initialState,
  reducers: {
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    resetAllData: () => initialState,
  },
  extraReducers: ({ addCase }) => {
    // get documents list AOA
    addCase(getSingleAoaDocuments.pending, (state) => {
      state.getSingleAoaDocumentsStatus = LOADING_STATUSES.pending;
    });
    addCase(getSingleAoaDocuments.fulfilled, (state, { payload }) => {
      state.getSingleAoaDocumentsStatus = LOADING_STATUSES.succeeded;
      state.AoaDocs = payload?.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    });
    addCase(getSingleAoaDocuments.rejected, (state) => {
      state.getSingleAoaDocumentsStatus = LOADING_STATUSES.failed;
    });

    //get documents list SA
    addCase(getSingleSaDocuments.pending, (state) => {
      state.getSingleSaDocumentsStatus = LOADING_STATUSES.pending;
    });
    addCase(getSingleSaDocuments.fulfilled, (state, { payload }) => {
      state.getSingleSaDocumentsStatus = LOADING_STATUSES.succeeded;
      state.SaDocs = payload?.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    });
    addCase(getSingleSaDocuments.rejected, (state) => {
      state.getSingleSaDocumentsStatus = LOADING_STATUSES.failed;
    });

    //put AOA file name
    addCase(putAoaFileName.pending, (state) => {
      state.putAoaFileNameStatus = LOADING_STATUSES.pending;
    });
    addCase(putAoaFileName.fulfilled, (state, { payload }) => {
      state.AoaDocs = state.AoaDocs.map((doc) => {
        if (doc.id === payload.selectedId) {
          return { ...doc, filename: payload.value };
        }
        return doc;
      });
      state.putAoaFileNameStatus = LOADING_STATUSES.succeeded;
    });
    addCase(putAoaFileName.rejected, (state) => {
      state.putAoaFileNameStatus = LOADING_STATUSES.failed;
    });

    //put Sa file name
    addCase(putSaFileName.pending, (state) => {
      state.putSaFileNameStatus = LOADING_STATUSES.pending;
    });
    addCase(putSaFileName.fulfilled, (state, { payload }) => {
      state.SaDocs = state.SaDocs.map((doc) => {
        if (doc.id === payload.selectedId) {
          return { ...doc, filename: payload.value };
        }
        return doc;
      });
      state.putSaFileNameStatus = LOADING_STATUSES.succeeded;
    });
    addCase(putSaFileName.rejected, (state) => {
      state.putSaFileNameStatus = LOADING_STATUSES.failed;
    });

    // upload Aoa document

    addCase(uploadAoaDocument.pending, (state) => {
      state.uploadAoaDocumentStatus = LOADING_STATUSES.pending;
    });
    addCase(uploadAoaDocument.fulfilled, (state, { payload }) => {
      const { aoa, ...restData } = payload.data;

      state.AoaDocs = [{ ...aoa, ...restData }, ...state.AoaDocs];
      state.uploadAoaDocumentStatus = LOADING_STATUSES.succeeded;
    });

    addCase(uploadAoaDocument.rejected, (state) => {
      state.uploadAoaDocumentStatus = LOADING_STATUSES.failed;
    });

    //   upload Sa document

    addCase(uploadSaDocument.pending, (state) => {
      state.uploadSaDocumentStatus = LOADING_STATUSES.pending;
    });
    addCase(uploadSaDocument.fulfilled, (state, { payload }) => {
      const { sa, ...restData } = payload.data;

      state.SaDocs = [{ ...sa, ...restData }, ...state.SaDocs];
      state.uploadSaDocumentStatus = LOADING_STATUSES.succeeded;
    });

    addCase(uploadSaDocument.rejected, (state) => {
      state.uploadSaDocumentStatus = LOADING_STATUSES.failed;
    });

    //get tracker list
    addCase(getTracker.pending, (state) => {
      state.getTrackerDataStatus = LOADING_STATUSES.pending;
    });
    addCase(getTracker.fulfilled, (state, { payload }) => {
      state.trackerData = payload.data;
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
      state.getTrackerDataStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getTracker.rejected, (state) => {
      state.getTrackerDataStatus = LOADING_STATUSES.failed;
    });

    // export csv
    addCase(exportCSV.pending, (state) => {
      state.exportCsvStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCSV.fulfilled, (state, { payload }) => {
      state.downloadCsvHref = payload;
      state.exportCsvStatus = LOADING_STATUSES.succeeded;
    });
    addCase(exportCSV.rejected, (state) => {
      state.exportCsvStatus = LOADING_STATUSES.failed;
    });
  },
});

export const singleDocumentsListReducer = singleDocumentsList.reducer;

export const documentsSingleActions = {
  ...singleDocumentsList.actions,
  exportCSV,
  getSingleAoaDocuments,
  getSingleSaDocuments,
  getTracker,
  putAoaFileName,
  putSaFileName,
  uploadAoaDocument,
  uploadSaDocument,
};
