import * as yup from "yup";

export const createProfileSchema = yup.object({
  name: yup.string().required("Full Name is required"),
  email: yup.string().email("Invalid email address").required("Email is required"),
  password: yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  role_description: yup.string().required("Role description is required").max(255, "Maximum 255 characters"),
});
