import { ToastContainer } from "react-toastify";
import Router from "router";

import { AcceptModal } from "components/AcceptModal";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <Router />
      <AcceptModal />
      <ToastContainer
        closeOnClick
        draggable
        hideProgressBar
        pauseOnFocusLoss
        pauseOnHover
        autoClose={5000}
        newestOnTop={false}
        position="top-right"
        rtl={false}
        theme="colored"
      />
    </>
  );
}

export default App;
