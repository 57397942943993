import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const InvoicesScrollBlock = styled.div`
  overflow-y: auto;
  height: calc(100vh - 60px);
  min-height: auto;
  padding-bottom: 60px;

  &::-webkit-scrollbar {
    background-color: ${COLORS.overlay_150};
    width: 6px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 4px;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    width: auto;
  }
`;

export const InvoicesContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1vw;
  margin-bottom: 40px;
  background: ${COLORS.white};
  padding: 6px 6px;
  color: ${COLORS.deepDark};
  border-radius: 10px;
  transition-duration: 0.28s;
  border: 1px solid ${COLORS.green};

  &:hover {
    box-shadow: 0 5px 14px 0 ${COLORS.green};
    cursor: pointer;
  }

  @media screen and (${BREAKPOINTS.sm}) {
    width: 90%;
    padding: 14px;
  }

  @media screen and (${BREAKPOINTS.md}) {
    width: 360px;
    height: 20px;
    padding: 34px 20px;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    width: 482px;
    height: 60px;
    padding: 34px 24px;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    width: 720px;
    height: 124px;
    padding: 40px;
  }
`;

export const LoaderBox = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
`;

export const InvoicesLink = styled.a``;

export const InvoicesText = styled.div``;

export const InvoicesIconBlock = styled.div`
  background-color: ${COLORS.green};
  border-radius: 5px;
  padding: 2px;
`;

export const InvoicesMessageContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1vw;
  margin-bottom: 40px;
  background: ${COLORS.white};
  padding: 6px 6px;
  color: ${COLORS.deepDark};
  border-radius: 10px;
  border: 1px solid ${COLORS.green};

  @media screen and (${BREAKPOINTS.sm}) {
    width: 90%;
    padding: 14px;
  }

  @media screen and (${BREAKPOINTS.md}) {
    width: 360px;
    height: 20px;
    padding: 34px 20px;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    width: 482px;
    height: 60px;
    padding: 34px 24px;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    width: 720px;
    height: 124px;
    padding: 40px;
  }
`;
