import { Checkbox as TailWindCheckbox } from "@material-tailwind/react";
import { ErrorMessage } from "common";

import * as S from "./styled";

export const Checkbox = ({ error, description, register, ...rest }) => (
  <>
    <S.Container>
      <TailWindCheckbox {...rest} {...register} className="focus:ring-transparent" />
      <S.Description>{description}</S.Description>
    </S.Container>
    {error && <ErrorMessage>{error.message}</ErrorMessage>}
  </>
);
