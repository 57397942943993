import { useSelector } from "react-redux";
import { Table } from "common";
import { singleArchivedSelectors } from "store/admin-slices/single-archived-slice/selectors";
import { isLoading } from "utils/constants";

import { Footer } from "./Footer";
import * as S from "./styled";
export const ReferredBy = () => {
  const { referredBy, postReferredByStatus, getReferredByStatus } = useSelector(singleArchivedSelectors);

  const tableData = [
    { field: "full_name", header: "Name" },
    { field: "", header: "" },
    { field: "email", header: "Email" },
    { field: "", header: "" },
    { field: "referral_code", header: "Code" },
    { field: "", header: "" },
    { header: "Date", field: "updated_at" },
    {
      header: "Percent",
      body: (item) => (item ? "10%" : ""),
    },
  ];

  return (
    <S.Container>
      <S.Content>
        <S.FilterBar>
          <S.ContentTitle>Referred by</S.ContentTitle>
        </S.FilterBar>
        <Table
          dataKey="id"
          footer={<Footer referralsLength={referredBy ? 1 : null} />}
          loading={isLoading(getReferredByStatus) || isLoading(postReferredByStatus)}
          tableData={tableData}
          value={[referredBy]}
        />
      </S.Content>
    </S.Container>
  );
};
