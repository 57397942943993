import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, RESIDENT_TYPES, SLICE_NAME, TOAST_TYPES } from "utils/constants";
import { toastify } from "utils/helpers";

import {
  exportCompsCsv,
  getCompsFilters,
  getEvidencesEquities,
  getEvidencesSales,
  getLeftBarValues,
  getSaveComps,
  getSubjectProperty,
  saveComps,
} from "./actions";

const initialState = {
  isOpenSettingsModal: false,
  isSideBarOpen: true,
  equities: [],
  sales: [],
  savedComps: [],
  subjectProperty: null,
  leftBarValues: null,
  downloadCompsCsvHref: null,
  haveLeftBarValues: false,
  filteredBody: {},
  sortData: {
    field: "adjusted_value",
    direction: -1,
  },
  per_page: 50,
  last_page: 0,
  last_page_sales: 0,
  paginationData: {
    first: 10,
    rows: 50,
    page: 1,
  },
  compsFilters: {
    propertyClass: [],
    stateCode: [],
    condition: [],
    mapsco: [],
    neighborhoodCode: [],
    quality: [],
    tadMap: [],
  },
  residentType: RESIDENT_TYPES.equity,
  filters: {
    final_market_value: [-Infinity, Infinity],
    total_area: [-Infinity, Infinity],
    main_area: [-Infinity, Infinity],
    effective_year_built: [-Infinity, Infinity],
    year_built: [-Infinity, Infinity],
  },
  compFilters: {
    neighborhood_code: "",
    property_type: "",
    property_class: "",
    state_code: "",
    radius: "",
  },
  tableData: [
    {
      field: "account_number",
      header: "Account Number",
      sortable: true,
      isShow: true,
    },
    {
      field: "adjusted_value",
      header: "Adjusted value",
      body: (item) => Number(item?.adjusted_value).toLocaleString(),
      sortable: true,
      isShow: true,
    },
    {
      field: "net_adj",
      header: "Net Adj %",
      sortable: true,
      isShow: true,
    },
    {
      field: "land_area",
      body: (item) => Number(item?.land_area).toLocaleString(),
      header: "Main area",
      sortable: true,
      isShow: true,
    },
    {
      field: "year_build",
      header: "Year built",
      sortable: true,
      isShow: true,
    },
    {
      field: "effective_year_build",
      header: "EYOC",
      sortable: true,
      isShow: true,
    },
    {
      field: "current_year_market_value",
      header: "Noticed val",
      body: (item) => Number(item?.current_year_market_value).toLocaleString(),
      sortable: true,
      isShow: true,
    },
    {
      field: "current_year_market_value",
      header: "Market val",
      body: (item) => Number(item?.current_year_market_value).toLocaleString(),
      sortable: true,
      isShow: true,
    },
    {
      field: "quality",
      header: "Quality",
      sortable: true,
      isShow: true,
    },
    {
      field: "condition",
      header: "Condition",
      sortable: true,
      isShow: true,
    },
    {
      field: "close_date",
      header: "Sale date",
      sortable: true,
      isShow: false,
    },
    {
      field: "close_price",
      header: "Sale price",
      sortable: true,
      isShow: false,
    },
    {
      field: "neighborhood_code",
      header: "Neighborhood Code",
      sortable: true,
      isShow: true,
    },
    {
      field: "property_index",
      header: "Index",
      sortable: true,
      isShow: true,
    },
    {
      field: "other_feature_value",
      body: (item) => Number(item?.other_feature_value).toLocaleString(),
      header: "Other feature value",
      sortable: true,
      isShow: true,
    },
    {
      field: "outbuilding_value",
      body: (item) => Number(item?.outbuilding_value).toLocaleString(),
      header: "Outbuilding value",
      sortable: true,
      isShow: true,
    },
    {
      field: "pool_value",
      body: (item) => Number(item?.pool_value).toLocaleString(),
      header: "Pool value",
      sortable: true,
      isShow: true,
    },
    {
      field: "garage_value",
      body: (item) => Number(item?.garage_value).toLocaleString(),
      header: "Garage value",
      sortable: true,
      isShow: true,
    },
  ],
  getEvidencesSalesStatus: LOADING_STATUSES.idle,
  getEvidencesEquitiesStatus: LOADING_STATUSES.idle,
  getSaveCompsStatus: LOADING_STATUSES.idle,
  saveCompsStatus: LOADING_STATUSES.idle,
  exportCompsCsvStatus: LOADING_STATUSES.idle,
  getCompsFiltersStatus: LOADING_STATUSES.idle,
  getLeftBarValuesStatus: LOADING_STATUSES.idle,
  getSubjectPropertyStatus: LOADING_STATUSES.idle,
};

export const singleEvidenceSlice = createSlice({
  name: SLICE_NAME.singleEvidenceSlice,
  initialState,
  reducers: {
    resetAllData: () => initialState,
    toggleSideBar: (state) => {
      state.isSideBarOpen = !state.isSideBarOpen;
    },
    toggleSettingsModal: (state) => {
      state.isOpenSettingsModal = !state.isOpenSettingsModal;
    },

    selectResidentType: (state, { payload }) => {
      state.residentType = payload;

      const showingRowsForResidentTypes = {
        [RESIDENT_TYPES.sales]: [
          "account_number",
          "situs",
          "adjusted_value",
          "net_adj",
          "distance",
          "land_area",
          "year_build",
          "quality",
          "property_index",
          "condition",
          "current_year_market_value",
          "close_price",
          "close_date",
          "effective_year_build",
          "other_feature_value",
          "outbuilding_value",
          "pool_value",
          "garage_value",
        ],
        [RESIDENT_TYPES.equity]: [
          "account_number",
          "adjusted_value",
          "net_adj",
          "land_area",
          "year_build",
          "effective_year_build",
          "current_year_market_value",
          "quality",
          "condition",
          "neighborhood_code",
          "property_index",
          "other_feature_value",
          "outbuilding_value",
          "pool_value",
          "garage_value",
        ],
      };

      state.tableData = state.tableData.map((item) => {
        if (showingRowsForResidentTypes[payload].includes(item.field)) {
          return { ...item, isShow: true };
        }
        return { ...item, isShow: false };
      });
    },
    addFilters: (state, { payload }) => {
      state.filters = payload;
    },
    addCompFilters: (state, { payload }) => {
      state.compFilters = payload;
    },
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.compFilters = initialState.compFilters;
    },
    setFilteredBody: (state, { payload }) => {
      state.filteredBody = payload;
    },
    setHaveLeftBarValues: (state, { payload }) => {
      state.haveLeftBarValues = payload;
    },
    setSortData: (state, { payload }) => {
      state.sortData = payload;
    },
  },
  extraReducers: ({ addCase }) => {
    // get evidences equities
    addCase(getEvidencesEquities.pending, (state) => {
      state.getEvidencesEquitiesStatus = LOADING_STATUSES.pending;
    });
    addCase(getEvidencesEquities.fulfilled, (state, { payload }) => {
      state.equities = payload.data;
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
      state.getEvidencesEquitiesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getEvidencesEquities.rejected, (state) => {
      state.getEvidencesEquitiesStatus = LOADING_STATUSES.failed;
    });

    // get sales
    addCase(getEvidencesSales.pending, (state) => {
      state.getEvidencesSalesStatus = LOADING_STATUSES.pending;
    });
    addCase(getEvidencesSales.fulfilled, (state, { payload }) => {
      state.sales = payload.data;
      state.last_page_sales = payload?.meta?.total;
      state.getEvidencesSalesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getEvidencesSales.rejected, (state) => {
      state.getEvidencesSalesStatus = LOADING_STATUSES.pending;
    });

    // get saved comps
    addCase(getSaveComps.pending, (state) => {
      state.getSaveCompsStatus = LOADING_STATUSES.pending;
      state.savedComps = [];
    });
    addCase(getSaveComps.fulfilled, (state, { payload }) => {
      state.getSaveCompsStatus = LOADING_STATUSES.succeeded;
      if (Array.isArray(payload)) {
        state.savedComps = payload.map((item) => Number(item));
      }
    });
    addCase(getSaveComps.rejected, (state) => {
      state.getSaveCompsStatus = LOADING_STATUSES.failed;
    });

    // get left bar values
    addCase(getLeftBarValues.pending, (state) => {
      state.getLeftBarValuesStatus = LOADING_STATUSES.pending;
    });
    addCase(getLeftBarValues.fulfilled, (state, { payload }) => {
      state.getLeftBarValuesStatus = LOADING_STATUSES.succeeded;
      state.leftBarValues = payload.data;
    });
    addCase(getLeftBarValues.rejected, (state) => {
      state.getLeftBarValuesStatus = LOADING_STATUSES.failed;
    });

    // save comps
    addCase(saveComps.pending, (state) => {
      state.saveCompsStatus = LOADING_STATUSES.pending;
    });
    addCase(saveComps.fulfilled, (state) => {
      state.saveCompsStatus = LOADING_STATUSES.succeeded;
      toastify(TOAST_TYPES.success, "Comps saved successfully");
    });
    addCase(saveComps.rejected, (state) => {
      state.saveCompsStatus = LOADING_STATUSES.failed;
    });

    // export csv
    addCase(exportCompsCsv.pending, (state) => {
      state.exportCompsCsvStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCompsCsv.fulfilled, (state, { payload }) => {
      state.exportCompsCsvStatus = LOADING_STATUSES.succeeded;
      state.downloadCompsCsvHref = payload;
    });
    addCase(exportCompsCsv.rejected, (state) => {
      state.exportCompsCsvStatus = LOADING_STATUSES.failed;
    });

    // get subject property
    addCase(getSubjectProperty.fulfilled, (state, { payload }) => {
      state.subjectProperty = payload.data;
      state.subjectProperty.property_index = 0;
      function updateFilter(state, filterArray, payloadData, propertyName) {
        const newValue = payloadData[propertyName];

        const existingIndex = state.compsFilters[filterArray].findIndex((item) => item.description === newValue);

        if (existingIndex !== -1) {
          state.compsFilters[filterArray].splice(existingIndex, 1);
        }

        state.compsFilters[filterArray].unshift({
          description: newValue,
          value: newValue,
        });
      }

      updateFilter(state, "propertyClass", payload.data, "class_code");
      updateFilter(state, "condition", payload.data, "condition");
      updateFilter(state, "neighborhoodCode", payload.data, "neighborhood_code");
      updateFilter(state, "quality", payload.data, "quality");
      updateFilter(state, "stateCode", payload.data, "state_code");

      state.getSubjectPropertyStatus = LOADING_STATUSES.succeeded;
    });

    // get comps filters
    addCase(getCompsFilters.pending, (state) => {
      state.getCompsFiltersStatus = LOADING_STATUSES.pending;
    });
    addCase(getCompsFilters.fulfilled, (state, { payload }) => {
      state.getCompsFiltersStatus = LOADING_STATUSES.succeeded;

      const stateCode = payload.data?.state_code
        .map((item) => {
          if (item === "") {
            return { description: "None", value: "None" };
          } else {
            return { description: item.trim(), value: item.trim() };
          }
        })
        ?.filter((item) => item.value !== state.compsFilters.stateCode?.[0]?.value);

      const propertyClass = payload.data?.class_code
        .map((item) => {
          if (item === "") {
            return { description: "None", value: "None" };
          } else {
            return { description: item.trim(), value: item.trim() };
          }
        })
        ?.filter((item) => item.value !== state.compsFilters.state_code?.[0]?.value);

      const condition = payload.data?.condition
        .map((item) => {
          if (item === "") {
            return { description: "None", value: "None" };
          } else {
            return { description: item.trim(), value: item.trim() };
          }
        })
        ?.filter((item) => item.value !== state.compsFilters.condition?.[0]?.value);

      const mapsco = payload.data?.mapsco
        .map((item) => {
          if (item === "") {
            return { description: "None", value: "None" };
          } else {
            return { description: item.trim(), value: item.trim() };
          }
        })
        ?.filter((item) => item.value !== state.compsFilters.mapsco?.[0]?.value);

      const neighborhoodCode = payload.data?.neighborhood_code
        .map((item) => {
          if (item === "") {
            return { description: "None", value: "None" };
          } else {
            return { description: item.trim(), value: item.trim() };
          }
        })
        ?.filter((item) => item.value !== state.compsFilters.neighborhood_code?.[0]?.value);

      const quality = payload.data?.quality
        .map((item) => {
          if (item === "") {
            return { description: "None", value: "None" };
          } else {
            return { description: item.trim(), value: item.trim() };
          }
        })
        ?.filter((item) => item.value !== state.compsFilters.quality?.[0]?.value);

      const tadMap = payload.data?.tad_map
        .map((item) => {
          if (item === "") {
            return { description: "None", value: "None" };
          } else {
            return { description: item.trim(), value: item.trim() };
          }
        })
        ?.filter((item) => item.value !== state.compsFilters.tad_map?.[0]?.value);

      state.compsFilters = {
        stateCode: [...state.compsFilters.stateCode, ...stateCode],
        propertyClass: [...state.compsFilters.propertyClass, ...propertyClass],
        condition: [...state.compsFilters.condition, ...condition],
        mapsco: [...state.compsFilters.mapsco, ...mapsco],
        neighborhoodCode: [...state.compsFilters.neighborhoodCode, ...neighborhoodCode],
        quality: [...state.compsFilters.quality, ...quality],
        tadMap: [...state.compsFilters.tadMap, ...tadMap],
      };
    });
    addCase(getCompsFilters.rejected, (state) => {
      state.getCompsFiltersStatus = LOADING_STATUSES.failed;
    });
  },
});

export const singleEvidenceReducer = singleEvidenceSlice.reducer;

export const singleEvidenceActions = {
  ...singleEvidenceSlice.actions,
  exportCompsCsv,
  getEvidencesSales,
  getLeftBarValues,
  getSaveComps,
  saveComps,
  getCompsFilters,
  getEvidencesEquities,
  getSubjectProperty,
};
