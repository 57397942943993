export const HelpIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_347_454)" id="Frame">
        <g id="Group">
          <path
            d="M10 0C4.47301 0 0 4.4725 0 10C0 15.5269 4.4725 20 10 20C15.527 20 20 15.5275 20 10C20 4.47301 15.5275 0 10 0ZM10 18.6046C5.25539 18.6046 1.39535 14.7446 1.39535 10C1.39535 5.25535 5.25539 1.39535 10 1.39535C14.7446 1.39535 18.6046 5.25535 18.6046 10C18.6046 14.7446 14.7446 18.6046 10 18.6046Z"
            fill="white"
            id="Vector"
          />
          <path
            d="M9.70401 12.6533C9.15116 12.6533 8.70362 13.114 8.70362 13.6668C8.70362 14.2065 9.138 14.6804 9.70401 14.6804C10.27 14.6804 10.7175 14.2065 10.7175 13.6668C10.7175 13.114 10.2568 12.6533 9.70401 12.6533ZM9.87511 4.97925C8.09808 4.97925 7.28198 6.03233 7.28198 6.74311C7.28198 7.25647 7.71636 7.49343 8.07175 7.49343C8.78257 7.49343 8.493 6.47983 9.83562 6.47983C10.4937 6.47983 11.0203 6.76944 11.0203 7.37495C11.0203 8.08573 10.2832 8.49378 9.84878 8.86233C9.46702 9.19136 8.96687 9.73108 8.96687 10.8631C8.96687 11.5476 9.15116 11.745 9.69081 11.745C10.3358 11.745 10.4674 11.4555 10.4674 11.2053C10.4674 10.5208 10.4806 10.126 11.2046 9.55995C11.56 9.28355 12.6788 8.38843 12.6788 7.15112C12.6788 5.91382 11.56 4.97925 9.87511 4.97925Z"
            fill="white"
            id="Vector_2"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_347_454">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
