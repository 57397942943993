import axios from "axios";
import { API } from "configs/api";

export const documentsListApi = {
  getDocuments: (params) => axios.get(API.documents, { token: true, params }),
  exportCSV: (params) =>
    axios.get(API.documents + API.exportCSV, {
      token: true,
      responseType: "blob",
      params,
    }),
};
