import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME, TOAST_TYPES } from "utils/constants";
import { toastify } from "utils/helpers";
import { v4 as uuid } from "uuid";

import { exportCSV, getArchivesList, importArchiveProperties, postContactAgain, unarchiveProperty } from "./actions";

const initialState = {
  archivesList: [],
  last_page: 0,
  downloadCsvHref: null,
  isOpenModal: false,
  selectedOwnerId: null,
  selectedPropertyId: null,
  allowScroll: false,
  archivedPageFilters: {},
  exportCSVStatus: LOADING_STATUSES.idle,
  importArchivePropertiesStatus: LOADING_STATUSES.idle,
  getArchivesListStatus: LOADING_STATUSES.idle,
  unarchivePropertyStatus: LOADING_STATUSES.idle,
};

export const archivesList = createSlice({
  name: SLICE_NAME.archiveSlice,
  initialState,
  reducers: {
    setIsOpenModal: (state, { payload }) => {
      state.isOpenModal = payload;
    },
    setSelectedOwnerId: (state, { payload }) => {
      state.selectedOwnerId = payload;
    },
    setSelectedPropertyId: (state, { payload }) => {
      state.selectedPropertyId = payload;
    },
    setArchivedPageFilters: (state, { payload }) => {
      state.archivedPageFilters = payload;
    },
    setAllowScroll: (state, { payload }) => {
      state.allowScroll = payload;
    },
    resetAllData: (state, { payload }) => {
      if (!payload) {
        return {
          ...initialState,
          archivedPageFilters: state.archivedPageFilters,
        };
      } else {
        return initialState;
      }
    },
  },
  extraReducers: ({ addCase }) => {
    // get archived list
    addCase(getArchivesList.pending, (state) => {
      state.getArchivesListStatus = LOADING_STATUSES.pending;
    });
    addCase(getArchivesList.fulfilled, (state, { payload }) => {
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
      state.archivesList = payload?.data.map((item) => ({
        ...item,
        uniqueId: uuid(),
      }));
      state.getArchivesListStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getArchivesList.rejected, (state) => {
      state.getArchivesListStatus = LOADING_STATUSES.failed;
    });

    // import accounts
    addCase(importArchiveProperties.pending, (state) => {
      state.importArchivePropertiesStatus = LOADING_STATUSES.pending;
    });
    addCase(importArchiveProperties.fulfilled, (state) => {
      toastify(TOAST_TYPES.success, "Accounts successfully imported");
      state.importArchivePropertiesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(importArchiveProperties.rejected, (state) => {
      state.importArchivePropertiesStatus = LOADING_STATUSES.failed;
    });

    // contact again
    addCase(postContactAgain.pending, (state) => {
      state.getArchivesListStatus = LOADING_STATUSES.pending;
    });
    addCase(postContactAgain.fulfilled, (state, { payload }) => {
      state.archivesList = state.archivesList.map((item) => {
        if (item.property_id === payload.property_id) {
          return {
            ...item,
            ...payload,
          };
        }
        return item;
      });
      state.getArchivesListStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postContactAgain.rejected, (state) => {
      state.getArchivesListStatus = LOADING_STATUSES.failed;
    });

    // export CSV
    addCase(exportCSV.pending, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCSV.fulfilled, (state, { payload }) => {
      state.downloadCsvHref = payload;
      state.exportCSVStatus = LOADING_STATUSES.succeeded;
    });
    addCase(exportCSV.rejected, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.failed;
    });

    // unarchived property
    addCase(unarchiveProperty.pending, (state) => {
      state.unarchivePropertyStatus = LOADING_STATUSES.pending;
    });
    addCase(unarchiveProperty.fulfilled, (state) => {
      state.unarchivePropertyStatus = LOADING_STATUSES.succeeded;
    });
    addCase(unarchiveProperty.rejected, (state) => {
      state.unarchivePropertyStatus = LOADING_STATUSES.failed;
    });
  },
});

export const archivesListReducer = archivesList.reducer;

export const archiveActions = {
  ...archivesList.actions,
  getArchivesList,
  exportCSV,
  importArchiveProperties,
  postContactAgain,
  unarchiveProperty,
};
