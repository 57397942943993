export const ClarityProcessIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_347_462)" id="clarity:process-on-vm-line">
        <path
          d="M18.6057 14.5999C18.5666 14.4614 18.4752 14.3434 18.3509 14.2708C18.2265 14.1983 18.0788 14.1768 17.939 14.211L16.5557 14.5833C17.3126 13.4674 17.7716 12.1765 17.889 10.8333C18.0025 9.57654 17.8187 8.31074 17.3526 7.13815C16.8864 5.96557 16.151 4.91909 15.2057 4.08326C15.0964 3.99288 14.9567 3.94771 14.8152 3.95701C14.6737 3.9663 14.5411 4.02936 14.4445 4.13326C14.3474 4.24383 14.2982 4.38841 14.3075 4.53527C14.3169 4.68213 14.3842 4.81927 14.4945 4.9166C15.3091 5.63562 15.9428 6.53643 16.3444 7.54603C16.7459 8.55564 16.9039 9.64564 16.8057 10.7277C16.7105 11.8674 16.3282 12.9645 15.6945 13.9166L15.5557 12.5222C15.5661 12.4401 15.5581 12.3567 15.5323 12.2781C15.5064 12.1995 15.4633 12.1277 15.4062 12.0679C15.349 12.0081 15.2792 11.9618 15.2019 11.9325C15.1245 11.9031 15.0416 11.8914 14.9591 11.8981C14.8767 11.9049 14.7968 11.93 14.7253 11.9716C14.6537 12.0132 14.5924 12.0702 14.5458 12.1385C14.4991 12.2068 14.4683 12.2847 14.4556 12.3665C14.4429 12.4482 14.4486 12.5318 14.4723 12.611L14.7501 16.1888L18.2223 15.2666C18.3599 15.2264 18.4765 15.1345 18.548 15.0103C18.6194 14.8861 18.6401 14.739 18.6057 14.5999Z"
          fill="white"
          id="Vector"
        />
        <path
          d="M2.39446 9.48901C2.46829 9.53649 2.55181 9.56686 2.6389 9.5779C2.78389 9.59692 2.93053 9.558 3.04702 9.4696C3.1635 9.3812 3.24043 9.25043 3.26112 9.10567C3.49068 7.41076 4.35205 5.86512 5.67273 4.77828C6.99342 3.69145 8.67594 3.14364 10.3833 3.24456L9.1389 4.09456C9.05085 4.1609 8.98453 4.25193 8.94837 4.35607C8.91221 4.46021 8.90785 4.57275 8.93585 4.67938C8.96384 4.78601 9.02293 4.88189 9.10559 4.95484C9.18824 5.02778 9.29073 5.07449 9.40001 5.08901C9.52517 5.10045 9.65052 5.06911 9.75557 5.00012L12.7167 2.96679L10.1833 0.422341C10.1383 0.353264 10.0784 0.295092 10.008 0.252049C9.93764 0.209006 9.85857 0.182171 9.77655 0.173493C9.69452 0.164815 9.61159 0.174512 9.53378 0.201879C9.45596 0.229247 9.38522 0.273598 9.32668 0.331713C9.26815 0.389828 9.22329 0.460249 9.19536 0.537862C9.16743 0.615475 9.15714 0.698334 9.16522 0.780422C9.17331 0.86251 9.19957 0.941768 9.2421 1.01244C9.28464 1.08311 9.34237 1.14343 9.41112 1.18901L10.3556 2.13345C8.38381 2.03917 6.44835 2.68754 4.93128 3.95057C3.41421 5.21359 2.42578 6.99948 2.16112 8.95567C2.14688 9.05746 2.16115 9.16119 2.20234 9.25535C2.24353 9.34951 2.31004 9.43039 2.39446 9.48901Z"
          fill="white"
          id="Vector_2"
        />
        <path
          d="M12.0723 16.6279C11.2047 16.905 10.2896 17.0014 9.38336 16.9112C8.27968 16.803 7.2189 16.4278 6.29262 15.8181C5.36633 15.2083 4.60237 14.3822 4.0667 13.4112L5.45003 13.9279C5.57755 13.95 5.70878 13.9269 5.82109 13.8626C5.93339 13.7982 6.01971 13.6967 6.06516 13.5755C6.1106 13.4544 6.11231 13.3211 6.06999 13.1988C6.02767 13.0765 5.94398 12.9728 5.83337 12.9056L3.28337 11.9612L2.4667 11.6667L1.85003 15.1945C1.82865 15.3355 1.86229 15.4793 1.94399 15.5961C2.02569 15.713 2.14918 15.794 2.28892 15.8223H2.38337C2.51412 15.8247 2.64152 15.7809 2.74315 15.6985C2.84477 15.6162 2.9141 15.5007 2.93892 15.3723L3.17225 14.039C3.8006 15.1397 4.68318 16.0741 5.74629 16.7641C6.80941 17.4542 8.02219 17.8799 9.28336 18.0056C10.3378 18.1101 11.4024 17.9967 12.4111 17.6723C12.5396 17.6191 12.6434 17.5197 12.7021 17.3937C12.7609 17.2677 12.7702 17.1243 12.7284 16.9917C12.6865 16.8592 12.5965 16.7471 12.476 16.6777C12.3556 16.6083 12.2135 16.5865 12.0778 16.6167L12.0723 16.6279Z"
          fill="white"
          id="Vector_3"
        />
        <path
          d="M12.2222 7.22217H7.77772C7.63038 7.22217 7.48907 7.2807 7.38489 7.38489C7.2807 7.48907 7.22217 7.63038 7.22217 7.77772V12.2222C7.22217 12.3695 7.2807 12.5108 7.38489 12.615C7.48907 12.7192 7.63038 12.7777 7.77772 12.7777H12.2222C12.3695 12.7777 12.5108 12.7192 12.615 12.615C12.7192 12.5108 12.7777 12.3695 12.7777 12.2222V7.77772C12.7777 7.63038 12.7192 7.48907 12.615 7.38489C12.5108 7.2807 12.3695 7.22217 12.2222 7.22217ZM11.6666 11.6666H8.33328V8.33328H11.6666V11.6666Z"
          fill="white"
          id="Vector_4"
        />
      </g>
      <defs>
        <clipPath id="clip0_347_462">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
