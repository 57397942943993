import { Input as TailwindInput } from "@material-tailwind/react";
import { styled } from "styled-components/macro";

export const InputWrapper = styled.div``;

export const Input = styled(TailwindInput)`
  font-size: 16px;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: unset;
  font-family: Montserrat, serif;
  outline: none;

  &:focus {
    box-shadow: none;
  }

  ::placeholder {
    font-family: Montserrat, serif;
  }
`;
