import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { dashboardApi } from "./services";

export const getDashboardList = createAsyncThunk(
  `${SLICE_NAME.dashboardSlice}/getDashboardList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await dashboardApi.getDashboardList(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
