import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 340px;
`;

export const List = styled.ul`
  position: absolute;
  width: 100%;
  top: 60px;
  background-color: ${COLORS.white};
  box-shadow: 0 3px 2px -2px ${COLORS.overlay_200}, 0 2px 2px 0 ${COLORS.overlay_150}, 0 1px 5px 0 ${COLORS.overlay_150};

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 180px;
  }
`;

export const ListItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px;
  border: 1px solid ${COLORS.grey};
  flex: 1 1 auto;
  font-size: 16px;
  min-height: 14px;
  min-width: 1px;

  &:hover {
    background-color: ${COLORS.overlay_060};
  }
`;

export const ErrorText = styled.div`
  color: ${COLORS.red};
  font-size: 12px;
`;
