import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminReloadButton, FilterMultiSelect, PrimaryButton, SearchInput, Select, Table } from "common";
import { useDebounce } from "hooks";
import { Paginator } from "primereact/paginator";
import { adminRoutes } from "router/routes";
import { evidenceSelectors } from "store/admin-slices/evidence-slice/selectors";
import { evidenceActions } from "store/admin-slices/evidence-slice/slice";
import { singleEvidenceSelectors } from "store/admin-slices/single-evidence-slice/selectors";
import { singleEvidenceActions } from "store/admin-slices/single-evidence-slice/slice";
import {
  AVAILABILITY_OPTIONS,
  COUNTIES_FILTER,
  isLoading,
  LOADING_STATUSES,
  TOAST_TYPES,
  YEARS_FILTER,
} from "utils/constants";
import { downloadFileByLink, getFileType, getPaginationDescription, numberToDollar, toastify } from "utils/helpers";

import { Template } from "components/Admin/TableRowCustomTemplate";
import { Filter } from "components/Filter";

import * as S from "./styled";

export const EvidencePage = () => {
  const {
    last_page,
    evidences,
    downloadCsvHref,
    massPrintDownloadHref,
    getEvidencesStatus,
    importAccountsStatus,
    exportCsvStatus,
    massPrintStatus,
    printPropertyStatus,
    printPropertyDownloadHref,
    evidenceListFilters,
    allowScroll,
  } = useSelector(evidenceSelectors);
  const { downloadCompsCsvHref, exportCompsCsvStatus } = useSelector(singleEvidenceSelectors);

  const [selectedYear, setSelectedYear] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationData, setPaginationData] = useState({
    first: 10,
    rows: 50,
    page: 1,
  });
  const [selectedCountyOptions, setSelectedCountyOptions] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [sortData, setSortData] = useState({
    field: "updated_at",
    direction: -1,
  });
  const [is_uploaded_manually, setIsUploadedManually] = useState("");
  const [propertyId, setPropertyId] = useState(null);
  const [selectValue, setSelectValue] = useState("");
  const [packetCounty, setPacketCounty] = useState("");
  const [accountNumber, setAccountNumber] = useState();

  const fileInputRef = useRef();

  const tableData = useMemo(
    () => [
      { headerClassName: "column-header", selectionMode: "multiple" },
      { field: "account_number", header: "Account number", sortable: true },
      { field: "updated_at", header: "Updated", sortable: true },
      { field: "full_name", header: "Owner", sortable: true },
      { field: "street_address", header: "Situs" },
      { field: "county", header: "County", sortable: true },
      {
        body: (item) => numberToDollar(item.previous_year_market_value),
        header: "2022 cert",
        sortable: true,
      },
      {
        body: (item) => numberToDollar(item.current_year_market_value),
        header: "2023 prelim",
        sortable: true,
      },
      { field: "sales", header: "2023 sale" },
      { field: "unequal", header: "2023 unequal" },
      {
        body: (item) => Template.EvidenceStatus(item),
        header: "Status",
        sortable: true,
      },
      {
        body: (item) => Template.PrintProperty(item, printPropertyStatus, handlePrintClick, propertyId),
        header: "Actions",
      },
    ],
    [printPropertyStatus]
  );

  const debouncedSearchQuery = useDebounce(searchQuery, 200);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const ref = useRef(null);

  const handleRowClick = (item) => {
    dispatch(evidenceActions.setAllowScroll(false));
    navigate(`${adminRoutes.prefix}/${adminRoutes.evidence}/${item.data?.property_id}`, {
      state: { packetCounty: item.data.county, accountNumber: item.data.account_number },
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedFileTypes = ["csv", "xlx", "xls", "xlsx"];
    if (allowedFileTypes.includes(getFileType(file.name))) {
      dispatch(evidenceActions.importAccounts(file));
    } else {
      toastify(TOAST_TYPES.error, "The file must be a file of type: csv, xlx, xls, xlsx.");
    }

    fileInputRef.current.value = null;
  };

  const resetData = () => {
    dispatch(evidenceActions.setAllowScroll(false));
    setPaginationData({
      first: 10,
      rows: 50,
      page: 1,
    });
    setSortData({
      field: "updated_at",
      direction: -1,
    });
    setSelectedCountyOptions([]);
    setSearchQuery("");
    setSelectedProperties([]);
    setIsUploadedManually("");
    setSelectValue("");
    setSelectedYear([]);
  };
  const handleImportAccountsClick = () => fileInputRef.current?.click();

  const handleExportCsvClick = () => {
    const params = {
      properties: selectedProperties.map((item) => item.property_id),
      counties: selectedCountyOptions,
      search: searchQuery,
      is_uploaded_manually,
    };
    dispatch(evidenceActions.exportCsv(params));
  };

  const handleMassPrintClick = () =>
    dispatch(
      evidenceActions.massPrint({
        properties: selectedProperties.map((item) => item.property_id),
      })
    );

  const handlePrintClick = (propertyId, item) => {
    dispatch(evidenceActions.printProperty(propertyId));
    setPropertyId(propertyId);
    setPacketCounty(item.county);
    setAccountNumber(item.account_number);
  };

  const deSelectCounties = () => {
    if (selectedCountyOptions?.length > 0) {
      setSelectedCountyOptions([]);
    } else {
      setSelectedCountyOptions(COUNTIES_FILTER.map((item) => item.value));
    }
  };

  const onSearch = (searchResult) => setSearchQuery(searchResult);

  const onSort = (e) => setSortData({ field: e.sortField, direction: e.sortOrder });

  const onPageChange = ({ first, rows, page }) => setPaginationData({ first, rows, page: page + 1 });

  const onSelectChange = (value) => {
    setSelectValue(value);
    setIsUploadedManually(AVAILABILITY_OPTIONS.find((item) => item.label === value).value);
  };

  const deSelectYears = () => {
    if (selectedYear?.length > 0) {
      setSelectedYear([]);
    } else {
      setSelectedYear(YEARS_FILTER.map((item) => item.value));
    }
  };

  useEffect(() => {
    downloadFileByLink(exportCsvStatus, downloadCsvHref, "evidences.csv");
  }, [downloadCsvHref]);

  useEffect(() => {
    downloadFileByLink(massPrintStatus, massPrintDownloadHref, "evidence.zip");
  }, [massPrintDownloadHref]);

  useEffect(() => {
    downloadFileByLink(exportCompsCsvStatus, downloadCompsCsvHref, "comps.csv");
  }, [downloadCompsCsvHref]);

  useEffect(() => {
    downloadFileByLink(
      printPropertyStatus,
      printPropertyDownloadHref,
      `${accountNumber}_${new Date().toISOString().split("T")[0]}_${packetCounty}.pdf`
    );
  }, [printPropertyDownloadHref]);

  useEffect(() => {
    return () => {
      dispatch(evidenceActions.resetAllData());
      dispatch(singleEvidenceActions.resetAllData());
    };
  }, []);

  useEffect(() => {
    if (printPropertyStatus === LOADING_STATUSES.succeeded) {
      dispatch(evidenceActions.resetPrintProperty());
    }
  }, [printPropertyDownloadHref, printPropertyStatus]);

  useEffect(() => {
    const params = {
      page: paginationData.page,
      limit: paginationData.rows,
      search: debouncedSearchQuery,
      years: selectedYear,
      counties: selectedCountyOptions,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "asc" : "desc",
    };

    if (is_uploaded_manually) {
      params.is_uploaded_manually = is_uploaded_manually;
    }

    dispatch(evidenceActions.getEvidences(params));

    dispatch(
      evidenceActions.setEvidenceListFilters({
        searchQuery: searchQuery,
        selectedYear: selectedYear,
        selectedCountyOptions: selectedCountyOptions,
        selectValue: selectValue,
        paginationData: paginationData,
      })
    );
  }, [debouncedSearchQuery, paginationData, selectedCountyOptions, sortData, is_uploaded_manually, selectedYear]);

  useEffect(() => {
    dispatch(evidenceActions.setAllowScroll(false));
  }, [debouncedSearchQuery, selectedCountyOptions, sortData, is_uploaded_manually, selectedYear]);

  useEffect(() => {
    setSelectedProperties([]);
  }, [paginationData.page]);

  useEffect(() => {
    setSelectedCountyOptions(evidenceListFilters.selectedCountyOptions);
    setSelectedYear(evidenceListFilters.selectedYear);
    setSelectValue(evidenceListFilters.selectValue);
    setIsUploadedManually(
      evidenceListFilters.selectValue === "Yes" ? 1 : evidenceListFilters.selectValue === "No" ? 0 : ""
    );
    setSearchQuery(evidenceListFilters.searchQuery || "");
    setPaginationData(evidenceListFilters.paginationData || paginationData);
  }, []);

  useEffect(() => {
    allowScroll && ref.current.scrollIntoView({ behavior: "auto" });
  }, [getEvidencesStatus]);

  return (
    <S.EvidenceContainer>
      <S.EvidenceTitle>Evidence</S.EvidenceTitle>
      <AdminReloadButton onClick={resetData} />
      <Filter>
        <SearchInput
          className="search-input"
          placeholder="Search Client List"
          value={searchQuery}
          onSearch={onSearch}
        />
        <FilterMultiSelect
          buttonLabel="COUNTY FILTER"
          options={COUNTIES_FILTER}
          selectedOptions={selectedCountyOptions}
          setSelectedOptions={setSelectedCountyOptions}
        />
        <PrimaryButton
          label={(selectedCountyOptions?.length ? "DE" : "") + "SELECT ALL COUNTIES"}
          mode="gray"
          onClick={deSelectCounties}
        />
        <PrimaryButton
          eventHandler={handleImportAccountsClick}
          label="Import Accounts"
          loading={isLoading(importAccountsStatus)}
          mode="gray"
        />
        <S.FileInput ref={fileInputRef} accept=".csv" type="file" onChange={handleFileChange} />
        <PrimaryButton
          eventHandler={handleExportCsvClick}
          label="Download CSV"
          loading={isLoading(exportCsvStatus)}
          mode="gray"
        />
        <PrimaryButton
          eventHandler={handleMassPrintClick}
          label="Mass Print"
          loading={isLoading(massPrintStatus)}
          mode="gray"
        />
        <FilterMultiSelect
          buttonLabel="YEAR FILTER"
          options={YEARS_FILTER}
          selectedOptions={selectedYear}
          setSelectedOptions={setSelectedYear}
        />
        <PrimaryButton
          label={(selectedYear?.length ? "DE" : "") + "SELECT ALL YEARS"}
          mode="gray"
          onClick={deSelectYears}
        />
        <Select
          itemKey="label"
          items={AVAILABILITY_OPTIONS}
          label="Is uploaded manually ?"
          value={selectValue}
          onChange={onSelectChange}
        />
      </Filter>
      <Table
        dataKey="uniqueId"
        loading={isLoading(getEvidencesStatus)}
        selection={selectedProperties}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={evidences}
        onRowDoubleClick={handleRowClick}
        onSelectionChange={(e) => setSelectedProperties(e.value)}
        onSort={onSort}
      />
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[50, 100, 200]}
          totalRecords={last_page}
          onClick={() => dispatch(evidenceActions.setAllowScroll(true))}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.EvidenceContainer>
  );
};
