import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { PDF_ICONS } from "utils/constants";
import { v4 as uuid } from "uuid";

export const SaPage = ({ owner, signature, signType }) => {
  const page2Content = [
    { label: "EXECUTED:", content: dayjs().format("MM/DD/YYYY") },
    {
      label: "Owner /s/:",
      isSignature: true,
      content: signature ? (
        <>
          {signType === "type" ? <View style={{ width: 1, height: 26 }}></View> : null}
          <Text style={{ position: "absolute", fontSize: 8, fontWeight: "bold", width: 260, top: -11, left: 30 }}>
            {signType === "type" ? "Electronically Typed and Signed By" : "Electronically Drawn and Signed By"}
          </Text>
          <View style={styles.signatureContent}></View>
          <Image
            src={signature}
            style={{
              position: `${signType === "type" ? "absolute" : ""}`,
              margin: `${signType === "type" ? 16 : 0}`,
              left: `${signType === "type" ? 16 : 0}`,
              top: `${signType === "type" ? -18 : 0}`,
              height: `${signType === "type" ? 36 : 26}`,
              objectFit: "contain",
            }}
          />
        </>
      ) : (
        ""
      ),
    },
    { label: "Owner email:", content: owner?.email },
    {
      label: "Owner Name (printed)",
      content: `${owner.first_name} ${owner?.last_name}`,
    },
    { label: "Mobile Phone:", content: owner?.phone_number },
    {
      label: "PTP Signature",
      isSignature: true,
      content: (
        <Image
          src={PDF_ICONS.ptp_signature}
          style={{
            height: 50,
            objectFit: "contain",
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Page size="A4" style={[styles.page, styles.text]}>
        <Text style={styles.title}>CONDITIONAL FEE SERVICE AGREEMENT</Text>
        <Text>
          In consideration of services rendered and to be rendered by Property Tax Protest, LLC, a Texas Limited
          Liability Company (“PTP”) the undersigned Owner (“Owner”) agrees to pay to PTP One Percent (1.0%) of the
          amount of Reduction (herein so called) in Owner’s Market Value or Values resulting from PTP’s representation
          to the county Central Appraisal District (“CAD”) or Appraisal Review Board (“ARB”) of property or properties
          specified by Owner on Comptroller’s Form 50-162 “Appointment of Agent for Property Tax Matters” and naming PTP
          as its agent. Owner agrees to be bound by the terms of this Agreement.
        </Text>
        <Text>
          Reduction shall be the difference between the Market (not Assessed or Homestead Capped) Value proposed by the
          Appraisal District and the Market Value resulting from PTP’s efforts heretofore or hereafter made. For
          example, if the Proposed Market Value is $525,000 and PTP obtains a Reduced Market Value of $475,000, then the
          fee would be $500, being 1.0% of the Reduction.
        </Text>
        <Text>
          However, if Owner’s Assessed (or Homestead Capped) Value is less than the value to which PTP has reduced its
          Market Value then the fee shall be reduced by half to 0.5% of the Reduction in consideration of the fact that
          Owner’s tax savings may therefore be delayed to a future year. For example, if the Proposed Market Value is
          $525,000 and PTP obtains a Reduced Market Value of $475,000 but the Assessed (or Homestead Capped) value is
          less than $475,000, then the fee would be $250, being 0.5% of the Reduction.
        </Text>
        <Text style={[styles.text, { fontWeight: "bold" }]}>If there is no Reduction, there is no fee.</Text>
        <Text>
          <Text style={{ fontWeight: "bold" }}>
            This agreement shall continue annually until terminated by either party.
          </Text>
          Owner may terminate this agreement and revoke PTP’s authorization by sending Comptroller’s Form 50-813
          “Revocation of Appointment of Agent for Property Tax Matters” to the CAD with courtesy copy to PTP. Owner
          agrees not revoke PTP’s authority after April 1 and prior to September 30 of any calendar year without PTP’s
          prior written consent.
        </Text>
        <Text>
          PTP is hereby authorized to execute on Owner’s behalf any documents which may be necessary or advisable to
          effectuate matters herein described. PTP is specifically authorized by Owner (i) to execute and submit on
          behalf of Owner and in Owner’s name to the appropriate CAD the Appointment of Agent form or any amendment,
          revision or correction thereto deemed necessary by PTP in its independent judgment reasonably exercised and
          (ii) to negotiate with such CAD authorities a Market Value for Owner’s property or properties.
        </Text>
        <Text>
          Invoice is deemed received when transmitted to Owner’s email. Payment shall be due within fifteen (15) days of
          invoice receipt. Late payments may accrue interest at the highest rate allowed by law but in no case in excess
          of such highest rate and if any excess exists it shall be deemed a clerical error and correctable. Owner shall
          be liable for any costs of collection including, without limitation, court costs and attorney’s fees. PTP does
          not engage in the practice of law. PTP is authorized to charge Owner’s credit card on file for any amount
          remaining unpaid after thirty (30) days of invoice transmittal. If any invoice remains unpaid after forty-five
          (45) days of receipt, then Owner authorizes PTP to record a “Memorandum of Balance Due” or similar document in
          the deed records of the county in which the property is located.
        </Text>
        <Text>
          PTP MAKES NO REPRESENTATIONS AND GRANTS NO WARRANTIES, EXPRESS OR IMPLIED, EITHER IN FACT OR BY OPERATION OF
          LAW, BY STATUTE OR OTHERWISE UNDER THIS AGREEMENT AND PTP SPECIFICALLY DISCLAIMS ANY SUCH WARRANTIES, WHETHER
          WRITTEN OR ORAL, OR EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, PTP DOES NOT GUARANTEE THAT ANY
          REDUCTION IN THE MARKET VALUE OF OWNER’S PROPERTY WILL BE ACHIEVED OR THAT ANY REDUCTION IN MARKET VALUE
          ACHIEVED IN ANY YEAR WILL RESULT IN TAX SAVINGS IN SUCH YEAR BUT, CONVERSELY, SUCH SAVINGS MAY BE DEFERRED TO
          A FUTURE YEAR IF OWNER’S ASSESSED (OR HOMESTEAD CAPPED) VALUE IS LOWER THAN OWNER’S REDUCED MARKET VALUE.
        </Text>
        <Text>
          TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW (i) IN NO EVENT WILL PTP BE LIABLE TO OWNER OR ANY THIRD
          PARTY OF ANY INCIDENTAL, INDIRECT, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATED TO THE
          AGREEMENT OR THE TERMINATION OF THIS AGREEMENT; AND (ii) IN NO EVENT WILL PTP’S LIABILITY ARISING OUT OF OR
          RELATED TO THIS AGREEMENT EXCEED THE TOTAL AMOUNT PAID BY OWNER TO PTP HEREUNDER. IF A CONFLICT OF LAWS EXISTS
          THEN THIS SECTION WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW
        </Text>
        <Text>
          This Agreement shall survive until all amounts due and owing by Owner have been paid. This Agreement
          constitutes the entire agreement between the parties and may be amended or revised only by the written
          agreement of all parties. This agreement shall be governed by the laws of the State of Texas, without regard
          to its choice or conflict of law rules. Exclusive jurisdiction
        </Text>
      </Page>
      <Page size="A4" style={[styles.page, styles.text]}>
        <Text>
          and venue for any actions related to the subject matter of this Agreement shall be Collin County, Texas. Owner
          consents to accept SMS messages.
        </Text>
        <Text style={[styles.title, { letterSpacing: -0.6 }]}>
          <Text style={{ fontWeight: "bold" }}>PTP is regulated by Texas Real Estate Commission,</Text>
          PO 12188. Austin, TX 78711.
        </Text>
        <View>
          <View style={styles.flexColumn}>
            {page2Content.map((item) => (
              <View key={uuid()} style={styles.flexRow}>
                <Text>{item.label}</Text>
                <View style={styles.underline}>{item.isSignature ? item.content : <Text>{item.content}</Text>}</View>
              </View>
            ))}
          </View>
          <Text style={{ marginTop: 2 }}>PTP MAIN ADDRESS: 3824 Cedar Springs Rd., Ste. 361, Dallas, TX 75219</Text>
        </View>
      </Page>
    </>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 50,
    display: "flex",
    gap: 23,
    fontFamily: "Roboto",
  },
  title: {
    fontSize: 10,
    textAlign: "center",
    letterSpacing: -1.1,
  },
  text: {
    fontSize: 9,
    letterSpacing: 0,
  },
  flexColumn: {
    display: "flex",
    gap: 10,
  },
  flexRow: {
    marginVertical: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    gap: 4,
  },
  underline: {
    paddingVertical: 4,
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    minWidth: 140,
  },
  signatureContent: {
    position: "absolute",
    top: -5,
    left: 18,
    width: 10,
    height: 44,
    borderWidth: 1,
    justifyContent: "center",
    borderColor: "#005196",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderRightWidth: 0,
  },
});
