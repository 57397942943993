import axios from "axios";
import { API } from "configs/api";

export const evidencesSliceApi = {
  getEvidences: (params) => axios.get(API.evidences, { token: true, params }),
  importAccounts: (file) => axios.post(API.evidences + API.importAccounts, { file }, { formData: true, token: true }),
  exportCsv: (params) =>
    axios.get(API.evidences + API.exportCSV, {
      token: true,
      responseType: "blob",
      params,
    }),
  massPrint: (params) =>
    axios.get(API.evidences + API.massPrint, {
      token: true,
      params,
      responseType: "blob",
    }),
  printProperty: (propertyId) =>
    axios.get(`${API.comps + API.print}/${propertyId}`, {
      token: true,
      responseType: "blob",
    }),
};
