import axios from "axios";
import { API } from "configs/api";

export const singleDashboardApi = {
  getSingleDashboardList: ({ id, ...params }) =>
    axios.get(`${API.admin}${API.dashboard}/${id}`, {
      token: true,
      params,
    }),
};
