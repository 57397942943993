import axios from "axios";
import { API } from "configs/api";

export const singleClientEvidenceApi = {
  getSingleClientEvidences: (id) =>
    axios.get(`${API.clientEvidence}/${id}`, {
      token: true,
    }),
  postFileStatus: ({ id, status }) =>
    axios.post(`${API.clientEvidence}${API.changeFileStatus}/${id}`, { status }, { token: true }),
  postEvidenceStatus: ({ id, status }) =>
    axios.post(`${API.clientEvidence}${API.changeEvidenceStatus}/${id}`, { status }, { token: true }),
  mergeEvidenceFiles: ({ id, ...params }) =>
    axios.post(`${API.clientEvidence}${API.mergeEvidenceFiles}/${id}`, {}, { params, token: true }),
  postChangeFileType: ({ id, evidence_type }) =>
    axios.post(`${API.clientEvidence}${API.changeFileType}/${id}`, { evidence_type }, { token: true }),
  postChangeFileName: ({ id, name }) =>
    axios.post(`${API.clientEvidence}${API.changeFileName}/${id}`, { name }, { token: true }),
  postAddFile: ({ id, body }) =>
    axios.post(`${API.clientEvidence}${API.addFile}/${id}`, body, { token: true, formData: true }),
  getTracker: ({ id, params }) => axios.get(`${API.clientEvidence}${API.tracker}/${id}`, { token: true, params }),
  exportCSV: (params) =>
    axios.get(`${API.clientEvidence}${API.tracker}${API.action}${API.exportCSV}`, {
      token: true,
      params,
      responseType: "blob",
    }),
  deleteEvidenceFile: (id) => axios.delete(`${API.clientEvidence}${API.file}/${id}`, { token: true }),
};
