export const ArrowDownIcon = (props) => (
  <svg
    fill="none"
    height="20px"
    stroke="currentColor"
    strokeWidth={2}
    viewBox="0 0 24 24"
    width="20px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19.5 8.25l-7.5 7.5-7.5-7.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
