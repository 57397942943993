import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { submitEvidenceApi } from "./services";

export const propertySearchFromDb = createAsyncThunk(
  `${SLICE_NAME.submitEvidenceSlice}/propertySearchFromDb`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await submitEvidenceApi.propertySearchFromDb(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const propertySearch = createAsyncThunk(
  `${SLICE_NAME.submitEvidenceSlice}/propertySearch`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await submitEvidenceApi.propertySearch(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadEvidenceProperties = createAsyncThunk(
  `${SLICE_NAME.submitEvidenceSlice}/uploadEvidenceProperties`,
  async (file, { rejectWithValue }) => {
    try {
      const response = await submitEvidenceApi.uploadEvidence(file);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postSubmitEvidenceAction = createAsyncThunk(
  `${SLICE_NAME.submitEvidenceSlice}/postSubmitEvidence`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await submitEvidenceApi.postSubmitEvidence(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
