import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { COLORS } from "assets/styles/colors";
import { v4 as uuid } from "uuid";

export const CheckboxContainer = ({ title, labels = [], isRow = false, isHideTitle = false, isBoldTitle = false }) => {
  return (
    <View style={[styles.container, isRow ? { flexDirection: "row" } : {}]}>
      {!isHideTitle && (
        <Text style={[styles.text, isBoldTitle ? { fontWeight: "bold" } : {}]}>{title} (check one)</Text>
      )}
      {labels.map((item) => (
        <View key={uuid()} style={styles.flexBox}>
          <View style={styles.checkbox}>{item.checked ? <Text>X</Text> : null}</View>
          <Text style={styles.text}>{item.content}</Text>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
    display: "flex",
    gap: 6,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
  text: {
    fontSize: 8,
  },
  checkbox: {
    minWidth: 12,
    minHeight: 12,
    borderWidth: 1,
    borderColor: COLORS.blue,
    color: COLORS.deepDark,
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});
