import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Icons } from "assets/svgs";
import { PrimaryButton, SearchInput, Table } from "common";
import { SimpleSelect } from "common/SimpleSelect";
import { useDebounce } from "hooks";
import { Filter } from "pages/admin/components/Filter";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { referralsSelectors } from "store/referral-slice/selectors";
import { referralActions } from "store/referral-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";

import { Modal } from "components/Modal";

import { Footer } from "./Footer";
import * as S from "./styled";
export const ReferredBy = () => {
  const {
    referredBy,
    isModalOpen,
    postReferredByStatus,
    isDeleteModalOpen,
    deleteReferredByStatus,
    isEditReferralModalOpen,
    getReferredByStatus,
    updateReferralStatus,
    postReferredByError,
    updateReferralError,
  } = useSelector(singleOwnerSelectors);

  const { referralEmailList, getReferralEmailListStatus } = useSelector(referralsSelectors);

  const [searchQuery, setSearchQuery] = useState("");
  const [wrongEmail, setWrongEmail] = useState("");

  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  const { id } = useParams();

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(referralActions.clearReferralEmailList());
    dispatch(singleOwnerActions.setIsModalOpen(true));
  };

  const handleDeleteReferredBy = () => {
    if (referredBy && referredBy.id) {
      dispatch(singleOwnerActions.deleteReferredBy(referredBy.id));
    }
  };

  const handleOpenDeleteModal = () => {
    dispatch(singleOwnerActions.setIsDeleteModalOpen(true));
  };

  const handleDeleteReferral = () => {
    dispatch(singleOwnerActions.setIsEditReferralModalOpen(true));
  };

  const onSearch = (searchResult) => {
    dispatch(singleOwnerActions.resetReferredStatuses());
    dispatch(referralActions.clearReferralEmailList());
    setSearchQuery(searchResult);
    if (!searchQuery) {
      dispatch(singleOwnerActions.resetReferredStatuses());
    }
  };

  const handleSelectEmail = (value) => {
    setWrongEmail(value);
    const body = new FormData();
    body.append("email", value);
    body.append("receiver_owner_id", id);
    dispatch(singleOwnerActions.postReferredBy(body));
    dispatch(referralActions.clearReferralEmailList());
  };

  const handleSelectUpdatedEmail = (value) => {
    setWrongEmail(value);
    dispatch(
      singleOwnerActions.updateReferral({
        receiver_owner_id: id,
        email: value,
      })
    );
    dispatch(referralActions.clearReferralEmailList());
  };

  const handleCloseModal = () => {
    dispatch(singleOwnerActions.setIsModalOpen(false));
    dispatch(referralActions.clearReferralEmailList());
    dispatch(singleOwnerActions.resetReferredStatuses());
    setSearchQuery("");
  };

  const handleCloseEditModal = () => {
    dispatch(singleOwnerActions.setIsEditReferralModalOpen(false));
    dispatch(referralActions.clearReferralEmailList());
    dispatch(singleOwnerActions.resetReferredStatuses());
    setSearchQuery("");
  };

  const tableData = [
    {
      body: (item) => (referredBy ? <Icons.EditIcon onClick={() => handleDeleteReferral(item)} /> : null),
      header: "Actions",
    },
    { field: "full_name", header: "Name" },
    { field: "", header: "" },
    { field: "email", header: "Email" },
    { field: "", header: "" },
    { field: "referral_code", header: "Code" },
    { field: "", header: "" },
    { header: "Date", field: "updated_at" },
    {
      header: "Percent",
      body: (item) => (item ? "10%" : ""),
    },

    {
      header: "Remove",
      body: () =>
        referredBy ? (
          <S.RemoveContainer>
            <Icons.TrashIcon onClick={() => handleOpenDeleteModal()} />
          </S.RemoveContainer>
        ) : null,
    },
  ];

  useEffect(() => {
    if (debouncedSearchQuery) {
      dispatch(
        referralActions.getReferralEmailList({
          email: debouncedSearchQuery,
        })
      );
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    const handleSuccess = (actionType, modalAction) => {
      if (actionType === LOADING_STATUSES.succeeded) {
        dispatch(modalAction(false));
        if (actionType === postReferredByStatus) {
          dispatch(singleOwnerActions.getReferredBy(id));
          dispatch(referralActions.clearReferralEmailList());
        }
      }
    };

    handleSuccess(postReferredByStatus, singleOwnerActions.setIsModalOpen);
    handleSuccess(updateReferralStatus, singleOwnerActions.setIsEditReferralModalOpen);
    handleSuccess(deleteReferredByStatus, singleOwnerActions.setIsDeleteModalOpen);
  }, [postReferredByStatus, updateReferralStatus, deleteReferredByStatus]);

  useEffect(() => {
    if (updateReferralStatus === LOADING_STATUSES.succeeded || postReferredByStatus === LOADING_STATUSES.succeeded) {
      setSearchQuery("");
    }
  }, [updateReferralStatus, postReferredByStatus]);

  useEffect(() => {
    if (updateReferralStatus === LOADING_STATUSES.failed || postReferredByStatus === LOADING_STATUSES.failed) {
      setSearchQuery(wrongEmail);
    }
  }, [updateReferralStatus, postReferredByStatus]);

  return (
    <S.Container>
      <S.Content>
        <S.FilterBar>
          <S.ContentTitle>Referred by</S.ContentTitle>
        </S.FilterBar>
        <Filter>{referredBy ? null : <PrimaryButton eventHandler={handleOpenModal} label="Add" mode="gray" />}</Filter>
        <Modal isModalOpen={isModalOpen}>
          <SearchInput
            className="search-input"
            error={postReferredByError}
            placeholder="Search Email"
            value={searchQuery}
            onSearch={onSearch}
          />
          {!postReferredByError ? (
            <SimpleSelect
              handleSelect={handleSelectEmail}
              items={referralEmailList}
              loading={isLoading(getReferralEmailListStatus)}
              searchQuery={searchQuery}
            />
          ) : null}
          {!referralEmailList.length && getReferralEmailListStatus === LOADING_STATUSES.succeeded ? (
            <S.NoResult>No results found</S.NoResult>
          ) : null}
          <S.ButtonsContainer>
            <PrimaryButton eventHandler={handleCloseModal} label="Cancel" mode="gray" />
          </S.ButtonsContainer>
        </Modal>
        <Modal
          cancelButtonText="no"
          className="delete-modal"
          description="Are you sure ?"
          isModalOpen={isDeleteModalOpen}
          successButtonText="yes"
          successLoadingStatus={isLoading(deleteReferredByStatus)}
          onCancel={() => dispatch(singleOwnerActions.setIsDeleteModalOpen(false))}
          onSuccess={handleDeleteReferredBy}
        />
        <Modal isModalOpen={isEditReferralModalOpen}>
          <S.ModalContent>
            <SearchInput
              className="search-input"
              error={updateReferralError}
              placeholder="Search Email"
              value={searchQuery}
              onSearch={onSearch}
            />
            {!updateReferralError ? (
              <SimpleSelect
                handleSelect={handleSelectUpdatedEmail}
                items={referralEmailList}
                loading={isLoading(getReferralEmailListStatus)}
                searchQuery={searchQuery}
              />
            ) : null}
            {!referralEmailList.length && getReferralEmailListStatus === LOADING_STATUSES.succeeded ? (
              <S.NoResult>No results found</S.NoResult>
            ) : null}
            <S.ButtonsContainer>
              <PrimaryButton eventHandler={handleCloseEditModal} label="Cancel" mode="gray" />
            </S.ButtonsContainer>
          </S.ModalContent>
        </Modal>
        <Table
          dataKey="id"
          footer={<Footer referralsLength={referredBy ? 1 : null} />}
          loading={isLoading(getReferredByStatus) || isLoading(postReferredByStatus)}
          tableData={tableData}
          value={[referredBy]}
        />
      </S.Content>
    </S.Container>
  );
};
