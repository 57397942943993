import axios from "axios";
import { API } from "configs/api";

export const archivesListApi = {
  getArchivesList: (params) => axios.get(API.archives, { token: true, params }),
  importArchiveProperties: (file) =>
    axios.post(API.archives + API.importArchiveProperties, { file }, { formData: true, token: true }),
  postContactAgain: ({ property_id, ...body }) =>
    axios.post(`${API.archives}${API.contactAgain}/${property_id}`, body, {
      token: true,
    }),
  exportCSV: (params) =>
    axios.get(API.archives + API.exportCSV, {
      token: true,
      responseType: "blob",
      params,
    }),
  unarchiveProperty: (params) =>
    axios.put(`${API.archives + API.unarchiveProperty}/${params.owner_id}/${params.property_id}`, {}, { token: true }),
};
