import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const EvidenceTableContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .p-datatable-frozen-tbody {
    position: sticky;
    top: 58px !important;

    & > tr {
      background-color: ${COLORS.darkGrey};

      .p-checkbox {
        display: none;
      }
    }
  }

  .p-datatable-thead {
    height: 60px;
  }
`;

export const FilterRightButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  height: 60px;
  margin-top: 40px;
  margin-bottom: 10px;
  margin-right: 10px;

  @media (max-width: 1500px) {
    height: 140px;
  }

  .search-input {
    margin-left: 20px;
  }
`;

export const Box = styled.div`
  transition: 0.5s;

  &.flex-center {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    flex-wrap: wrap;
  }

  &.arrowLeft {
    cursor: pointer;
  }

  &.arrowRight {
    transform: rotate(180deg);
    cursor: pointer;
  }
`;

export const ResidentalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: 10px;
`;

export const SettingsBlock = styled.div`
  .settingButton {
    display: flex;
    align-items: center;
  }
`;

export const PaginatorWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
  justify-content: center;
`;

export const TableContainer = styled.div`
  position: relative;
  border-top: 1px solid ${COLORS.overlay_30};
  border-bottom: 1px solid ${COLORS.overlay_30};

  table > thead {
    position: sticky;
    top: -1px !important;
  }
`;
