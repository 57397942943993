import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { singleEvidenceSelectors } from "store/admin-slices/single-evidence-slice/selectors";
import { singleEvidenceActions } from "store/admin-slices/single-evidence-slice/slice";
import { APP_ENV_MODE } from "utils/constants";

import { EvidenceSideBar } from "./EvidenceSideBar";
import { EvidenceTable } from "./EvidenceTable";
import { SettingsModal } from "./SettingsModal";
import * as S from "./styled";

export const EvidenceSinglePage = () => {
  const { isOpenSettingsModal } = useSelector(singleEvidenceSelectors);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(singleEvidenceActions.resetAllData());
  }, []);

  return (
    <S.EvidenceContainer>
      {process.env.REACT_APP_ENV === APP_ENV_MODE.dev ? (
        <>
          <EvidenceSideBar />
          <EvidenceTable />
          {isOpenSettingsModal && <SettingsModal />}
        </>
      ) : null}
    </S.EvidenceContainer>
  );
};
