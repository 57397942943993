import { useState } from "react";
import { Icons } from "assets/svgs";
import { ErrorMessage } from "common";

import * as S from "./styled";

const inputType = {
  text: "text",
  password: "password",
};

export const PrimaryInput = ({ isPasswordInput, placeholder, register, error, wrapperClassName, ...rest }) => {
  const [type, setType] = useState(inputType.password);

  const changeType = () => {
    setType(type === inputType.text ? inputType.password : inputType.text);
  };

  return (
    <S.InputWrapper className={`flex flex-col w-100 gap-2 primary-100 my-2 relative ${wrapperClassName}`}>
      <S.Input
        className="!outline-transparent"
        color="green"
        error={!!error}
        label={placeholder}
        labelProps={{
          style: { fontFamily: "Montserrat" },
          className: "peer-placeholder-shown:text-base",
        }}
        type={isPasswordInput && type}
        variant="standard"
        {...register}
        {...rest}
      />
      {isPasswordInput ? (
        <div className="cursor-pointer absolute right-2" style={{ top: error ? "12px" : "14px" }}>
          {type === inputType.password ? (
            <Icons.ShowPasswordIcon onClick={changeType} />
          ) : (
            <Icons.HidePassword onClick={changeType} />
          )}
        </div>
      ) : null}

      {error ? <ErrorMessage>{error?.message}</ErrorMessage> : null}
    </S.InputWrapper>
  );
};
