import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminReloadButton, FilterMultiSelect, PrimaryButton, SearchInput, Table } from "common";
import { useDebounce } from "hooks";
import { Paginator } from "primereact/paginator";
import { adminRoutes } from "router/routes";
import { leadsListSelectors } from "store/admin-slices/leads-slice/selectors";
import { leadsActions } from "store/admin-slices/leads-slice/slice";
import { isLoading, LOADING_STATUSES, YEARS_FILTER } from "utils/constants";
import { downloadFileByLink, getPaginationDescription } from "utils/helpers";

import { Template } from "components/Admin/TableRowCustomTemplate";
import { Filter } from "components/Filter";

import * as S from "./styled";

const tableData = [
  { selectionMode: "multiple" },
  { sortable: true, field: "updated_at", header: "Sign up date" },
  { field: "email", header: "Email", sortable: true },
  { field: "full_name", header: "Name", sortable: true },
  { body: Template.FlagsColumnLeads, header: "Flags" },
  { body: Template.SignUpStep, header: "Sign up step" },
  { body: Template.ReferralColumn, header: "Referral" },
];

export const LeadsPage = () => {
  const {
    leadsList,
    last_page,
    getLeadsListStatus,
    downloadCsvHref,
    leadsExportCsvStatus,
    leadsListFilters,
    allowScroll,
  } = useSelector(leadsListSelectors);

  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [paginationData, setPaginationData] = useState({
    first: 10,
    rows: 50,
    page: 1,
  });
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 200);
  const [sortData, setSortData] = useState({
    field: "updated_at",
    direction: -1,
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const ref = useRef(null);

  const handleRowClick = (item) => {
    dispatch(leadsActions.setAllowScroll(false));
    navigate(`${adminRoutes.prefix}/${adminRoutes.leads}/${item.data.owner_id}`);
  };

  const getLeads = (page, rows, searchValue) => {
    const params = {
      page: page ?? paginationData.page,
      limit: rows ?? paginationData.rows,
      search: searchValue ?? search,
      years: selectedYear,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "asc" : "desc",
    };
    dispatch(leadsActions.getLeadsList(params));
  };

  const onPageChange = ({ first, rows, page }) => {
    setPaginationData({
      first,
      rows,
      page: page + 1,
    });
    getLeads(page + 1, rows);
  };

  const resetData = () => {
    dispatch(leadsActions.setAllowScroll(false));
    setPaginationData({ first: 10, rows: 50, page: 1 });
    setSearch("");
    setSortData({ field: "updated_at", direction: -1 });
    setSelectedYear([]);
  };

  const onSearch = (search) => {
    setSearch(search);
  };

  const onSort = (e) => {
    setSortData({ field: e.sortField, direction: e.sortOrder });
  };

  const deSelectYears = () => {
    if (selectedYear?.length > 0) {
      setSelectedYear([]);
    } else {
      setSelectedYear(YEARS_FILTER.map((item) => item.value));
    }
  };

  const downloadCsv = () => {
    let owner_ids = selectedProducts?.map((prop) => prop.owner_id);
    owner_ids = owner_ids?.filter((id, index, self) => self.indexOf(id) === index);
    const params = {
      search: debouncedSearch,
      owner_ids,
    };
    dispatch(leadsActions.leadsExportCsv(params));
  };

  useEffect(() => {
    getLeads();
    dispatch(
      leadsActions.setLeadsListFilters({
        selectedYear: selectedYear,
        search: search,
        paginationData: paginationData,
      })
    );
  }, [debouncedSearch, sortData, selectedYear, paginationData]);

  useEffect(() => {
    dispatch(leadsActions.setAllowScroll(false));
  }, [debouncedSearch, sortData, selectedYear]);

  useEffect(() => {
    downloadFileByLink(leadsExportCsvStatus, downloadCsvHref, "leads-properties.csv");
  }, [downloadCsvHref]);

  useEffect(() => {
    return () => dispatch(leadsActions.resetAllData());
  }, []);

  useEffect(() => {
    setSelectedProducts(null);
  }, [paginationData.page]);

  useEffect(() => {
    allowScroll && ref.current.scrollIntoView({ behavior: "auto" });
  }, [getLeadsListStatus]);

  useEffect(() => {
    setSelectedYear(leadsListFilters.selectedYear);
    setSearch(leadsListFilters.search || "");
    setPaginationData(leadsListFilters.paginationData || paginationData);
  }, []);

  return (
    <S.LeadsContainer>
      <S.LeadsTitle>Leads</S.LeadsTitle>
      <S.ReloadButton>
        <AdminReloadButton onClick={resetData} />
      </S.ReloadButton>
      <Filter>
        <SearchInput className="search-input" placeholder="Search Client List" value={search} onSearch={onSearch} />
        <PrimaryButton
          label="download csv"
          loading={isLoading(leadsExportCsvStatus)}
          mode="gray"
          onClick={downloadCsv}
        />
        <FilterMultiSelect
          buttonLabel="YEAR FILTER"
          options={YEARS_FILTER}
          selectedOptions={selectedYear}
          setSelectedOptions={setSelectedYear}
        />
        <PrimaryButton
          label={(selectedYear?.length ? "DE" : "") + "SELECT ALL YEARS"}
          mode="gray"
          onClick={deSelectYears}
        />
      </Filter>
      <Table
        dataKey="uniqueId"
        loading={getLeadsListStatus !== LOADING_STATUSES.succeeded}
        selection={selectedProducts}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={leadsList}
        onRowDoubleClick={handleRowClick}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
        onSort={onSort}
      />
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[50, 100, 200]}
          totalRecords={last_page}
          onClick={() => dispatch(leadsActions.setAllowScroll(true))}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.LeadsContainer>
  );
};
