import { Step, Stepper as MtStepper } from "@material-tailwind/react";
import { Icons } from "assets/svgs";

import * as S from "./styled";

const Stepper = ({ step, maxStep = 3, isShowDescription = false, descriptions = [] }) => {
  const activeStepArray = [
    { isChecked: step > 0 && <Icons.CheckIcon /> },
    { isChecked: step > 1 && <Icons.CheckIcon /> },
    { isChecked: step > 2 && <Icons.CheckIcon /> },
    { isChecked: step > 3 && <Icons.CheckIcon /> },
  ];

  return (
    <div className="w-full py-4">
      <MtStepper activeLineClassName="bg-primary-100" activeStep={step} className="!w-full" lineClassName="bg-gray-100">
        {activeStepArray.splice(0, maxStep).map((data, index) => (
          <Step
            key={index}
            activeClassName="!bg-white !ring-[6px] !ring-green-600"
            className="h-3 w-3 !bg-gray-400 cursor-pointer"
            completedClassName="!bg-primary-100 w-6 h-6"
          >
            {data.isChecked}
            {isShowDescription && <S.Description>{descriptions[index]}</S.Description>}
          </Step>
        ))}
      </MtStepper>
    </div>
  );
};

export default Stepper;
