import { SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components/macro";

export const Container = styled.div``;

export const Content = styled(SinglePageContentShadow)`
  min-width: 400px;
  height: 100%;
`;

export const ClientData = styled.div`
  margin-block: 16px;
`;
