import { useState } from "react";
import { useSelector } from "react-redux";
import { FilterMultiSelect, PrimaryButton, Table } from "common";
import { singleArchivedSelectors } from "store/admin-slices/single-archived-slice/selectors";
import { isLoading, YEARS_FILTER } from "utils/constants";

import { Footer } from "./Footer";
import * as S from "./styled";

export const ReferredTo = () => {
  const { referrals, singleArchived, postReferredToStatus } = useSelector(singleArchivedSelectors);
  const [selectedYear, setSelectedYear] = useState(YEARS_FILTER.map((item) => item.value));

  const tableData = [
    { header: "Name", field: "full_name" },
    { header: "Email", field: "email" },
    {
      header: "Status",
      body: (item) => <>{item.documents_yn ? <S.Box>Approve</S.Box> : <S.Box className="red">Pending</S.Box>}</>,
    },
    { header: "Code", body: () => singleArchived.referral_code },
    { header: "Date", field: "updated_at" },
    { header: "Percent", body: () => "10%" },
  ];

  const deSelectCounties = () => {
    if (selectedYear?.length > 0) {
      setSelectedYear([]);
    } else {
      setSelectedYear(YEARS_FILTER.map((item) => item.value));
    }
  };

  return (
    <S.Container>
      <S.Content>
        <S.FilterBar>
          <S.ContentTitle>Referred to</S.ContentTitle>
          <S.FilterButtons>
            <FilterMultiSelect
              buttonLabel="YEAR FILTER"
              options={YEARS_FILTER}
              selectedOptions={selectedYear}
              setSelectedOptions={setSelectedYear}
            />
            <PrimaryButton
              label={(selectedYear.length ? "DE" : "") + "SELECT ALL YEARS"}
              mode="gray"
              onClick={deSelectCounties}
            />
          </S.FilterButtons>
        </S.FilterBar>
        <Table
          dataKey="id"
          footer={
            <Footer
              referralsLength={
                referrals?.filter((item) => selectedYear.some((year) => item?.updated_at?.includes(year))).length
              }
            />
          }
          loading={isLoading(postReferredToStatus)}
          tableData={tableData}
          value={referrals?.filter((item) => selectedYear.some((year) => item?.updated_at?.includes(year)))}
        />
      </S.Content>
    </S.Container>
  );
};
