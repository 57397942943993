import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const AccountBlock = styled.div`
  width: 616px;
  padding: 32px;
  background: ${COLORS.white};
  border-radius: 10px;
  border: 1px solid ${COLORS.green};
  box-shadow: 0 5px 14px 0 ${COLORS.green};
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .contact-us-button {
    max-width: 274px;
    margin-top: 40px;
  }

  @media screen and (${BREAKPOINTS.sm}) {
    width: 90%;
  }

  @media screen and (${BREAKPOINTS.md}) {
    width: 360px;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    width: 482px;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    width: 720px;
  }
`;

export const AccountInfo = styled.div``;

export const AccountInfoTitle = styled.div`
  font-weight: bold;
`;

export const AccountInfoBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const ContactUsLink = styled.a`
  display: block;
  width: fit-content;
`;

export const LoaderBox = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
`;

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
