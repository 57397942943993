import { Textarea } from "@material-tailwind/react";
import { styled } from "styled-components/macro";

export const TextareaWrapper = styled.div``;

export const Area = styled(Textarea)`
  font-size: 16px;
  box-shadow: unset;
  border-right: none;
  border-left: none;
  border-top: none;
  padding: 16px 0 0 0;

  font-family: Montserrat, serif;
  outline: none;

  &:focus {
    box-shadow: none;
  }

  ::placeholder {
    font-family: Montserrat, serif;
    padding: 0;
  }
`;
