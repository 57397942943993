import { styled } from "styled-components";

export const SearchInputContainer = styled.div`
  width: 20vw;
  height: 64px;
  position: relative;
  margin-top: 5px;

  svg {
    position: absolute;
    right: 10px;
    top: 22px;
    cursor: pointer;
  }
`;
