import axios from "axios";
import { API } from "configs/api";

export const submitEvidenceApi = {
  propertySearchFromDb: (params) => axios.get(API.propertiesSearch, { params }),
  propertySearch: (id) => axios.get(`${API.getPropertyInfo}/${id}`),
  uploadEvidence: (file) =>
    axios.post(API.archives + API.importArchiveProperties, { file }, { formData: true, token: true }),
  postSubmitEvidence: (body) => axios.post(API.clientEvidence, body, { formData: true }),
};
