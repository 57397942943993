import { useSelector } from "react-redux";
import { ContentHeader } from "pages/admin/OwnersSinglePage/styled";
import { singleArchivedSelectors } from "store/admin-slices/single-archived-slice/selectors";

import * as S from "./styled";

export const ClientDetails = () => {
  const { singleArchived } = useSelector(singleArchivedSelectors);

  const { full_name, email, phone_number, created_at, referral_code } = singleArchived;

  return (
    <S.Container>
      <S.Content>
        <ContentHeader>Client Details</ContentHeader>
        <S.ClientData>Full Name: {full_name}</S.ClientData>
        <S.ClientData>E-Mail Address: {email}</S.ClientData>
        <S.ClientData>Phone: {phone_number}</S.ClientData>
        <S.ClientData>Referral Code: {referral_code}</S.ClientData>
        <S.ClientData>Sign Up Date: {created_at}</S.ClientData>
      </S.Content>
    </S.Container>
  );
};
