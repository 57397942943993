import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { singleEvidenceSliceApi } from "./services";
import { singleEvidenceActions } from "./slice";

export const getEvidencesSales = createAsyncThunk(
  `${SLICE_NAME.singleEvidenceSlice}/getEvidencesSales`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await singleEvidenceSliceApi.getEvidencesSales(data);
      dispatch(singleEvidenceActions.setFilteredBody(data));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSaveComps = createAsyncThunk(
  `${SLICE_NAME.singleEvidenceSlice}/getSaveComps`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleEvidenceSliceApi.getSaveComps(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLeftBarValues = createAsyncThunk(
  `${SLICE_NAME.singleEvidenceSlice}/getLeftBarValues`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleEvidenceSliceApi.getLeftBarValues(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const saveComps = createAsyncThunk(
  `${SLICE_NAME.singleEvidenceSlice}/saveComps`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleEvidenceSliceApi.saveComps(body);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCompsCsv = createAsyncThunk(
  `${SLICE_NAME.singleEvidenceSlice}/exportCompsCsv`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleEvidenceSliceApi.exportCsv(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getEvidencesEquities = createAsyncThunk(
  `${SLICE_NAME.singleEvidenceSlice}/getEvidencesEquities`,
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await singleEvidenceSliceApi.getEvidencesEquities(data);
      dispatch(singleEvidenceActions.setFilteredBody(data));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCompsFilters = createAsyncThunk(
  `${SLICE_NAME.singleEvidenceSlice}/getCompsFilters`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await singleEvidenceSliceApi.getCompsFilters();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSubjectProperty = createAsyncThunk(
  `${SLICE_NAME.singleEvidenceSlice}/getSubjectProperty`,
  async (propertyId, { rejectWithValue }) => {
    try {
      const response = await singleEvidenceSliceApi.getSubjectProperty(propertyId);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
