import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import { getReferralEmailList, postReferralCode, postReferralEmail } from "./action";

const initialState = {
  postReferralCodeStatus: LOADING_STATUSES.idle,
  postReferralEmailStatus: LOADING_STATUSES.idle,
  getReferralEmailListStatus: LOADING_STATUSES.idle,
  referralEmailList: [],
  referralEmail: null,
  referralCode: null,
};

export const referralsPage = createSlice({
  name: SLICE_NAME.referralSlice,
  initialState,
  reducers: {
    resetAllData: () => initialState,
    clearReferralEmailList: (state) => {
      state.getReferralEmailListStatus = LOADING_STATUSES.idle;
      state.referralEmailList = [];
    },
  },
  extraReducers: ({ addCase }) => {
    //postReferralCode
    addCase(postReferralCode.pending, (state) => {
      state.postReferralCodeStatus = LOADING_STATUSES.pending;
    });
    addCase(postReferralCode.fulfilled, (state, { payload }) => {
      state.postReferralCodeStatus = LOADING_STATUSES.succeeded;
      state.referralCode = payload.data.referral_code;
    });
    addCase(postReferralCode.rejected, (state) => {
      state.postReferralCodeStatus = LOADING_STATUSES.failed;
      state.referralCode = null;
    });

    //postReferralEmail
    addCase(postReferralEmail.pending, (state) => {
      state.postReferralEmailStatus = LOADING_STATUSES.pending;
    });
    addCase(postReferralEmail.fulfilled, (state) => {
      state.postReferralEmailStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postReferralEmail.rejected, (state) => {
      state.postReferralEmailStatus = LOADING_STATUSES.failed;
      state.referralEmail = null;
    });

    //getReferralEmailList
    addCase(getReferralEmailList.pending, (state) => {
      state.getReferralEmailListStatus = LOADING_STATUSES.pending;
    });
    addCase(getReferralEmailList.fulfilled, (state, { payload }) => {
      state.getReferralEmailListStatus = LOADING_STATUSES.succeeded;
      state.referralEmailList = payload;
    });
    addCase(getReferralEmailList.rejected, (state) => {
      state.getReferralEmailListStatus = LOADING_STATUSES.failed;
      state.referralEmailList = [];
    });
  },
});

export const referralPageReducer = referralsPage.reducer;

export const referralActions = { ...referralsPage.actions, getReferralEmailList, postReferralCode, postReferralEmail };
