import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import { deleteAgent, editAgent, exportCSV, getAgent, getTracker } from "./actions";

const initialState = {
  agentData: [],
  openAccordions: {},
  checkedItems: [],
  checkedRelatedItems: [],
  trackerData: [],
  per_page: 10,
  last_page: 0,
  isModalOpen: false,
  paginationData: {
    first: 0,
    rows: 10,
    page: 1,
  },
  downloadCsvHref: null,
  exportCSVStatus: LOADING_STATUSES.idle,
  getAgentDataStatus: LOADING_STATUSES.idle,
  editAgentDataStatus: LOADING_STATUSES.idle,
  getTrackerDataStatus: LOADING_STATUSES.idle,
  deleteAgentStatus: LOADING_STATUSES.idle,
};

export const singleAgentSlice = createSlice({
  name: SLICE_NAME.singleAgentSlice,
  initialState,
  reducers: {
    setOpenAccordion: (state, { payload }) => {
      state.openAccordions[payload.permissionValue] = payload.isOpen;
    },
    setCheckedItems: (state, { payload }) => {
      state.checkedItems = payload.checkedItems;
    },
    setCheckedRelatedItems: (state, { payload }) => {
      state.checkedRelatedItems = payload.checkedRelatedItems;
    },
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    setIsModalOpen: (state, { payload }) => {
      state.isModalOpen = payload;
    },
    resetAllData: () => initialState,
  },
  extraReducers: ({ addCase }) => {
    //get agent
    addCase(getAgent.pending, (state) => {
      state.getAgentDataStatus = LOADING_STATUSES.pending;
    });
    addCase(getAgent.fulfilled, (state, { payload }) => {
      state.getAgentDataStatus = LOADING_STATUSES.succeeded;
      state.agentData = payload.data;
      const items = [];
      const relatedItems = [];
      payload.data?.permissions?.forEach((permission) => {
        items.push(permission.value);
        permission?.related?.forEach((related) => relatedItems.push(related.value));
      });

      state.checkedItems = items;
      state.checkedRelatedItems = relatedItems;
    });

    addCase(getAgent.rejected, (state) => {
      state.getAgentDataStatus = LOADING_STATUSES.failed;
    });

    // edit agent
    addCase(editAgent.pending, (state) => {
      state.editAgentDataStatus = LOADING_STATUSES.pending;
    });
    addCase(editAgent.fulfilled, (state) => {
      state.editAgentDataStatus = LOADING_STATUSES.succeeded;
    });
    addCase(editAgent.rejected, (state) => {
      state.editAgentDataStatus = LOADING_STATUSES.failed;
    });

    // get tracker list

    addCase(getTracker.pending, (state) => {
      state.getTrackerDataStatus = LOADING_STATUSES.pending;
    });
    addCase(getTracker.fulfilled, (state, { payload }) => {
      state.getTrackerDataStatus = LOADING_STATUSES.succeeded;
      state.trackerData = payload.data;
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
    });
    addCase(getTracker.rejected, (state) => {
      state.getTrackerDataStatus = LOADING_STATUSES.failed;
    });

    // delete agent

    addCase(deleteAgent.pending, (state) => {
      state.deleteAgentStatus = LOADING_STATUSES.pending;
    });
    addCase(deleteAgent.fulfilled, (state) => {
      state.deleteAgentStatus = LOADING_STATUSES.succeeded;
    });
    addCase(deleteAgent.rejected, (state) => {
      state.deleteAgentStatus = LOADING_STATUSES.failed;
    });

    //   export CSV

    addCase(exportCSV.pending, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCSV.fulfilled, (state, { payload }) => {
      state.downloadCsvHref = payload;
      state.exportCSVStatus = LOADING_STATUSES.succeeded;
    });
    addCase(exportCSV.rejected, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.failed;
    });
  },
});

export const singleAgentReducer = singleAgentSlice.reducer;

export const singleAgentActions = {
  ...singleAgentSlice.actions,
  deleteAgent,
  editAgent,
  exportCSV,
  getAgent,
  getTracker,
};
