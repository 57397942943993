import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

export const Container = styled.label`
  display: inline-flex;
  align-items: center;
  position: relative;
  right: 10px;

  input:checked {
    background-color: ${COLORS.primary};
    border-color: ${COLORS.primary};
  }
`;

export const Description = styled.p`
  font-size: 14px;
  max-width: 450px;

  &.error {
    color: ${COLORS.red};
  }

  @media screen and (${BREAKPOINTS.md}) {
    font-size: unset;
  }
`;
