import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { archivesListApi } from "./services";

export const getArchivesList = createAsyncThunk(
  `${SLICE_NAME.archiveSlice}/getArchivesList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await archivesListApi.getArchivesList(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postContactAgain = createAsyncThunk(
  `${SLICE_NAME.archiveSlice}/postContactAgain`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await archivesListApi.postContactAgain(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const importArchiveProperties = createAsyncThunk(
  `${SLICE_NAME.archiveSlice}/importArchiveProperties`,
  async (file, { rejectWithValue }) => {
    try {
      const response = await archivesListApi.importArchiveProperties(file);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCSV = createAsyncThunk(
  `${SLICE_NAME.archiveSlice}/exportCSV`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await archivesListApi.exportCSV(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const unarchiveProperty = createAsyncThunk(
  `${SLICE_NAME.archiveSlice}/unarchiveProperty`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await archivesListApi.unarchiveProperty(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
