import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const FilterMultiSelectContainer = styled.div`
  height: 40px;
  position: relative;

  .options {
    width: 300px;

    div {
      margin-top: 6px;
    }
  }

  .search-input {
    min-width: unset !important;
    width: 240px !important;
  }
`;

export const FilterListContainer = styled.div`
  position: absolute;
  min-height: auto;
  width: 190px;
  padding: 16px;
  z-index: 20;
  background-color: ${COLORS.white};
  border-radius: 8px;
  border: 1px solid ${COLORS.overlay_060};
  top: 44px;
  left: 2px;
`;

export const FilterCount = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${COLORS.green};
  font-size: 10px;
  position: absolute;
  right: -6px;
  top: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.white};
`;
