import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Can } from "@casl/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icons } from "assets/svgs";
import { Loader, PrimaryInput } from "common";
import { usePermissions } from "hooks/usePermissions";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { isLoading, PERMISSIONS_LIST } from "utils/constants";
import { adminSingleOwnerFlagsSchema } from "validations";

import Flag from "./Flag";
import * as S from "./styled";

export const Flags = () => {
  const { singleOwner, postAccountsLogStatus } = useSelector(singleOwnerSelectors);
  const { userInfo } = useSelector(adminSignInSelectors);

  const dispatch = useDispatch();

  const ability = usePermissions(userInfo.permissions);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(adminSingleOwnerFlagsSchema),
  });

  const onSubmit = (data) => {
    data.owner_id = singleOwner.id;
    data.flag = 1;
    dispatch(singleOwnerActions.postAccountLogs(data));
  };

  useEffect(() => {
    setValue("type", "");
    setValue("content", "");
  }, [postAccountsLogStatus]);

  return (
    <S.Container>
      <S.Content>
        <S.ContentTitle>Flags</S.ContentTitle>
        <S.FlagsContent>
          {singleOwner.account_logs.map((accLog) => (
            <Flag key={accLog.id} accLog={accLog} />
          ))}
        </S.FlagsContent>
        {isLoading(postAccountsLogStatus) ? (
          <Loader />
        ) : (
          <Can ability={ability} I={PERMISSIONS_LIST.addDeleteFlag}>
            <S.AddFlagForm onSubmit={handleSubmit(onSubmit)}>
              <S.Flag>
                <Icons.FlagIcon />
                <PrimaryInput error={errors.type} placeholder="Type" register={register("type")} />
                <PrimaryInput
                  className="content-input"
                  error={errors.content}
                  placeholder="Content"
                  register={register("content")}
                />
                <S.IconWrapper>
                  <Icons.PlusIcon className="plus-icon" />
                </S.IconWrapper>
              </S.Flag>
            </S.AddFlagForm>
          </Can>
        )}
      </S.Content>
    </S.Container>
  );
};
