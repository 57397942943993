import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { COLORS } from "assets/styles/colors";

export const UnderlineView = ({ signType, label, flexGrow = false, value, valueType = "text" }) => {
  return (
    <View style={[styles.container, flexGrow ? { flexGrow: 1 } : {}]}>
      {valueType === "img" ? (
        <>
          <View style={{ width: 1, height: 30 }}></View>
          <Text style={{ position: "absolute", fontSize: 8, fontWeight: "bold", width: 260, top: -5, left: 27 }}>
            {signType === "type" ? "Electronically Typed and Signed By" : "Electronically Drawn and Signed By"}
          </Text>
          <View style={styles.signatureContent}></View>
          <View
            style={{
              height: `${signType === "type" ? 40 : "auto"}`,
              position: "absolute",
              left: 24,
              top: `${signType === "type" ? -4 : 5}`,
              zIndex: 1,
            }}
          >
            {value}
          </View>
        </>
      ) : (
        <Text style={styles.value}>{value}</Text>
      )}
      <View style={styles.line} />
      <Text style={styles.label}>{label}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 4,
    minWidth: 160,
  },
  signatureContent: {
    position: "absolute",
    top: -2,
    left: 16,
    width: 10,
    height: 40,
    borderWidth: 1,
    justifyContent: "center",
    borderColor: "#005196",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderRightWidth: 0,
  },
  label: {
    fontSize: 7,
    marginTop: 2,
  },
  line: {
    borderBottomWidth: 0.5,
    borderBottomStyle: "solid",
    borderBottomColor: COLORS.blue,
  },
  value: {
    fontSize: 9,
    marginBottom: 6,
    minHeight: 8,
  },
});
