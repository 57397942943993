import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Can } from "@casl/react";
import { PrimaryButton, SearchInput, Table } from "common";
import { usePermissions } from "hooks/usePermissions";
import { Filter } from "pages/admin/components/Filter";
import { Paginator } from "primereact/paginator";
import { adminRoutes } from "router/routes";
import { agentsSelector } from "store/admin-slices/agents-slice/selectors";
import { agentsActions } from "store/admin-slices/agents-slice/slice";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { isLoading, PERMISSIONS_LIST } from "utils/constants";
import { downloadFileByLink, getPaginationDescription } from "utils/helpers";

import { Template } from "components/Admin/TableRowCustomTemplate";

import * as S from "./styled";

const tableData = [
  { headerClassName: "column-header", selectionMode: "multiple" },
  {
    header: "Id",
    field: "id",
    sortable: true,
  },
  {
    header: "Full name",
    field: "name",
    sortable: true,
  },
  {
    header: "Email",
    field: "email",
    sortable: true,
  },
  {
    header: "Role description",
    body: ({ role_description }) => Template.DescriptionTemplate(role_description, "Role description"),
  },
];

export const ProfilesTable = ({ setSortData, setSearchQuery, debouncedSearchQuery, onPageChange, sortData }) => {
  const { last_page, paginationData, agentsList, getAgentsStatus, exportCSVStatus, downloadCsvHref, allowScroll } =
    useSelector(agentsSelector);
  const { userInfo } = useSelector(adminSignInSelectors);

  const [selectedAgents, setSelectedAgents] = useState([]);

  const ref = useRef(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const ability = usePermissions(userInfo.permissions);

  const handleRowClick = (item) => {
    dispatch(agentsActions.setAllowScroll(false));
    const shouldRedirect =
      ability.can(PERMISSIONS_LIST.agentActionTracker) ||
      ability.can(PERMISSIONS_LIST.downloadActionCSV) ||
      ability.can(PERMISSIONS_LIST.deleteUsers) ||
      ability.can(PERMISSIONS_LIST.updateUsers);

    if (shouldRedirect) {
      navigate(`${adminRoutes.prefix}/${adminRoutes.agents}/${item.data.id}`);
    }
  };

  const onSort = (e) => setSortData({ field: e.sortField, direction: e.sortOrder });

  const onSearch = (searchResult) => {
    dispatch(agentsActions.setAllowScroll(false));
    setSearchQuery(searchResult);
  };

  const handleExportCsvClick = () => {
    const params = {
      agents: selectedAgents.map((item) => item.id),
      search: debouncedSearchQuery,
    };
    dispatch(agentsActions.exportCSV(params));
  };

  useEffect(() => {
    downloadFileByLink(exportCSVStatus, downloadCsvHref, "agents.csv");
  }, [downloadCsvHref]);

  useEffect(() => {
    setSelectedAgents([]);
  }, [paginationData.page]);

  useEffect(() => {
    allowScroll && ref.current.scrollIntoView({ behavior: "auto" });
  }, [getAgentsStatus]);

  return (
    <S.Container>
      <S.Content>
        <Filter>
          <SearchInput className="search-input" placeholder="Search Client List" onSearch={onSearch} />
          <Can ability={ability} I={PERMISSIONS_LIST.downloadAgentsCSV}>
            <PrimaryButton
              eventHandler={handleExportCsvClick}
              label="Download CSV"
              loading={isLoading(exportCSVStatus)}
              mode="gray"
            />
          </Can>
        </Filter>
        <Table
          dataKey="id"
          loading={isLoading(getAgentsStatus)}
          selection={selectedAgents}
          sortField={sortData.field}
          sortOrder={sortData.direction}
          tableData={tableData}
          value={agentsList || []}
          onRowDoubleClick={handleRowClick}
          onSelectionChange={(e) => setSelectedAgents(e.value)}
          onSort={onSort}
        />
        <S.PaginatorWrapper ref={ref}>
          <Paginator
            className="pagination"
            first={paginationData.first}
            rows={paginationData.rows}
            rowsPerPageOptions={[10, 50, 100, 200]}
            totalRecords={last_page}
            onClick={() => dispatch(agentsActions.setAllowScroll(true))}
            onPageChange={onPageChange}
          />
          <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
        </S.PaginatorWrapper>
      </S.Content>
    </S.Container>
  );
};
