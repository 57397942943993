import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Can } from "@casl/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icons } from "assets/svgs";
import {
  AdminReloadButton,
  FilterMultiSelect,
  PrimaryButton,
  PrimaryInput,
  PrimaryTextarea,
  SearchInput,
  Select,
  Table,
} from "common";
import { useDebounce } from "hooks";
import { usePermissions } from "hooks/usePermissions";
import { Paginator } from "primereact/paginator";
import { HearingDatesListSelectors } from "store/admin-slices/hearing-dates-slice/selectors";
import { hearingDatesActions } from "store/admin-slices/hearing-dates-slice/slice";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import {
  COMPLETE_FILTER_OPTIONS,
  COUNTIES_FILTER,
  isLoading,
  LOADING_STATUSES,
  PERMISSIONS_LIST,
} from "utils/constants";
import { downloadFileByLink, getPaginationDescription } from "utils/helpers";
import { hearingDatesSchema } from "validations";

import CountyCard from "components/Admin/CountriesCountCard";
import { Filter } from "components/Filter";
import { Modal } from "components/Modal";
import { UploadFileModal } from "components/UploadFileModal";

import * as S from "./styled";

export const HearingDatesPages = () => {
  const {
    hearingDatesList,
    last_page,
    getHearingDatesListStatus,
    postUploadHearingFileStatus,
    paginationData,
    exportCSVStatus,
    downloadCsvHref,
    isCommentsModalOpen,
    itemId,
    postUploadHearingCommentStatus,
    itemComment,
    allowScroll,
    upcomingHearingsList,
    postHearingsCompleteStatus,
  } = useSelector(HearingDatesListSelectors);
  const { userInfo } = useSelector(adminSignInSelectors);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(hearingDatesSchema),
    mode: "onBlur",
  });

  const [selectedCountyOptions, setSelectedCountyOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [selectValue, setSelectValue] = useState("");
  const [loadingId, setLoadingId] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 200);

  const [startDate, setStartDate] = useState("");
  const debouncedStartDate = useDebounce(startDate, 500);

  const [endDate, setEndDate] = useState("");
  const debouncedEndDate = useDebounce(endDate, 500);

  const [sortData, setSortData] = useState({
    field: "updated_at",
    direction: -1,
  });

  const selectedIds = useMemo(() => selectedProducts?.map((item) => item.id) || [], [selectedProducts]);

  const isCompleted = useMemo(
    () => COMPLETE_FILTER_OPTIONS.find((option) => option.label === selectValue)?.value,
    [selectValue]
  );

  const dispatch = useDispatch();

  const ref = useRef(null);

  const ability = usePermissions(userInfo.permissions);

  const tableData = useMemo(
    () => [
      {
        headerClassName: "column-header",
        selectionMode: "multiple",
      },
      { field: "property_id", header: "Property id" },
      { field: "account_number", header: "Account number" },
      { field: "county", header: "County" },
      { field: "date", header: "Date", sortable: true },
      { field: "updated_at", header: "Updated date", sortable: true },
      {
        field: "country_auth",
        header: "County auth",
        body: (item) => (
          <S.CountryAuth>
            {item.country_auth.length > 0 ? (
              <S.Text className={item.country_auth === "YES" ? "green" : "red"}>{item.country_auth}</S.Text>
            ) : (
              <S.Text className="red">Verification need</S.Text>
            )}
          </S.CountryAuth>
        ),
      },
      {
        field: "owner_status",
        header: "Owner status",
        body: (item) => (
          <S.OwnerStatus>
            {item.owner_status.includes("Not Signed-up") ? (
              <S.Text className="red">{item.owner_status}</S.Text>
            ) : (
              <S.Text className="green">{item.owner_status}</S.Text>
            )}
          </S.OwnerStatus>
        ),
      },
      {
        header: "Status",
        field: "is_completed",
        body: (item) => (
          <S.Status>
            {item.is_completed ? (
              <PrimaryButton
                eventHandler={() => {
                  setLoadingId(item.id);
                  handleComplete([item.id]);
                }}
                label="Completed"
                loading={isLoading(postHearingsCompleteStatus) && loadingId === item.id}
              />
            ) : (
              <PrimaryButton
                eventHandler={() => {
                  setLoadingId(item.id);
                  handleComplete([item.id]);
                }}
                label="Not completed"
                loading={isLoading(postHearingsCompleteStatus) && loadingId === item.id}
                mode="red"
              />
            )}
          </S.Status>
        ),
      },
      { field: "affidavit_submitted", header: "Affidavit submitted" },
      {
        field: "comments",
        header: "Comments",
        body: (item) => (
          <S.Comments title={item.comments}>
            <S.Comments className="reason-content">{item.comments}</S.Comments>
            <Icons.EditIcon
              className="edit-icon"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenModal(item);
              }}
            />
          </S.Comments>
        ),
      },
      { field: "reduction", header: "Reduction" },
      { field: "added", header: "Added" },
    ],
    [postHearingsCompleteStatus, loadingId]
  );

  const getHearingDates = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      counties: selectedCountyOptions,
      search: debouncedSearchQuery,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "asc" : "desc",
      start_date: debouncedStartDate,
      end_date: debouncedEndDate,
      is_completed: isCompleted,
    };

    dispatch(hearingDatesActions.getHearingDatesList(params));
  };

  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      hearingDatesActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getHearingDates({ page: page + 1, rows });
  };

  const resetData = () => {
    dispatch(hearingDatesActions.setAllowScroll(false));
    getHearingDates({ page: 1, limit: 50 });
    setSelectedCountyOptions([]);
    setSortData({
      field: "updated_at",
      direction: -1,
    });
    setSearchQuery("");
    setSelectedProducts(null);
    dispatch(hearingDatesActions.setPaginationData({ first: 10, rows: 50, page: 1 }));
    setStartDate("");
    setEndDate("");
    setSelectValue("");
  };

  const downloadCsv = () => {
    let hearing_dates = selectedProducts?.map((prop) => prop.id);
    hearing_dates = hearing_dates?.filter((id, index, self) => self.indexOf(id) === index);
    const params = {
      search: debouncedSearchQuery,
      counties: selectedCountyOptions,
      hearing_dates,
    };
    dispatch(hearingDatesActions.exportCSV(params));
  };

  const deSelectCounties = () => {
    if (selectedCountyOptions?.length > 0) {
      setSelectedCountyOptions([]);
    } else {
      setSelectedCountyOptions(COUNTIES_FILTER.map((item) => item.value));
    }
  };

  const onSearch = (searchResult) => {
    setSearchQuery(searchResult);
  };

  const onSort = (e) => setSortData({ field: e.sortField, direction: e.sortOrder });

  const openFileUploadModal = () => {
    dispatch(hearingDatesActions.setAllowScroll(false));
    setIsModalOpen(true);
  };

  const uploadFile = (body) => {
    dispatch(hearingDatesActions.setAllowScroll(false));
    dispatch(hearingDatesActions.postUploadHearingFile(body));
  };

  const handleOpenModal = (item) => {
    reset();
    dispatch(hearingDatesActions.setAllowScroll(false));
    dispatch(hearingDatesActions.setItemComment(item.comments));
    dispatch(hearingDatesActions.setItemId(item.id));
    dispatch(hearingDatesActions.setIsCommentsModalOpen(true));
  };

  const handleCloseModal = () => {
    dispatch(hearingDatesActions.setIsCommentsModalOpen(false));
    reset();
  };

  const handleConfirm = (data) => {
    const body = {
      id: itemId,
      comment: data.comment,
    };

    dispatch(hearingDatesActions.postUploadHearingComment(body));
  };

  const onSelectChange = (value) => {
    setSelectValue(value);
  };

  const handleComplete = (ids) => {
    dispatch(hearingDatesActions.setAllowScroll(false));
    const body = new FormData();

    ids.forEach((id, index) => body.append(`ids[${index}]`, id));
    dispatch(hearingDatesActions.postHearingsComplete(body));
  };

  const onWidgetClick = (e) => {
    const selectedCounty = e.target.innerText.split(" ")[0];
    const tomorrowFormatted = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0];
    const twoWeeksAfterFormatted = new Date(new Date().setDate(new Date().getDate() + 14)).toISOString().split("T")[0];

    setSelectedCountyOptions([]);
    setSelectedCountyOptions([selectedCounty]);
    setSelectValue(COMPLETE_FILTER_OPTIONS.find((option) => option.value === "0").label);
    setStartDate(tomorrowFormatted);
    setEndDate(twoWeeksAfterFormatted);
  };

  useEffect(() => {
    getHearingDates(paginationData.page, paginationData.rows);
    dispatch(hearingDatesActions.setAllowScroll(false));
  }, [selectedCountyOptions, debouncedSearchQuery, sortData]);

  useEffect(() => {
    dispatch(hearingDatesActions.setAllowScroll(false));
  }, [selectValue]);

  useEffect(() => {
    getHearingDates();
  }, [postUploadHearingFileStatus, postUploadHearingCommentStatus]);

  useEffect(() => {
    downloadFileByLink(exportCSVStatus, downloadCsvHref, "hearing-dates.csv");
  }, [downloadCsvHref]);

  useEffect(() => {
    if (postUploadHearingCommentStatus === LOADING_STATUSES.succeeded) {
      dispatch(hearingDatesActions.setIsCommentsModalOpen(false));
    }
  }, [postUploadHearingCommentStatus]);

  useEffect(() => {
    if (
      postUploadHearingFileStatus === LOADING_STATUSES.succeeded ||
      postHearingsCompleteStatus === LOADING_STATUSES.succeeded
    )
      dispatch(hearingDatesActions.getUpcomingHearings());
  }, [postUploadHearingFileStatus, postHearingsCompleteStatus]);

  useEffect(() => {
    return () => dispatch(hearingDatesActions.resetAllData());
  }, []);

  useEffect(() => {
    setSelectedProducts(null);
  }, [paginationData.page]);

  useEffect(() => {
    if (allowScroll) {
      ref.current.scrollIntoView({ behavior: "auto" });
    }
  }, [getHearingDatesListStatus, allowScroll]);

  useEffect(() => {
    if (postHearingsCompleteStatus === LOADING_STATUSES.succeeded) {
      setSelectedProducts(null);

      getHearingDates();
    }
  }, [postHearingsCompleteStatus]);

  useEffect(() => {
    dispatch(hearingDatesActions.getUpcomingHearings());
  }, []);

  useEffect(() => {
    getHearingDates();
  }, [debouncedSearchQuery, sortData, debouncedStartDate, debouncedEndDate, selectValue]);

  return (
    <S.HearingDatesContainer>
      <S.HearingDatesListTitle>Hearing Dates</S.HearingDatesListTitle>
      <S.ReloadAndCountriesSection>
        <AdminReloadButton onClick={resetData} />
        <Filter>
          {upcomingHearingsList?.map((item, index) => {
            return (
              <CountyCard
                key={index}
                colorIndex={index}
                subTitle={item.count}
                title={item.county}
                onClick={onWidgetClick}
              />
            );
          })}
        </Filter>
      </S.ReloadAndCountriesSection>

      <Filter>
        <SearchInput
          className="search-input"
          placeholder="Search Client List"
          value={searchQuery}
          onSearch={onSearch}
        />
        <FilterMultiSelect
          buttonLabel="COUNTY FILTER"
          options={COUNTIES_FILTER}
          selectedOptions={selectedCountyOptions}
          setSelectedOptions={setSelectedCountyOptions}
        />
        <PrimaryButton
          eventHandler={deSelectCounties}
          label={(selectedCountyOptions.length ? "DE" : "") + "SELECT ALL COUNTIES"}
          mode="gray"
        />
        <Can ability={ability} I={PERMISSIONS_LIST.uploadHearingDateFile}>
          <PrimaryButton eventHandler={openFileUploadModal} label="upload file" mode="gray" />
        </Can>
        <Can ability={ability} I={PERMISSIONS_LIST.downloadHearingDateCSV}>
          <PrimaryButton
            eventHandler={downloadCsv}
            label="DOWNLOAD CSV"
            loading={isLoading(exportCSVStatus)}
            mode="gray"
          />
        </Can>
        <UploadFileModal
          isOpenModal={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          status={postUploadHearingFileStatus}
          uploadFile={uploadFile}
        />
        <PrimaryButton
          disabled={!selectedIds.length}
          eventHandler={() => handleComplete(selectedIds)}
          label="Complete"
          mode="gray"
        />
        <Select
          itemKey="label"
          items={COMPLETE_FILTER_OPTIONS}
          label="Completed status"
          value={selectValue}
          onChange={onSelectChange}
        />
        <PrimaryInput
          placeholder="Start date"
          type="date"
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value);
            dispatch(hearingDatesActions.setAllowScroll(false));
          }}
        />
        <PrimaryInput
          placeholder="End date"
          type="date"
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value);
            dispatch(hearingDatesActions.setAllowScroll(false));
          }}
        />
      </Filter>
      <Table
        dataKey="uniqueId"
        loading={getHearingDatesListStatus !== LOADING_STATUSES.succeeded}
        selection={selectedProducts}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={hearingDatesList}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
        onSort={onSort}
      />
      <Modal isModalOpen={isCommentsModalOpen}>
        <S.Form onSubmit={handleSubmit(handleConfirm)}>
          <PrimaryTextarea
            defaultValue={itemComment}
            error={errors.comment}
            placeholder="Comment"
            register={register("comment")}
          />
          <S.ButtonsContainer>
            <PrimaryButton
              eventHandler={handleSubmit}
              label="Submit"
              loading={isLoading(postUploadHearingCommentStatus)}
            />
            <PrimaryButton eventHandler={handleCloseModal} label="Cancel" mode="gray" />
          </S.ButtonsContainer>
        </S.Form>
      </Modal>
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[50, 100, 200]}
          totalRecords={last_page}
          onClick={() => dispatch(hearingDatesActions.setAllowScroll(true))}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.HearingDatesContainer>
  );
};
