import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton, PrimaryInput } from "common";
import { postCheckCode } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { isLoading } from "utils/constants";
import { signinStep2Schema } from "validations";

import * as S from "./styled";

export const Step2 = () => {
  const { email, postCheckCodeStatus, checkCodeErrorMessage } = useSelector(clientSelectors);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signinStep2Schema),
  });

  const onSubmit = (body) => {
    body.email = email;
    dispatch(postCheckCode(body));
  };

  return (
    <S.StepContainer onSubmit={handleSubmit(onSubmit)}>
      <S.Description className="no-margin">Verification email was sent to {email}</S.Description>
      <S.Description>Please enter the 6-digit code</S.Description>

      <S.InputWrapper>
        {checkCodeErrorMessage ? <S.ErrorMessageBlock>{checkCodeErrorMessage}</S.ErrorMessageBlock> : null}
        <PrimaryInput error={errors.code} placeholder="Verification code" register={register("code")} />
      </S.InputWrapper>
      <S.ButtonWrapper>
        <PrimaryButton className="submit-button" label="sign in" loading={isLoading(postCheckCodeStatus)} />
      </S.ButtonWrapper>
    </S.StepContainer>
  );
};
