import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import {
  deleteEvidenceFile,
  exportCSV,
  getSingleClientEvidences,
  getTracker,
  mergeEvidenceFiles,
  postAddFile,
  postChangeFileName,
  postChangeFileType,
  postEvidenceStatus,
  postFileStatus,
} from "./actions";

const initialState = {
  singleClientEvidences: [],
  isModalOpen: false,
  isDeleteModalOpen: false,
  selectedOptions: null,
  fileName: "",
  isFileSelected: false,
  trackerData: [],
  per_page: 10,
  last_page: 0,
  paginationData: {
    first: 0,
    rows: 10,
    page: 1,
  },
  downloadCsvHref: null,
  getSingleClientEvidencesStatus: LOADING_STATUSES.idle,
  changeClientEvidenceStatus: LOADING_STATUSES.idle,
  mergeEvidenceFilesStatus: LOADING_STATUSES.idle,
  postChangeFileTypeStatus: LOADING_STATUSES.idle,
  postChangeFileNameStatus: LOADING_STATUSES.idle,
  postAddFileStatus: LOADING_STATUSES.idle,
  getTrackerDataStatus: LOADING_STATUSES.idle,
  exportCsvStatus: LOADING_STATUSES.idle,
  deleteEvidenceFileStatus: LOADING_STATUSES.idle,
};

export const singleClientEvidenceSlice = createSlice({
  name: SLICE_NAME.singleClientEvidenceSlice,
  initialState,
  reducers: {
    resetAllData: () => initialState,
    setIsModalOpen: (state, { payload }) => {
      state.isModalOpen = payload;
    },
    setSelectedOptions: (state, { payload }) => {
      state.selectedOptions = payload;
    },
    setFileName: (state, { payload }) => {
      state.fileName = payload;
    },
    setIsFileSelected: (state, { payload }) => {
      state.isFileSelected = payload;
    },
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    setIsDeleteModalOpen: (state, { payload }) => {
      state.isDeleteModalOpen = payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getSingleClientEvidences.pending, (state) => {
      state.getSingleClientEvidencesStatus = LOADING_STATUSES.pending;
    });
    addCase(getSingleClientEvidences.fulfilled, (state, { payload }) => {
      state.singleClientEvidences = payload.data;
      state.singleClientEvidences.files.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      state.getSingleClientEvidencesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getSingleClientEvidences.rejected, (state) => {
      state.getSingleClientEvidencesStatus = LOADING_STATUSES.failed;
    });

    //  change file status
    addCase(postFileStatus.pending, (state) => {
      state.getSingleClientEvidencesStatus = LOADING_STATUSES.pending;
    });
    addCase(postFileStatus.fulfilled, (state, { payload }) => {
      state.singleClientEvidences.files = state.singleClientEvidences.files.map((item) => {
        if (item.id === payload.id) {
          return { ...item, status: payload.status };
        }
        return item;
      });
      state.getSingleClientEvidencesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postFileStatus.rejected, (state) => {
      state.getSingleClientEvidencesStatus = LOADING_STATUSES.failed;
    });

    //   change client evidence status
    addCase(postEvidenceStatus.pending, (state) => {
      state.changeClientEvidenceStatus = LOADING_STATUSES.pending;
    });
    addCase(postEvidenceStatus.fulfilled, (state, { payload }) => {
      state.singleClientEvidences.status = payload.status;
      state.changeClientEvidenceStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postEvidenceStatus.rejected, (state) => {
      state.changeClientEvidenceStatus = LOADING_STATUSES.failed;
    });

    //   evidence file merge

    addCase(mergeEvidenceFiles.pending, (state) => {
      state.mergeEvidenceFilesStatus = LOADING_STATUSES.pending;
    });
    addCase(mergeEvidenceFiles.fulfilled, (state) => {
      state.singleClientEvidences.is_merged = state.singleClientEvidences.is_merged === 0 ? 1 : 0;
      state.mergeEvidenceFilesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(mergeEvidenceFiles.rejected, (state) => {
      state.mergeEvidenceFileStatus = LOADING_STATUSES.failed;
    });

    // change evidence file type

    addCase(postChangeFileType.pending, (state) => {
      state.postChangeFileTypeStatus = LOADING_STATUSES.pending;
    });
    addCase(postChangeFileType.fulfilled, (state, { payload }) => {
      state.singleClientEvidences.files = state.singleClientEvidences.files.map((item) => {
        if (item.id === payload.id) {
          item.type = payload.evidence_type;
        }
        return item;
      });
      state.postChangeFileTypeStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postChangeFileType.rejected, (state) => {
      state.postChangeFileTypeStatus = LOADING_STATUSES.failed;
    });

    // change evidence file name

    addCase(postChangeFileName.pending, (state) => {
      state.postChangeFileNameStatus = LOADING_STATUSES.pending;
    });
    addCase(postChangeFileName.fulfilled, (state, { payload }) => {
      state.singleClientEvidences.files = state.singleClientEvidences.files.map((item) => {
        if (item.id === payload.id) {
          item.name = payload.name;
        }
        return item;
      });
      state.postChangeFileNameStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postChangeFileName.rejected, (state) => {
      state.postChangeFileNameStatus = LOADING_STATUSES.failed;
    });

    // upload files

    addCase(postAddFile.pending, (state) => {
      state.postAddFileStatus = LOADING_STATUSES.pending;
    });
    addCase(postAddFile.fulfilled, (state) => {
      state.postAddFileStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postAddFile.rejected, (state) => {
      state.postAddFileStatus = LOADING_STATUSES.failed;
    });

    // get tracker list
    addCase(getTracker.pending, (state) => {
      state.getTrackerDataStatus = LOADING_STATUSES.pending;
    });
    addCase(getTracker.fulfilled, (state, { payload }) => {
      state.trackerData = payload.data;
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
      state.getTrackerDataStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getTracker.rejected, (state) => {
      state.getTrackerDataStatus = LOADING_STATUSES.failed;
    });

    // export action csv
    addCase(exportCSV.pending, (state) => {
      state.exportCsvStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCSV.fulfilled, (state, { payload }) => {
      state.downloadCsvHref = payload;
      state.exportCsvStatus = LOADING_STATUSES.succeeded;
    });
    addCase(exportCSV.rejected, (state) => {
      state.exportCsvStatus = LOADING_STATUSES.failed;
    });

    // delete evidence file
    addCase(deleteEvidenceFile.pending, (state) => {
      state.deleteEvidenceFileStatus = LOADING_STATUSES.pending;
    });
    addCase(deleteEvidenceFile.fulfilled, (state) => {
      state.deleteEvidenceFileStatus = LOADING_STATUSES.succeeded;
    });
    addCase(deleteEvidenceFile.rejected, (state) => {
      state.deleteEvidenceFileStatus = LOADING_STATUSES.failed;
    });
  },
});

export const singleClientEvidenceReducer = singleClientEvidenceSlice.reducer;

export const singleClientEvidenceActions = {
  ...singleClientEvidenceSlice.actions,
  exportCSV,
  getSingleClientEvidences,
  getTracker,
  mergeEvidenceFiles,
  postAddFile,
  postChangeFileName,
  postChangeFileType,
  postEvidenceStatus,
  postFileStatus,
  deleteEvidenceFile,
};
