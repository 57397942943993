import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox, PrimaryAccordion, PrimaryButton, PrimaryInput, PrimaryTextarea } from "common";
import { agentsSelector } from "store/admin-slices/agents-slice/selectors";
import { singleAgentSelector } from "store/admin-slices/single-agent-slice/selectors";
import { singleAgentActions } from "store/admin-slices/single-agent-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";
import { editAgentSchema } from "validations";

import * as S from "./styled";

export const EditAgent = () => {
  const { agentData, openAccordions, checkedItems, checkedRelatedItems, editAgentDataStatus } =
    useSelector(singleAgentSelector);
  const { permissions } = useSelector(agentsSelector);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editAgentSchema),
    mode: "onBlur",
  });

  const dispatch = useDispatch();

  const { id } = useParams();
  const handleAccordionToggle = (value) => {
    const permission = permissions.find((perm) => perm.value === value);
    if (checkedItems.includes(value) && permission.related.length > 0) {
      dispatch(singleAgentActions.setOpenAccordion({ permissionValue: value, isOpen: !openAccordions[value] }));
    }
  };

  const handleCheckboxHeadingToggle = (value) => {
    const newCheckedItems = checkedItems.includes(value)
      ? checkedItems.filter((item) => item !== value)
      : [...checkedItems, value];

    dispatch(singleAgentActions.setCheckedItems({ checkedItems: newCheckedItems }));

    if (!newCheckedItems.includes(value)) {
      dispatch(singleAgentActions.setOpenAccordion({ permissionValue: value, isOpen: false }));
      const relatedItemsToReset = permissions
        .find((permission) => permission.value === value)
        .related.map((item) => item.value);

      const newCheckedRelatedItems = checkedRelatedItems.filter((item) => !relatedItemsToReset.includes(item));
      dispatch(singleAgentActions.setCheckedRelatedItems({ checkedRelatedItems: newCheckedRelatedItems }));
    }
  };

  const handleCheckboxRelatedToggle = (value) => {
    const newCheckedRelatedItems = checkedRelatedItems.includes(value)
      ? checkedRelatedItems.filter((item) => item !== value)
      : [...checkedRelatedItems, value];

    dispatch(singleAgentActions.setCheckedRelatedItems({ checkedRelatedItems: newCheckedRelatedItems }));
  };

  const onSubmit = (data) => {
    const { name, email, role_description } = data;
    const permissions = checkedItems.concat(checkedRelatedItems);
    dispatch(singleAgentActions.editAgent({ id, name, email, role_description, permissions }));
  };

  const selectAllRelatedItems = (permission) => {
    const allRelatedItems = permission?.related.map((item) => item.value);
    const newCheckedRelatedItems = [...checkedRelatedItems, ...allRelatedItems];
    dispatch(singleAgentActions.setCheckedRelatedItems({ checkedRelatedItems: newCheckedRelatedItems }));
  };

  const deselectAllRelatedItems = (permission) => {
    const allRelatedItems = permission.related.map((item) => item.value);
    const newCheckedRelatedItems = checkedRelatedItems.filter((item) => !allRelatedItems.includes(item));
    dispatch(singleAgentActions.setCheckedRelatedItems({ checkedRelatedItems: newCheckedRelatedItems }));
  };

  useEffect(() => {
    if (agentData.name && agentData.email) {
      setValue("name", agentData?.name);
      setValue("email", agentData?.email);
      setValue("role_description", agentData?.role_description);
    }
  }, [agentData]);

  useEffect(() => {
    if (editAgentDataStatus === LOADING_STATUSES.failed) {
      toast.error("Edit agent failed");
    } else if (editAgentDataStatus === LOADING_STATUSES.succeeded) {
      toast.success("Edit agent succeeded");
    }
  }, [editAgentDataStatus]);

  return (
    <S.Content>
      <S.ContentTitle>Edit Agent</S.ContentTitle>
      <S.EditAccountForm onSubmit={handleSubmit(onSubmit)}>
        <S.EditWrapper>
          <S.EditContent>
            <S.Box>Edit Account</S.Box>
            <PrimaryInput error={errors.name} label="Full Name" register={register("name")} />
            <PrimaryInput error={errors.email} label="Email" register={register("email")} />
            <PrimaryTextarea
              error={errors.role_description}
              placeholder="Role description"
              register={register("role_description")}
            />
          </S.EditContent>
          <S.EditContent>
            <S.Box>Edit Permissions</S.Box>
            <S.EditPermissionsWrapper>
              {permissions?.slice(2).map((permission) => (
                <PrimaryAccordion
                  key={permission.value}
                  bodyContent={
                    <S.RelatedWrapper>
                      {permission.related?.map((item) => (
                        <S.RelatedItems key={item.value}>
                          <Checkbox
                            checked={checkedRelatedItems.includes(item.value)}
                            id={item.value}
                            onChange={() => handleCheckboxRelatedToggle(item.value)}
                          />
                          <S.RelatedLabel htmlFor={item.value}>{item.label}</S.RelatedLabel>
                        </S.RelatedItems>
                      ))}
                      <S.Buttons>
                        <PrimaryButton
                          className="select-all"
                          eventHandler={(e) => {
                            e.preventDefault();
                            selectAllRelatedItems(permission);
                          }}
                          label="Select All"
                          mode="gray"
                        />
                        <PrimaryButton
                          className="deselect-all"
                          eventHandler={(e) => {
                            e.preventDefault();
                            deselectAllRelatedItems(permission);
                          }}
                          label="Deselect All"
                          mode="gray"
                        />
                      </S.Buttons>
                    </S.RelatedWrapper>
                  }
                  checkboxChecked={checkedItems.includes(permission.value)}
                  checkboxId={permission.value}
                  checkboxOnChange={() => handleCheckboxHeadingToggle(permission.value)}
                  className={permission.related.length ? "" : "acordion"}
                  headerContent={
                    <S.HeadingContainer className={checkedItems.includes(permission.value) ? "" : "disabled"}>
                      {permission.label}
                    </S.HeadingContainer>
                  }
                  isOpen={openAccordions[permission.value] || false}
                  onToggle={() => handleAccordionToggle(permission.value)}
                />
              ))}
            </S.EditPermissionsWrapper>
          </S.EditContent>
        </S.EditWrapper>
        <PrimaryButton className="save-button" label="Save" loading={isLoading(editAgentDataStatus)} />
      </S.EditAccountForm>
    </S.Content>
  );
};
