import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Table } from "common";
import { discountSelector } from "store/admin-slices/discount-slice/selectors";
import { discountActions } from "store/admin-slices/discount-slice/slice";
import { isLoading } from "utils/constants";

import * as S from "./styled";

export const Discount = () => {
  const { getDiscountList, getDiscountStatus } = useSelector(discountSelector);

  const { id } = useParams();

  const dispatch = useDispatch();

  const tableData = useMemo(
    () => [
      { field: "reason", header: "Reason" },
      { field: "percent", header: "Percentage" },
      { field: "updated_at", header: "Last Update" },
      { field: "created_at", header: "Creation Date" },
    ],
    []
  );

  useEffect(() => {
    dispatch(discountActions.getOwnerDiscount({ owner_id: id }));
  }, [id]);

  useEffect(() => {
    return () => dispatch(discountActions.resetAllData());
  }, []);

  return (
    <S.DiscountWrapper>
      <S.ContentTitle>Discount</S.ContentTitle>
      <Table
        dataKey="created_at"
        loading={isLoading(getDiscountStatus)}
        tableData={tableData}
        value={getDiscountList || []}
      />
    </S.DiscountWrapper>
  );
};
