import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton, PrimaryInput } from "common";
import dayjs from "dayjs";
import { signupSelectors } from "store/signup-slice/selectors";
import { signupActions } from "store/signup-slice/slice";
import { isLoading, PTP_CONTACT_US_LINK } from "utils/constants";
import { isEmpty, setStorage } from "utils/helpers";
import { signUpStep1Schema } from "validations";

import { StyledLink } from "./styled";
import * as S from "./styled";

const Step1 = () => {
  const { signupStatus, ownerData } = useSelector(signupSelectors);
  const dispatch = useDispatch();

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(signUpStep1Schema),
  });

  const { firstName, lastName, email, phoneNumber } = watch();

  const [queryParameter] = useSearchParams();
  const referralCode = queryParameter.get("referral");

  const isContinueButtonDisabled = !firstName || !lastName || !email || !phoneNumber || !isEmpty(errors);

  const onSubmit = (data) => {
    const body = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone_number: data.phoneNumber.replace(/\D/g, ""),
      sign_up_date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      referral_code: data.referral,
      display_clients: 1,
      sign_up_step_number: 1,
      documents_yn: 0,
    };
    sessionStorage.setItem("first_name", data.firstName);
    sessionStorage.setItem("last_name", data.lastName);
    sessionStorage.setItem("email", data.email);
    setStorage("owner_data", body);
    dispatch(signupActions.signUp(body));
  };

  useEffect(() => {
    if (ownerData) {
      const { first_name, last_name, email, phone_number, referralCode } = ownerData;
      setValue("firstName", first_name);
      setValue("lastName", last_name);
      setValue("email", email);
      setValue("phoneNumber", phone_number?.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
      setValue("referral", referralCode);
    }
  }, [ownerData]);

  useEffect(() => {
    setValue("referral", referralCode);
  }, [referralCode]);

  useEffect(() => {
    dispatch(signupActions.setScrollTooErrorMessage());
  }, []);

  return (
    <>
      <S.Form className="flex w-full flex-col gap-4 py-6" onSubmit={handleSubmit(onSubmit)}>
        <S.Box>
          <S.SubTitle>Contact information</S.SubTitle>
        </S.Box>
        <PrimaryInput error={errors.firstName} placeholder="First Name" register={register("firstName")} />
        <PrimaryInput error={errors.lastName} placeholder="Last Name" register={register("lastName")} />
        <PrimaryInput error={errors.email} placeholder="Email address" register={register("email")} />
        <Controller
          control={control}
          defaultValue=""
          name="phoneNumber"
          render={({ field: { value, onChange } }) => (
            <PrimaryInput
              error={errors.phoneNumber}
              placeholder="Phone Number 123-456-7890"
              value={value}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
                onChange(e);
              }}
            />
          )}
        />
        <PrimaryInput error={errors.referral} placeholder="Referral Code (optional)" register={register("referral")} />
        <PrimaryButton
          className="mx-auto w-fit mt-8"
          disabled={isContinueButtonDisabled}
          label="Continue"
          loading={isLoading(signupStatus)}
          minwidth={200}
          styles={{
            width: "fit-content",
          }}
        />
      </S.Form>

      <S.Box className="flex w-full justify-center">
        <S.Description>Have questions or trouble signing up?</S.Description>
      </S.Box>

      <div className="flex w-full justify-center">
        <StyledLink className="primary" href={PTP_CONTACT_US_LINK} rel="noreferrer" target="_blank">
          Contact us
        </StyledLink>
      </div>
    </>
  );
};

export default Step1;
