import * as yup from "yup";

export const documentSchema = yup.object().shape({
  document: yup
    .string()
    .test({
      name: "noOnlySpaces",
      message: "The field is required.",
      test: (value) => {
        return value.trim() !== "";
      },
    })
    .required("The field is required."),
});
