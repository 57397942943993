import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Can } from "@casl/react";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { AdminReloadButton, FilterMultiSelect, PrimaryButton, SearchInput, Select, Table } from "common";
import { useDebounce } from "hooks";
import { usePermissions } from "hooks/usePermissions";
import { Paginator } from "primereact/paginator";
import { adminRoutes } from "router/routes";
import { OwnersListSelectors } from "store/admin-slices/owner-slice/selectors";
import { ownerActions } from "store/admin-slices/owner-slice/slice";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import {
  AUTH_FILTER,
  AVAILABILITY_OPTIONS,
  COUNTIES_FILTER,
  isLoading,
  LOADING_STATUSES,
  PERMISSIONS_LIST,
  YEARS_FILTER,
} from "utils/constants";
import { downloadFileByLink, getPaginationDescription, setStorage } from "utils/helpers";

import { Template } from "components/Admin/TableRowCustomTemplate";
import { Filter } from "components/Filter";

import * as S from "./styled";

export const OwnersListPage = () => {
  const {
    ownersList,
    last_page,
    verifyAoaStatus,
    getOwnersListStatus,
    exportCSVStatus,
    downloadCsvHref,
    paginationData,
    ownersListFilters,
    allowScroll,
  } = useSelector(OwnersListSelectors);
  const { userInfo } = useSelector(adminSignInSelectors);

  const [selectedCountyOptions, setSelectedCountyOptions] = useState([]);
  const [selectedAuthOptions, setSelectedAuthOptions] = useState(AUTH_FILTER.map((item) => item.value));
  const [selectedYear, setSelectedYear] = useState([]);
  const [sortData, setSortData] = useState({
    field: "updated_at",
    direction: -1,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 200);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [syncPropertyId, setSyncPropertyId] = useState();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectValue, setSelectValue] = useState("");
  const [hearing_dates, setIsHearingDates] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const ref = useRef(null);

  const ability = usePermissions(userInfo.permissions);

  const rowClassName = (row) => (row.property_id === selectedProperty?.property_id ? "checked-row" : "");

  const getOwnerList = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      search: debouncedSearchQuery,
      counties: selectedCountyOptions,
      years: selectedYear,
      authed_or_not: selectedAuthOptions,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "asc" : "desc",
      hearing_dates,
    };
    dispatch(ownerActions.getOwnersList(params));
  };

  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      ownerActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getOwnerList({ page: page + 1, rows });
  };

  const verifyAoA = (item) => {
    dispatch(ownerActions.setAllowScroll(false));
    setSyncPropertyId(item.property_id);
    dispatch(
      ownerActions.syncProperty({
        county: item.county,
        accountNumber: item.account_number,
        property_id: item.property_id,
      })
    );
  };

  const selectSyncProperty = (item) => setSelectedProperty(item);

  const resetData = () => {
    dispatch(ownerActions.setAllowScroll(false));
    setSortData({ field: "updated_at", direction: -1 });
    dispatch(ownerActions.setPaginationData({ first: 10, rows: 50, page: 1 }));
    setSelectedAuthOptions([]);
    setSelectedCountyOptions([]);
    setSearchQuery("");
    setSelectValue("");
    setIsHearingDates("");
    setSelectedYear([]);
  };

  const downloadCsv = () => {
    let properties = selectedProducts?.map((prop) => prop.property_id);
    properties = properties?.filter((id, index, self) => self.indexOf(id) === index);
    const params = {
      search: debouncedSearchQuery,
      counties: selectedCountyOptions,
      authed_or_not: selectedAuthOptions,
      properties,
    };
    dispatch(ownerActions.exportCSV(params));
  };

  const verifyAllAoAs = (is_only_our_agent) => {
    dispatch(ownerActions.verifyAllAoA({ is_only_our_agent }));
  };

  const deSelectCounties = () => {
    if (selectedCountyOptions?.length > 0) {
      setSelectedCountyOptions([]);
    } else {
      setSelectedCountyOptions(COUNTIES_FILTER.map((item) => item.value));
    }
  };

  const navigateToClientEvidence = (item) => {
    if (!item?.client_evidence_id) {
      setStorage("propertyInfo", item);
    }
    window.open(
      `${adminRoutes.clientEvidence}/${item?.client_evidence_id ? item?.client_evidence_id : "create"}`,
      "_blank"
    );
  };

  const handleRowClick = (item) => {
    dispatch(ownerActions.setAllowScroll(false));
    navigate(`${adminRoutes.prefix}/${adminRoutes.owners}/${item.data.owner_id}/${item.data.property_id}`);
  };
  const onSearch = (searchResult) => {
    setSearchQuery(searchResult);
  };

  const onSort = (e) => {
    setSortData({ field: e.sortField, direction: e.sortOrder });
  };

  const tableData = useMemo(
    () => [
      {
        headerClassName: "column-header",
        selectionMode: "multiple",
      },
      {
        body: (item) =>
          item.client_evidences_is_viewed === null ? (
            <Icons.ClientEvidenceIcon
              fill={COLORS.darkgrey}
              onClick={(e) => {
                e.stopPropagation();
                navigateToClientEvidence(item);
              }}
            />
          ) : item.client_evidences_is_viewed === 0 ? (
            <Icons.ClientEvidenceIcon
              fill={COLORS.green}
              onClick={(e) => {
                e.stopPropagation();
                navigateToClientEvidence(item);
              }}
            />
          ) : (
            <Icons.ClientEvidenceIcon
              fill={COLORS.lightgray}
              onClick={(e) => {
                e.stopPropagation();
                navigateToClientEvidence(item);
              }}
            />
          ),
        header: "Client Evidence",
      },
      { sortable: true, field: "updated_at", header: "Sign up date" },
      { sortable: true, field: "email", header: "Email" },
      { sortable: true, field: "full_name", header: "Name" },
      { sortable: true, field: "county", header: "County" },
      {
        field: "address",
        sortable: true,
        body: (item) => Template.ItemStreetAddress(item, selectSyncProperty),
        header: "Street address",
      },
      { field: "account_number", header: "Account number" },
      { body: Template.SendNoSend, header: "Sent/Not sent" },
      {
        body: (item) => (
          <Can ability={ability} I={PERMISSIONS_LIST.verifyAoaButton}>
            {(allowed) => (allowed ? Template.VerifyAoa(item, verifyAoA, syncPropertyId) : null)}
          </Can>
        ),
        header: (
          <Can ability={ability} I={PERMISSIONS_LIST.verifyAoaButton}>
            {(allowed) => (allowed ? "Verify auth" : null)}
          </Can>
        ),
      },
      {
        sortable: true,
        body: Template.AuthColumn,
        header: "Auth?",
        field: "authed_or_not",
      },
      {
        sortable: true,
        field: "",
        header: "Date unauth",
      },

      { body: Template.FlagsColumn, header: "Flags" },
      {
        sortable: true,
        field: "previous_year_market_value",
        header: "LY value",
      },
      {
        sortable: true,
        field: "current_year_market_value",
        header: "CY value",
      },
      { body: Template.ReferralColumn, header: "Referral" },
      { body: Template.HearingDatesColumn, header: "Hearing Dates" },
      { field: "deed_date", header: "Deed date" },
    ],
    [syncPropertyId]
  );

  const deSelectYears = () => {
    if (selectedYear?.length > 0) {
      setSelectedYear([]);
    } else {
      setSelectedYear(YEARS_FILTER.map((item) => item.value));
    }
  };

  const onSelectChange = (value) => {
    setSelectValue(value);
    setIsHearingDates(AVAILABILITY_OPTIONS.find((item) => item.label === value).value);
  };

  useEffect(() => {
    if (verifyAoaStatus === LOADING_STATUSES.succeeded) {
      setSyncPropertyId();
      getOwnerList();
    }
  }, [verifyAoaStatus]);

  useEffect(() => {
    dispatch(ownerActions.setAllowScroll(false));
  }, [selectedAuthOptions, selectedCountyOptions, debouncedSearchQuery, sortData, hearing_dates, selectedYear]);

  useEffect(() => {
    getOwnerList();
    dispatch(
      ownerActions.setOwnersListFilters({
        selectedCountyOptions: selectedCountyOptions,
        selectedAuthOptions: selectedAuthOptions,
        selectedYear: selectedYear,
        selectValue: selectValue,
        searchQuery: searchQuery,
        paginationData: paginationData,
      })
    );
  }, [
    selectedAuthOptions,
    selectedCountyOptions,
    debouncedSearchQuery,
    sortData,
    hearing_dates,
    selectedYear,
    paginationData,
  ]);

  useEffect(() => {
    downloadFileByLink(exportCSVStatus, downloadCsvHref, "properties.csv");
  }, [downloadCsvHref]);

  useEffect(() => {
    return () => dispatch(ownerActions.resetAllData());
  }, []);

  useEffect(() => {
    setSelectedProducts(null);
  }, [paginationData.page]);

  useEffect(() => {
    allowScroll && ref.current.scrollIntoView({ behavior: "auto" });
  }, [getOwnersListStatus]);

  useEffect(() => {
    setSelectedCountyOptions(ownersListFilters.selectedCountyOptions);
    setSelectedAuthOptions(ownersListFilters.selectedAuthOptions);
    setSelectedYear(ownersListFilters.selectedYear);
    setSelectValue(ownersListFilters.selectValue);
    setIsHearingDates(ownersListFilters.selectValue === "Yes" ? 1 : ownersListFilters.selectValue === "No" ? 0 : "");
    setSearchQuery(ownersListFilters.searchQuery || "");
    dispatch(ownerActions.setPaginationData(ownersListFilters.paginationData || paginationData));
  }, []);

  return (
    <S.OwnersListContainer>
      <S.OwnersListTitle>Owners List</S.OwnersListTitle>
      <AdminReloadButton onClick={resetData} />
      <Filter>
        <SearchInput
          className="search-input"
          placeholder="Search Client List"
          value={searchQuery}
          onSearch={onSearch}
        />
        <FilterMultiSelect
          buttonLabel="COUNTY FILTER"
          options={COUNTIES_FILTER}
          selectedOptions={selectedCountyOptions}
          setSelectedOptions={setSelectedCountyOptions}
        />
        <PrimaryButton
          label={(selectedCountyOptions?.length ? "DE" : "") + "SELECT ALL COUNTIES"}
          mode="gray"
          onClick={deSelectCounties}
        />
        <FilterMultiSelect
          buttonLabel="AUTH FILTER"
          options={AUTH_FILTER}
          selectedOptions={selectedAuthOptions}
          setSelectedOptions={setSelectedAuthOptions}
        />
        <Can ability={ability} I={PERMISSIONS_LIST.downloadOwnerCSV}>
          <PrimaryButton label="DOWNLOAD CSV" loading={isLoading(exportCSVStatus)} mode="gray" onClick={downloadCsv} />
        </Can>
        <Can ability={ability} I={PERMISSIONS_LIST.verifyAllAoas}>
          <PrimaryButton label="VERIFY ALL AOAS" mode="gray" onClick={() => verifyAllAoAs(0)} />
        </Can>
        <Can ability={ability} I={PERMISSIONS_LIST.verifyAllYesAoas}>
          <PrimaryButton label="VERIFY ALL YES AOAS" mode="gray" onClick={() => verifyAllAoAs(1)} />
        </Can>
        <FilterMultiSelect
          buttonLabel="YEAR FILTER"
          options={YEARS_FILTER}
          selectedOptions={selectedYear}
          setSelectedOptions={setSelectedYear}
        />
        <PrimaryButton
          label={(selectedYear?.length ? "DE" : "") + "SELECT ALL YEARS"}
          mode="gray"
          onClick={deSelectYears}
        />
        <Select
          itemKey="label"
          items={AVAILABILITY_OPTIONS}
          label="Has hearing date?"
          value={selectValue}
          onChange={onSelectChange}
        />
      </Filter>
      <Table
        dataKey="uniqueId"
        loading={getOwnersListStatus !== LOADING_STATUSES.succeeded}
        rowClassName={rowClassName}
        selection={selectedProducts}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={ownersList ?? []}
        onRowDoubleClick={handleRowClick}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
        onSort={onSort}
      />
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[50, 100, 200]}
          totalRecords={last_page}
          onClick={() => dispatch(ownerActions.setAllowScroll(true))}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.OwnersListContainer>
  );
};
