import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  border-radius: 4px;
  padding: 12px;
  box-shadow: 0 3px 1px -2px ${COLORS.overlay_200}, 0 2px 2px 0 ${COLORS.overlay_150}, 0 1px 5px 0 ${COLORS.overlay_150};
`;

export const AddAccountForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .add-property-to-owner-button {
    width: 300px;
  }
`;

export const Box = styled.div`
  &.red {
    color: ${COLORS.red};
  }
`;

export const Text = styled.div`
  color: ${COLORS.green};

  &.error {
    color: ${COLORS.red};
  }
`;
