import axios from "axios";
import { API } from "configs/api";

export const clientEvidenceApi = {
  getClientEvidences: (params) => axios.get(API.clientEvidence, { token: true, params }),
  exportCSV: (params) =>
    axios.get(API.clientEvidence + API.exportCSV, {
      token: true,
      responseType: "blob",
      params,
    }),
  deleteEvidence: (id) => axios.delete(`${API.clientEvidence}/${id}`, { token: true }),
};
