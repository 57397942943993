import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const Content = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 3px 1px -2px ${COLORS.overlay_200}, 0 2px 2px 0 ${COLORS.overlay_150}, 0 1px 5px 0 ${COLORS.overlay_150};
  margin-top: 20px;
`;

export const ContentTitle = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
`;

export const PaginatorWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
  justify-content: center;
`;

export const Box = styled.div`
  &.flex-center {
    display: flex;
    justify-content: center;
    align-self: center;
    height: 100%;
    flex-wrap: wrap;
  }
`;
