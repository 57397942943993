import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

export const StyledFooterContainer = styled.div`
  border-top: 2px solid ${COLORS.deepDark};
  margin-top: 12px;
  display: flex;
  justify-content: center;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6px;
`;

export const PrivacyPolicy = styled.div`
  margin-top: 20px;
  transition: opacity all 300ms;

  .privacy-policy {
    color: ${COLORS.primary};
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FooterLogoBlock = styled.div``;

export const FooterLogo = styled.img``;

export const Description = styled.div``;

export const FooterText = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  width: 300px;

  @media screen and (${BREAKPOINTS.md}) {
    width: 380px;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    width: 468px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px;
  background-color: ${COLORS.grey};
`;

export const CopyRight = styled.div``;
