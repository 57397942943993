import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import Stepper from "common/Stepper";
import { clientRoutes } from "router/routes";
import { clientSelectors } from "store/client-slice/selectors";
import { resetAllData } from "store/client-slice/slice";
import { getStorage } from "utils/helpers";

import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import * as S from "./styled";

export const SigninPage = () => {
  const { signinStep } = useSelector(clientSelectors);

  const isAuthorized = getStorage("owner_token");
  const location = useLocation();

  const dispatch = useDispatch();

  const stepObj = {
    0: <Step1 />,
    1: <Step2 />,
  };

  useEffect(() => {
    return () => dispatch(resetAllData());
  }, []);

  if (isAuthorized) {
    return <Navigate state={{ from: location }} to={clientRoutes.prefix} />;
  }

  return (
    <S.SignInContainer>
      <S.SignInContent>
        <S.StepperWrapper>
          <Stepper maxStep={2} step={signinStep} />
        </S.StepperWrapper>

        {stepObj[signinStep]}
      </S.SignInContent>
    </S.SignInContainer>
  );
};
