import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { usePermissions } from "hooks/usePermissions";
import { NotFoundPage } from "pages/client/NotFoundPage";
import { adminRoutes, appRoutes } from "router/routes";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { v4 as uuid } from "uuid";

const ProtectedRoute = ({ ability, permissions, component }) => {
  const isAllowed = permissions.map((item) => ability.can(item)).some((item) => item);

  return isAllowed ? component : <Navigate to={adminRoutes.prefix} />;
};

export const Router = () => {
  const { userInfo } = useSelector(adminSignInSelectors);

  const ability = usePermissions(userInfo?.permissions);

  return (
    <BrowserRouter>
      <Routes>
        {appRoutes.map((route) => (
          <Route key={uuid()} element={route.layout} path={route.path}>
            {route.children.map((child) =>
              child.permissions ? (
                <Route
                  key={uuid()}
                  element={
                    <ProtectedRoute ability={ability} component={child.component} permissions={child.permissions} />
                  }
                  index={!!child.index}
                  path={child.path}
                />
              ) : (
                <Route key={uuid()} element={child.component} index={!!child.index} path={child.path} />
              )
            )}
          </Route>
        ))}
        <Route key={uuid()} element={<NotFoundPage />} path="*" />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
