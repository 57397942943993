import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { singleClientEvidenceApi } from "./services";

export const getSingleClientEvidences = createAsyncThunk(
  `${SLICE_NAME.singleClientEvidenceSlice}/getSingleClientEvidences`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await singleClientEvidenceApi.getSingleClientEvidences(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postFileStatus = createAsyncThunk(
  `${SLICE_NAME.singleClientEvidenceSlice}/postFileStatus`,
  async (data, { rejectWithValue }) => {
    try {
      await singleClientEvidenceApi.postFileStatus(data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postEvidenceStatus = createAsyncThunk(
  `${SLICE_NAME.singleClientEvidenceSlice}/postEvidenceStatus`,
  async (data, { rejectWithValue }) => {
    try {
      await singleClientEvidenceApi.postEvidenceStatus(data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const mergeEvidenceFiles = createAsyncThunk(
  `${SLICE_NAME.singleClientEvidenceSlice}/mergeEvidenceFiles`,
  async (params, { rejectWithValue }) => {
    try {
      await singleClientEvidenceApi.mergeEvidenceFiles(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postChangeFileType = createAsyncThunk(
  `${SLICE_NAME.singleClientEvidenceSlice}/postChangeFileType`,
  async (data, { rejectWithValue }) => {
    try {
      await singleClientEvidenceApi.postChangeFileType(data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postChangeFileName = createAsyncThunk(
  `${SLICE_NAME.singleClientEvidenceSlice}/postChangeFileName`,
  async (data, { rejectWithValue }) => {
    try {
      await singleClientEvidenceApi.postChangeFileName(data);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postAddFile = createAsyncThunk(
  `${SLICE_NAME.singleClientEvidenceSlice}/postAddFile`,
  async (data, { rejectWithValue }) => {
    try {
      await singleClientEvidenceApi.postAddFile(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTracker = createAsyncThunk(
  `${SLICE_NAME.singleClientEvidenceSlice}/getTracker`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await singleClientEvidenceApi.getTracker(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCSV = createAsyncThunk(
  `${SLICE_NAME.singleClientEvidenceSlice}/exportCSV`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleClientEvidenceApi.exportCSV(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteEvidenceFile = createAsyncThunk(
  `${SLICE_NAME.singleOwnerSlice}/deleteEvidenceFile`,
  async (id, { rejectWithValue }) => {
    try {
      await singleClientEvidenceApi.deleteEvidenceFile(id);
      return { id };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
