import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const ArchivedContainer = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  div {
    min-width: unset;
  }

  svg {
    cursor: pointer;
  }

  .select {
    min-width: unset;
    width: 80px;
    margin: auto;

    span {
      left: 40%;
      top: 40%;
    }
  }

  .color-green {
    background-color: ${COLORS.green} !important;
    color: ${COLORS.white};
    border-radius: 6px;
  }

  .contact-again-yes {
    color: ${COLORS.green};
  }

  .contact-again-no {
    color: ${COLORS.red};
  }

  .contact-again {
    width: 160px;
  }

  .delete-modal {
    color: ${COLORS.red};
  }

  .unarchive-button {
    z-index: 10;
    position: absolute;
    height: 100%;
    opacity: 0;
  }

  .unarchive-icon {
    z-index: 1;
    position: absolute;
  }
`;

export const ContactAgain = styled.div``;

export const FileInput = styled.input`
  display: none;
`;

export const ArchivedTitle = styled.div`
  font-size: 24px;
  color: ${COLORS.deepDark};
  margin: 10px;
`;
export const ReloadButton = styled.div``;

export const PaginatorWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
  justify-content: center;
`;

export const Box = styled.div`
  &.flex-center {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    flex-wrap: wrap;
  }

  &.reason-content {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Block = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
`;
