import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import { createProfile, exportCSV, getAgents, getPermissions } from "./actions";

const initialState = {
  downloadCsvHref: null,
  createProfileStep: 0,
  isModalOpen: false,
  createProfileStep1Data: null,
  errorMessage: null,
  permissions: null,
  allowScroll: false,
  openAccordions: {},
  checkedItems: [],
  checkedRelatedItems: [],
  agentsList: [],
  per_page: 10,
  last_page: 0,
  paginationData: {
    first: 0,
    rows: 10,
    page: 1,
  },
  getAgentsStatus: LOADING_STATUSES.idle,
  createProfileStatus: LOADING_STATUSES.idle,
  getPermissionsStatus: LOADING_STATUSES.idle,
  exportCSVStatus: LOADING_STATUSES.idle,
};
export const agentsSlice = createSlice({
  name: SLICE_NAME.agentSlice,
  initialState,
  reducers: {
    setIsModalOpen: (state, { payload }) => {
      state.isModalOpen = payload;
    },
    backStep: (state) => {
      state.createProfileStep = state.createProfileStep - 1;
    },
    nextStep: (state) => {
      state.createProfileStep = state.createProfileStep + 1;
    },
    setCreateProfileStep1Data: (state, { payload }) => {
      state.createProfileStep1Data = payload;
    },
    setCheckedItems: (state, { payload }) => {
      state.checkedItems = payload.checkedItems;
    },
    setCheckedRelatedItems: (state, { payload }) => {
      state.checkedRelatedItems = payload.checkedRelatedItems;
    },
    setOpenAccordion: (state, { payload }) => {
      state.openAccordions[payload.permissionValue] = payload.isOpen;
    },
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    setAllowScroll: (state, { payload }) => {
      state.allowScroll = payload;
    },
    resetCreateProfileData: (state) => {
      state.createProfileStep = 0;
      state.isModalOpen = false;
      state.createProfileStep1Data = null;
      state.errorMessage = null;
      state.permissions = null;
      state.openAccordions = {};
      state.checkedItems = [];
      state.checkedRelatedItems = [];
      state.getPermissionsStatus = LOADING_STATUSES.idle;
      state.createProfileStatus = LOADING_STATUSES.idle;
    },
    resetAllData: () => initialState,
  },
  extraReducers: ({ addCase }) => {
    addCase(createProfile.pending, (state) => {
      state.createProfileStatus = LOADING_STATUSES.pending;
    });
    addCase(createProfile.fulfilled, (state) => {
      state.createProfileStatus = LOADING_STATUSES.succeeded;
    });
    addCase(createProfile.rejected, (state, { payload }) => {
      state.createProfileStatus = LOADING_STATUSES.failed;
      state.errorMessage = payload.data.message;
    });

    //   get all permissions

    addCase(getPermissions.pending, (state) => {
      state.getPermissionsStatus = LOADING_STATUSES.pending;
    });
    addCase(getPermissions.fulfilled, (state, { payload }) => {
      state.getPermissionsStatus = LOADING_STATUSES.succeeded;
      state.permissions = payload.data;
    });
    addCase(getPermissions.rejected, (state) => {
      state.getPermissionsStatus = LOADING_STATUSES.failed;
    });

    //   get all agents

    addCase(getAgents.pending, (state) => {
      state.getAgentsStatus = LOADING_STATUSES.pending;
    });
    addCase(getAgents.fulfilled, (state, { payload }) => {
      state.getAgentsStatus = LOADING_STATUSES.succeeded;
      state.agentsList = payload.data;
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
    });
    addCase(getAgents.rejected, (state) => {
      state.getAgentsStatus = LOADING_STATUSES.failed;
    });

    // export agents csv

    addCase(exportCSV.pending, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCSV.fulfilled, (state, { payload }) => {
      state.downloadCsvHref = payload;
      state.exportCSVStatus = LOADING_STATUSES.succeeded;
    });
    addCase(exportCSV.rejected, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.failed;
    });
  },
});

export const agentsReducer = agentsSlice.reducer;

export const agentsActions = { ...agentsSlice.actions, createProfile, exportCSV, getAgents, getPermissions };
