import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Can } from "@casl/react";
import { useDebounce } from "hooks";
import { usePermissions } from "hooks/usePermissions";
import { agentsSelector } from "store/admin-slices/agents-slice/selectors";
import { agentsActions } from "store/admin-slices/agents-slice/slice";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { LOADING_STATUSES, PERMISSIONS_LIST } from "utils/constants";

import CreateProfile from "./CreateProfile";
import { ProfilesTable } from "./ProfilesTable";
import * as S from "./styled";

export const AgentsPage = () => {
  const { paginationData, createProfileStatus } = useSelector(agentsSelector);
  const { userInfo } = useSelector(adminSignInSelectors);

  const [sortData, setSortData] = useState({
    field: "id",
    direction: -1,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 200);

  const dispatch = useDispatch();
  const ability = usePermissions(userInfo.permissions);

  const getAgentsList = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      search: debouncedSearchQuery,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "asc" : "desc",
    };
    dispatch(agentsActions.getAgents(params));
  };

  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      agentsActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getAgentsList({ page: page + 1, rows });
  };

  useEffect(() => {
    getAgentsList();
  }, [sortData, debouncedSearchQuery]);

  useEffect(() => {
    if (createProfileStatus === LOADING_STATUSES.succeeded) {
      getAgentsList();
    }
  }, [createProfileStatus]);

  useEffect(() => {
    return () => dispatch(agentsActions.resetAllData());
  }, []);

  return (
    <S.Container>
      <S.AgentsTitle>Agents</S.AgentsTitle>
      <Can ability={ability} I={PERMISSIONS_LIST.createUsers}>
        <CreateProfile />
      </Can>
      <ProfilesTable
        debouncedSearchQuery={debouncedSearchQuery}
        setSearchQuery={setSearchQuery}
        setSortData={setSortData}
        sortData={sortData}
        onPageChange={onPageChange}
      />
    </S.Container>
  );
};
