import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const ColumnContent = styled.div``;
export const ColumnText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &.red {
    color: ${COLORS.red};
  }

  &.green {
    color: ${COLORS.green};
  }
`;

export const Link = styled.a`
  text-decoration: underline;
  color: ${COLORS.green};
`;

export const ButtonContainer = styled.div``;

export const TooltipContent = styled.div`
  width: 200px;
  cursor: pointer;
`;

export const DescriptionContainer = styled.div`
  cursor: pointer;
`;

export const ApprovedSelectContainer = styled.div`
  .select {
    position: relative;
    span {
      position: absolute;
      left: 40%;
      top: 40%;
    }
  }

  .color-green {
    background-color: ${COLORS.green} !important;
    color: ${COLORS.white};
    border-radius: 6px;
  }
`;

export const FileTypesSelect = styled.div`
  .select {
    position: relative;
    span {
      position: absolute;
    }
  }
`;
