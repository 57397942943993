import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import { getDashboardList } from "./actions";

const initialState = {
  dashboardList: [],
  per_page: 50,
  last_page: 0,
  paginationData: {
    first: 10,
    rows: 50,
    page: 1,
  },
  getDashboardListStatus: LOADING_STATUSES.idle,
  exportCSVStatus: LOADING_STATUSES.idle,
};

export const dashboardSlice = createSlice({
  name: SLICE_NAME.dashboardSlice,
  initialState,
  reducers: {
    resetAllData: (state) => ({
      ...initialState,
      paginationData: state.paginationData,
    }),
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
  },
  extraReducers: ({ addCase }) => {
    //get dashboard list
    addCase(getDashboardList.pending, (state) => {
      state.getDashboardListStatus = LOADING_STATUSES.pending;
    });
    addCase(getDashboardList.fulfilled, (state, { payload }) => {
      state.dashboardList = payload.data;
      state.per_page = payload?.per_page;
      state.last_page = payload?.total;
      state.getDashboardListStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getDashboardList.rejected, (state) => {
      state.getDashboardListStatus = LOADING_STATUSES.failed;
    });
  },
});

export const dashboardReducer = dashboardSlice.reducer;

export const dashboardActions = {
  ...dashboardSlice.actions,
  getDashboardList,
};
