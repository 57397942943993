import * as S from "./styled";

export const PrivacyPolicy = () => (
  <S.PrivacyPolicyContainer>
    <S.PrivacyPolicyContent>
      <S.PrivacyPolicyHeading>Privacy Policy</S.PrivacyPolicyHeading>
      <S.PrivacyPolicyTitle>Effective Date: 7/10/2024</S.PrivacyPolicyTitle>
      <S.PrivacyPolicyParagraph>
        Thank you for choosing to engage with Property Tax Protest. This Privacy Policy outlines how we collect, use,
        and protect your information when you provide your mobile number for SMS communications and consent to receive
        messages from us.
      </S.PrivacyPolicyParagraph>
    </S.PrivacyPolicyContent>

    <S.PrivacyPolicyContent>
      <S.PrivacyPolicyTitle>1. Information We Collect</S.PrivacyPolicyTitle>
      <S.PrivacyPolicyParagraph>
        When you provide your mobile number for SMS communications, we may collect and process the following
        information:
      </S.PrivacyPolicyParagraph>
      <S.List>
        <S.ListItem>
          <S.TextBold>Mobile Number:</S.TextBold> To send you SMS messages related to our services or updates.
        </S.ListItem>
        <S.ListItem>
          <S.TextBold>Consent Information:</S.TextBold> Details regarding your consent to receive SMS messages from us.
        </S.ListItem>
      </S.List>
    </S.PrivacyPolicyContent>

    <S.PrivacyPolicyContent>
      <S.PrivacyPolicyTitle>2. Use Of Information</S.PrivacyPolicyTitle>
      <S.PrivacyPolicyParagraph>We use the information collected to:</S.PrivacyPolicyParagraph>
      <S.List>
        <S.ListItem>
          <S.TextBold>Send SMS Messages:</S.TextBold> To provide you with updates, alerts, or other information related
          to our services.
        </S.ListItem>
        <S.ListItem>
          <S.TextBold>Comply with Legal Obligations:</S.TextBold> To meet legal requirements and obligations.
        </S.ListItem>
      </S.List>
    </S.PrivacyPolicyContent>

    <S.PrivacyPolicyContent>
      <S.PrivacyPolicyTitle>3. Data Security</S.PrivacyPolicyTitle>
      <S.PrivacyPolicyParagraph>
        We prioritize the security of your information and have implemented appropriate technical and organizational
        measures to protect it against unauthorized access, alteration, disclosure, or destruction.
      </S.PrivacyPolicyParagraph>

      <S.PrivacyPolicyTitle>4. Third-Party Disclosure</S.PrivacyPolicyTitle>
      <S.PrivacyPolicyParagraph>
        We do not sell, trade, or otherwise transfer your information to third parties. This does not include trusted
        third parties who assist us in operating our website, conducting our business, or servicing you, as long as
        those parties agree to keep this information confidential.
      </S.PrivacyPolicyParagraph>
    </S.PrivacyPolicyContent>

    <S.PrivacyPolicyContent>
      <S.PrivacyPolicyTitle>5. Consent</S.PrivacyPolicyTitle>
      <S.PrivacyPolicyParagraph>
        By providing your mobile number and opting in to receive SMS messages from us, you consent to our collection,
        use, and storage of your information as described in this Privacy Policy.
      </S.PrivacyPolicyParagraph>
    </S.PrivacyPolicyContent>

    <S.PrivacyPolicyContent>
      <S.PrivacyPolicyTitle>6. Your Rights</S.PrivacyPolicyTitle>
      <S.PrivacyPolicyParagraph>You have the right to:</S.PrivacyPolicyParagraph>
      <S.List>
        <S.ListItem>
          <S.TextBold>Access:</S.TextBold> Request access to the personal information we hold about you.
        </S.ListItem>
        <S.ListItem>
          <S.TextBold>Correction:</S.TextBold> Request correction of the personal information that we hold about you.
        </S.ListItem>
        <S.ListItem>
          <S.TextBold>Withdraw Consent:</S.TextBold> Withdraw your consent for us to use your information for SMS
          communications. Please note that withdrawing consent may affect our ability to provide you with certain
          services.
        </S.ListItem>
      </S.List>
    </S.PrivacyPolicyContent>

    <S.PrivacyPolicyContent>
      <S.PrivacyPolicyTitle>7. Changes To This Privacy Policy</S.PrivacyPolicyTitle>
      <S.PrivacyPolicyParagraph>
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
        Privacy Policy on this page.
      </S.PrivacyPolicyParagraph>
    </S.PrivacyPolicyContent>

    <S.PrivacyPolicyContent>
      <S.PrivacyPolicyTitle>8. Contact Us</S.PrivacyPolicyTitle>
      <S.PrivacyPolicyParagraph>
        If you have any questions about this Privacy Policy, please contact us at{" "}
        <S.Link href="mailto:info@propertytaxprotest.com">info@propertytaxprotest.com</S.Link>.
      </S.PrivacyPolicyParagraph>
    </S.PrivacyPolicyContent>
  </S.PrivacyPolicyContainer>
);
