import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const AdminSignInContainer = styled.div`
  width: 90%;
  min-height: 200px;
  border-radius: 4px;
  margin: 20px auto;
`;

export const SignInBanner = styled.div`
  width: 100%;
  height: 80px;
  border-radius: 4px;
  background-color: ${COLORS.deepDark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BannerTitle = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  color: ${COLORS.white};
`;

export const AdminSignInForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  gap: 40px;
`;

export const SignInButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ErrorMessageBlock = styled.div`
  display: flex;
  align-items: center;
  padding-left: 30px;
  column-gap: 18px;
  width: 100%;
  height: 60px;
  background-color: ${COLORS.pink};
  margin-bottom: 16px;
  border-radius: 4px;
  animation: WarningAnimation 0.5s ease-in-out;

  @keyframes WarningAnimation {
    from {
      max-height: 0;
    }
    to {
      max-height: 100vh;
    }
  }
`;

export const ErrorMessageText = styled.div`
  color: ${COLORS.danger};
`;

export const WarningIconBlock = styled.div`
  svg {
    height: 26px;
    fill: ${COLORS.danger};
  }
`;
