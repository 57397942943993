import { Document } from "@react-pdf/renderer";

import { SaPage } from "./SAPage";

export const SADocument = ({ owner, signature, signType }) => {
  return (
    <Document>
      <SaPage owner={owner} signature={signature} signType={signType} />
    </Document>
  );
};
