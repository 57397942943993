import * as S from "./styled";

const Flag = ({ accLog }) => {

  return (
          <S.Flag>
            <S.FlagItem title={accLog.type}>{accLog.type}</S.FlagItem>
            <S.FlagItem title={accLog.content}>{accLog.content}</S.FlagItem>
          </S.Flag>
  );
};

export default Flag;
