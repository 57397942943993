import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.overlay_300};
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
  opacity: 0;
  pointer-events: none;
  z-index: 10;

  &.active {
    width: 100vw;
    height: 100vh;
    opacity: 1;
    pointer-events: all;
  }
`;

export const Content = styled.div`
  width: 300px;
  background-color: white;
  padding: 42px 64px;
  transition: 0.3s ease;
  transform: scale(0);
  border-radius: 4px;

  .no {
    margin-left: 10px;
  }

  &.active {
    transform: scale(1);
  }
`;

export const Question = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;
