import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Stepper from "common/Stepper";
import { signupSelectors } from "store/signup-slice/selectors";
import { signupActions } from "store/signup-slice/slice";
import { getStorage } from "utils/helpers";

import Step1 from "./step-1";
import Step2 from "./step-2";
import Step3 from "./step-3";
import * as S from "./styled";
import { SuccessModal } from "./SuccessModal";

export const SignupPage = () => {
  const { signupStep } = useSelector(signupSelectors);

  const dispatch = useDispatch();

  const [queryParameter] = useSearchParams();
  const isSuccess = queryParameter.get("success");
  const stepOneData = getStorage("owner_data");

  const stepObj = {
    0: <Step1 />,
    1: <Step2 />,
    2: <Step3 />,
  };

  useEffect(() => {
    return () => dispatch(signupActions.resetAllData());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(signupActions.setOwnerData(stepOneData));
    }
  }, [isSuccess]);

  return (
    <S.StyledSignUpContainer className="w-full ">
      <S.StyledSignUpStepArea className="px-9 py-4 md:p-16 md:pt-5 mx-auto sm:w-4/5 md:w-4/5 lg:w-3/5 xl:w-[600px]">
        {isSuccess ? (
          <SuccessModal />
        ) : (
          <>
            <Stepper step={signupStep} />
            {stepObj[signupStep]}{" "}
          </>
        )}
      </S.StyledSignUpStepArea>
    </S.StyledSignUpContainer>
  );
};
