import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Can } from "@casl/react";
import { AdminReloadButton, FilterMultiSelect, PrimaryButton, SearchInput, Table } from "common";
import { useDebounce } from "hooks";
import { usePermissions } from "hooks/usePermissions";
import { Paginator } from "primereact/paginator";
import { ProtestedAccountsListSelectors } from "store/admin-slices/protested-accounts-slice/selectors";
import { protestedAccountsActions } from "store/admin-slices/protested-accounts-slice/slice";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { COUNTIES_FILTER, isLoading, LOADING_STATUSES, PERMISSIONS_LIST, YEARS_FILTER } from "utils/constants";
import { downloadFileByLink, getPaginationDescription } from "utils/helpers";

import { Filter } from "components/Filter";
import { UploadFileModal } from "components/UploadFileModal";

import * as S from "./styled";

export const ProtestedAccountsPage = () => {
  const {
    protestedAccountsList,
    last_page,
    getProtestedAccountsListStatus,
    postUploadFileStatus,
    paginationData,
    exportCSVStatus,
    downloadCsvHref,
    allowScroll,
  } = useSelector(ProtestedAccountsListSelectors);
  const { userInfo } = useSelector(adminSignInSelectors);

  const [selectedCountyOptions, setSelectedCountyOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 200);

  const [selectedYear, setSelectedYear] = useState([]);
  const [sortData, setSortData] = useState({
    field: "updated_at",
    direction: -1,
  });

  const dispatch = useDispatch();

  const ref = useRef(null);

  const ability = usePermissions(userInfo.permissions);

  const tableData = [
    {
      headerClassName: "column-header",
      selectionMode: "multiple",
    },
    { field: "property_id", header: "Property id" },
    { field: "account_number", header: "Account number" },
    { field: "county", header: "County" },
    { field: "email", header: "Email" },
    { field: "full_name", header: "Name" },
    { field: "address", header: "Address" },
    { field: "date_filed", header: "Date filed" },
    { field: "updated_at", header: "Updated date", sortable: true },
    { field: "segment", header: "Segment" },
    { field: "emailed", header: "Emailed" },
    { field: "notes", header: "Notes" },
  ];

  const getProtestedAccounts = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      counties: selectedCountyOptions,
      search: debouncedSearchQuery,
      years: selectedYear,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "asc" : "desc",
    };
    dispatch(protestedAccountsActions.getProtestedAccountsList(params));
  };

  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      protestedAccountsActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getProtestedAccounts({ page: page + 1, rows });
  };

  const resetData = () => {
    dispatch(protestedAccountsActions.setAllowScroll(false));
    dispatch(protestedAccountsActions.getProtestedAccountsList({ page: 1, limit: 50 }));
    dispatch(protestedAccountsActions.setPaginationData({ first: 10, rows: 50, page: 1 }));
    setSelectedCountyOptions([]);
    setSortData({
      field: "updated_at",
      direction: -1,
    });
    setSearchQuery("");
    setSelectedProducts(null);
    setSelectedYear([]);
  };

  const downloadCsv = () => {
    let protested_accounts = selectedProducts?.map((prop) => prop.id);
    protested_accounts = protested_accounts?.filter((id, index, self) => self.indexOf(id) === index);
    const params = {
      search: debouncedSearchQuery,
      counties: selectedCountyOptions,
      protested_accounts,
    };
    dispatch(protestedAccountsActions.exportCSV(params));
  };

  const deSelectCounties = () => {
    if (selectedCountyOptions?.length > 0) {
      setSelectedCountyOptions([]);
    } else {
      setSelectedCountyOptions(COUNTIES_FILTER.map((item) => item.value));
    }
  };

  const deSelectYears = () => {
    if (selectedYear?.length > 0) {
      setSelectedYear([]);
    } else {
      setSelectedYear(YEARS_FILTER.map((item) => item.value));
    }
  };

  const onSearch = (searchResult) => {
    setSearchQuery(searchResult);
  };

  const onSort = (e) => setSortData({ field: e.sortField, direction: e.sortOrder });

  const openFileUploadModal = () => {
    setIsModalOpen(true);
  };

  const uploadFile = (body) => {
    dispatch(protestedAccountsActions.postUploadFile(body));
  };

  useEffect(() => {
    getProtestedAccounts();
    dispatch(protestedAccountsActions.setAllowScroll(false));
  }, [selectedCountyOptions, debouncedSearchQuery, sortData, selectedYear]);

  useEffect(() => {
    if (postUploadFileStatus === LOADING_STATUSES.succeeded) {
      getProtestedAccounts();
    }
  }, [postUploadFileStatus]);

  useEffect(() => {
    downloadFileByLink(exportCSVStatus, downloadCsvHref, "protested-accounts.csv");
  }, [downloadCsvHref]);

  useEffect(() => {
    return () => dispatch(protestedAccountsActions.resetAllData());
  }, []);

  useEffect(() => {
    setSelectedProducts(null);
  }, [paginationData.page]);

  useEffect(() => {
    allowScroll && ref.current.scrollIntoView({ behavior: "auto" });
  }, [getProtestedAccountsListStatus]);

  return (
    <S.ProtestedAccountsContainer>
      <S.ProtestedAccountsListTitle>Protested Accounts</S.ProtestedAccountsListTitle>
      <AdminReloadButton onClick={resetData} />
      <Filter>
        <SearchInput
          className="search-input"
          placeholder="Search Client List"
          value={searchQuery}
          onSearch={onSearch}
        />
        <FilterMultiSelect
          buttonLabel="COUNTY FILTER"
          options={COUNTIES_FILTER}
          selectedOptions={selectedCountyOptions}
          setSelectedOptions={setSelectedCountyOptions}
        />
        <PrimaryButton
          label={(selectedCountyOptions.length ? "DE" : "") + "SELECT ALL COUNTIES"}
          mode="gray"
          onClick={deSelectCounties}
        />
        <Can ability={ability} I={PERMISSIONS_LIST.uploadProtestedAccountFile}>
          <PrimaryButton label="upload file" mode="gray" onClick={openFileUploadModal} />
        </Can>
        <Can ability={ability} I={PERMISSIONS_LIST.downloadProtestedAccountCSV}>
          <PrimaryButton label="DOWNLOAD CSV" loading={isLoading(exportCSVStatus)} mode="gray" onClick={downloadCsv} />
        </Can>
        <UploadFileModal
          isOpenModal={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          status={postUploadFileStatus}
          uploadFile={uploadFile}
        />
        <FilterMultiSelect
          buttonLabel="YEAR FILTER"
          options={YEARS_FILTER}
          selectedOptions={selectedYear}
          setSelectedOptions={setSelectedYear}
        />
        <PrimaryButton
          label={(selectedYear.length ? "DE" : "") + "SELECT ALL YEARS"}
          mode="gray"
          onClick={deSelectYears}
        />
      </Filter>
      <Table
        dataKey="uniqueId"
        loading={getProtestedAccountsListStatus !== LOADING_STATUSES.succeeded}
        selection={selectedProducts}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={protestedAccountsList}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
        onSort={onSort}
      />
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[50, 100, 200]}
          totalRecords={last_page}
          onClick={() => dispatch(protestedAccountsActions.setAllowScroll(true))}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.ProtestedAccountsContainer>
  );
};
