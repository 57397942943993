import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminRoutes } from "router/routes";
import { SLICE_NAME } from "utils/constants";

import { singleArchivedApi } from "./services";

export const getSingleArchived = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/getSingleArchived`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.getSingleArchived(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postAccountLogs = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/postAccountLogs`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.postAccountLogs(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteAccountLogs = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/deleteAccountLogs`,
  async (id, { rejectWithValue }) => {
    try {
      await singleArchivedApi.deleteAccountLogs(id);
      return { id };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteProperty = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/deleteProperty`,
  async (body, { rejectWithValue }) => {
    try {
      await singleArchivedApi.deleteProperty(body);
      return { id: body.property_id };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateNotes = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/updateNotes`,
  async (body, { rejectWithValue }) => {
    try {
      await singleArchivedApi.updateNotes(body.note.id, body.note.notes);
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postNote = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/postNote`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.postNote(body);
      response.data.property_id = body.property_id;
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const connectArchivedProperty = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/connectArchivedProperty`,
  async ({ body, navigate, leads, archivedId }, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.connectArchivedProperty(body);

      if (!leads) {
        navigate(`${adminRoutes.prefix}/${adminRoutes.archived}/${archivedId}/${response.data.data.id}`);
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifySingleProperty = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/verifySingleProperty`,
  async (params, { rejectWithValue }) => {
    try {
      await singleArchivedApi.verifySingleProperty(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateDocument = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/updateDocument`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.updateDoc(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/deleteDocument`,
  async (id, { rejectWithValue }) => {
    try {
      await singleArchivedApi.deleteDoc(id);
      return { id };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sendAoA = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/sendAoA`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.sendAoA(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createDocument = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/createDocument`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.createDoc(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReferrals = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/getReferrals`,
  async (archivedId, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.getArchivedReferrals(archivedId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReferredBy = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/getReferredBy`,
  async (archivedId, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.getArchivedReferredBy(archivedId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// export const archiveArchived = createAsyncThunk(
//   `${SLICE_NAME.singleArchivedSlice}/archiveArchived`,
//   async ({ params, navigate }, { rejectWithValue }) => {
//     try {
//       await singleArchivedApi.archiveArchived(params);
//       navigate(`${adminRoutes.prefix}/${adminRoutes.archived}`);
//     } catch (err) {
//       return rejectWithValue(err);
//     }
//   }
// );

export const archiveProperty = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/archiveProperty`,
  async (params, { rejectWithValue }) => {
    try {
      await singleArchivedApi.archiveProperty(params);
      return params;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postReferredBy = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/postReferredBy`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.postReferredBy(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteReferredBy = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/deleteReferredBy`,
  async (id, { rejectWithValue }) => {
    try {
      await singleArchivedApi.deleteReferredBy(id);
      return id;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postReferredTo = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/postReferredTo`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.postReferredTo(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateReferral = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/updateReferral`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.updateReferral(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteReferredTo = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/deleteReferredTo`,
  async (id, { rejectWithValue }) => {
    try {
      await singleArchivedApi.deleteReferredBy(id);
      return id;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAccountNumber = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/updateAccountNumber`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.updateAccountNumber(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateStreetAddress = createAsyncThunk(
  `${SLICE_NAME.singleArchivedSlice}/updateStreetAddress`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleArchivedApi.updateStreetAddress(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
