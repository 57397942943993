import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";
import { removeStorage, setStorage } from "utils/helpers";

import { adminSignIn, getUsersInfo } from "./actions";

const initialState = {
  adminSignInStatus: LOADING_STATUSES.idle,
  adminSignInMessage: null,
  adminSignInResponse: null,
  userInfo: null,
  getUserInfoStatus: LOADING_STATUSES.idle,
};

export const adminSignInSlice = createSlice({
  name: SLICE_NAME.adminSignInSlice,
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("credentials");
      state.userInfo = null;
      state.getUsersInfoStatus = LOADING_STATUSES.idle;
      state.adminSignInStatus = LOADING_STATUSES.idle;
      state.adminSignInMessage = null;
      state.adminSignInResponse = null;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(adminSignIn.pending, (state) => {
      state.adminSignInStatus = LOADING_STATUSES.pending;
    });
    addCase(adminSignIn.fulfilled, (state, { payload }) => {
      state.adminSignInStatus = LOADING_STATUSES.succeeded;
      state.adminSignInMessage = null;
      state.adminSignInResponse = payload;
      setStorage("credentials", payload.access_token);
    });
    addCase(adminSignIn.rejected, (state, { payload }) => {
      state.adminSignInStatus = LOADING_STATUSES.failed;
      state.adminSignInMessage = payload?.data?.message;
    });

    //   get users information

    addCase(getUsersInfo.pending, (state) => {
      state.getUserInfoStatus = LOADING_STATUSES.pending;
    });
    addCase(getUsersInfo.fulfilled, (state, { payload }) => {
      state.getUserInfoStatus = LOADING_STATUSES.succeeded;
      state.userInfo = payload.data;
    });
    addCase(getUsersInfo.rejected, (state) => {
      state.getUserInfoStatus = LOADING_STATUSES.failed;
      removeStorage("credentials");
    });
  },
});

export const adminSignInReducer = adminSignInSlice.reducer;

export const adminSignInActions = {
  ...adminSignInSlice.actions,
  adminSignIn,
  getUsersInfo,
};
