import { SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 10px;

  .back-button {
    margin-bottom: 20px;
  }
`;

export const Content = styled(SinglePageContentShadow)``;

export const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: end;
`;
