import { Option, Select as TailwindSelect } from "@material-tailwind/react";
import { v4 as uuid } from "uuid";

import * as S from "./styled";

export const Select = ({
  variant = "standard",
  items = [],
  itemKey,
  register = {},
  customClassName,
  error,
  ...rest
}) => {
  return (
    <S.Container className={customClassName ? "single-row" : ""}>
      <TailwindSelect
        color="green"
        error={!!error}
        style={{ fontFamily: "Montserrat" }}
        variant={variant}
        {...register}
        {...rest}
      >
        {items.map((item) => (
          <Option key={uuid()} value={itemKey ? item[itemKey] : item}>
            {itemKey ? item[itemKey] : item}
          </Option>
        ))}
      </TailwindSelect>
      {error && <S.ErrorText>{error.message}</S.ErrorText>}
    </S.Container>
  );
};
