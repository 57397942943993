import styled from "styled-components";

export const Card = styled.div`
  button {
    width: fit-content;
    padding: 12px;
    gap: 8px;
    background-color: #f8f8f8;
    color: ${(props) => props.color};
    white-space: nowrap;
    text-transform: capitalize;
  }
`;
