import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const Container = styled.div`
  margin-bottom: 16px;
  svg {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 3px 1px -2px ${COLORS.overlay_200}, 0 2px 2px 0 ${COLORS.overlay_150}, 0 1px 5px 0 ${COLORS.overlay_150};
`;

export const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const PdfInput = styled.input`
  display: none;
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
`;

export const UploadButton = styled.div`
  height: 80%;
`;
