import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Can } from "@casl/react";
import { Icons } from "assets/svgs";
import { AdminReloadButton, FilterMultiSelect, PrimaryButton, SearchInput, Select, Table } from "common";
import { useDebounce } from "hooks";
import { usePermissions } from "hooks/usePermissions";
import { Paginator } from "primereact/paginator";
import { adminRoutes } from "router/routes";
import { archivesListSelectors } from "store/admin-slices/archived-slice/selectors";
import { archiveActions } from "store/admin-slices/archived-slice/slice";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleArchivedActions } from "store/admin-slices/single-archived-slice/slice";
import {
  AVAILABILITY_OPTIONS,
  COUNTIES_FILTER,
  isLoading,
  LOADING_STATUSES,
  PERMISSIONS_LIST,
  REASON_TYPES_ITEMS,
  TOAST_TYPES,
  YEARS_FILTER,
} from "utils/constants";
import { downloadFileByLink, getFileType, getPaginationDescription, toastify } from "utils/helpers";

import { Template } from "components/Admin/TableRowCustomTemplate";
import { Filter } from "components/Filter";
import { Modal } from "components/Modal";

import * as S from "./styled";

export const ArchivedPage = () => {
  const {
    archivesList,
    last_page,
    getArchivesListStatus,
    importArchivePropertiesStatus,
    downloadCsvHref,
    exportCSVStatus,
    unarchivePropertyStatus,
    isOpenModal,
    selectedOwnerId,
    selectedPropertyId,
    archivedPageFilters,
    allowScroll,
  } = useSelector(archivesListSelectors);

  const { userInfo } = useSelector(adminSignInSelectors);

  const [selectedYear, setSelectedYear] = useState([]);
  const [paginationData, setPaginationData] = useState({
    first: 10,
    rows: 50,
    page: 1,
  });
  const [search, setSearch] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const debouncedSearch = useDebounce(search, 200);
  const [sortData, setSortData] = useState({
    field: "archived_date",
    direction: -1,
  });
  const [selectedCountyOptions, setSelectedCountyOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [contact_again, setIsContactAgain] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [selectedReasons, setSelectedReasons] = useState([]);

  const fileInputRef = useRef();

  const contactAgainSelectItems = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const ref = useRef(null);

  const ability = usePermissions(userInfo.permissions);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedFileTypes = ["csv", "xlx", "xls", "xlsx"];
    if (allowedFileTypes.includes(getFileType(file.name))) {
      dispatch(archiveActions.importArchiveProperties(file));
    } else {
      toastify(TOAST_TYPES.error, "The file must be a file of type: csv, xlx, xls, xlsx.");
    }

    fileInputRef.current.value = null;
  };

  const downloadCsv = () => {
    let properties = selectedProducts?.map((prop) => prop.property_id);
    properties = properties?.filter((id, index, self) => self.indexOf(id) === index);
    const params = {
      search: search,
      counties: selectedCountyOptions,
      properties,
    };
    dispatch(archiveActions.exportCSV(params));
  };

  const deSelectCounties = () => {
    if (selectedCountyOptions?.length > 0) {
      setSelectedCountyOptions([]);
    } else {
      setSelectedCountyOptions(COUNTIES_FILTER.map((item) => item.value));
    }
  };

  const deSelectYears = () => {
    if (selectedYear?.length > 0) {
      setSelectedYear([]);
    } else {
      setSelectedYear(YEARS_FILTER.map((item) => item.value));
    }
  };

  const handleImportAccountsClick = () => fileInputRef.current?.click();

  const rowClassName = (row) => (row.property_id === selectedProperty?.property_id ? "checked-row" : "");
  const selectSyncProperty = (item) => setSelectedProperty(item);

  const handleContactAgainChange = (label, { property_id }) => {
    const contact_again = contactAgainSelectItems.find((item) => item.label === label).value;

    dispatch(archiveActions.postContactAgain({ contact_again, property_id }));
  };

  const unArchiveProperty = (item) => {
    dispatch(archiveActions.setSelectedOwnerId(item.owner_id));
    dispatch(archiveActions.setSelectedPropertyId(item.property_id));
    dispatch(archiveActions.setIsOpenModal(true));
  };

  const tableData = useMemo(
    () => [
      { headerClassName: "column-header", selectionMode: "multiple" },
      { field: "email", header: "Email", sortable: true },
      { field: "full_name", header: "Name", sortable: true },
      { field: "county", header: "County", sortable: true },
      {
        sortable: true,
        body: (item) => Template.ItemStreetAddress(item, selectSyncProperty),
        header: "Street address",
      },
      { field: "account_number", header: "Account number" },
      {
        header: "Contact again",
        body: (item) => (
          <Can passThrough ability={ability} I={PERMISSIONS_LIST.contactAgain}>
            {(allowed) =>
              allowed ? (
                <Select
                  customClassName
                  className={`select ${item.contact_again ? "color-green" : ""}`}
                  itemKey="label"
                  items={contactAgainSelectItems}
                  labelProps={{ style: { display: "none" } }}
                  value={contactAgainSelectItems.find((selectItem) => selectItem.value === item.contact_again).label}
                  onChange={(label) => handleContactAgainChange(label, item)}
                />
              ) : (
                <S.ContactAgain className={`select ${item.contact_again ? "contact-again-yes" : "contact-again-no"}`}>
                  {item.contact_again ? "Yes" : "No"}
                </S.ContactAgain>
              )
            }
          </Can>
        ),
      },
      {
        field: "reason",
        header: "Reason",
        body: (item) => (
          <S.Box title={item.reason}>
            <S.Box className="reason-content">{item.reason}</S.Box>
          </S.Box>
        ),
      },
      { field: "archived_date", header: "Archived date", sortable: true },
      {
        header: (
          <Can ability={ability} I={PERMISSIONS_LIST.unarchive}>
            {(allowed) => (allowed ? "Unarchive" : null)}
          </Can>
        ),
        body: (item) => (
          <Can ability={ability} I={PERMISSIONS_LIST.unarchive}>
            <S.Block>
              <PrimaryButton className="unarchive-button" eventHandler={() => unArchiveProperty(item)} mode="gray" />
              <Icons.UnArchivedIcon className="unarchive-icon" />
            </S.Block>
          </Can>
        ),
      },
    ],
    []
  );

  const getArchivedUser = (page, rows, searchValue) => {
    const params = {
      page: page ?? paginationData.page,
      limit: rows ?? paginationData.rows,
      search: searchValue ?? search,
      years: selectedYear,
      reasons: selectedReasons,
      counties: selectedCountyOptions,
      order_column: sortData.field,
      direction: sortData.direction === 1 ? "asc" : "desc",
    };

    if (contact_again) {
      params.contact_again = contact_again;
    }
    dispatch(archiveActions.getArchivesList(params));
  };

  const onPageChange = ({ first, rows, page }) => {
    setPaginationData({
      first,
      rows,
      page: page + 1,
    });
    getArchivedUser(page + 1, rows);
  };

  const onSelectChange = (value) => {
    setSelectValue(value);
    setIsContactAgain(AVAILABILITY_OPTIONS.find((item) => item.label === value).value);
  };

  const handleUnarchiveProperty = () => {
    const params = {
      owner_id: selectedOwnerId,
      property_id: selectedPropertyId,
    };

    dispatch(archiveActions.unarchiveProperty(params));
  };

  const resetData = () => {
    dispatch(archiveActions.setAllowScroll(false));
    setPaginationData({ first: 10, rows: 50, page: 1 });
    setSearch("");
    setSortData({ field: "archived_date", direction: -1 });
    setSelectedCountyOptions([]);
    setSelectedProducts(null);
    setSelectedYear([]);
    setSelectValue("");
    setIsContactAgain("");
    setSelectedReasons([]);
  };

  const onSearch = (search) => {
    setSearch(search);
  };

  const onSort = (e) => {
    setSortData({ field: e.sortField, direction: e.sortOrder });
  };

  const handleRowClick = (item) => {
    dispatch(archiveActions.setAllowScroll(false));
    dispatch(singleArchivedActions.setSingleSelectedPropertyId(item.data.property_id));
    navigate(`${adminRoutes.prefix}/${adminRoutes.archived}/${item.data.owner_id}/${item.data.property_id}`);
  };

  useEffect(() => {
    getArchivedUser();

    dispatch(
      archiveActions.setArchivedPageFilters({
        selectedCountyOptions: selectedCountyOptions,
        selectedYear: selectedYear,
        contactAgain: selectValue,
        selectedReasons: selectedReasons,
        paginationData: paginationData,
        search: search,
      })
    );
  }, [
    debouncedSearch,
    sortData,
    selectedCountyOptions,
    selectedYear,
    selectValue,
    contact_again,
    selectedReasons,
    paginationData,
    search,
  ]);

  useEffect(() => {
    dispatch(archiveActions.setAllowScroll(false));
  }, [
    debouncedSearch,
    sortData,
    selectedCountyOptions,
    selectedYear,
    selectValue,
    contact_again,
    selectedReasons,
    search,
  ]);

  useEffect(() => {
    if (unarchivePropertyStatus === LOADING_STATUSES.succeeded) {
      getArchivedUser();
    }
  }, [unarchivePropertyStatus]);

  useEffect(() => {
    downloadFileByLink(exportCSVStatus, downloadCsvHref, "archived-properties.csv");
  }, [downloadCsvHref]);

  useEffect(() => {
    return () => dispatch(archiveActions.resetAllData());
  }, []);

  useEffect(() => {
    setSelectedProducts(null);
  }, [paginationData?.page]);

  useEffect(() => {
    if (unarchivePropertyStatus === LOADING_STATUSES.succeeded) {
      dispatch(archiveActions.setIsOpenModal(false));
    }
  }, [unarchivePropertyStatus]);

  useEffect(() => {
    allowScroll && ref.current.scrollIntoView({ behavior: "auto" });
  }, [getArchivesListStatus]);

  useEffect(() => {
    setSelectedCountyOptions(archivedPageFilters.selectedCountyOptions);
    setSelectedYear(archivedPageFilters.selectedYear);
    setSelectValue(archivedPageFilters.contactAgain);
    setSelectedReasons(archivedPageFilters.selectedReasons);
    setPaginationData(archivedPageFilters.paginationData || paginationData);
    setIsContactAgain(
      archivedPageFilters.contactAgain === "Yes" ? "1" : archivedPageFilters.contactAgain === "No" ? "0" : ""
    );
    setSearch(archivedPageFilters.search || search);
  }, []);

  return (
    <S.ArchivedContainer>
      <S.ArchivedTitle>Archived</S.ArchivedTitle>
      <S.ReloadButton>
        <AdminReloadButton onClick={resetData} />
      </S.ReloadButton>
      <Filter>
        <SearchInput className="search-input" placeholder="Search Client List" value={search} onSearch={onSearch} />
        <FilterMultiSelect
          buttonLabel="COUNTY FILTER"
          options={COUNTIES_FILTER}
          selectedOptions={selectedCountyOptions}
          setSelectedOptions={setSelectedCountyOptions}
        />
        <PrimaryButton
          label={(selectedCountyOptions?.length ? "DE" : "") + "SELECT ALL COUNTIES"}
          mode="gray"
          onClick={deSelectCounties}
        />
        <Can ability={ability} I={PERMISSIONS_LIST.downloadArchiveCSV}>
          <PrimaryButton label="download csv" mode="gray" onClick={downloadCsv} />
        </Can>
        <Can ability={ability} I={PERMISSIONS_LIST.importArchivedProperties}>
          <PrimaryButton
            eventHandler={handleImportAccountsClick}
            label="Import archived properties"
            loading={isLoading(importArchivePropertiesStatus)}
            mode="gray"
          />
        </Can>
        <S.FileInput ref={fileInputRef} accept=".csv" type="file" onChange={handleFileChange} />
        <FilterMultiSelect
          buttonLabel="YEAR FILTER"
          options={YEARS_FILTER}
          selectedOptions={selectedYear}
          setSelectedOptions={setSelectedYear}
        />
        <PrimaryButton
          label={(selectedYear?.length ? "DE" : "") + "SELECT ALL YEARS"}
          mode="gray"
          onClick={deSelectYears}
        />
        <Select
          className="contact-again"
          itemKey="label"
          items={AVAILABILITY_OPTIONS}
          label="Contact again"
          value={selectValue}
          onChange={onSelectChange}
        />
        <FilterMultiSelect
          buttonLabel="REASON FILTER"
          options={REASON_TYPES_ITEMS}
          optionsClassName={true}
          selectedOptions={selectedReasons}
          setSelectedOptions={setSelectedReasons}
        />
      </Filter>
      <Table
        dataKey="uniqueId"
        loading={getArchivesListStatus !== LOADING_STATUSES.succeeded}
        rowClassName={rowClassName}
        selection={selectedProducts}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={archivesList}
        onRowDoubleClick={handleRowClick}
        onSelectionChange={(e) => {
          if (e.originalEvent.target.localName !== "li") {
            setSelectedProducts(e.value);
          }
        }}
        onSort={onSort}
      />
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[50, 100, 200]}
          totalRecords={last_page}
          onClick={() => dispatch(archiveActions.setAllowScroll(true))}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
      <Modal
        cancelButtonText="no"
        className="delete-modal"
        description="Are you sure ?"
        isModalOpen={isOpenModal}
        successButtonText="yes"
        successLoadingStatus={isLoading(unarchivePropertyStatus)}
        onCancel={() => dispatch(archiveActions.setIsOpenModal(false))}
        onSuccess={handleUnarchiveProperty}
      />
    </S.ArchivedContainer>
  );
};
