import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { singleAgentApi } from "./services";

export const getAgent = createAsyncThunk(`${SLICE_NAME.singleAgentSlice}/getAgent`, async (id, { rejectWithValue }) => {
  try {
    const response = await singleAgentApi.getAgent(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const editAgent = createAsyncThunk(
  `${SLICE_NAME.singleAgentSlice}/editAgent`,
  async (body, { rejectWithValue }) => {
    try {
      await singleAgentApi.editAgent(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTracker = createAsyncThunk(
  `${SLICE_NAME.singleAgentSlice}/getTracker`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await singleAgentApi.getTracker(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteAgent = createAsyncThunk(
  `${SLICE_NAME.singleAgentSlice}/deleteAgent`,
  async (id, { rejectWithValue }) => {
    try {
      await singleAgentApi.deleteAgent(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCSV = createAsyncThunk(
  `${SLICE_NAME.singleAgentSlice}/exportCSV`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleAgentApi.exportCSV(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
