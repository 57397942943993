import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${(props) =>
    props.className &&
    props.className.includes("single-row") &&
    `
    > div > ul {
      top: -50px !important;
    }
  `}
`;

export const ErrorText = styled.div`
  color: ${COLORS.red};
  font-size: 12px;
`;
