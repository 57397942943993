import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";
import { v4 as uuid } from "uuid";

import { exportCSV, getOwnersList, syncProperty, verifyAllAoA } from "./actions";

const initialState = {
  ownersList: [],
  per_page: 50,
  last_page: 0,
  allowScroll: false,
  paginationData: {
    first: 10,
    rows: 50,
    page: 1,
  },
  ownersListFilters: {},
  downloadCsvHref: null,
  getOwnersListStatus: LOADING_STATUSES.idle,
  verifyAoaStatus: LOADING_STATUSES.idle,
  exportCSVStatus: LOADING_STATUSES.idle,
  verifyAllAoAStatus: LOADING_STATUSES.idle,
};

export const ownersList = createSlice({
  name: SLICE_NAME.ownersSlice,
  initialState,
  reducers: {
    setOwnersListFilters: (state, { payload }) => {
      state.ownersListFilters = payload;
    },
    resetAllData: (state, { payload }) => {
      if (!payload) {
        return {
          ...initialState,
          ownersListFilters: state.ownersListFilters,
        };
      } else {
        return initialState;
      }
    },

    setAllowScroll: (state, { payload }) => {
      state.allowScroll = payload;
    },
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
  },
  extraReducers: ({ addCase }) => {
    // get owner list
    addCase(getOwnersList.pending, (state) => {
      state.getOwnersListStatus = LOADING_STATUSES.pending;
    });
    addCase(getOwnersList.fulfilled, (state, { payload }) => {
      state.ownersList = payload?.data.map((item) => ({
        ...item,
        uniqueId: uuid(),
      }));
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
      state.verifyAoaStatus = LOADING_STATUSES.idle;
      state.getOwnersListStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getOwnersList.rejected, (state) => {
      state.getOwnersListStatus = LOADING_STATUSES.failed;
    });

    // sync property
    addCase(syncProperty.pending, (state) => {
      state.verifyAoaStatus = LOADING_STATUSES.pending;
    });
    addCase(syncProperty.fulfilled, (state) => {
      state.verifyAoaStatus = LOADING_STATUSES.succeeded;
    });
    addCase(syncProperty.rejected, (state) => {
      state.verifyAoaStatus = LOADING_STATUSES.failed;
    });

    // export CSV
    addCase(exportCSV.pending, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCSV.fulfilled, (state, { payload }) => {
      state.downloadCsvHref = payload;
      state.exportCSVStatus = LOADING_STATUSES.succeeded;
    });
    addCase(exportCSV.rejected, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.failed;
    });

    // verify all aoa
    addCase(verifyAllAoA.pending, (state) => {
      state.verifyAllAoAStatus = LOADING_STATUSES.pending;
    });
    addCase(verifyAllAoA.fulfilled, (state) => {
      state.verifyAllAoAStatus = LOADING_STATUSES.succeeded;
    });
    addCase(verifyAllAoA.rejected, (state) => {
      state.verifyAllAoAStatus = LOADING_STATUSES.failed;
    });
  },
});
export const ownersListReducer = ownersList.reducer;

export const ownerActions = {
  ...ownersList.actions,
  exportCSV,
  getOwnersList,
  syncProperty,
  verifyAllAoA,
};
