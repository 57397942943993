import { IMAGES } from "assets/images";
import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

export const SubmitEvidenceWrapper = styled.div`
  min-height: calc(100vh - 75px);
  background-image: url(${IMAGES.home_2});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

export const StyledSignUpStepArea = styled.div`
  border: 1px solid ${COLORS.primary};
  border-radius: 10px;
  background-color: ${COLORS.white};
`;

export const SubmitEvidenceForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 18px;
  margin-top: 30px;
  width: 100%;

  .disable {
    pointer-events: none;
    opacity: 0.5;
    margin: auto;
    min-width: 280px;
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .not-disabled {
    margin: auto;
    min-width: 280px;
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-button {
    display: flex;
    column-gap: 10px;
    align-items: center;
    max-width: 400px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid ${COLORS.green};
    background-color: ${COLORS.white};
  }

  .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 230px;
    width: fit-content;
  }

  .input-wrapper {
    & > div {
      min-width: unset !important;
    }
  }

  @media screen and (${BREAKPOINTS.md}) {
    gap: 10px;
  }
`;

export const Animation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: ${COLORS.white};
  transform: scaleY(1);
  transform-origin: top;
  animation: 0.6s show ease-in;
  max-height: 280px;

  @keyframes show {
    from {
      height: 0;
      transform: scaleY(0);
    }
    to {
      height: 220px;
      transform: scaleY(1);
    }
  }
`;

export const Title = styled.div`
  color: ${COLORS.deepDark};
  width: 100%;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  margin: 10px auto;
`;

export const Text = styled.div`
  color: ${COLORS.deepDark};
  width: 100%;
  font-size: 14px;
  display: flex;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 20px;
`;

export const UploadSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;

  @media screen and (${BREAKPOINTS.md}) {
    flex-direction: row;
  }
`;

export const ConfirmYourDetails = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const ContactUsText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: ${COLORS.deepDark};
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
`;

export const ContactUs = styled.div`
  color: ${COLORS.green};
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 4px;
`;

export const UploadedFiles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media screen and (${BREAKPOINTS.xl}) {
    flex-direction: row;
  }

  .evidence-type {
    width: 100%;

    @media screen and (${BREAKPOINTS.md}) {
      width: 350px;
    }
  }
`;

export const EvidenceFiles = styled.div`
  margin-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ChooseEvidenceInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Montserrat;
  width: 90%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: ${COLORS.deepDark};

  @media screen and (${BREAKPOINTS.md}) {
    width: 50%;
  }
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 280px;
  gap: 10px;
  border-radius: 4px;
  max-height: 44px;
  border: 1px solid ${COLORS.green};
  padding: 10px;

  .close-button {
    cursor: pointer;
  }
`;

export const FileText = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${COLORS.deepDark};
  text-align: center;
`;

export const FileInput = styled.input`
  display: none;
`;

export const UploadButton = styled.div`
  position: relative;
  font-family: Montserrat;
  display: flex;
  padding: 8px 12px;
  max-width: 400px;
  font-size: 16px;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  gap: 14px;
  border-radius: 4px;
  border: 1px solid ${COLORS.green};
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;

  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 10px #000;
  }
`;

export const FileUploadButton = styled.label`
  .invalid {
    color: ${COLORS.red};
    border: 1px solid ${COLORS.red};

    svg {
      path {
        stroke: ${COLORS.red};
      }
    }
  }
`;

export const FileErrorMessage = styled.div`
  position: absolute;
  top: 42px;
  right: 7px;
  color: ${COLORS.red};
`;

export const Form = styled.div``;

export const SearchBoxContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 46px;
  width: 100%;
  background-color: ${COLORS.white};
  box-shadow: 0 3px 2px -2px ${COLORS.overlay_200}, 0 2px 2px 0 ${COLORS.overlay_150}, 0 1px 5px 0 ${COLORS.overlay_150};
`;

export const LoaderBox = styled.div`
  padding: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchBox = styled.div`
  cursor: default;
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px;
  border: 1px solid ${COLORS.grey};
  flex: 1 1 auto;
  font-size: 10px;
  min-height: 14px;
  min-width: 1px;

  &:hover {
    background-color: ${COLORS.overlay_060};
  }

  .street-address {
    font-weight: bold;
    font-size: 12px;
  }

  svg {
    width: 18px;
    fill: ${COLORS.darkgrey};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media screen and (${BREAKPOINTS.md}) {
    flex-direction: row !important;
  }
`;

export const Box = styled.div`
  background-color: ${COLORS.white};
`;

export const Span = styled.span``;

export const NoResult = styled.div`
  padding: 8px;
`;
