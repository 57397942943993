import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Can } from "@casl/react";
import { Loader, PrimaryButton } from "common";
import { useDebounce } from "hooks";
import { usePermissions } from "hooks/usePermissions";
import { adminRoutes } from "router/routes";
import { agentsActions } from "store/admin-slices/agents-slice/slice";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleAgentSelector } from "store/admin-slices/single-agent-slice/selectors";
import { singleAgentActions } from "store/admin-slices/single-agent-slice/slice";
import { isLoading, LOADING_STATUSES, PERMISSIONS_LIST } from "utils/constants";

import { Modal } from "components/Modal";

import { EditAgent } from "./EditAgent";
import * as S from "./styled";
import { TrackerTable } from "./TrackerTable";

export const AgentSinglePage = () => {
  const { deleteAgentStatus, paginationData, getAgentDataStatus, isModalOpen } = useSelector(singleAgentSelector);
  const { userInfo } = useSelector(adminSignInSelectors);

  const [sortData, setSortData] = useState({
    field: "created_at",
    direction: -1,
  });

  const [startDate, setStartDate] = useState("");
  const debouncedStartDate = useDebounce(startDate, 500);

  const [endDate, setEndDate] = useState("");
  const debouncedEndDate = useDebounce(endDate, 500);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  const ability = usePermissions(userInfo.permissions);

  const goBack = () => {
    navigate(`${adminRoutes.prefix}/${adminRoutes.agents}`);
  };

  const getTrackerList = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      order_column: sortData.field,
      start_date: debouncedStartDate,
      end_date: debouncedEndDate,
      direction: sortData.direction === 1 ? "asc" : "desc",
    };
    dispatch(singleAgentActions.getTracker({ id, params }));
  };

  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      singleAgentActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getTrackerList({ page: page + 1, rows });
  };

  const handleDeleteAgent = () => {
    dispatch(singleAgentActions.deleteAgent(id));
  };

  const handleOpenModal = () => {
    dispatch(singleAgentActions.setIsModalOpen(true));
  };

  const handleCloseModal = () => {
    dispatch(singleAgentActions.setIsModalOpen(false));
  };

  useEffect(() => {
    if (ability.can(PERMISSIONS_LIST.agentActionTracker)) {
      getTrackerList();
    }
  }, [sortData, debouncedStartDate, debouncedEndDate]);

  useEffect(() => {
    dispatch(singleAgentActions.getAgent(id));
    dispatch(agentsActions.getPermissions());
  }, [id]);

  useEffect(() => {
    if (deleteAgentStatus === LOADING_STATUSES.succeeded) {
      goBack();
    }
  }, [deleteAgentStatus]);

  useEffect(() => {
    return () => dispatch(singleAgentActions.resetAllData());
  }, []);

  if (isLoading(getAgentDataStatus)) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return (
    <S.Container>
      <PrimaryButton className="back-button" eventHandler={goBack} label="Back" />
      <Can ability={ability} I={PERMISSIONS_LIST.updateUsers}>
        <EditAgent />
      </Can>
      <Can ability={ability} I={PERMISSIONS_LIST.agentActionTracker}>
        <TrackerTable
          debouncedEndDate={debouncedEndDate}
          debouncedStartDate={debouncedStartDate}
          setEndDate={setEndDate}
          setSortData={setSortData}
          setStartDate={setStartDate}
          sortData={sortData}
          onPageChange={onPageChange}
        />
      </Can>
      <Can ability={ability} I={PERMISSIONS_LIST.deleteUsers}>
        <PrimaryButton className="delete-button" eventHandler={handleOpenModal} label="Delete Agent" mode="gray" />
        <Modal
          cancelButtonText="no"
          className="delete-modal"
          description="Are you sure ?"
          isModalOpen={isModalOpen}
          successButtonText="yes"
          successLoadingStatus={isLoading(deleteAgentStatus)}
          onCancel={handleCloseModal}
          onSuccess={handleDeleteAgent}
        />
      </Can>
    </S.Container>
  );
};
