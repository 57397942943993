import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { clientEvidenceApi } from "./services";

export const getClientEvidences = createAsyncThunk(
  `${SLICE_NAME.clientEvidenceSlice}/getClientEvidences`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await clientEvidenceApi.getClientEvidences(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCSV = createAsyncThunk(
  `${SLICE_NAME.clientEvidenceSlice}/exportCSV`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await clientEvidenceApi.exportCSV(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteEvidence = createAsyncThunk(
  `${SLICE_NAME.clientEvidenceSlice}/deleteEvidence`,
  async (id, { rejectWithValue }) => {
    try {
      await clientEvidenceApi.deleteEvidence(id);
      return { id };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
