const NotFound = (props) => (
  <svg fill="none" height="102" viewBox="0 0 279 102" width="279" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.76 77.74V65.14L49.06 1.99999H68.52L20.92 65.14L11.82 62.34H89.52V77.74H0.76ZM54.52 100V77.74L55.08 62.34V42.6H72.16V100H54.52ZM138.06 101.4C130.313 101.4 123.313 99.44 117.06 95.52C110.9 91.6 106.046 85.9067 102.5 78.44C98.9529 70.88 97.1795 61.7333 97.1795 51C97.1795 40.2667 98.9529 31.1667 102.5 23.7C106.046 16.14 110.9 10.4 117.06 6.47999C123.313 2.55999 130.313 0.599995 138.06 0.599995C145.9 0.599995 152.9 2.55999 159.06 6.47999C165.22 10.4 170.073 16.14 173.62 23.7C177.26 31.1667 179.08 40.2667 179.08 51C179.08 61.7333 177.26 70.88 173.62 78.44C170.073 85.9067 165.22 91.6 159.06 95.52C152.9 99.44 145.9 101.4 138.06 101.4ZM138.06 85.58C142.633 85.58 146.6 84.3667 149.96 81.94C153.32 79.42 155.933 75.5933 157.8 70.46C159.76 65.3267 160.74 58.84 160.74 51C160.74 43.0667 159.76 36.58 157.8 31.54C155.933 26.4067 153.32 22.6267 149.96 20.2C146.6 17.68 142.633 16.42 138.06 16.42C133.673 16.42 129.753 17.68 126.3 20.2C122.94 22.6267 120.28 26.4067 118.32 31.54C116.453 36.58 115.52 43.0667 115.52 51C115.52 58.84 116.453 65.3267 118.32 70.46C120.28 75.5933 122.94 79.42 126.3 81.94C129.753 84.3667 133.673 85.58 138.06 85.58ZM189.979 77.74V65.14L238.279 1.99999H257.739L210.139 65.14L201.039 62.34H278.739V77.74H189.979ZM243.739 100V77.74L244.299 62.34V42.6H261.379V100H243.739Z"
      fill="#437F55"
    />
  </svg>
);

export default NotFound;
