import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Can } from "@casl/react";
import { IMAGES } from "assets/images";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { usePermissions } from "hooks/usePermissions";
import { adminRoutes } from "router/routes";
import { archiveActions } from "store/admin-slices/archived-slice/slice";
import { clientEvidenceActions } from "store/admin-slices/client-evidence-slice/slice";
import { documentsActions } from "store/admin-slices/documents-slice/slice";
import { evidenceActions } from "store/admin-slices/evidence-slice/slice";
import { leadsActions } from "store/admin-slices/leads-slice/slice";
import { ownerActions } from "store/admin-slices/owner-slice/slice";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { adminSignInActions } from "store/admin-slices/signin-slice/slice";
import { PERMISSIONS_LIST } from "utils/constants";
import { v4 as uuid } from "uuid";

import * as S from "./styled";

const NavigationBar = () => {
  const { pathname } = useLocation();
  const { userInfo } = useSelector(adminSignInSelectors);

  const dispatch = useDispatch();

  const ability = usePermissions(userInfo.permissions);

  const Menus = [
    {
      title: "Home",
      src: `${adminRoutes.prefix}/${adminRoutes.home}`,
      icon: <Icons.HomeIcon fill={COLORS.lightgray} height="24px" width="24" />,
      permission: PERMISSIONS_LIST.accessHome,
    },
    // {
    //   title: "New Data",
    //   src: `${adminRoutes.prefix}/${adminRoutes.dashboard}`,
    //   icon: <Icons.DashboardIcon fill={COLORS.lightgray} height="24px" width="24" />,
    //   permission: PERMISSIONS_LIST.accessDashboard,
    // },
    {
      title: "Agents",
      src: `${adminRoutes.prefix}/${adminRoutes.agents}`,
      icon: <Icons.AgentIcon fill={COLORS.lightgray} height="24px" width="24" />,
      permission: PERMISSIONS_LIST.accessAgents,
    },
    {
      title: "Owners",
      src: `${adminRoutes.prefix}/${adminRoutes.owners}`,
      icon: <Icons.UserGroupIcon fill={COLORS.lightgray} height="24px" width="24" />,
      permission: PERMISSIONS_LIST.accessOwners,
    },
    {
      title: "Leads",
      src: `${adminRoutes.prefix}/${adminRoutes.leads}`,
      icon: <Icons.LeadIcon fill={COLORS.lightgray} height="24px" width="24" />,
      permission: PERMISSIONS_LIST.accessLeads,
    },
    {
      title: "Archived",
      src: `${adminRoutes.prefix}/${adminRoutes.archived}`,
      icon: <Icons.ArchivedIcon fill={COLORS.lightgray} height="24px" width="24" />,
      permission: PERMISSIONS_LIST.accessArchived,
    },
    {
      title: "Evidence",
      src: `${adminRoutes.prefix}/${adminRoutes.evidence}`,
      icon: <Icons.EvidenceIcon fill={COLORS.lightgray} height="24px" width="24" />,
      permission: PERMISSIONS_LIST.accessEvidence,
    },
    {
      title: "Client Evidence",
      src: `${adminRoutes.prefix}/${adminRoutes.clientEvidence}`,
      icon: <Icons.ClientEvidenceIcon fill={COLORS.lightgray} height="24px" width="24" />,
      permission: PERMISSIONS_LIST.accessClientEvidence,
    },
    {
      title: "Documents",
      src: `${adminRoutes.prefix}/${adminRoutes.documents}`,
      icon: <Icons.DocumentIcon fill={COLORS.lightgray} height="24px" width="24" />,
      permission: PERMISSIONS_LIST.accessDocuments,
    },
    {
      title: "Protested Accounts",
      src: `${adminRoutes.prefix}/${adminRoutes.protestedAccounts}`,
      icon: <Icons.ProtestedAccountIcon fill={COLORS.lightgray} height="24px" width="24" />,
      permission: PERMISSIONS_LIST.accessProtestedAccounts,
    },
    {
      title: "Hearing Dates",
      src: `${adminRoutes.prefix}/${adminRoutes.hearingDates}`,
      icon: <Icons.HearingDatesIcon fill={COLORS.lightgray} height="24px" width="24" />,
      permission: PERMISSIONS_LIST.accessHearingDates,
    },
    {
      title: "Sign Out",
      src: adminRoutes.signIn,
      icon: <Icons.LogoutIcon fill={COLORS.lightgray} height="24px" width="20" />,
      permission: false,
      isSignOut: true,
    },
  ];

  const logOut = () => {
    dispatch(adminSignInActions.logout());
  };

  const resetTabsData = () => {
    if (!window.location.href.includes("owners")) {
      dispatch(ownerActions.resetAllData("all"));
    }
    if (!window.location.href.includes("leads")) {
      dispatch(leadsActions.resetAllData("all"));
    }
    if (!window.location.href.includes("client-evidence")) {
      dispatch(clientEvidenceActions.resetAllData("all"));
    }
    if (!window.location.href.includes("documents")) {
      dispatch(documentsActions.resetAllData("all"));
    }
    if (!window.location.href.includes("evidence")) {
      dispatch(evidenceActions.resetAllData("all"));
    }
    if (!window.location.href.includes("archived")) {
      dispatch(archiveActions.resetAllData("all"));
    }
  };

  return (
    <S.Container>
      <S.Content>
        <S.Box>
          <S.Logo src={IMAGES.logo} />
        </S.Box>
        <S.MenuContent>
          {Menus.map((menu) => (
            <S.MenuWrapper key={uuid()} onClick={() => (menu.isSignOut ? logOut() : resetTabsData())}>
              {menu.permission ? (
                <Can ability={ability} I={menu.permission}>
                  <S.NavLink
                    className={`link ${pathname.includes(menu.src) ? "selected-link" : "hover-link"}`}
                    to={menu.src}
                  >
                    <S.Box>{menu.icon}</S.Box>
                    <S.MenuTitle className="menu-title">{menu.title}</S.MenuTitle>
                  </S.NavLink>
                </Can>
              ) : (
                <S.NavLink
                  className={`link ${pathname.includes(menu.src) ? "selected-link" : "hover-link"}`}
                  to={menu.src}
                >
                  <S.Box>{menu.icon}</S.Box>
                  <S.MenuTitle className="menu-title">{menu.title}</S.MenuTitle>
                </S.NavLink>
              )}
            </S.MenuWrapper>
          ))}
        </S.MenuContent>
      </S.Content>
    </S.Container>
  );
};
export default NavigationBar;
