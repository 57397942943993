import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-tailwind/react";
import initAxiosInterceptors from "configs/axios";
import { PrimeReactProvider } from "primereact/api";
import { store } from "store";
import { StyleSheetManager } from "styled-components";

import App from "./App";

import "./index.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
initAxiosInterceptors();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider>
      <PrimeReactProvider>
        <StyleSheetManager shouldForwardProp={(prop) => !prop.startsWith("$")}>
          <App />
        </StyleSheetManager>
      </PrimeReactProvider>
    </ThemeProvider>
  </Provider>
);
