import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";
import { setStorage, toggleClassOnRoot } from "utils/helpers";

import {
  addPropertyInLeads,
  completeSignUp,
  connectOwnerProperty,
  deletePropertyLeads,
  postAccountLogs,
  postProperty,
  postPropertyId,
  propertySearch,
  propertySearchFromDb,
  signUp,
  updateOwner,
} from "./actions";

const initialState = {
  signupStep: 0,
  properties: [],
  searchedProperties: [],
  mailingInformation: {},
  ownerData: null,
  signupErrorMessage: null,
  propertySearchData: null,
  isOpenAcceptModal: false,
  duplicateProperty: false,
  isOpenSuccessModal: false,
  isAddedManually: false,
  scrollTooErrorMessage: false,
  deletePropertyKey: null,
  deletePropertyId: null,
  deletePropertyOwnerId: null,
  duplicateIdError: null,
  signature: null,
  signType: "type",
  signatureErr: null,
  signupStatus: LOADING_STATUSES.idle,
  propertySearchStatus: LOADING_STATUSES.idle,
  postPropertyStatus: LOADING_STATUSES.idle,
  searchedPropertiesStatus: LOADING_STATUSES.idle,
  completeSignUpStatus: LOADING_STATUSES.idle,
  addPropertyInLeadsStatus: LOADING_STATUSES.idle,
  deletePropertyLeadsStatus: LOADING_STATUSES.idle,
  postPropertyIdStatus: LOADING_STATUSES.idle,
};

export const signupSlice = createSlice({
  name: SLICE_NAME.signupSlice,
  initialState,
  reducers: {
    setSignType: (state, { payload }) => {
      state.signType = payload;
    },
    backStep: (state) => {
      state.signupStep = state.signupStep - 1;
    },
    changeStep: (state, { payload }) => {
      state.signupStep = payload;
      state.signature = null;
      state.properties = [];
      state.isOpenSuccessModal = false;
      state.completeSignUpStatus = LOADING_STATUSES.idle;
    },
    toggleAcceptModal: (state, { payload }) => {
      if (state.isOpenAcceptModal) {
        state.deletePropertyKey = null;
        state.deletePropertyId = null;
        state.deletePropertyOwnerId = null;
      } else {
        state.deletePropertyKey = payload.deletePropertyKey ?? null;
        state.deletePropertyId = payload.id ?? null;
        state.deletePropertyOwnerId = payload.owner_id ?? null;
      }
      state.isOpenAcceptModal = !state.isOpenAcceptModal;
      toggleClassOnRoot();
    },
    deleteProperty: (state) => {
      state.properties = state.properties.filter((property) => property.index !== state.deletePropertyKey);
    },
    setSignature: (state, { payload }) => {
      state.signature = payload.signature;
    },
    deleteSignature: (state) => {
      state.signature = null;
    },
    setSignatureErr: (state) => {
      state.signatureErr = "Signature is required";
    },
    deleteSignatureErr: (state) => {
      state.signatureErr = null;
    },
    setIsOpenSuccessModal: (state, { payload }) => {
      state.isOpenSuccessModal = payload;
    },
    resetErrorMessages: (state) => {
      state.duplicateIdError = null;
    },
    resetStepTwoData: (state) => {
      state.propertySearchData = null;
    },
    setIsAddedManually: (state, payload) => {
      state.isAddedManually = payload;
    },
    setScrollTooErrorMessage: (state) => {
      state.scrollTooErrorMessage = !state.scrollTooErrorMessage;
    },
    setMailingInformation: (state, { payload }) => {
      state.mailingInformation = payload;
    },
    setOwnerData: (state, { payload }) => {
      state.ownerData = payload;
    },
    resetAllData: () => initialState,
  },
  extraReducers: ({ addCase }) => {
    // Sign Up
    addCase(signUp.pending, (state) => {
      state.signupStatus = LOADING_STATUSES.pending;
    });
    addCase(signUp.fulfilled, (state, { payload }) => {
      state.signupStatus = LOADING_STATUSES.succeeded;
      state.signupErrorMessage = null;
      state.ownerData = payload.data;
      state.signupStep = 1;
      setStorage("owner_id", payload.data.id);
    });
    addCase(signUp.rejected, (state, { payload }) => {
      state.signupStatus = LOADING_STATUSES.failed;
      state.signupErrorMessage = payload?.data?.message;
    });

    // property search
    addCase(propertySearch.pending, (state) => {
      state.propertySearchStatus = LOADING_STATUSES.pending;
      state.propertySearchData = null;
    });
    addCase(propertySearch.fulfilled, (state, { payload }) => {
      state.propertySearchStatus = LOADING_STATUSES.succeeded;
      state.propertySearchData = payload.data;
    });
    addCase(propertySearch.rejected, (state) => {
      state.propertySearchStatus = LOADING_STATUSES.failed;
    });

    //add property
    addCase(postProperty.pending, (state) => {
      state.postPropertyStatus = LOADING_STATUSES.pending;
    });
    addCase(postProperty.fulfilled, (state) => {
      state.postPropertyStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postProperty.rejected, (state) => {
      state.postPropertyStatus = LOADING_STATUSES.failed;
    });

    //update owner
    addCase(updateOwner.pending, (state) => {
      state.signupStatus = LOADING_STATUSES.pending;
    });
    addCase(updateOwner.fulfilled, (state, { payload }) => {
      state.signupStatus = LOADING_STATUSES.succeeded;
      state.ownerData = { ...state.ownerData, ...payload.data };
      state.signupStep = 2;
    });
    addCase(updateOwner.rejected, (state) => {
      state.signupStatus = LOADING_STATUSES.failed;
      state.signupStep = 2;
    });

    // search property in db
    addCase(propertySearchFromDb.pending, (state) => {
      state.searchedPropertiesStatus = LOADING_STATUSES.pending;
    });
    addCase(propertySearchFromDb.fulfilled, (state, { payload }) => {
      state.searchedProperties = payload.data;
      state.searchedPropertiesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(propertySearchFromDb.rejected, (state) => {
      state.searchedPropertiesStatus = LOADING_STATUSES.failed;
    });

    // complete sign up
    addCase(completeSignUp.pending, (state) => {
      state.completeSignUpStatus = LOADING_STATUSES.pending;
    });
    addCase(completeSignUp.fulfilled, (state) => {
      state.completeSignUpStatus = LOADING_STATUSES.succeeded;
    });
    addCase(completeSignUp.rejected, (state) => {
      state.completeSignUpStatus = LOADING_STATUSES.failed;
    });

    //add property in leads
    addCase(addPropertyInLeads.pending, (state) => {
      state.addPropertyInLeadsStatus = LOADING_STATUSES.pending;
    });

    addCase(addPropertyInLeads.fulfilled, (state, { payload }) => {
      const isDuplicateProperty = state.properties.some((item) => item.id === payload.id);

      if (isDuplicateProperty) {
        state.duplicateIdError = "Please add a property that is different from the property already selected.";
      } else {
        const newIndex = state.properties.length ? state.properties[state.properties.length - 1].index + 1 : 1;
        const updatedPayload = { ...payload, index: newIndex };
        state.properties.push(updatedPayload);
        state.propertySearchData = null;
      }

      const lastIndex = state.properties.length - 1;
      if (lastIndex >= 0 && !isDuplicateProperty) {
        const lastProperty = state.properties[lastIndex];
        const {
          street_address,
          id,
          county_owner_address,
          county_owner_city,
          county_owner_name,
          county_owner_state,
          county_owner_zip,
          account_number,
        } = payload;

        Object.assign(lastProperty, {
          street_address,
          id,
          county_owner_address,
          county_owner_city,
          county_owner_name,
          county_owner_state,
          county_owner_zip,
          account_number,
        });
      }

      state.addPropertyInLeadsStatus = LOADING_STATUSES.succeeded;
    });

    addCase(addPropertyInLeads.rejected, (state) => {
      state.addPropertyInLeadsStatus = LOADING_STATUSES.failed;
    });

    //delete property in leads
    addCase(deletePropertyLeads.pending, (state) => {
      state.deletePropertyLeadsStatus = LOADING_STATUSES.pending;
    });
    addCase(deletePropertyLeads.fulfilled, (state) => {
      state.deletePropertyLeadsStatus = LOADING_STATUSES.succeeded;
    });
    addCase(deletePropertyLeads.rejected, (state) => {
      state.deletePropertyLeadsStatus = LOADING_STATUSES.failed;
    });

    // post property id
    addCase(postPropertyId.pending, (state) => {
      state.postPropertyIdStatus = LOADING_STATUSES.pending;
    });
    addCase(postPropertyId.fulfilled, (state) => {
      state.postPropertyIdStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postPropertyId.rejected, (state) => {
      state.postPropertyIdStatus = LOADING_STATUSES.failed;
    });
  },
});

export const signupReducer = signupSlice.reducer;

export const signupActions = {
  ...signupSlice.actions,
  addPropertyInLeads,
  completeSignUp,
  deletePropertyLeads,
  postProperty,
  postPropertyId,
  propertySearch,
  propertySearchFromDb,
  signUp,
  connectOwnerProperty,
  updateOwner,
  postAccountLogs,
};
