import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Can } from "@casl/react";
import { PrimaryButton, PrimaryInput, Select } from "common";
import { usePermissions } from "hooks/usePermissions";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { COUNTY_DETAILS, COUNTY_EMAILS, DOCUMENT_TYPE, isLoading, PERMISSIONS_LIST } from "utils/constants";

import { Document } from "./Document";
import * as S from "./styled";

export const Documents = ({ leads }) => {
  const { singleOwner, verifySinglePropertyStatus, verifySinglePropertyData, createDocumentStatus } =
    useSelector(singleOwnerSelectors);
  const { userInfo } = useSelector(adminSignInSelectors);

  const pdfInputRef = useRef();

  const [email, setEmail] = useState("");
  const [agentCode, setAgentCode] = useState("");
  const [body, setBody] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);

  const { selectedPropertyId } = useParams();

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const ability = usePermissions(userInfo.permissions);

  const handleEmailChange = (value) => setEmail(value);

  const handleAgentCodeChange = (value) => setAgentCode(value);

  const handleVerifyAuthorizationClick = () => {
    dispatch(singleOwnerActions.verifySingleProperty(body));
  };

  const handleSelectFile = () => {
    pdfInputRef.current.value = null;
    pdfInputRef.current?.click();
    dispatch(singleOwnerActions.resetCreateDocumentStatus());
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const formData = new FormData();
    formData.append("send_email", 0);
    formData.append("owner_id", singleOwner.id);
    formData.append("docs_aoa", file);

    dispatch(singleOwnerActions.createDocument(formData));
  };

  useEffect(() => {
    const property = singleOwner.properties.find((prop) => prop.id === Number(selectedPropertyId));

    setSelectedProperty(property);
    handleEmailChange(COUNTY_DETAILS[property?.county]?.email);
    handleAgentCodeChange(COUNTY_DETAILS[property?.county]?.agent);

    setBody({
      county: property?.county,
      account_number: property?.account_number,
      owner_id: singleOwner.id,
    });
  }, [selectedPropertyId]);

  useEffect(() => {
    if (searchParams.get("is_dev")) {
      setEmail("");
    }
  }, [searchParams]);

  return (
    <S.Container>
      <S.ContentWrapper>
        <S.Header>Documents</S.Header>
        <S.Content>
          <S.DocumentsWrapper>
            <S.DocumentsContent>
              {singleOwner.documents
                ?.filter((document) => document.type === DOCUMENT_TYPE.AOA)
                ?.map((doc) => (
                  <Document key={doc.id} agentCode={agentCode} doc={doc} email={email} leads={leads} />
                ))}
            </S.DocumentsContent>
            <S.SettingName>Select Email to Send AOA To</S.SettingName>
            <Can ability={ability} I={PERMISSIONS_LIST.uploadNewOwnerAoa}>
              <S.UploadNewAoAContent>
                {searchParams.get("is_dev") === "1" ? (
                  <PrimaryInput placeholder="Email" value={email} onChange={(e) => handleEmailChange(e.target.value)} />
                ) : (
                  <Select
                    items={Object.values(COUNTY_EMAILS)}
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                )}
                <S.PdfInput ref={pdfInputRef} accept="application/pdf" type="file" onChange={handleFileChange} />
                <PrimaryButton
                  eventHandler={handleSelectFile}
                  label="upload new aoa"
                  loading={isLoading(createDocumentStatus)}
                  mode="gray"
                  styles={{
                    width: 300,
                  }}
                />
              </S.UploadNewAoAContent>
            </Can>
          </S.DocumentsWrapper>
          {!leads && (
            <S.SettingsWrapper>
              <S.SettingsContent>
                <S.SettingName>
                  Checked-
                  <S.VerifyAoAStatus className={selectedProperty?.authed_or_not === "yes" ? "" : "no-auth"}>
                    {singleOwner.properties
                      .find((prop) => prop.id === Number(selectedPropertyId))
                      ?.authed_or_not?.toUpperCase()}
                  </S.VerifyAoAStatus>
                </S.SettingName>
                <Can ability={ability} I={PERMISSIONS_LIST.verifyAoaButton}>
                  <PrimaryButton
                    eventHandler={handleVerifyAuthorizationClick}
                    label="verify authorization"
                    loading={isLoading(verifySinglePropertyStatus)}
                    mode="gray"
                  />
                </Can>
                {verifySinglePropertyData && (
                  <S.Text className={verifySinglePropertyData.error ? "red" : "success"}>
                    {verifySinglePropertyData.message}
                  </S.Text>
                )}
              </S.SettingsContent>
              <S.SettingsContent>
                <S.SettingName>Service Agreement</S.SettingName>
                <S.Link
                  href={
                    singleOwner.documents?.filter((document) => document.type === DOCUMENT_TYPE.SA)?.[0]?.sa
                      ?.preview_url
                  }
                  target="_blank"
                >
                  View Service Agreement
                </S.Link>
              </S.SettingsContent>
            </S.SettingsWrapper>
          )}
        </S.Content>
      </S.ContentWrapper>
    </S.Container>
  );
};
