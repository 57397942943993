import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { PrimaryButton } from "common";
import { routes } from "router/routes";
import { submitEvidenceActions } from "store/submit-evidence-slice/slice";
import { PTP_CONTACT_US_LINK } from "utils/constants";
import { toggleClassOnRoot } from "utils/helpers";

import * as S from "./styled";

export const SuccessModal = ({ setUploadedFiles }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstAnimation, setFirstAnimation] = useState(true);
  const [secondAnimation, setSecondAnimation] = useState(false);

  const navigateHome = () => {
    window.open(routes.home, "_blank");
  };

  const navigateSubmitEvidence = () => {
    dispatch(submitEvidenceActions.resetAllData());
    navigate(routes.submitEvidence);
    setUploadedFiles([]);
  };

  const handleAnimate = () => setSecondAnimation(true);

  const navigateContactUs = () => {
    window.open(PTP_CONTACT_US_LINK, "_blank");
  };

  const handleAnimationEnd = () => {
    setFirstAnimation(false);
    setSecondAnimation(false);
  };

  useEffect(() => {
    return () => toggleClassOnRoot();
  }, []);

  return (
    <S.Container onClick={handleAnimate}>
      <S.Content
        className={`${secondAnimation ? "second-animation" : ""} ${firstAnimation ? "first-animation" : ""}`}
        onAnimationEnd={handleAnimationEnd}
        onClick={(e) => e.stopPropagation()}
      >
        <S.BoxCenter>
          <S.IconWrapper>
            <Icons.CheckIcon size={45} />
          </S.IconWrapper>
        </S.BoxCenter>
        <S.BoxCenter>
          <S.Title>Thank you for submitting evidence. </S.Title>
        </S.BoxCenter>
        <S.BoxCenter>
          <S.Description>The evidence has been added to your file to be used for this year's protest.</S.Description>
        </S.BoxCenter>
        <S.BoxCenter>
          <S.ButtonsWrapper>
            <PrimaryButton
              eventHandler={navigateHome}
              label="RETURN TO HOMEPAGE"
              styles={{
                backgroundColor: COLORS.white,
                color: COLORS.dark,
                border: `1px solid ${COLORS.dark}`,
                height: 50,
              }}
            />
            <PrimaryButton
              eventHandler={navigateSubmitEvidence}
              label="SUBMIT ADDITIONAL EVIDENCE"
              styles={{
                backgroundColor: COLORS.deepDark,
                height: 50,
              }}
            />
          </S.ButtonsWrapper>
        </S.BoxCenter>
        <S.ContactUsText>
          <S.Description>Have questions or trouble while submitting evidence?</S.Description>
          <S.Contact onClick={navigateContactUs}>Contact us</S.Contact>
        </S.ContactUsText>
      </S.Content>
    </S.Container>
  );
};
