import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { singleDashboardSelector } from "store/admin-slices/dashboard-single-slice/selectors";
import { dashboardSingleActions } from "store/admin-slices/dashboard-single-slice/slice";

import * as S from "./styled";

const formatKey = (key) => {
  return key.replace(/_/g, " ").replace(/\w\S*/g, (w) => w.charAt(0).toUpperCase() + w.substr(1).toLowerCase());
};

export const DashboardSinglePage = () => {
  const { singleDashboardList } = useSelector(singleDashboardSelector);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(dashboardSingleActions.getSingleDashboardList({ id }));
  }, [dispatch, id]);

  return (
    <S.Container>
      {Object.entries(singleDashboardList).map(([key, value]) => (
        <S.Box key={key}>
          <S.Description>
            <S.Title>{formatKey(key)}: </S.Title>
            <S.Text>
              &nbsp;
              {typeof value === "string" ? value : JSON.stringify(value)}
            </S.Text>
          </S.Description>
        </S.Box>
      ))}
    </S.Container>
  );
};
