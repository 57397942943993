import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { adminSignInApi } from "./services";

export const adminSignIn = createAsyncThunk(
  `${SLICE_NAME.adminSignInSlice}/adminSignIn`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await adminSignInApi.signIn(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUsersInfo = createAsyncThunk(
  `${SLICE_NAME.adminSignInSlice}/getUsersInfo`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminSignInApi.getUserInfo();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
