import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const Content = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 3px 1px -2px ${COLORS.overlay_200}, 0 2px 2px 0 ${COLORS.overlay_150}, 0 1px 5px 0 ${COLORS.overlay_150};

  .save-button {
    align-self: center;
    margin-bottom: 10px;
  }

  .acordion svg {
    display: none;
  }

  .select-all,
  deselect-all {
    width: 200px;
    user-select: none;
  }
`;

export const ContentTitle = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
`;

export const EditWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const EditContent = styled(Content)`
  margin-block: 10px;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 680px;
  overflow-y: auto;
  padding: 16px 12px;
`;

export const Buttons = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const EditAccountForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

export const Box = styled.div``;
export const EditPermissionsWrapper = styled.div``;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RelatedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
`;

export const RelatedItems = styled.div`
  display: flex;
  align-items: center;
`;

export const RelatedLabel = styled.label``;
