import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const SlidebarContainer = styled.div`
  max-width: 316px;
  height: fit-content;
  background-color: ${COLORS.overlay_060};
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: 0.5s;

  &.closed {
    max-width: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
  }

  .multi-select {
    width: 100% !important;

    button {
      width: 100% !important;
    }
  }

  .back-button {
    overflow: unset !important;
  }
`;

export const SiteBarFixedContainer = styled.div`
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  row-gap: 24px;
  overflow-y: auto;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 26px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const HeadingText = styled.h2`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 10px;
  }
`;

export const InputsBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

export const Description = styled.span`
  font-size: 14px;
  font-style: normal;
`;

export const Span = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
`;

export const FilterInput = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  div {
    min-width: 0;
  }
`;

export const Border = styled.div`
  border: 1px solid ${COLORS.darkgrey};
`;

export const PropertyParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const PropertyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
`;

export const Label = styled.h2`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const Value = styled.h2`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: end;
`;

export const MultiSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding-bottom: 16px;
  padding-top: 10px;
`;

export const Block = styled.div``;

export const Div = styled.div`
  display: flex;
  align-items: center;
`;
