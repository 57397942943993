import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Checkbox, PrimaryAccordion, PrimaryButton } from "common";
import { agentsSelector } from "store/admin-slices/agents-slice/selectors";
import { agentsActions } from "store/admin-slices/agents-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";

import * as S from "./styled";

const Step2 = () => {
  const {
    permissions,
    openAccordions,
    checkedItems,
    checkedRelatedItems,
    createProfileStep1Data,
    createProfileStatus,
    errorMessage,
  } = useSelector(agentsSelector);

  const dispatch = useDispatch();

  const handleAccordionToggle = (value) => {
    const permission = permissions.find((perm) => perm.value === value);
    if (checkedItems.includes(value) && permission.related.length > 0) {
      dispatch(agentsActions.setOpenAccordion({ permissionValue: value, isOpen: !openAccordions[value] }));
    }
  };

  const handleCheckboxHeadingToggle = (value) => {
    const newCheckedItems = checkedItems.includes(value)
      ? checkedItems.filter((item) => item !== value)
      : [...checkedItems, value];

    dispatch(agentsActions.setCheckedItems({ checkedItems: newCheckedItems }));

    if (!newCheckedItems.includes(value)) {
      dispatch(agentsActions.setOpenAccordion({ permissionValue: value, isOpen: false }));
      const relatedItemsToReset = permissions
        .find((permission) => permission.value === value)
        .related.map((item) => item.value);

      const newCheckedRelatedItems = checkedRelatedItems.filter((item) => !relatedItemsToReset.includes(item));
      dispatch(agentsActions.setCheckedRelatedItems({ checkedRelatedItems: newCheckedRelatedItems }));
    }
  };

  const handleCheckboxRelatedToggle = (value) => {
    const newCheckedRelatedItems = checkedRelatedItems.includes(value)
      ? checkedRelatedItems.filter((item) => item !== value)
      : [...checkedRelatedItems, value];
    dispatch(agentsActions.setCheckedRelatedItems({ checkedRelatedItems: newCheckedRelatedItems }));
  };

  const handleBackStep = () => {
    dispatch(agentsActions.backStep());
  };

  const handleSubmit = () => {
    const allPermissions = checkedItems.concat(checkedRelatedItems);
    const body = {
      ...createProfileStep1Data,
      permissions: allPermissions.concat(["access-dashboard", "access-home"]),
    };
    dispatch(agentsActions.createProfile(body));
  };

  const selectAllRelatedItems = (permission) => {
    const allRelatedItems = permission.related.map((item) => item.value);
    const newCheckedRelatedItems = [...checkedRelatedItems, ...allRelatedItems];
    dispatch(agentsActions.setCheckedRelatedItems({ checkedRelatedItems: newCheckedRelatedItems }));
  };

  const deselectAllRelatedItems = (permission) => {
    const allRelatedItems = permission.related.map((item) => item.value);
    const newCheckedRelatedItems = checkedRelatedItems.filter((item) => !allRelatedItems.includes(item));
    dispatch(agentsActions.setCheckedRelatedItems({ checkedRelatedItems: newCheckedRelatedItems }));
  };

  useEffect(() => {
    if (createProfileStatus === LOADING_STATUSES.succeeded) {
      dispatch(agentsActions.resetCreateProfileData());
    }
  }, [createProfileStatus]);

  useEffect(() => {
    if (createProfileStatus === LOADING_STATUSES.failed) {
      toast.error(errorMessage);
    }
  }, [createProfileStatus]);

  return (
    <S.Content>
      <S.PermissionsWrapper>
        {permissions.slice(2).map((permission) => (
          <PrimaryAccordion
            key={permission.value}
            bodyContent={
              <S.RelatedWrapper>
                {permission.related?.map((item) => (
                  <S.RelatedItems key={item.value}>
                    <Checkbox
                      checked={checkedRelatedItems.includes(item.value)}
                      id={item.value}
                      onChange={() => handleCheckboxRelatedToggle(item.value)}
                    />
                    <S.RelatedLabel htmlFor={item.value}>{item.label}</S.RelatedLabel>
                  </S.RelatedItems>
                ))}
                <S.Buttons>
                  <PrimaryButton
                    className="select-all"
                    eventHandler={() => selectAllRelatedItems(permission)}
                    label="Select All"
                    mode="gray"
                  />
                  <PrimaryButton
                    className="deselect-all"
                    eventHandler={() => deselectAllRelatedItems(permission)}
                    label="Deselect All"
                    mode="gray"
                  />
                </S.Buttons>
              </S.RelatedWrapper>
            }
            checkboxChecked={checkedItems.includes(permission.value)}
            checkboxId={permission.value}
            checkboxOnChange={() => handleCheckboxHeadingToggle(permission.value)}
            className={permission.related.length ? "" : "acordion"}
            headerContent={
              <S.HeadingContainer className={checkedItems.includes(permission.value) ? "" : "disabled"}>
                {permission.label}
              </S.HeadingContainer>
            }
            isOpen={openAccordions[permission.value] || false}
            onToggle={() => handleAccordionToggle(permission.value)}
          />
        ))}
      </S.PermissionsWrapper>
      <S.ButtonsContainer>
        <PrimaryButton eventHandler={handleBackStep} label="Back" mode="gray" />
        <PrimaryButton eventHandler={handleSubmit} label="Submit" loading={isLoading(createProfileStatus)} />
      </S.ButtonsContainer>
    </S.Content>
  );
};

export default Step2;
