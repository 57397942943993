import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { singleDashboardApi } from "./services";

export const getSingleDashboardList = createAsyncThunk(
  `${SLICE_NAME.singleDashboardSlice}/getSingleDashboardList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await singleDashboardApi.getSingleDashboardList(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
