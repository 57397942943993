import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { hearingDatesListApi } from "./services";

export const getHearingDatesList = createAsyncThunk(
  `${SLICE_NAME.hearingDatesSlice}/getHearingDatesList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await hearingDatesListApi.getHearingDates(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postUploadHearingFile = createAsyncThunk(
  `${SLICE_NAME.hearingDatesSlice}/postUploadHearingFile`,
  async (body, { rejectWithValue }) => {
    try {
      await hearingDatesListApi.postUploadHearingFile(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCSV = createAsyncThunk(
  `${SLICE_NAME.hearingDatesSlice}/exportCSV`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await hearingDatesListApi.exportCSV(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postUploadHearingComment = createAsyncThunk(
  `${SLICE_NAME.hearingDatesSlice}/postUploadHearingComment`,
  async (data, { rejectWithValue }) => {
    try {
      await hearingDatesListApi.postUploadHearingComment(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUpcomingHearings = createAsyncThunk(
  `${SLICE_NAME.hearingDatesSlice}/getUpcomingHearings`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await hearingDatesListApi.getUpcomingHearings();
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postHearingsComplete = createAsyncThunk(
  `${SLICE_NAME.hearingDatesSlice}/postHearingsComplete`,
  async (body, { rejectWithValue }) => {
    try {
      await hearingDatesListApi.postHearingsComplete(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
