import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton, PrimaryInput, PrimaryTextarea } from "common";
import { agentsSelector } from "store/admin-slices/agents-slice/selectors";
import { agentsActions } from "store/admin-slices/agents-slice/slice";
import { isEmpty } from "utils/helpers";
import { createProfileSchema } from "validations";

import * as S from "./styled";

const Step1 = () => {
  const { createProfileStep1Data } = useSelector(agentsSelector);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: yupResolver(createProfileSchema),
    mode: "onBlur",
  });

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(agentsActions.setCreateProfileStep1Data(data));
    dispatch(agentsActions.nextStep());
  };

  useEffect(() => {
    if (!isEmpty(createProfileStep1Data)) {
      setValue("name", createProfileStep1Data.name);
      setValue("email", createProfileStep1Data.email);
      setValue("password", createProfileStep1Data.password);
      setValue("password_confirmation", createProfileStep1Data.password_confirmation);
      setValue("role_description", createProfileStep1Data.role_description);
    }
  }, [createProfileStep1Data]);

  return (
    <>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <PrimaryInput error={errors.name} placeholder="Full Name" register={register("name")} />
        <PrimaryInput error={errors.email} placeholder="Email" register={register("email")} />
        <PrimaryInput isPasswordInput error={errors.password} placeholder="Password" register={register("password")} />
        <PrimaryInput
          isPasswordInput
          error={errors.password_confirmation}
          placeholder="Confirm Password"
          register={register("password_confirmation")}
        />
        <PrimaryTextarea
          error={errors.role_description}
          placeholder="Role description"
          register={register("role_description")}
        />
        <PrimaryButton className="continue-button" disabled={!isValid} label="Continue" />
      </S.Form>
    </>
  );
};

export default Step1;
