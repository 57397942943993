import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const EvidenceContainer = styled.div`
  width: 100%;
  height: 90vh;
  max-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  svg {
    cursor: pointer;
  }
`;

export const EvidenceTitle = styled.div`
  font-size: 24px;
  color: ${COLORS.deepDark};
  margin: 10px;
`;

export const PaginatorWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
  justify-content: center;
`;

export const Box = styled.div`
  &.flex-center {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    flex-wrap: wrap;
  }
`;

export const FileInput = styled.input`
  display: none;
`;
