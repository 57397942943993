import { useDispatch } from "react-redux";
import { singleEvidenceActions } from "store/admin-slices/single-evidence-slice/slice";

import * as S from "./styled";

export const SettingsModal = () => {
  const dispatch = useDispatch();
  return (
    <S.Container onClick={() => dispatch(singleEvidenceActions.toggleSettingsModal())}>
      <S.Content></S.Content>
    </S.Container>
  );
};
