import { COLORS } from "assets/styles/colors";
import { SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  .delete-modal {
    color: ${COLORS.red};
  }
`;

export const Content = styled(SinglePageContentShadow)``;

export const Link = styled.a`
  color: ${COLORS.green};
  text-decoration: underline;
`;

export const RemoveContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    cursor: pointer;
    width: 15px;
    fill: ${COLORS.dimgrey};
  }

  .select-button {
    background-color: ${COLORS.grey};
    color: ${COLORS.dark};
  }
`;

export const RemoveButtonContainer = styled.div``;

export const ArchiveContainer = styled.div`
  svg {
    cursor: pointer;
    fill: ${COLORS.dimgrey};
  }
`;

export const ContentHeader = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
`;

export const AccountNumberContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  svg {
    height: 18px;
    cursor: pointer;
  }
`;

export const StreetAddressContainer = styled(AccountNumberContainer)``;

export const AccountNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 60px;
`;

export const StreetAddress = styled(AccountNumber)``;

export const AccountNumberErrorMessage = styled.div`
  color: ${COLORS.red};
  font-size: 12px;
`;

export const StreetAddressErrorMessage = styled.div`
  color: ${COLORS.red};
  font-size: 12px;
`;

export const AccountNumberInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StreetAddressInputContainer = styled(AccountNumberInputContainer)``;
