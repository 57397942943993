import axios from "axios";
import { API } from "configs/api";

export const singleDocumentsListApi = {
  getSingleAoaDocuments: ({ id, ...params }) =>
    axios.get(`${API.documentsAoa}/${id}`, {
      token: true,
      params,
    }),
  getSingleSaDocuments: ({ id, ...params }) =>
    axios.get(`${API.documentsSa}/${id}`, {
      token: true,
      params,
    }),
  putAoaFileName: (id, params) =>
    axios.put(
      `${API.docs}/${id}`,
      { filename: params },
      {
        token: true,
      }
    ),

  putSaFileName: (id, params) =>
    axios.put(
      `${API.docs}/${id}`,
      { filename: params },
      {
        token: true,
      }
    ),
  uploadAoaDoc: (body) => axios.post(`${API.admin}${API.aoaDocs}`, body, { token: true, formData: true }),
  uploadSaDoc: (body) => axios.post(`${API.admin}${API.saDocs}`, body, { token: true, formData: true }),
  getTracker: ({ id, params }) => axios.get(`${API.documents}${API.actions}/${id}`, { token: true, params }),
  exportCSV: (params) =>
    axios.get(`${API.documents}${API.action}${API.exportCSV}`, {
      token: true,
      params,
      responseType: "blob",
    }),
};
