import { styled } from "styled-components/macro";

export const AccordionContainer = styled.div`
  .rotate {
    transform: rotate(180deg);
  }
`;

export const AccordionHeaderContainer = styled.div`
  display: flex;
`;
