import { useSelector } from "react-redux";
import { Document, PDFViewer } from "@react-pdf/renderer";
import { PrimaryButton } from "common";
import { signupSelectors } from "store/signup-slice/selectors";

import { AoAPage } from "./Documents/AoAPage";
import { AoAPage2 } from "./Documents/AoAPage2";
import { SaPage } from "./Documents/SAPage";
import * as S from "./styled";

export const ViewPdfModal = ({ signType, toggleModal, firstProp }) => {
  const { properties, ownerData, signature } = useSelector(signupSelectors);

  return (
    <S.ViewPdfModalContainer onClick={toggleModal}>
      <S.ViewPdfModalContent onClick={(e) => e.stopPropagation()}>
        <PDFViewer className="pdf-viewer">
          <Document>
            <AoAPage firstProp={firstProp} owner={ownerData} properties={properties.slice(0, 4)} />
            {properties.length > 4 && (
              <AoAPage firstProp={firstProp} owner={ownerData} properties={properties.slice(4)} />
            )}
            <AoAPage2 firstProp={firstProp} owner={ownerData} signature={signature} signType={signType} />
            <SaPage owner={ownerData} signature={signature} signType={signType} />
          </Document>
        </PDFViewer>
        <PrimaryButton eventHandler={toggleModal} label="ok" />
      </S.ViewPdfModalContent>
    </S.ViewPdfModalContainer>
  );
};
