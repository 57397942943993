import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 6px;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px 0 #00000026, 0 1px 5px 0 #00000026;
`;

export const Box = styled.div`
  width: 48%;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  font-weight: bold;
`;
export const Text = styled.div``;

export const Description = styled.div`
  display: flex;
`;
