import { PrimaryButton } from "common/PrimaryButton";

import * as S from "./styled";

export const Modal = ({
  isModalOpen,
  title,
  description,
  successButtonText,
  cancelButtonText,
  onSuccess,
  onCancel,
  successLoadingStatus,
  className,
  children,
}) => {
  return (
    <>
      {isModalOpen ? (
        <S.Modal className={className}>
          <S.ModalContent>
            {title ? <S.Heading>{title}</S.Heading> : null}
            {description ? <S.Text>{description}</S.Text> : null}
            {children}
            {successButtonText || cancelButtonText ? (
              <S.ConfirmContent>
                {successButtonText ? (
                  <PrimaryButton eventHandler={onSuccess} label={successButtonText} loading={successLoadingStatus} />
                ) : null}
                {cancelButtonText ? (
                  <PrimaryButton eventHandler={onCancel} label={cancelButtonText} mode="gray" />
                ) : null}
              </S.ConfirmContent>
            ) : null}
          </S.ModalContent>
        </S.Modal>
      ) : null}
    </>
  );
};
