import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100vh;
  background-color: ${COLORS.overlay_200};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Content = styled.div`
  padding: 40px 60px;
  background-color: ${COLORS.white};
  animation: 0.6s containerAnimation linear;

  @keyframes containerAnimation {
    0% {
      padding-inline: 0;
      padding-block: 4px;
    }
    50% {
      padding-inline: 60px;
      padding-block: 4px;
    }
    100% {
      padding-block: 40px;
    }
  }
`;
