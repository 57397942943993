export const PropertiesIcon = (props) => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_346_1908)" id="Frame">
      <g id="Group">
        <g id="Layer 2">
          <g id="InterfaceIcon">
            <g id="01.Home">
              <path
                d="M24 17.8144V6.18563C24 2.7694 21.2306 0 17.8144 0H6.18563C2.7694 0 0 2.7694 0 6.18563V17.8144C0 21.2306 2.7694 24 6.18563 24H17.8144C21.2306 24 24 21.2306 24 17.8144Z"
                fill="#437F55"
                id="Background"
              />
              <path
                d="M24.0001 15.9745V17.8125C24.0001 19.4535 23.3482 21.0274 22.1878 22.1877C21.0274 23.3481 19.4536 24 17.8126 24H12.6367L6.7065 18.0699L7.299 14.13L5.27588 12.1069L18.6821 10.6584L24.0001 15.9745Z"
                fill="black"
                id="Vector"
                opacity="0.25"
              />
              <path
                d="M18.6839 10.6585L13.2347 6.0938C12.8886 5.80359 12.4514 5.64453 11.9998 5.64453C11.5481 5.64453 11.1109 5.80359 10.7648 6.0938L5.31609 10.6585C4.62468 11.2379 5.03484 12.3647 5.93625 12.3647H6.43031V17.3958C6.43031 17.6508 6.5316 17.8953 6.7119 18.0756C6.8922 18.2559 7.13673 18.3572 7.39171 18.3572H10.1461V15.1079C10.146 14.8644 10.1939 14.6233 10.2871 14.3983C10.3802 14.1734 10.5168 13.969 10.6889 13.7968C10.8611 13.6247 11.0655 13.4881 11.2905 13.3949C11.5154 13.3018 11.7565 13.2539 12 13.254C12.2435 13.254 12.4845 13.3019 12.7095 13.3951C12.9344 13.4882 13.1388 13.6248 13.3109 13.797C13.4831 13.9691 13.6196 14.1735 13.7128 14.3984C13.8059 14.6233 13.8539 14.8644 13.8539 15.1079V18.3572H16.6083C16.8633 18.3572 17.1078 18.2559 17.2881 18.0756C17.4684 17.8953 17.5697 17.6508 17.5697 17.3958V12.3647H18.0633C18.9656 12.3647 19.3753 11.2379 18.6839 10.6585V10.6585Z"
                fill="white"
                id="01.Home_2"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_346_1908">
        <rect fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </svg>
);
