import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "common";
import { referralsSelectors } from "store/referral-slice/selectors";
import { referralActions } from "store/referral-slice/slice";
import { LOADING_STATUSES, SIGN_UP_REFERRAL_CODE_LINK } from "utils/constants";

import GetReferralCodeSection from "./GetReferralCodeSection";
import SendReferralEmailSection from "./SendReferralEmailSection";
import * as S from "./styled";

export const ReferralPage = () => {
  const { referralCode, postReferralCodeStatus } = useSelector(referralsSelectors);

  const referralLinkRef = useRef();
  const referralCodeRef = useRef();

  const dispatch = useDispatch();

  const copyText = async (ref) => {
    const textToCopy = ref.current.innerText;

    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (error) {
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    }
  };

  useEffect(() => {
    return () => dispatch(referralActions.resetAllData());
  }, []);

  return (
    <S.ReferralWrapper>
      <S.ReferralContainer>
        <S.ReferralTitle>Save 10% on Each Referral!</S.ReferralTitle>
        <S.ReferralText>
          Refer friends, family, neighbors by sharing your referral code, which you can find by typing in your email
          address below (after signing up). For each referral who signs up using your code, you and your referral will
          save an additional 10% on your successful protest this year.
        </S.ReferralText>
        <S.ReferralText>
          The more friends you refer, the bigger the rewards get! For each successful referral, you'll receive an
          additional 10% discount. So, for every friend who joins through your recommendation, your discount grows.
        </S.ReferralText>
        <S.ReferralText>
          The ultimate goal? Reach 10 referrals and you'll receive a 100% discount! Yes, you read that right - a
          completely FREE protest!
        </S.ReferralText>
        <S.ReferralEmailTitle>Enter your email to look up your referral code:</S.ReferralEmailTitle>
        <GetReferralCodeSection />
        {referralCode ? (
          <S.ReferralCode>
            <S.ReferralSpan ref={referralCodeRef} id="referralCode">
              {referralCode}
            </S.ReferralSpan>
          </S.ReferralCode>
        ) : null}
        {postReferralCodeStatus === LOADING_STATUSES.succeeded ? (
          <S.ShareViaEmailBlock>
            <S.ReferralEmailTitle>Share Via Email</S.ReferralEmailTitle>
            <S.ReferralText>
              Invite people to sign up by entering their emails. (We'll automatically add your referral link) Enter one
              email at a time and click "Send".
            </S.ReferralText>
            <SendReferralEmailSection />
            <S.ReferralEmailTitle>Share Your Referral Code</S.ReferralEmailTitle>
            <S.ReferralText>
              Easily send your referral code to friends, neighbors, and colleagues by copying the code below. Those you
              refer will also receive a 10% discount on our services this year. You are saving them money!
            </S.ReferralText>
            <PrimaryButton
              className="email-button"
              eventHandler={() => copyText(referralCodeRef)}
              label="COPY REFERRAL CODE"
            />
            <S.ReferralText className="bottom-side-text">
              Gain referrals by sharing your personal referral link with others:
              <S.ReferralSpan ref={referralLinkRef} id="referralText">
                {`${SIGN_UP_REFERRAL_CODE_LINK}${referralCode ? referralCode : ""}`}
              </S.ReferralSpan>
            </S.ReferralText>
            <PrimaryButton
              className="email-button"
              eventHandler={() => copyText(referralLinkRef)}
              label="COPY REFERRAL LINK"
            />
          </S.ShareViaEmailBlock>
        ) : null}
      </S.ReferralContainer>
    </S.ReferralWrapper>
  );
};
