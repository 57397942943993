import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink, Outlet, useLocation } from "react-router-dom";
import { Icons } from "assets/svgs";
import { adminRoutes } from "router/routes";
import { getUsersInfo } from "store/admin-slices/signin-slice/actions";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { getStorage } from "utils/helpers";

import NavigationBar from "components/Admin/NavigationBar";

import {
  AdminHeader,
  AdminLayoutContainer,
  AdminNavigationBar,
  AvatarContainer,
  AvatarIconContainer,
  RightSectionContainer,
  UserName,
} from "./styled";

export const AdminLayout = () => {
  const { adminSignInResponse, userInfo } = useSelector(adminSignInSelectors);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (getStorage("credentials") && !adminSignInResponse?.access_token) {
      dispatch(getUsersInfo());
    }
  }, []);

  if (!userInfo && getStorage("credentials")) {
    return null;
  }

  if (!userInfo) {
    return <Navigate state={{ from: location }} to={adminRoutes.signIn} />;
  }

  return (
    <AdminLayoutContainer>
      <AdminNavigationBar>
        <NavigationBar />
      </AdminNavigationBar>
      <RightSectionContainer>
        <AdminHeader>
          <NavLink to="/">PTP</NavLink>
          <AvatarContainer>
            <UserName>{userInfo.name}</UserName>
            <AvatarIconContainer>
              <Icons.AvatarIcon />
            </AvatarIconContainer>
          </AvatarContainer>
        </AdminHeader>
        <Outlet />
      </RightSectionContainer>
    </AdminLayoutContainer>
  );
};
