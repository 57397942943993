import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

const StyledMessage = styled.p`
  font-size: ${({ fontSize }) => fontSize}px;
  color: ${COLORS.red};
`;
export const ErrorMessage = ({ children, fontSize = 12 }) => (
  <StyledMessage fontSize={fontSize}>{children}</StyledMessage>
);
