import { COLORS } from "assets/styles/colors";
import { SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: calc(100vh - 70px);
  min-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 10px;

  svg {
    cursor: pointer;
  }

  .back-button {
    margin-bottom: 20px;
  }

  .approved {
    color: ${COLORS.green};
  }

  .not-approved {
    color: ${COLORS.red};
  }

  .delete-modal {
    color: ${COLORS.red};
  }
`;

export const EditEvidenceType = styled.div``;

export const Approved = styled.div``;

export const Content = styled(SinglePageContentShadow)``;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 20px;
  align-items: center;
  padding-top: 8px;
`;

export const RemoveContainer = styled.div`
  svg {
    cursor: pointer;
    width: 15px;
    fill: ${COLORS.dimgrey};
  }
`;
