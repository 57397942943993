import axios from "axios";
import { API } from "configs/api";

export const leadsListApi = {
  getLeads: (params) => axios.get(API.leads, { token: true, params }),
  exportCSV: (params) =>
    axios.get(API.leads + API.exportCSV, {
      token: true,
      params,
      responseType: "blob",
    }),
};
