import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icons } from "assets/svgs";
import { PrimaryButton, PrimaryInput, Table } from "common";
import { Filter } from "pages/admin/components/Filter";
import { discountSelector } from "store/admin-slices/discount-slice/selectors";
import { discountActions } from "store/admin-slices/discount-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";
import { discountSchema } from "validations";

import { Modal } from "components/Modal";

import * as S from "./styled";

export const Discount = () => {
  const {
    isModalOpen,
    isEditModalOpen,
    getDiscountList,
    getDiscountStatus,
    postDiscountStatus,
    selectedDiscount,
    updateDiscountStatus,
    isDeleteModalOpen,
    selectedDiscountId,
    deleteDiscountStatus,
  } = useSelector(discountSelector);

  const {
    register,
    control,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(discountSchema),
    mode: "onBlur",
  });

  const { id } = useParams();

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setValue("reason", "");
    setValue("percent", "");
    dispatch(discountActions.setIsModalOpen(true));
  };

  const handleConfirm = (data) => {
    const body = new FormData();
    body.append("reason", data.reason);
    body.append("percent", data.percent);
    body.append("owner_id", id);
    dispatch(discountActions.postDiscount(body));
  };

  const handleCloseModal = () => {
    dispatch(discountActions.setIsModalOpen(false));
    clearErrors("reason");
    clearErrors("percent");
  };

  const handleCloseEditModal = () => {
    dispatch(discountActions.setIsEditModalOpen(false));
    clearErrors("reason");
    clearErrors("percent");
  };

  const handleDeleteDiscount = () => {
    dispatch(discountActions.deleteDiscount(selectedDiscountId));
  };

  const handleOpenDeleteModal = (id) => {
    dispatch(discountActions.setSelectedDiscountId(id));
    dispatch(discountActions.setIsDeleteModalOpen(true));
  };

  const handleEditDiscount = (item) => {
    dispatch(discountActions.setIsEditModalOpen(true));
    dispatch(discountActions.setSelectedDiscount(item));
    setValue("reason", item?.reason);
    setValue("percent", item?.percent);
  };

  const handleConfirmEditDiscount = (data) => {
    const body = {
      id: selectedDiscount.id,
      reason: data.reason,
      percent: data.percent,
    };
    dispatch(discountActions.updateDiscount(body));
  };

  const tableData = useMemo(
    () => [
      {
        body: (item) => <Icons.EditIcon onClick={() => handleEditDiscount(item)} />,
        header: "Actions",
      },
      { field: "reason", header: "Reason" },
      { field: "percent", header: "Percentage" },
      { field: "updated_at", header: "Last Update" },
      { field: "created_at", header: "Creation Date" },
      {
        header: "Remove",
        body: ({ id }) => (
          <S.RemoveContainer>
            <Icons.TrashIcon onClick={() => handleOpenDeleteModal(id)} />
          </S.RemoveContainer>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (postDiscountStatus === LOADING_STATUSES.succeeded) {
      dispatch(discountActions.setIsModalOpen(false));
      reset();
    }
  }, [postDiscountStatus]);

  useEffect(() => {
    if (updateDiscountStatus === LOADING_STATUSES.succeeded) {
      dispatch(discountActions.setIsEditModalOpen(false));
    }
  }, [updateDiscountStatus]);

  useEffect(() => {
    if (deleteDiscountStatus === LOADING_STATUSES.succeeded) {
      dispatch(discountActions.setIsDeleteModalOpen(false));
    }
  }, [deleteDiscountStatus]);

  useEffect(() => {
    dispatch(discountActions.getOwnerDiscount({ owner_id: id }));
  }, [id]);

  useEffect(() => {
    return () => dispatch(discountActions.resetAllData());
  }, []);

  return (
    <S.DiscountWrapper>
      <S.ContentTitle>Discount</S.ContentTitle>
      <Filter>
        <PrimaryButton eventHandler={handleOpenModal} label="Add Discount" mode="gray" />
      </Filter>
      <Modal isModalOpen={isModalOpen}>
        <S.Form onSubmit={handleSubmit(handleConfirm)}>
          <PrimaryInput error={errors.reason} placeholder="Reason" register={register("reason")} />
          <Controller
            control={control}
            name="percent"
            render={({ field: { value, onChange } }) => (
              <PrimaryInput
                error={errors.percent}
                placeholder="Percent"
                value={value}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  onChange(e);
                }}
              />
            )}
          />
          <S.ButtonsContainer>
            <PrimaryButton label="Submit" loading={isLoading(postDiscountStatus)} />
            <PrimaryButton eventHandler={handleCloseModal} label="Cancel" mode="gray" />
          </S.ButtonsContainer>
        </S.Form>
      </Modal>
      <Modal isModalOpen={isEditModalOpen}>
        <S.Form onSubmit={handleSubmit(handleConfirmEditDiscount)}>
          <PrimaryInput error={errors.reason} placeholder="Reason" register={register("reason")} />
          <Controller
            control={control}
            name="percent"
            render={({ field: { value, onChange } }) => (
              <PrimaryInput
                error={errors.percent}
                placeholder="Percent"
                value={value}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  onChange(e);
                }}
              />
            )}
          />
          <S.ButtonsContainer>
            <PrimaryButton label="Edit" loading={isLoading(updateDiscountStatus)} />
            <PrimaryButton eventHandler={handleCloseEditModal} label="Cancel" mode="gray" />
          </S.ButtonsContainer>
        </S.Form>
      </Modal>
      <Table
        dataKey="created_at"
        loading={isLoading(getDiscountStatus)}
        tableData={tableData}
        value={getDiscountList || []}
      />
      <Modal
        cancelButtonText="no"
        className="delete-modal"
        description="Are you sure ?"
        isModalOpen={isDeleteModalOpen}
        successButtonText="yes"
        successLoadingStatus={isLoading(deleteDiscountStatus)}
        onCancel={() => dispatch(discountActions.setIsDeleteModalOpen(false))}
        onSuccess={handleDeleteDiscount}
      />
    </S.DiscountWrapper>
  );
};
