import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { PrimaryButton } from "common";
import { routes } from "router/routes";
import { signupActions } from "store/signup-slice/slice";
import { getStorage, toggleClassOnRoot } from "utils/helpers";

import * as S from "./styled";

export const SuccessModal = () => {
  const navigate = useNavigate();
  const [firstAnimation, setFirstAnimation] = useState(true);
  const [secondAnimation, setSecondAnimation] = useState(false);
  const ownerId = getStorage("owner_id");
  const properties = getStorage("properties");
  const isAddedManually = getStorage("is_added_manually");

  const [queryParameter] = useSearchParams();
  const isSuccess = queryParameter.get("success");
  const ownerData = getStorage("owner_data");

  const dispatch = useDispatch();

  const location = useLocation();
  const navigateHome = () => navigate(routes.home);

  const navigateSignup = () => {
    navigate(routes.signup);
    dispatch(signupActions.changeStep(1));
  };

  const navigateSubmitEvidence = () => navigate(routes.submitEvidence, { state: location.pathname });

  const handleAnimate = () => setSecondAnimation(true);

  const handleAnimationEnd = () => {
    setFirstAnimation(false);
    setSecondAnimation(false);
  };
  window.dataLayer.push({
    event: "user_email",
    email: ownerData?.email,
  });

  useEffect(() => {
    if (isSuccess) {
      toggleClassOnRoot();
      properties.forEach((prop) => {
        dispatch(
          signupActions.connectOwnerProperty({
            owner_id: ownerId,
            property_id: prop.id,
            is_completed: 1,
            unit_id: prop.unit,
            is_manually: isAddedManually ? 0 : 1,
          })
        );
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    return () => {
      toggleClassOnRoot();
      dispatch(signupActions.resetErrorMessages());
      dispatch(signupActions.resetStepTwoData());
    };
  }, []);

  return (
    <S.Container onClick={handleAnimate}>
      <S.Content
        className={`${secondAnimation ? "second-animation" : ""} ${firstAnimation ? "first-animation" : ""}`}
        onAnimationEnd={handleAnimationEnd}
        onClick={(e) => e.stopPropagation()}
      >
        <S.BoxCenter>
          <S.IconWrapper>
            <Icons.CheckIcon size={45} />
          </S.IconWrapper>
        </S.BoxCenter>
        <S.BoxCenter>
          <S.Description id="success-message">Congratulations, you have successfully registered!</S.Description>
        </S.BoxCenter>
        <S.BoxCenter>
          <S.Description>
            We have sent you a confirmation email to <span id="owner-email">{ownerData?.email}</span> Please check your
            inbox to ensure you have received it. If you have any questions please contact us at
            info@propertytaxprotest.com Thank you!
          </S.Description>
        </S.BoxCenter>
        <S.BoxCenter>
          <S.ButtonsWrapper>
            <PrimaryButton
              eventHandler={navigateSubmitEvidence}
              label="submit evidence"
              styles={{
                backgroundColor: COLORS.deepDark,
                height: 50,
              }}
            />
            <PrimaryButton
              eventHandler={navigateSignup}
              label="add more properties"
              styles={{
                backgroundColor: COLORS.deepDark,
                height: 50,
              }}
            />
            <PrimaryButton
              eventHandler={navigateHome}
              label="exit"
              styles={{
                backgroundColor: COLORS.white,
                color: COLORS.dark,
                border: `1px solid ${COLORS.dark}`,
                height: 50,
              }}
            />
          </S.ButtonsWrapper>
        </S.BoxCenter>
      </S.Content>
    </S.Container>
  );
};
