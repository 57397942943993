import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "common";
import { adminRoutes } from "router/routes";
import { archivesListSelectors } from "store/admin-slices/archived-slice/selectors";
import { archiveActions } from "store/admin-slices/archived-slice/slice";
import { singleArchivedSelectors } from "store/admin-slices/single-archived-slice/selectors";
import { isLoading, LOADING_STATUSES } from "utils/constants";

import { Modal } from "components/Modal";

import * as S from "./styled";

export const UnArchiveArchived = () => {
  const { singleArchived, selectedPropertyId } = useSelector(singleArchivedSelectors);

  const { unarchivePropertyStatus, isOpenModal } = useSelector(archivesListSelectors);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleUnarchiveProperty = () => {
    const params = {
      owner_id: singleArchived?.id,
      property_id: selectedPropertyId,
    };

    dispatch(archiveActions.unarchiveProperty(params));
  };

  useEffect(() => {
    if (unarchivePropertyStatus === LOADING_STATUSES.succeeded) {
      navigate(`${adminRoutes.prefix}/${adminRoutes.archived}`);
    }
  }, [unarchivePropertyStatus]);

  return (
    <S.ArchiveOwnerContent>
      <S.ContentTitle>Unarchive Owner</S.ContentTitle>

      <Modal
        cancelButtonText="no"
        className="delete-modal"
        description="Are you sure ?"
        isModalOpen={isOpenModal}
        successButtonText="yes"
        successLoadingStatus={isLoading(unarchivePropertyStatus)}
        onCancel={() => dispatch(archiveActions.setIsOpenModal(false))}
        onSuccess={handleUnarchiveProperty}
      />

      <PrimaryButton
        className="archive-owner-button"
        eventHandler={() => dispatch(archiveActions.setIsOpenModal(!isOpenModal))}
        label="unarchive owner"
        mode="gray"
      />
    </S.ArchiveOwnerContent>
  );
};
