import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Can } from "@casl/react";
import { PrimaryButton, PrimaryInput, Table } from "common";
import { usePermissions } from "hooks/usePermissions";
import { Filter } from "pages/admin/components/Filter";
import { Paginator } from "primereact/paginator";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { singleAgentSelector } from "store/admin-slices/single-agent-slice/selectors";
import { singleAgentActions } from "store/admin-slices/single-agent-slice/slice";
import { isLoading, LOADING_STATUSES, PERMISSIONS_LIST } from "utils/constants";
import { downloadFileByLink, getPaginationDescription } from "utils/helpers";

import * as S from "./styled";

const tableData = [
  { headerClassName: "column-header", selectionMode: "multiple" },
  {
    header: "Name",
    field: "name",
  },
  {
    header: "Email",
    field: "email",
  },
  {
    header: "Tab",
    field: "tab",
  },
  {
    header: "Description",
    field: "description",
  },
  {
    header: "Owner Name",
    field: "owner_name",
  },
  {
    header: "Owner Email",
    field: "owner_email",
  },
  {
    header: "Creation Date",
    field: "created_at",
    sortable: true,
  },
];

export const TrackerTable = ({
  onPageChange,
  setSortData,
  sortData,
  setStartDate,
  setEndDate,
  debouncedEndDate,
  debouncedStartDate,
}) => {
  const { last_page, paginationData, trackerData, exportCSVStatus, downloadCsvHref, getTrackerDataStatus } =
    useSelector(singleAgentSelector);
  const { userInfo } = useSelector(adminSignInSelectors);

  const [selectedSelection, setSelectedSelection] = useState([]);
  const [paginationChange, setPaginationChange] = useState({ prev: paginationData, new: paginationData });

  const dispatch = useDispatch();
  const ref = useRef();

  const { id } = useParams();

  const ability = usePermissions(userInfo.permissions);

  const onSort = (e) => setSortData({ field: e.sortField, direction: e.sortOrder });

  const downloadCsv = () => {
    let actions = selectedSelection?.map((prop) => prop.id);
    actions = actions?.filter((id, index, self) => self.indexOf(id) === index);
    const params = {
      start_date: debouncedStartDate,
      end_date: debouncedEndDate,
      user_id: id,
      actions,
    };
    dispatch(singleAgentActions.exportCSV(params));
  };

  useEffect(() => {
    downloadFileByLink(exportCSVStatus, downloadCsvHref, "agent's-action-tracking.csv");
  }, [downloadCsvHref]);

  useEffect(() => {
    setPaginationChange({ prev: paginationChange.new, new: paginationData });

    if (
      (paginationChange.prev?.page !== paginationChange.new?.page ||
        paginationChange.prev?.rows !== paginationChange.new?.rows) &&
      getTrackerDataStatus === LOADING_STATUSES.succeeded
    ) {
      ref.current.scrollIntoView({ behavior: "auto" });
    }
  }, [paginationData, getTrackerDataStatus]);

  return (
    <S.Content>
      <S.ContentTitle>Action Tracker</S.ContentTitle>
      <Filter>
        <PrimaryInput placeholder="Start date" type="datetime-local" onChange={(e) => setStartDate(e.target.value)} />
        <PrimaryInput placeholder="End date" type="datetime-local" onChange={(e) => setEndDate(e.target.value)} />
        <Can ability={ability} I={PERMISSIONS_LIST.downloadActionCSV}>
          <PrimaryButton
            eventHandler={downloadCsv}
            label="Download CSV"
            loading={isLoading(exportCSVStatus)}
            mode="gray"
          />
        </Can>
      </Filter>
      <Table
        dataKey="id"
        loading={isLoading(getTrackerDataStatus)}
        selection={selectedSelection}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={trackerData || []}
        onSelectionChange={(e) => setSelectedSelection(e.value)}
        onSort={onSort}
      />
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[10, 50, 100, 200]}
          totalRecords={last_page}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.Content>
  );
};
