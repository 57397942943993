import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Can } from "@casl/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { COLORS } from "assets/styles/colors";
import { Icons } from "assets/svgs";
import { Loader, PrimaryButton, Table } from "common";
import { usePermissions } from "hooks/usePermissions";
import { singleDocumentSelectors } from "store/admin-slices/documents-single-slice/selectors";
import { documentsSingleActions } from "store/admin-slices/documents-single-slice/slice";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { DOCUMENT_TYPE, isLoading, LOADING_STATUSES, PERMISSIONS_LIST } from "utils/constants";
import { singleDocumentsSchema } from "validations/singleDocumentsSchema";

import { Template } from "components/Admin/TableRowCustomTemplate";

import * as S from "./styled";

export const SingleDocumentsTable = ({
  type,
  documents,
  getSingleDocumentsStatus,
  putFileNameStatus,
  tableHeader,
  putDocumentsFileName,
  uploadButtonLabel,
  permissions,
}) => {
  const { userInfo } = useSelector(adminSignInSelectors);
  const { uploadAoaDocumentStatus, uploadSaDocumentStatus } = useSelector(singleDocumentSelectors);

  const [selectedId, setSelectedId] = useState(null);

  const pdfInputRef = useRef();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(singleDocumentsSchema),
  });

  const { document } = watch();

  const ability = usePermissions(userInfo.permissions);

  const tableData = useMemo(
    () => [
      {
        field: "download_url",
        header: tableHeader,
        sortable: true,
        body: (item) => Template.DocumentsName(item, selectedId, register, errors),
      },
      {
        header: "Actions",
        body: (item) => {
          return (
            <>
              {!item?.is_user_generated && (
                <>
                  {item.id === selectedId && isLoading(putFileNameStatus) ? (
                    <Loader />
                  ) : item.id === selectedId ? (
                    <Icons.CheckIcon color={COLORS.green} onClick={handleSubmit(handleSaveDocs)} />
                  ) : (
                    <Icons.EditIcon onClick={() => handleEditDocs(item)} />
                  )}
                </>
              )}
            </>
          );
        },
      },
    ],
    [setSelectedId, selectedId, document, putFileNameStatus, documents]
  );

  const rowClassName = (row) => {
    return row?.is_user_generated ? "selected" : "";
  };

  const handleEditDocs = (item) => {
    setSelectedId(item.id);
    setValue("document", item.filename ?? item.download_url);
  };

  const handleSaveDocs = () => {
    if (selectedId && document) {
      dispatch(putDocumentsFileName({ selectedId, value: watch().document }));
    }
  };

  const handleSelectFile = () => pdfInputRef.current?.click();

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const formData = new FormData();
    formData.append("send_email", 0);
    formData.append("owner_id", documents[0].owner_id);

    if (type === DOCUMENT_TYPE.SA) {
      formData.append("docs_sa", file);
      dispatch(documentsSingleActions.uploadSaDocument(formData));
    } else {
      formData.append("docs_aoa", file);
      dispatch(documentsSingleActions.uploadAoaDocument(formData));
    }
  };

  useEffect(() => {
    if (putFileNameStatus === LOADING_STATUSES.succeeded) {
      setSelectedId(null);
    }
  }, [putFileNameStatus]);

  return (
    <S.Container>
      <S.Content>
        <Table
          paginator
          dataKey="id"
          loading={getSingleDocumentsStatus !== LOADING_STATUSES.succeeded}
          rowClassName={rowClassName}
          rows={10}
          tableData={tableData}
          value={documents ?? []}
        />
        <S.FooterContainer>
          <Can ability={ability} I={permissions ? PERMISSIONS_LIST.uploadNewAoa : PERMISSIONS_LIST.uploadNewSa}>
            <S.UploadButton>
              <S.PdfInput ref={pdfInputRef} accept="application/pdf" type="file" onChange={handleFileChange} />
              <PrimaryButton
                eventHandler={handleSelectFile}
                label={uploadButtonLabel}
                loading={
                  DOCUMENT_TYPE.AOA === type ? isLoading(uploadAoaDocumentStatus) : isLoading(uploadSaDocumentStatus)
                }
                mode="gray"
                styles={{ width: 300 }}
              />
            </S.UploadButton>
          </Can>
        </S.FooterContainer>
      </S.Content>
    </S.Container>
  );
};
