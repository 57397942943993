import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME, TOAST_TYPES } from "utils/constants";
import { toastify } from "utils/helpers";
import { v4 as uuid } from "uuid";

import { exportCsv, getEvidences, importAccounts, massPrint, printProperty } from "./actions";

const initialState = {
  last_page: 0,
  evidences: [],
  downloadCsvHref: null,
  massPrintDownloadHref: null,
  printPropertyDownloadHref: null,
  allowScroll: false,
  evidenceListFilters: {},
  getEvidencesStatus: LOADING_STATUSES.idle,
  importAccountsStatus: LOADING_STATUSES.idle,
  exportCsvStatus: LOADING_STATUSES.idle,
  massPrintStatus: LOADING_STATUSES.idle,
  printPropertyStatus: LOADING_STATUSES.idle,
};

export const evidenceSlice = createSlice({
  name: SLICE_NAME.evidenceSlice,
  initialState,
  reducers: {
    resetPrintProperty: (state) => {
      state.printPropertyStatus = LOADING_STATUSES.idle;
      state.printPropertyDownloadHref = null;
    },
    setEvidenceListFilters: (state, { payload }) => {
      state.evidenceListFilters = payload;
    },
    setAllowScroll: (state, { payload }) => {
      state.allowScroll = payload;
    },
    resetAllData: (state, { payload }) => {
      if (!payload) {
        return {
          ...initialState,
          evidenceListFilters: state.evidenceListFilters,
        };
      } else {
        return initialState;
      }
    },
  },
  extraReducers: ({ addCase }) => {
    // get evidences
    addCase(getEvidences.pending, (state) => {
      state.getEvidencesStatus = LOADING_STATUSES.pending;
    });
    addCase(getEvidences.fulfilled, (state, { payload }) => {
      state.evidences = payload?.data.map((item) => ({
        ...item,
        uniqueId: uuid(),
      }));
      state.last_page = payload.meta.total;
      state.getEvidencesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getEvidences.rejected, (state) => {
      state.getEvidencesStatus = LOADING_STATUSES.pending;
    });

    // import accounts
    addCase(importAccounts.pending, (state) => {
      state.importAccountsStatus = LOADING_STATUSES.pending;
    });
    addCase(importAccounts.fulfilled, (state) => {
      toastify(TOAST_TYPES.success, "Accounts successfully imported");
      state.importAccountsStatus = LOADING_STATUSES.succeeded;
    });
    addCase(importAccounts.rejected, (state) => {
      state.importAccountsStatus = LOADING_STATUSES.failed;
    });

    // export csv
    addCase(exportCsv.pending, (state) => {
      state.exportCsvStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCsv.fulfilled, (state, { payload }) => {
      state.exportCsvStatus = LOADING_STATUSES.succeeded;
      state.downloadCsvHref = payload;
    });
    addCase(exportCsv.rejected, (state) => {
      state.exportCsvStatus = LOADING_STATUSES.failed;
    });

    // mass print
    addCase(massPrint.pending, (state) => {
      state.massPrintStatus = LOADING_STATUSES.pending;
    });
    addCase(massPrint.fulfilled, (state, { payload }) => {
      state.massPrintDownloadHref = payload;
      state.massPrintStatus = LOADING_STATUSES.succeeded;
    });
    addCase(massPrint.rejected, (state) => {
      state.massPrintStatus = LOADING_STATUSES.failed;
    });

    // print
    addCase(printProperty.pending, (state) => {
      state.printPropertyStatus = LOADING_STATUSES.pending;
    });
    addCase(printProperty.fulfilled, (state, { payload }) => {
      state.printPropertyDownloadHref = payload;
      state.printPropertyStatus = LOADING_STATUSES.succeeded;
    });
    addCase(printProperty.rejected, (state) => {
      state.printPropertyStatus = LOADING_STATUSES.failed;
    });
  },
});

export const evidenceReducer = evidenceSlice.reducer;

export const evidenceActions = {
  ...evidenceSlice.actions,
  exportCsv,
  getEvidences,
  importAccounts,
  massPrint,
  printProperty,
};
