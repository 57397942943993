import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminReloadButton, FilterMultiSelect, PrimaryButton, SearchInput, Table } from "common";
import { useDebounce } from "hooks";
import { Paginator } from "primereact/paginator";
import { adminRoutes } from "router/routes";
import { documentListSelectors } from "store/admin-slices/documents-slice/selectors";
import { documentsActions } from "store/admin-slices/documents-slice/slice";
import { LOADING_STATUSES, YEARS_FILTER } from "utils/constants";
import { downloadFileByLink, getPaginationDescription, snakeToCamel } from "utils/helpers";

import { Filter } from "components/Filter";

import * as S from "./styled";

export const DocumentsPage = () => {
  const {
    documentsList,
    last_page,
    paginationData,
    getDocumentsListStatus,
    documentsListFilters,
    downloadCsvHref,
    exportCSVStatus,
    allowScroll,
  } = useSelector(documentListSelectors);

  const [sortData, setSortData] = useState({
    field: "updated_at",
    direction: -1,
  });
  const [selectedYear, setSelectedYear] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 200);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const ref = useRef(null);

  const getDocumentList = (props) => {
    const params = {
      page: props?.page ?? paginationData.page,
      limit: props?.rows ?? paginationData.rows,
      search: debouncedSearchQuery,
      years: selectedYear,
      order_column: snakeToCamel(sortData.field),
      direction: sortData.direction === 1 ? "asc" : "desc",
    };
    dispatch(documentsActions.getDocumentsList(params));
  };

  const onPageChange = ({ first, rows, page }) => {
    dispatch(
      documentsActions.setPaginationData({
        first,
        rows,
        page: page + 1,
      })
    );

    getDocumentList({ page: page + 1, rows });
  };

  const resetData = () => {
    dispatch(documentsActions.setAllowScroll(false));
    setSortData({ field: "updated_at", direction: -1 });
    dispatch(documentsActions.setPaginationData({ first: 10, rows: 50, page: 1 }));
    setSelectedYear([]);
    setSearchQuery("");
  };

  const handleRowClick = (item) => {
    dispatch(documentsActions.setAllowScroll(false));
    navigate(`${adminRoutes.prefix}/${adminRoutes.documents}/${item.data.id}`);
  };

  const onSearch = (searchResult) => {
    setSearchQuery(searchResult);
  };

  const onSort = (e) => {
    setSortData({ field: e.sortField, direction: e.sortOrder });
  };

  const deSelectYears = () => {
    if (selectedYear?.length > 0) {
      setSelectedYear([]);
    } else {
      setSelectedYear(YEARS_FILTER.map((item) => item.value));
    }
  };

  const downloadCsv = () => {
    const params = {
      search: debouncedSearchQuery,
      years: selectedYear,
    };
    dispatch(documentsActions.exportCSV(params));
  };

  const tableData = useMemo(
    () => [
      { field: "sign_up_date", header: "Sign up date", sortable: true },
      { field: "email", header: "Email", sortable: true },
      { field: "full_name", header: "Name", sortable: true },
    ],
    []
  );

  useEffect(() => {
    getDocumentList();
    dispatch(
      documentsActions.setDocumentsListFilters({
        selectedYear: selectedYear,
        searchQuery: searchQuery,
        paginationData: paginationData,
      })
    );
  }, [debouncedSearchQuery, sortData, selectedYear, paginationData]);

  useEffect(() => {
    dispatch(documentsActions.setAllowScroll(false));
  }, [debouncedSearchQuery, sortData, selectedYear]);

  useEffect(() => {
    return () => dispatch(documentsActions.resetAllData());
  }, []);

  useEffect(() => {
    allowScroll && ref.current.scrollIntoView({ behavior: "auto" });
  }, [getDocumentsListStatus]);

  useEffect(() => {
    setSelectedYear(documentsListFilters.selectedYear);
    setSearchQuery(documentsListFilters.searchQuery || "");
    dispatch(documentsActions.setPaginationData(documentsListFilters.paginationData || paginationData));
  }, []);

  useEffect(() => {
    downloadFileByLink(exportCSVStatus, downloadCsvHref, "documents.csv");
  }, [downloadCsvHref]);

  return (
    <S.DocumentsContainer>
      <S.DocumentsTitle>Documents</S.DocumentsTitle>
      <AdminReloadButton onClick={resetData} />
      <Filter>
        <SearchInput
          className="search-input"
          placeholder="Search Client List"
          value={searchQuery}
          onSearch={onSearch}
        />
        <FilterMultiSelect
          buttonLabel="YEAR FILTER"
          options={YEARS_FILTER}
          selectedOptions={selectedYear}
          setSelectedOptions={setSelectedYear}
        />
        <PrimaryButton
          eventHandler={deSelectYears}
          label={(selectedYear?.length ? "DE" : "") + "SELECT ALL YEARS"}
          mode="gray"
        />
        <PrimaryButton label="DOWNLOAD CSV" mode="gray" onClick={downloadCsv} />
      </Filter>
      <Table
        dataKey="email"
        loading={getDocumentsListStatus !== LOADING_STATUSES.succeeded}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={documentsList}
        onRowDoubleClick={handleRowClick}
        onSort={onSort}
      />
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[50, 100, 200]}
          totalRecords={last_page}
          onClick={() => dispatch(documentsActions.setAllowScroll(true))}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.DocumentsContainer>
  );
};
