import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "common";
import { routes } from "router/routes";

import { StyledHomePageContainer } from "./styled";

export const HomePage = () => {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate(routes.signup);
  };

  return (
    <StyledHomePageContainer className="w-full flex items-center justify-center">
      <div className="flex flex-col items-center justify-center text-center text-white bg-gray-900 bg-opacity-50 p-12 rounded-3xl gap-6">
        <p className="text-5xl font-medium mb-0">Reduce Your Home's Property Tax</p>
        <p className="text-2xl">
          We'll protest on your behalf at <strong>no risk.</strong> <br />
          Our fee: Just one percent of your
          <strong> market value reduction.</strong>
          <br />
          If there's no reduction, there's <strong>no fee.</strong>
        </p>
        <div className="flex">
          <PrimaryButton eventHandler={handleSignUpClick} label="Sign Up" />
        </div>
      </div>
    </StyledHomePageContainer>
  );
};
