import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PrimaryButton, PrimaryInput, Table } from "common";
import { Filter } from "pages/admin/components/Filter";
import { Paginator } from "primereact/paginator";
import { singleDocumentSelectors } from "store/admin-slices/documents-single-slice/selectors";
import { documentsSingleActions } from "store/admin-slices/documents-single-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";
import { downloadFileByLink, getPaginationDescription } from "utils/helpers";

import * as S from "./styled";

const tableData = [
  { headerClassName: "column-header", selectionMode: "multiple" },
  {
    header: "Name",
    field: "user_name",
  },
  {
    header: "Email",
    field: "email",
  },
  {
    header: "Description",
    field: "description",
  },
  {
    header: "Owner Name",
    field: "owner_name",
  },
  {
    header: "Owner Email",
    field: "owner_email",
  },
  {
    header: "Creation Date",
    field: "created_at",
    sortable: true,
  },
];

export const TrackerTable = ({
  onPageChange,
  setSortData,
  sortData,
  setStartDate,
  setEndDate,
  debouncedEndDate,
  debouncedStartDate,
}) => {
  const { trackerData, getTrackerDataStatus, paginationData, last_page, downloadCsvHref, exportCsvStatus } =
    useSelector(singleDocumentSelectors);

  const [selectedSelection, setSelectedSelection] = useState([]);
  const [paginationChange, setPaginationChange] = useState({ prev: null, new: paginationData });

  const ref = useRef();

  const dispatch = useDispatch();

  const { id } = useParams();
  const onSort = (e) => setSortData({ field: e.sortField, direction: e.sortOrder });

  const downloadCsv = () => {
    let actions = selectedSelection?.map((prop) => prop.id);
    actions = actions?.filter((id, index, self) => self.indexOf(id) === index);
    const params = {
      start_date: debouncedStartDate,
      end_date: debouncedEndDate,
      owner_id: id,
      actions,
    };

    dispatch(documentsSingleActions.exportCSV(params));
  };

  useEffect(() => {
    downloadFileByLink(exportCsvStatus, downloadCsvHref, "documents-action-tracking.csv");
  }, [downloadCsvHref]);

  useEffect(() => {
    setPaginationChange({ prev: paginationChange.new, new: paginationData });

    if (
      (paginationChange.prev?.page !== paginationChange.new?.page ||
        paginationChange.prev?.rows !== paginationChange.new?.rows) &&
      getTrackerDataStatus === LOADING_STATUSES.succeeded
    ) {
      ref.current.scrollIntoView({ behavior: "auto" });
    }
  }, [paginationData, getTrackerDataStatus]);

  return (
    <S.Content>
      <S.ContentTitle>Action Tracker</S.ContentTitle>
      <Filter>
        <PrimaryInput placeholder="Start date" type="datetime-local" onChange={(e) => setStartDate(e.target.value)} />
        <PrimaryInput placeholder="End date" type="datetime-local" onChange={(e) => setEndDate(e.target.value)} />
        <PrimaryButton
          eventHandler={downloadCsv}
          label="Download CSV"
          loading={isLoading(exportCsvStatus)}
          mode="gray"
        />
      </Filter>
      <Table
        loading={isLoading(getTrackerDataStatus)}
        selection={selectedSelection}
        sortField={sortData.field}
        sortOrder={sortData.direction}
        tableData={tableData}
        value={trackerData || []}
        onSelectionChange={(e) => setSelectedSelection(e.value)}
        onSort={onSort}
      />
      <S.PaginatorWrapper ref={ref}>
        <Paginator
          className="pagination"
          first={paginationData.first}
          rows={paginationData.rows}
          rowsPerPageOptions={[10, 50, 100, 200]}
          totalRecords={last_page}
          onPageChange={onPageChange}
        />
        <S.Box className="flex-center">{getPaginationDescription(paginationData, last_page)}</S.Box>
      </S.PaginatorWrapper>
    </S.Content>
  );
};
