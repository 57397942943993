import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton, PrimaryInput } from "common";
import { referralsSelectors } from "store/referral-slice/selectors";
import { referralActions } from "store/referral-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";
import { generateReferralCode } from "utils/helpers";
import { referralSchema } from "validations";

import * as S from "./styled";

const GetReferralCodeSection = () => {
  const { postReferralCodeStatus } = useSelector(referralsSelectors);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(referralSchema),
  });

  const onSubmit = (data) => {
    dispatch(
      referralActions.postReferralCode({
        email: data.email,
        referral_code: generateReferralCode(),
      })
    );
  };

  useEffect(() => {
    setValue("email", "");
  }, [postReferralCodeStatus]);

  return (
    <S.ReferralForm onSubmit={handleSubmit(onSubmit)}>
      <PrimaryInput
        className="email-input"
        error={errors.email}
        placeholder="Email address"
        register={register("email")}
        wrapperClassName="email-input"
      />
      {postReferralCodeStatus === LOADING_STATUSES.failed ? (
        <S.EmailErrorMessage>
          We could not find your email address. Confirm you are using the same email you used to sign up. If the email
          can still not be found please contact us.
        </S.EmailErrorMessage>
      ) : null}
      <PrimaryButton
        className="email-button"
        label="Get your referral code"
        loading={isLoading(postReferralCodeStatus)}
      />
    </S.ReferralForm>
  );
};

export default GetReferralCodeSection;
