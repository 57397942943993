import { useSelector } from "react-redux";
import { Loader } from "common";
import { singleArchivedSelectors } from "store/admin-slices/single-archived-slice/selectors";
import { isLoading } from "utils/constants";

import * as S from "./styled";

export const Document = ({ doc }) => {
  const { updateDocumentStatus, documentSelectedId } = useSelector(singleArchivedSelectors);

  const isPending = (status) => isLoading(status) && documentSelectedId === doc.id;

  const isPendingUpdate = isPending(updateDocumentStatus);

  return (
    <S.Document>
      {isPendingUpdate ? (
        <S.LinkWrapper>
          <Loader />
        </S.LinkWrapper>
      ) : (
        <>
          <S.LinkWrapper>
            <S.Text>View AoA</S.Text> -
            <S.Link href={doc?.aoa?.docs_aoa} target="_blank">
              {doc?.aoa?.filename}
            </S.Link>
          </S.LinkWrapper>
        </>
      )}
      {doc.last_sent_to_county ? (
        <S.Text className="success center">{doc.last_sent_to_county}</S.Text>
      ) : (
        <S.Text className="red center">Not Sent</S.Text>
      )}
    </S.Document>
  );
};
