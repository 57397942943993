export const UploadIcon = (props) => (
  <svg fill="none" height="25" viewBox="0 0 24 25" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="document-upload">
      <g id="vuesax/linear/document-upload">
        <g id="document-upload_2">
          <path
            d="M9 17.5V11.5L7 13.5"
            id="Vector"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M9 11.5L11 13.5"
            id="Vector_2"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M22 10.5V15.5C22 20.5 20 22.5 15 22.5H9C4 22.5 2 20.5 2 15.5V9.5C2 4.5 4 2.5 9 2.5H14"
            id="Vector_3"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M22 10.5H18C15 10.5 14 9.5 14 6.5V2.5L22 10.5Z"
            id="Vector_4"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </g>
  </svg>
);
