import { useNavigate } from "react-router-dom";
import { Icons } from "assets/svgs";
import { PrimaryButton } from "common";

import * as S from "./styled";

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const navigateHandler = () => navigate(-1);

  return (
    <S.NotFoundBackground>
      <S.IconBlock>
        <Icons.NotFound />
      </S.IconBlock>
      <S.PageNotFound>
        <S.Text>Page not found</S.Text>
        <S.PropertiesButtonBlock>
          <PrimaryButton className="go-back" eventHandler={navigateHandler} label="GO BACK" />
        </S.PropertiesButtonBlock>
      </S.PageNotFound>
    </S.NotFoundBackground>
  );
};
