import { toast } from "react-toastify";
import * as referralCodes from "referral-codes";

import { COUNTY_DETAILS, LOADING_STATUSES } from "./constants";

export const setStorage = (key, value) => {
  if (typeof value === "string") {
    localStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (err) {
    return localStorage.getItem(key);
  }
};

export const isValidFileType = (files) => {
  if (!files || files.length === 0) {
    return false;
  }
  const validExtensions = ["jpg", "jpeg", "png", "xlsx", "pdf", "csv", "doc", "docx", "txt"];

  for (const file of Array.from(files)) {
    const fileName = file.name.split(".");
    const fileExtension = fileName[fileName.length - 1].toLowerCase();

    if (!validExtensions.includes(fileExtension)) {
      return false;
    }
  }
  return true;
};

export const removeStorage = (key) => localStorage.removeItem(key);

export const isEmpty = (object) => {
  if (Array.isArray(object)) {
    return !object.length;
  }

  if (typeof object === "object" && !Array.isArray(object) && object !== null) {
    return !Object.keys(object).length;
  }

  if (typeof object === "string") {
    return !object.length;
  }

  return true;
};

export const generateAddressLink = (item = {}) => {
  if (item.county === "Harris") {
    return COUNTY_DETAILS[item.county]?.link?.url;
  }

  if (item.county === "Montgomery" && item.account_number) {
    const accountNumber = item.account_number.startsWith("R") ? item.account_number.substring(1) : item.account_number;
    return COUNTY_DETAILS[item.county]?.link?.url + accountNumber + COUNTY_DETAILS[item.county]?.link?.info;
  }

  if (item.county) {
    if (item.account_number) {
      return COUNTY_DETAILS[item.county]?.link?.url + item.account_number + COUNTY_DETAILS[item.county]?.link?.info;
    } else {
      return COUNTY_DETAILS[item.county]?.link?.search_url;
    }
  }
};

export const preloadImage = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = img.onabort = () => {
      reject(src);
    };
    img.src = src;
  });

export const snakeToCamel = (snakeString) => {
  let camelString = "";
  let isSnake = false;
  for (let i = 0; i < snakeString.length; i++) {
    if (snakeString[i] === "_") {
      isSnake = true;
    } else {
      if (isSnake) {
        camelString += snakeString[i].toUpperCase();
        isSnake = false;
      } else {
        camelString += snakeString[i];
      }
    }
  }

  return camelString;
};

export const getPaginationDescription = (paginationData, lastPage) => {
  return `${(paginationData.page - 1) * paginationData.rows + 1} - 
            ${
              paginationData.rows * paginationData.page >= lastPage
                ? lastPage
                : paginationData.rows * paginationData.page
            } of ${lastPage}`;
};

export const downloadFileByLink = (status, href, name = "properties.csv") => {
  if (status === LOADING_STATUSES.succeeded) {
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
};

export const generateReferralCode = () => {
  return referralCodes.generate({
    length: 7,
    count: 3,
    charset: "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  })[0];
};

export const toggleClassOnRoot = () => document.getElementById("root").classList.toggle("prevent-scroll-desktop");

export const numberToDollar = (value) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  return formatter.format(value);
};

export const toastify = (type, message) => {
  toast[type](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const getFileType = (filename) => filename.split(".").pop().toLowerCase();

export const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "red";
    case "reviewed":
      return "gray";
    case "worked":
      return "green";
    default:
      return "green";
  }
};
