import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";
import { removeStorage, setStorage } from "utils/helpers";

import { getAppeals, getInvoices, getMe, getProperties, postCheckCode, postCheckEmail } from "./actions";

const initialState = {
  signinStep: 0,
  email: null,
  properties: [],
  invoices: [],
  account: null,
  appeals: [],
  checkEmailErrorMessage: null,
  checkCodeErrorMessage: null,
  postCheckEmailStatus: LOADING_STATUSES.idle,
  postCheckCodeStatus: LOADING_STATUSES.idle,
  getMeStatus: LOADING_STATUSES.idle,
  getAppealsStatus: LOADING_STATUSES.idle,
  getPropertiesStatus: LOADING_STATUSES.idle,
  getInvoicesStatus: LOADING_STATUSES.idle,
};

export const clientSlice = createSlice({
  name: SLICE_NAME.clientSlice,
  initialState,
  reducers: {
    logout: () => removeStorage("owner_token"),
    resetAllData: () => initialState,
  },
  extraReducers: ({ addCase }) => {
    //CHECK EMAIL
    addCase(postCheckEmail.pending, (state) => {
      state.postCheckEmailStatus = LOADING_STATUSES.pending;
    });
    addCase(postCheckEmail.fulfilled, (state, { payload }) => {
      state.signinStep = 1;
      state.email = payload.data.email;
      state.postCheckEmailStatus = LOADING_STATUSES.succeeded;
    });
    addCase(postCheckEmail.rejected, (state) => {
      state.checkEmailErrorMessage = "Unable to find client profile with this E-mail";
      state.postCheckEmailStatus = LOADING_STATUSES.failed;
    });

    // CHECK CODE
    addCase(postCheckCode.pending, (state) => {
      state.postCheckCodeStatus = LOADING_STATUSES.pending;
    });
    addCase(postCheckCode.fulfilled, (state, { payload }) => {
      state.postCheckCodeStatus = LOADING_STATUSES.succeeded;
      setStorage("owner_token", payload.user_token);
    });
    addCase(postCheckCode.rejected, (state) => {
      state.checkCodeErrorMessage = "Invalid verification code";
      state.postCheckCodeStatus = LOADING_STATUSES.failed;
    });

    // getMe
    addCase(getMe.pending, (state) => {
      state.getMeStatus = LOADING_STATUSES.pending;
    });
    addCase(getMe.fulfilled, (state, { payload }) => {
      state.account = payload.data;
      state.getMeStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getMe.rejected, (state) => {
      state.getMeStatus = LOADING_STATUSES.failed;
    });

    //appeals
    addCase(getAppeals.pending, (state) => {
      state.getAppealsStatus = LOADING_STATUSES.pending;
    });
    addCase(getAppeals.fulfilled, (state, { payload }) => {
      state.appeals = payload.data;
      state.getAppealsStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getAppeals.rejected, (state) => {
      state.getAppealsStatus = LOADING_STATUSES.failed;
    });

    //properties;
    addCase(getProperties.pending, (state) => {
      state.getPropertiesStatus = LOADING_STATUSES.pending;
    });
    addCase(getProperties.fulfilled, (state, { payload }) => {
      state.properties = payload.data;
      state.getPropertiesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getProperties.rejected, (state) => {
      state.getPropertiesStatus = LOADING_STATUSES.failed;
    });

    // invoices;
    addCase(getInvoices.pending, (state) => {
      state.getInvoicesStatus = LOADING_STATUSES.pending;
    });
    addCase(getInvoices.fulfilled, (state, { payload }) => {
      state.invoices = payload.data;
      state.getInvoicesStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getInvoices.rejected, (state) => {
      state.getInvoicesStatus = LOADING_STATUSES.failed;
    });
  },
});

export const { logout, resetAllData } = clientSlice.actions;

export const clientReducer = clientSlice.reducer;
