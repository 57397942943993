import { MultiSelect } from "primereact/multiselect";

import * as S from "./styled";

export const LazyLoadSelect = ({
  options,
  className,
  selectedOptions = [],
  setSelectedOptions,
  floatingLabel,
  placeholder,
  description,
}) => {
  const handleSelectionChange = (e) => {
    e.originalEvent.stopPropagation();
    setSelectedOptions(e.value);
  };

  return (
    <S.Container>
      {selectedOptions?.length ? <S.FilterCount>{selectedOptions?.length}</S.FilterCount> : null}
      {floatingLabel ? (
        <S.Span className="p-float-label w-full md:w-14rem">
          <MultiSelect
            filter
            className={`${className} multi-select w-full md:w-14rem`}
            inputId="dd-city"
            maxSelectedLabels={3}
            optionLabel="value"
            options={options}
            placeholder={placeholder}
            value={selectedOptions}
            virtualScrollerOptions={{ itemSize: 43 }}
            onChange={handleSelectionChange}
          />
          <S.Label htmlFor="dd-city">
            {placeholder}&nbsp;
            <S.Description>{description ? `(${description})` : null}</S.Description>
          </S.Label>
        </S.Span>
      ) : (
        <MultiSelect
          filter
          className={`${className} multi-select w-full md:w-14rem`}
          maxSelectedLabels={3}
          optionLabel="value"
          options={options}
          placeholder={placeholder}
          value={selectedOptions}
          virtualScrollerOptions={{ itemSize: 43 }}
          onChange={(e) => setSelectedOptions(e.value)}
        />
      )}
    </S.Container>
  );
};
