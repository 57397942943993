export const CloseIcon = (props) => (
  <svg fill="none" height="16px" viewBox="0 0 16 16" width="16px" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="close">
      <g id="vuesax/linear/add">
        <g id="add">
          <g id="Group 33824">
            <path
              d="M3.33398 3.3335L12.6667 12.6662"
              id="Vector"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              d="M3.33331 12.6662L12.666 3.3335"
              id="Vector_2"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
