import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  max-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const AgentsTitle = styled.div`
  font-size: 24px;
  color: ${COLORS.deepDark};
  margin: 10px;
`;
