import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import { exportCSV, getOwnerTrackerList } from "./actions";

const initialState = {
  ownerTrackerList: [],
  per_page: 10,
  last_page: 0,
  paginationData: {
    first: 0,
    rows: 10,
    page: 1,
  },
  downloadCsvHref: null,
  getOwnerTrackerListStatus: LOADING_STATUSES.idle,
  exportCsvStatus: LOADING_STATUSES.idle,
};

export const ownerTrackerList = createSlice({
  name: SLICE_NAME.ownerTrackerSlice,
  initialState,
  reducers: {
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    resetAllData: () => initialState,
  },
  extraReducers: ({ addCase }) => {
    addCase(getOwnerTrackerList.pending, (state) => {
      state.getOwnerTrackerListStatus = LOADING_STATUSES.pending;
    });
    addCase(getOwnerTrackerList.fulfilled, (state, { payload }) => {
      state.getOwnerTrackerListStatus = LOADING_STATUSES.succeeded;
      state.ownerTrackerList = payload?.data;
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
    });
    addCase(getOwnerTrackerList.rejected, (state) => {
      state.getOwnerTrackerListStatus = LOADING_STATUSES.failed;
    });

    // export csv

    addCase(exportCSV.pending, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCSV.fulfilled, (state, { payload }) => {
      state.exportCSVStatus = LOADING_STATUSES.succeeded;
      state.downloadCsvHref = payload;
    });
    addCase(exportCSV.rejected, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.failed;
    });
  },
});

export const ownerTrackerReducer = ownerTrackerList.reducer;

export const ownerTrackerActions = {
  ...ownerTrackerList.actions,
  exportCSV,
  getOwnerTrackerList,
};
