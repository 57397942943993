import axios from "axios";
import { API } from "configs/api";

export const singleArchivedApi = {
  getSingleArchived: (body) => axios.get(`${API.archives}/${body.id}`, { token: true }),
  postAccountLogs: (body) => axios.post(`${API.archives}${API.flags}`, body, { token: true }),
  deleteAccountLogs: (id) => axios.delete(`${API.archives}${API.flags}/${id}`, { token: true }),
  deleteProperty: (data) => axios.delete(`${API.archives}${API.deleteProperty}`, { data, token: true }),
  updateNotes: (id, notes) => axios.put(`${API.notes}/${id}`, { notes }, { token: true }),
  postNote: (body) => axios.post(API.notes, body, { token: true }),
  connectArchivedProperty: (body) => axios.post(`${API.archives}${API.property}`, body, { token: true }),
  verifySingleProperty: (params) => axios.get(API.syncProperty, { token: true, params }),
  updateDoc: ({ id, ...body }) => axios.put(`${API.docs}/${id}`, body, { token: true }),
  deleteDoc: (id) => axios.delete(`${API.docs}/${id}`, { token: true }),
  createDoc: (body) => axios.post(`${API.admin}${API.docs}`, body, { token: true, formData: true }),
  sendAoA: (body) => axios.post(API.sendAoA, body, { token: true }),
  getArchivedReferrals: (ownerId) => axios.get(`${API.ownerReferrals}/${ownerId}`, { token: true }),
  getArchivedReferredBy: (ownerId) => axios.get(`${API.ownerReferredBy}/${ownerId}`, { token: true }),
  archiveProperty: ({ id, ...params }) => axios.post(`${API.archiveProperty}/${id}`, {}, { params, token: true }),
  deleteReferredBy: (id) => axios.delete(`${API.referrals}/${id}`, { token: true, id }),
  postReferredBy: (body) => axios.post(`${API.signup}${API.updateSender}`, body, { token: true, formData: true }),
  postReferredTo: ({ id, email }) =>
    axios.post(`${API.archives}${API.addReceiver}/${id}`, { email }, { token: true, formData: true }),
  updateReferral: (body) => axios.post(`${API.signup}${API.updateSender}`, body, { token: true }),
  updateAccountNumber: (params) =>
    axios.put(
      `${API.archives + API.property + API.accountNumber}/${params.property_id}`,
      { account_number: params.account_number },
      { token: true }
    ),
  updateStreetAddress: (params) =>
    axios.put(
      `${API.archives + API.property + API.streetAddress}/${params.property_id}`,
      { street_address: params.street_address },
      { token: true }
    ),
};
