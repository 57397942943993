import { useEffect, useState } from "react";
import { ErrorMessage, PrimaryButton, PrimaryInput, Select } from "common";
import { isLoading, REASON_TYPES_ITEMS, REASON_TYPES_SELECT } from "utils/constants";

import * as S from "./styled";

export const ArchiveModal = ({
  isOpenModal,
  setIsOpenModal,
  singleArchiveId,
  singleArchiveStatus,
  handleArchiveDispatch,
  isArchived,
}) => {
  const [isOpenReasonContent, setIsOpenReasonContent] = useState(false);
  const [reason, setReason] = useState(null);
  const [contact_again, setContactAgain] = useState(0);
  const [isShowError, setIsShowError] = useState(false);
  const [otherReason, setOtherReason] = useState("");

  const contactAgainSelectItems = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];
  const handleModalToggle = () => setIsOpenModal(!isOpenModal);

  const handleContactAgainChange = (label) =>
    setContactAgain(contactAgainSelectItems.find((item) => item.label === label).value);

  const handleReasonTypeChange = (label) => {
    const newReason = REASON_TYPES_ITEMS.find((item) => item.label === label).value;
    setReason(newReason);

    if (
      newReason === REASON_TYPES_ITEMS[0].label ||
      newReason === REASON_TYPES_ITEMS[3].label ||
      newReason === REASON_TYPES_ITEMS[7].label
    ) {
      handleContactAgainChange("Yes");
    } else {
      handleContactAgainChange("No");
    }
  };

  const handleConfirmClick = () => {
    if (isArchived) {
      if ((!reason || !otherReason) && isArchived === 0) {
        setIsShowError(true);
        return;
      }
    } else {
      if (!reason || (reason === REASON_TYPES_SELECT.other && !otherReason)) {
        setIsShowError(true);
        return;
      }
    }

    const params = {
      id: singleArchiveId,
      description: reason === REASON_TYPES_SELECT.other ? otherReason : reason,
      contact_again,
      is_archived: isArchived === 1 ? 0 : 1,
    };

    if (isArchived === 1) {
      params.description = "x";
    }

    if (isArchived === undefined) {
      delete params.is_archived;
    }

    handleArchiveDispatch(params);
  };

  const handleOpenReasonContent = () => {
    if (isArchived === 1) {
      handleConfirmClick();
    } else {
      setIsOpenReasonContent(true);
    }
  };

  useEffect(() => {
    if (reason) {
      setIsShowError(false);
    }
  }, [reason]);

  useEffect(() => {
    if (!isOpenModal) {
      setReason("");
      setOtherReason("");
      setIsShowError(false);
      setIsOpenReasonContent(false);
    }
  }, [isOpenModal]);

  return (
    <>
      {isOpenModal && (
        <S.ArchiveModalContainer>
          <S.ArchiveModalContent
            className={isOpenReasonContent ? "open-reason-content" : ""}
            onClick={(e) => e.stopPropagation()}
          >
            {isOpenReasonContent ? (
              <>
                <Select
                  className="reason-select"
                  itemKey="label"
                  items={REASON_TYPES_ITEMS}
                  label="Select a reason"
                  onChange={handleReasonTypeChange}
                />
                {reason === REASON_TYPES_SELECT.other && (
                  <>
                    <br />
                    <PrimaryInput
                      placeholder="Please specify"
                      value={otherReason}
                      onChange={(e) => setOtherReason(e.target.value)}
                    />
                  </>
                )}
                {otherReason ? null : isShowError && <ErrorMessage children="The field is required" />}
                <br />
                <Select
                  itemKey="label"
                  items={contactAgainSelectItems}
                  label="Contact Again"
                  value={contact_again === 0 ? "No" : "Yes"}
                  onChange={handleContactAgainChange}
                />
                <br />
                <S.ConfirmContent className="reason-content-buttons">
                  <PrimaryButton
                    eventHandler={handleConfirmClick}
                    label="submit"
                    loading={isLoading(singleArchiveStatus)}
                  />
                  <PrimaryButton eventHandler={handleModalToggle} label="cancel" mode="gray" />
                </S.ConfirmContent>
              </>
            ) : (
              <>
                <S.Box className="red">Are you sure?</S.Box>
                <S.ConfirmContent className="archive-owner-button">
                  <PrimaryButton
                    eventHandler={handleOpenReasonContent}
                    label="yes"
                    loading={isLoading(singleArchiveStatus)}
                  />
                  <PrimaryButton eventHandler={handleModalToggle} label="no" mode="gray" />
                </S.ConfirmContent>
              </>
            )}
          </S.ArchiveModalContent>
        </S.ArchiveModalContainer>
      )}
    </>
  );
};
