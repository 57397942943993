import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const Modal = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.overlay_200};
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: ${COLORS.white};
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  padding: 40px 60px;
  border-radius: 6px;
`;

export const ConfirmContent = styled.div`
  display: flex;
  gap: 24px;
`;

export const Text = styled.p``;

export const Heading = styled.h2`
  text-align: center;
  font-weight: bold;
`;
