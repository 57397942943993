import { Icons } from "assets/svgs";
import { PrimaryInput } from "common";

import { SearchInputContainer } from "./styled";

export const SearchInput = ({ placeholder, onSearch, className, value, ...rest }) => {
  return (
    <SearchInputContainer className={className}>
      <PrimaryInput placeholder={placeholder} value={value} onChange={(e) => onSearch(e.target.value)} {...rest} />
      <Icons.SearchIcon />
    </SearchInputContainer>
  );
};
