import * as yup from "yup";

export const signUpAddPropertySchema = yup.object().shape({
  searchAddress: yup.string(),
  unit: yup.string().nullable(),
  address: yup.string().required("The field is required."),
  ownerName: yup.string().required("The field is required."),
  county: yup
    .string()
    .required("The field is required.")
    .matches(
      /^(COLLIN|DENTON|DALLAS|HARRIS|TARRANT|TRAVIS|MONTGOMERY|WILLIAMSON|FORTBEND|FORT BEND)$/i,
      "County must be one of the following: Collin, Denton, Dallas, FortBend, Fort Bend, Harris, Montgomery, Tarrant, Travis, or Williamson"
    ),
  cityStateZip: yup.string().required("The field is required."),
  addressNumber: yup.string().required("The field is required."),
  streetName: yup.string().required("The field is required."),
  city: yup.string().required("The field is required."),
  state: yup.string().required("The field is required."),
  zip: yup.string().required("The field is required."),
  ownerAddress: yup.string().required("The field is required."),
  ownerCity: yup.string().required("The field is required."),
  ownerState: yup.string().required("The field is required."),
  ownerZip: yup.string().required("The field is required."),
});
