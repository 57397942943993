import { useEffect, useRef, useState } from "react";
import { Checkbox, PrimaryButton, SearchInput } from "common";
import { useOutsideClick } from "hooks";

import * as S from "./styled";

export const FilterMultiSelect = ({
  buttonLabel,
  options,
  optionsClassName,
  selectedOptions = [],
  setSelectedOptions,
  className,
  search,
}) => {
  const [showFilterList, setShowFilterList] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const filterListRef = useRef(null);

  const openSelect = () => {
    setShowFilterList(!showFilterList);
  };

  const handleOptionToggle = (option) => {
    const index = selectedOptions.indexOf(option);

    if (index === -1) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      const updatedOptions = [...selectedOptions];
      updatedOptions.splice(index, 1);
      setSelectedOptions(updatedOptions);
    }
  };

  const onBlur = (e) => {
    if (filterListRef.current && !filterListRef.current.contains(e.target)) {
      setShowFilterList(false);
    }
  };

  const onSearch = (value) => {
    setSearchQuery(value);
  };

  useOutsideClick(onBlur);

  useEffect(() => {
    if (!showFilterList) {
      setSearchQuery("");
    }
  }, [showFilterList]);

  return (
    <S.FilterMultiSelectContainer ref={filterListRef} className={className}>
      <PrimaryButton
        label={buttonLabel}
        mode="gray"
        styles={{
          width: "180px",
        }}
        onClick={openSelect}
      />
      {selectedOptions?.length ? <S.FilterCount>{selectedOptions?.length}</S.FilterCount> : null}
      {showFilterList ? (
        <S.FilterListContainer className={optionsClassName ? "options" : ""}>
          {search ? <SearchInput className="search-input" value={searchQuery} onSearch={onSearch} /> : null}
          {options
            ?.filter((item) => item.value?.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((item) => (
              <Checkbox
                key={item.value}
                checked={selectedOptions.includes(item.value)}
                description={item.description}
                id={item.value}
                onChange={() => handleOptionToggle(item.value)}
              />
            ))}
        </S.FilterListContainer>
      ) : null}
    </S.FilterMultiSelectContainer>
  );
};
