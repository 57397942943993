import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { ownersListApi } from "./services";

export const getOwnersList = createAsyncThunk(
  `${SLICE_NAME.ownersSlice}/getOwnersList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await ownersListApi.getOwners(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const syncProperty = createAsyncThunk(
  `${SLICE_NAME.ownersSlice}/syncProperty`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await ownersListApi.syncProperty(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportCSV = createAsyncThunk(
  `${SLICE_NAME.ownersSlice}/exportCSV`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await ownersListApi.exportCSV(params);
      const href = URL.createObjectURL(response.data);

      return href;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyAllAoA = createAsyncThunk(
  `${SLICE_NAME.ownersSlice}/verifyAllAoA`,
  async (params, { rejectWithValue }) => {
    try {
      await ownersListApi.verifyAllAoA(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
