import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { singleArchivedSelectors } from "store/admin-slices/single-archived-slice/selectors";

import * as S from "./styled";

export const Notes = () => {
  const { singleArchived } = useSelector(singleArchivedSelectors);

  const [note, setNote] = useState(null);

  const { selectedPropertyId } = useParams();

  useEffect(() => {
    const property = singleArchived.properties.find((item) => item.id === Number(selectedPropertyId));

    setNote(property?.note);
  }, [selectedPropertyId, singleArchived.properties]);

  return (
    <S.Container>
      <S.Content>
        <S.ContentTitle>Notes</S.ContentTitle>
        <S.TextArea readOnly value={note?.notes ?? ""} />
      </S.Content>
    </S.Container>
  );
};
