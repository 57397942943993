import { COLORS } from "assets/styles/colors";
import styled from "styled-components/macro";

export const Container = styled.div`
  .column {
    font-size: 12px;
    font-family: Montserrat;
    padding: 5px !important;

    div {
      justify-content: center;
    }
  }

  .checked-row > td {
    background-color: ${COLORS.grey} !important;
  }

  .selected > td {
    background-color: ${COLORS.light_green} !important;
  }

  .archived > td {
    background-color: rgba(129, 129, 129, 0.3) !important;
  }

  .column-header {
    font-size: 10px;
    height: 40px;
    div {
      display: flex;
      justify-content: center;
    }
  }

  .column-body {
    font-size: 14px;
    text-align: center !important;
    padding: 10px 10px !important;

    & > div,
    & > svg {
      display: flex;
      justify-content: center;
      width: 100% !important;
    }
  }
`;
