import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "assets/svgs";
import { Loader } from "common";
import { getInvoices } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { isLoading } from "utils/constants";
import { generateAddressLink } from "utils/helpers";

import * as S from "./styled";

export const InvoicesPage = () => {
  const { getInvoicesStatus, invoices } = useSelector(clientSelectors);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvoices());
  }, []);

  if (isLoading(getInvoicesStatus)) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return (
    <S.InvoicesScrollBlock>
      {invoices.length ? (
        invoices.map((invoices) => (
          <S.InvoicesLink
            key={invoices.id}
            className="text-green-700 text-base"
            href={generateAddressLink(invoices)}
            rel="noreferrer"
            target="_blank"
          >
            <S.InvoicesContent>
              <S.InvoicesIconBlock>
                <Icons.InvoicesIcon />
              </S.InvoicesIconBlock>
              <S.InvoicesText>| |{invoices.owner_name}</S.InvoicesText>
            </S.InvoicesContent>
          </S.InvoicesLink>
        ))
      ) : (
        <S.InvoicesMessageContent>
          <S.InvoicesText>You don't have any invoices yet.</S.InvoicesText>
        </S.InvoicesMessageContent>
      )}
    </S.InvoicesScrollBlock>
  );
};
