import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icons } from "assets/svgs";
import { PrimaryButton, PrimaryInput } from "common";
import { adminRoutes } from "router/routes";
import { adminSignInSelectors } from "store/admin-slices/signin-slice/selectors";
import { adminSignInActions } from "store/admin-slices/signin-slice/slice";
import { isLoading, LOADING_STATUSES } from "utils/constants";
import { getStorage } from "utils/helpers";
import { adminSignInSchema } from "validations";

import {
  AdminSignInContainer,
  AdminSignInForm,
  BannerTitle,
  ErrorMessageBlock,
  ErrorMessageText,
  SignInBanner,
  SignInButton,
  WarningIconBlock,
} from "./styled";

export const AdminSignInPage = () => {
  const { adminSignInStatus, adminSignInResponse, userInfo, getUserInfoStatus } = useSelector(adminSignInSelectors);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(adminSignInSchema),
  });

  const onSubmit = (data) => {
    const body = {
      username: data.email,
      password: data.password,
    };
    dispatch(adminSignInActions.adminSignIn(body));
  };

  useEffect(() => {
    if (adminSignInResponse?.access_token && adminSignInStatus === LOADING_STATUSES.succeeded) {
      dispatch(adminSignInActions.getUsersInfo());
    }
  }, [adminSignInStatus, adminSignInResponse]);

  useEffect(() => {
    if (userInfo) {
      navigate(`${adminRoutes.prefix}/${adminRoutes.home}`);
    }
  }, [userInfo]);

  useEffect(() => {
    if (getStorage("credentials") && !adminSignInResponse?.access_token) {
      dispatch(adminSignInActions.getUsersInfo());
    }
  }, []);

  return (
    <AdminSignInContainer>
      {adminSignInStatus === LOADING_STATUSES.failed || getUserInfoStatus === LOADING_STATUSES.failed ? (
        <ErrorMessageBlock>
          <WarningIconBlock>
            <Icons.WarningIcon />
          </WarningIconBlock>
          <ErrorMessageText>Wrong Email or Password</ErrorMessageText>
        </ErrorMessageBlock>
      ) : null}

      <SignInBanner>
        <BannerTitle>LOGIN</BannerTitle>
        <Icons.UserIcon />
      </SignInBanner>

      <AdminSignInForm onSubmit={handleSubmit(onSubmit)}>
        <PrimaryInput error={errors.email} placeholder="E-mail" register={register("email")} />
        <PrimaryInput isPasswordInput error={errors.password} placeholder="Password" register={register("password")} />
        <SignInButton>
          <PrimaryButton label="LOGIN" loading={isLoading(adminSignInStatus)} />
        </SignInButton>
      </AdminSignInForm>
    </AdminSignInContainer>
  );
};
