import { AbilityBuilder, createMongoAbility } from "@casl/ability";

export function usePermissions(permissions) {
  const { can, build } = new AbilityBuilder(createMongoAbility);
  permissions?.forEach((permission) => {
    can(permission.value);
    permission?.related?.forEach((related) => {
      can(related.value);
    });
  });

  return build();
}
