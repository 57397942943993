import { COLORS } from "assets/styles/colors";
import { ContentHeader, SinglePageContentShadow } from "pages/admin/OwnersSinglePage/styled";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  width: 50%;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const Content = styled(SinglePageContentShadow)``;

export const ContentTitle = styled(ContentHeader)``;

export const TextArea = styled.textarea`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 3px 1px -2px ${COLORS.overlay_200}, 0 2px 2px 0 ${COLORS.overlay_150}, 0 1px 5px 0 ${COLORS.overlay_150} !important;
  border: none;
  width: 100%;
  margin-block: 15px;
  min-height: 100px;
`;
