import axios from "axios";
import { API } from "configs/api";

export const protestedAccountsListApi = {
  getProtestedAccounts: (params) => axios.get(API.getProtestedAccountsList, { token: true, params }),
  postUploadFile: (body) =>
    axios.post(`${API.getProtestedAccountsList}${API.uploadFile}`, body, { token: true, formData: true }),
  exportCSV: (params) =>
    axios.get(API.getProtestedAccountsList + API.exportCSV, {
      token: true,
      responseType: "blob",
      params,
    }),
};
