import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";

import { deleteEvidence, exportCSV, getClientEvidences } from "./actions";

const initialState = {
  clientEvidences: [],
  isDeleteModalOpen: false,
  downloadCsvHref: null,
  allowScroll: false,
  per_page: 50,
  last_page: 0,
  paginationData: {
    first: 10,
    rows: 50,
    page: 1,
  },
  clientEvidenceListFilters: {},
  getClientEvidencesStatus: LOADING_STATUSES.idle,
  exportCSVStatus: LOADING_STATUSES.idle,
  deleteEvidenceStatus: LOADING_STATUSES.idle,
};
export const clientEvidenceSlice = createSlice({
  name: SLICE_NAME.clientEvidenceSlice,
  initialState,
  reducers: {
    setClientEvidenceListFilters: (state, { payload }) => {
      state.clientEvidenceListFilters = payload;
    },
    resetAllData: (state, { payload }) => {
      if (!payload) {
        return {
          ...initialState,
          clientEvidenceListFilters: state.clientEvidenceListFilters,
        };
      } else {
        return initialState;
      }
    },
    setPaginationData: (state, { payload }) => {
      state.paginationData = payload;
    },
    setIsDeleteModalOpen: (state, { payload }) => {
      state.isDeleteModalOpen = payload;
    },
    setAllowScroll: (state, { payload }) => {
      state.allowScroll = payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getClientEvidences.pending, (state) => {
      state.getClientEvidencesStatus = LOADING_STATUSES.pending;
    });
    addCase(getClientEvidences.fulfilled, (state, { payload }) => {
      state.getClientEvidencesStatus = LOADING_STATUSES.succeeded;
      state.clientEvidences = payload.data;
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
    });
    addCase(getClientEvidences.rejected, (state) => {
      state.clientEvidencesStatus = LOADING_STATUSES.failed;
    });

    // export csv
    addCase(exportCSV.pending, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.pending;
    });
    addCase(exportCSV.fulfilled, (state, { payload }) => {
      state.downloadCsvHref = payload;
      state.exportCSVStatus = LOADING_STATUSES.succeeded;
    });
    addCase(exportCSV.rejected, (state) => {
      state.exportCSVStatus = LOADING_STATUSES.failed;
    });

    //delete evidence
    addCase(deleteEvidence.pending, (state) => {
      state.deleteEvidenceStatus = LOADING_STATUSES.pending;
    });
    addCase(deleteEvidence.fulfilled, (state, { payload }) => {
      state.deleteEvidenceStatus = LOADING_STATUSES.succeeded;
    });
    addCase(deleteEvidence.rejected, (state) => {
      state.deleteEvidenceStatus = LOADING_STATUSES.failed;
    });
  },
});

export const clientEvidenceReducer = clientEvidenceSlice.reducer;

export const clientEvidenceActions = {
  ...clientEvidenceSlice.actions,
  getClientEvidences,
  exportCSV,
  deleteEvidence,
};
