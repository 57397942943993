import { ErrorMessage } from "common";

import * as S from "./styled";

export const PrimaryTextarea = ({ placeholder, register, error, ...rest }) => {
  return (
    <S.TextareaWrapper className="w-100 primary-100 my-2 relative">
      <S.Area
        className="!outline-transparent"
        color="green"
        error={!!error}
        label={placeholder}
        labelProps={{
          style: { fontFamily: "Montserrat" },
          className: "peer-placeholder-shown:text-base",
        }}
        {...register}
        {...rest}
        variant="standard"
      />

      {error ? <ErrorMessage>{error?.message}</ErrorMessage> : null}
    </S.TextareaWrapper>
  );
};
