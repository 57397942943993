import { useEffect, useState } from "react";
import { PrimaryInput } from "common";

import * as S from "./styled";

export const AutocompleteInput = ({ register, value, children, ...rest }) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  const onBlur = () => setTimeout(() => setFocused(false), 200);

  const onFocus = () => setFocused(true);

  useEffect(() => {
    if (value && focused) {
      setSelectOpen(true);
    } else {
      setSelectOpen(false);
    }
  }, [value, focused]);

  return (
    <S.Container>
      <PrimaryInput register={register} onBlur={onBlur} onFocus={onFocus} {...rest} />
      {selectOpen && <S.Box className="pac-container">{children}</S.Box>}
    </S.Container>
  );
};
