import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const Container = styled.div`
  position: relative;

  .multi-select {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    font-weight: 500;
    letter-spacing: 0.0892857143em;
    text-transform: uppercase;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    min-width: ${({ minwidth }) => (minwidth ? minwidth + "px" : "none")};
    font-size: 0.875rem;
    border-radius: 4px;
    font-family: Montserrat, serif;
    background-color: ${COLORS.grey};
    color: ${COLORS.dark};
    border: unset;

    &:hover {
      box-shadow: 0 3px 10px #000;
    }

    .p-multiselect-label {
      display: flex;
      justify-content: center;
    }

    .p-multiselect-trigger {
      display: none;
    }
  }
`;

export const FilterCount = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${COLORS.green};
  font-size: 10px;
  position: absolute;
  right: -6px;
  top: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.white};
  z-index: 1;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px !important;
  font-weight: bold !important;
  color: ${COLORS.deepDark} !important;
`;

export const Description = styled.span`
  font-size: 14px !important;
  font-style: normal;
  font-weight: 100 !important;
`;

export const Span = styled.span``;
