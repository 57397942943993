import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { IMAGES } from "assets/images";
import { Icons } from "assets/svgs";
import { clientRoutes } from "router/routes";
import { logout, resetAllData } from "store/client-slice/slice";

import * as S from "./styled";

export const NavigationBar = () => {
  const [open, setOpen] = useState(false);

  const mainOptions = [
    {
      label: "Appeals",
      href: clientRoutes.prefix + clientRoutes.appeals,
      icon: <Icons.ClarityProcessIcon />,
    },
    {
      label: "Properties",
      href: clientRoutes.prefix + clientRoutes.properties,
      icon: <Icons.LocationIcon />,
    },
    {
      label: "Invoices",
      href: clientRoutes.prefix + clientRoutes.invoices,
      icon: <Icons.InvoicesIcon />,
    },
  ];

  const bottomOptions = [
    {
      label: "My Info",
      href: clientRoutes.prefix + clientRoutes.account,
      icon: <Icons.HelpIcon />,
    },
    {
      label: "Log Out",
      href: "null",
      icon: <Icons.LogoutIcon />,
      logout: true,
    },
  ];

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const handleLogoutClick = () => {
    dispatch(resetAllData());
    dispatch(logout());
  };

  return (
    <S.Container>
      <S.NavContent>
        <S.AppLogo alt="PTP Logo" src={IMAGES.logo} />
        <S.BurgerMenuIcon onClick={() => setOpen(!open)}>
          <Icons.BurgerIcon />
        </S.BurgerMenuIcon>
      </S.NavContent>
      <S.OptionsContent className={open ? "open" : ""}>
        <S.MainOptions>
          {mainOptions.map((item) => (
            <S.NavLinkWrapper key={item.label} className={pathname.includes(item.href) ? "active" : ""}>
              <S.NavLink to={item.href}>
                {item.icon} {item.label}
              </S.NavLink>
            </S.NavLinkWrapper>
          ))}
        </S.MainOptions>
        <S.BottomOptions>
          {bottomOptions.map((item) => (
            <S.NavLinkWrapper key={item.label} className={pathname.includes(item.href) ? "active" : ""}>
              {item.logout ? (
                <S.NavLink className="logout" onClick={handleLogoutClick}>
                  {item.icon} {item.label}
                </S.NavLink>
              ) : (
                <S.NavLink to={item.href}>
                  {item.icon} {item.label}
                </S.NavLink>
              )}
            </S.NavLinkWrapper>
          ))}
        </S.BottomOptions>
      </S.OptionsContent>
    </S.Container>
  );
};
