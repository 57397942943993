import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";

export const HomeContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  a {
    font-size: 16px;
    color: ${COLORS.green};
    margin: 2px 0;
  }
`;

export const HomeTitle = styled.p`
  font-size: 26px;
  color: ${COLORS.deepDark};
  margin: 10px 0;
`;

export const HomeDescription = styled.p`
  font-size: 18px;
  color: ${COLORS.deepDark};
  margin: 6px 0;
`;
