import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PrimaryButton } from "common";
import { singleOwnerSelectors } from "store/admin-slices/single-property-slice/selectors";
import { singleOwnerActions } from "store/admin-slices/single-property-slice/slice";
import { isLoading } from "utils/constants";

import * as S from "./styled";

export const Notes = () => {
  const { singleOwner, updateNotesStatus } = useSelector(singleOwnerSelectors);

  const [note, setNote] = useState(null);

  const { selectedPropertyId } = useParams();

  const dispatch = useDispatch();

  const handleNoteChange = (e) => setNote((prev) => ({ ...prev, notes: e.target.value }));

  const handleChangeNoteClick = () => {
    if (selectedPropertyId && note) {
      dispatch(
        singleOwnerActions.updateNotes({
          note,
          property_id: selectedPropertyId,
        })
      );
    }
  };

  useEffect(() => {
    const property = singleOwner.properties.find((item) => item.id === Number(selectedPropertyId));

    setNote(property?.note);
  }, [selectedPropertyId, singleOwner.properties]);

  return (
    <S.Container>
      <S.Content>
        <S.ContentTitle>Notes</S.ContentTitle>
        <S.TextArea value={note?.notes ?? ""} onChange={handleNoteChange} />
        <PrimaryButton
          eventHandler={handleChangeNoteClick}
          label="update notes"
          loading={isLoading(updateNotesStatus)}
          mode="gray"
        />
      </S.Content>
    </S.Container>
  );
};
