import axios from "axios";
import { API } from "configs/api";

export const agentsApi = {
  createProfile: (body) => axios.post(API.adminUsers, body, { token: true }),
  getPermissions: () => axios.get(API.adminUsers + API.getAllPermissions, { token: true }),
  getAgents: (params) => axios.get(API.adminUsers, { params, token: true }),
  exportCSV: (params) =>
    axios.get(API.adminUsers + API.agentExportCSV, {
      token: true,
      responseType: "blob",
      params,
    }),
};
