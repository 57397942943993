import { useSelector } from "react-redux";
import Stepper from "common/Stepper";
import { submitEvidenceSelectors } from "store/submit-evidence-slice/selectors";

import Step1 from "./step-1";
import Step2 from "./step-2";
import * as S from "./styled";

export const SubmitEvidencePage = () => {
  const { submitEvidenceStep } = useSelector(submitEvidenceSelectors);

  const stepObj = {
    0: <Step1 />,
    1: <Step2 />,
  };

  return (
    <S.SubmitEvidenceWrapper className="w-full">
      <S.StyledSignUpStepArea className="px-9 py-4 md:p-16 md:pt-5 sm:w-4/5 md:w-4/5 lg:w-3/5 xl:w-[800px]">
        <Stepper maxStep={2} step={submitEvidenceStep} />
        {stepObj[submitEvidenceStep]}
      </S.StyledSignUpStepArea>
    </S.SubmitEvidenceWrapper>
  );
};
