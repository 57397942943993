import { PrimaryButton } from "common";

import * as S from "./styled.js";
const colors = ["#3C2C9D", "#298501", "#1A73E8", "#F88125", "#AF9FFF", "#9DAE01", " #9747FF", "#6FDA44", "#FF6473"];

const CountyCard = ({ title, subTitle, colorIndex, onClick }) => {
  const getColor = (index) => colors[index % colors.length];
  return (
    <S.Card color={getColor(colorIndex)}>
      <PrimaryButton eventHandler={onClick} label={`${title} ${subTitle}`} mode="gray" />
    </S.Card>
  );
};

export default CountyCard;
