import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATUSES, SLICE_NAME } from "utils/constants";
import { v4 as uuid } from "uuid";

import { getLeadsList, leadsExportCsv } from "./actions";

const initialState = {
  leadsList: [],
  per_page: 50,
  last_page: 0,
  leadsListFilters: {},
  downloadCsvHref: null,
  allowScroll: false,
  getLeadsListStatus: LOADING_STATUSES.idle,
  leadsExportCsvStatus: LOADING_STATUSES.idle,
};

export const leadsList = createSlice({
  name: SLICE_NAME.leadsSlice,
  initialState,
  reducers: {
    setLeadsListFilters: (state, { payload }) => {
      state.leadsListFilters = payload;
    },
    setAllowScroll: (state, { payload }) => {
      state.allowScroll = payload;
    },
    resetAllData: (state, { payload }) => {
      if (!payload) {
        return {
          ...initialState,
          leadsListFilters: state.leadsListFilters,
        };
      } else {
        return initialState;
      }
    },
  },
  extraReducers: ({ addCase }) => {
    // get leads list
    addCase(getLeadsList.pending, (state) => {
      state.getLeadsListStatus = LOADING_STATUSES.pending;
    });
    addCase(getLeadsList.fulfilled, (state, { payload }) => {
      state.leadsList = payload?.data.map((item) => ({
        ...item,
        uniqueId: uuid(),
      }));
      state.per_page = payload?.meta?.per_page;
      state.last_page = payload?.meta?.total;
      state.getLeadsListStatus = LOADING_STATUSES.succeeded;
    });
    addCase(getLeadsList.rejected, (state) => {
      state.getLeadsListStatus = LOADING_STATUSES.failed;
    });

    // export csv
    addCase(leadsExportCsv.pending, (state) => {
      state.leadsExportCsvStatus = LOADING_STATUSES.pending;
    });
    addCase(leadsExportCsv.fulfilled, (state, { payload }) => {
      state.downloadCsvHref = payload;
      state.leadsExportCsvStatus = LOADING_STATUSES.succeeded;
    });
    addCase(leadsExportCsv.rejected, (state) => {
      state.leadsExportCsvStatus = LOADING_STATUSES.failed;
    });
  },
});

export const leadsListReducer = leadsList.reducer;

export const leadsActions = {
  ...leadsList.actions,
  getLeadsList,
  leadsExportCsv,
};
