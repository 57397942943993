import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components";
export const Container = styled.div`
  margin: 10px;
`;

export const ModalContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.overlay_200};
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
`;

export const StepArea = styled.div`
  border: 1px solid ${COLORS.primary};
  border-radius: 10px;
  background-color: ${COLORS.white};
  position: relative;

  .close-button {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
  }

  .continue-button {
    width: fit-content;
    align-self: center;
  }
`;

export const Content = styled.div`
  .disabled {
    color: ${COLORS.overlay_300};
  }

  .acordion svg {
    display: none;
  }

  .select-all,
  deselect-all {
    width: 200px;
    user-select: none;
  }
`;

export const Buttons = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const PermissionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 446px;
  overflow: auto;
  padding: 20px;
  margin-bottom: 20px;
`;
export const RelatedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
`;
export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const RelatedItems = styled.div`
  display: flex;
  align-items: center;
`;

export const RelatedLabel = styled.label``;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 10px;
`;
