import * as S from "./styled";

export const Footer = ({ referralsLength }) => {
  return (
    <S.FooterContainer>
      <S.FooterTitle>TOTALS</S.FooterTitle>
      <S.PercentTotal>{referralsLength * 10}%</S.PercentTotal>
    </S.FooterContainer>
  );
};
