import axios from "axios";
import { STATUS_CODES, TOAST_TYPES } from "utils/constants";
import { getStorage, removeStorage, toastify } from "utils/helpers";

const initAxiosInterceptors = () => {
  const requestQueue = new Map();

  const generateRequestKey = (config) => {
    return `${config.method}_${config.url}`;
  };

  const addRequestToQueue = (requestKey, cancelTokenSource) => {
    if (requestQueue.has(requestKey)) {
      const { cancelTokenSource: prevCancelTokenSource } = requestQueue.get(requestKey);
      prevCancelTokenSource.cancel("Duplicate request canceled.");
    }
    requestQueue.set(requestKey, { cancelTokenSource });
  };

  const removeRequestFromQueue = (requestKey) => {
    requestQueue.delete(requestKey);
  };

  axios.interceptors.request.use((config) => {
    config.url = process.env.REACT_APP_API_URL + config.url;
    config.headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": "true",
      Accept: "application/json",
    };

    if (config.formData) {
      config.headers = {
        ...config.headers,
        "Content-Type": "multipart/form-data",
      };
    } else {
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
      };
    }

    if (config.token) {
      const token = getStorage("credentials");
      if (!token) {
        throw new Error("token not found");
      }
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    if (config.owner_token) {
      const token = getStorage("owner_token");
      if (!token) {
        throw new Error("token not found");
      }
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    if (!config.noCancel) {
      const requestKey = generateRequestKey(config);
      const cancelTokenSource = axios.CancelToken.source();
      addRequestToQueue(requestKey, cancelTokenSource);
      config.cancelToken = cancelTokenSource.token;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      const requestKey = generateRequestKey(response.config);
      removeRequestFromQueue(requestKey);
      return response;
    },
    (error) => {
      if (error.response.status === STATUS_CODES.unauthorized) {
        removeStorage("credentials");
        removeStorage("owner_token");
        window.location.replace("/");
      }
      if (error.response.status === STATUS_CODES.forbidden) {
        removeStorage("credentials");
        removeStorage("owner_token");
        window.location.replace("/");
      }

      if (error.response.status === STATUS_CODES.serverError) {
        toastify(TOAST_TYPES.error, "Server Error");
      }

      const requestKey = generateRequestKey(error.config);
      removeRequestFromQueue(requestKey);

      throw new Object({
        data: error.response.data,
        status: error.response.status,
      });
    }
  );
};

export default initAxiosInterceptors;
