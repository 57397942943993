import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "assets/svgs";
import { Loader, PrimaryButton } from "common";
import { getProperties } from "store/client-slice/actions";
import { clientSelectors } from "store/client-slice/selectors";
import { isLoading } from "utils/constants";
import { generateAddressLink } from "utils/helpers";
import { v4 as uuid } from "uuid";

import * as S from "./styled";

export const PropertiesPage = () => {
  const { getPropertiesStatus, properties } = useSelector(clientSelectors);

  const [dataEnd, setDataEnd] = useState(5);

  const scrollBlockRef = useRef();
  const dispatch = useDispatch();

  const scrollHandler = (e) => {
    if (e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < 100) {
      setDataEnd((prevDataEnd) => prevDataEnd + 4);
    }
  };

  useEffect(() => {
    dispatch(getProperties());
  }, []);

  if (isLoading(getPropertiesStatus)) {
    return (
      <S.LoaderBox>
        <Loader size={80} />
      </S.LoaderBox>
    );
  }

  return (
    <S.PropertiesScrollBlock ref={scrollBlockRef} onScroll={scrollHandler}>
      <S.PropertiesButtonBlock>
        <PrimaryButton className="add-another-property" label="ADD ANOTHER PROPERTY" />
      </S.PropertiesButtonBlock>
      {properties.length
        ? properties.slice(0, dataEnd).map((property) => (
            <S.PropertiesLink
              key={uuid()}
              className="text-green-700 text-base"
              href={generateAddressLink(property)}
              rel="noreferrer"
              target="_blank"
            >
              <S.PropertiesContent>
                <Icons.PropertiesIcon />
                <S.PropertiesText>
                  {property.street_address} | {property.county} | {property.county_owner_name}
                </S.PropertiesText>
              </S.PropertiesContent>
            </S.PropertiesLink>
          ))
        : null}
    </S.PropertiesScrollBlock>
  );
};
