import { Link } from "react-router-dom";
import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const Navbar = styled.div`
  margin: 0 auto;
  padding: 20px 10px;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const NavbarLink = styled(Link)`
  color: ${COLORS.deepDark};
  transition: color 0.3s ease-in-out;
  font-size: 16px;

  &:hover {
    color: ${COLORS.primary};
  }
`;

export const Dropdown = styled.div`
  display: flex;
  div:first-child {
    display: flex;

    button {
      color: ${COLORS.deepDark};
      font-weight: 600;
      font-size: 16px;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: ${COLORS.primary};
      }
    }
  }

  li {
    color: ${COLORS.deepDark};

    &:hover {
      color: ${COLORS.primary};
      text-decoration: underline;
    }
  }
`;

export const NavbarItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 10px;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  padding: 6px 10px;

  @media screen and (${BREAKPOINTS.lg}) {
    width: auto;
    flex-direction: row;
    gap: 25px;
    max-height: unset;
    overflow: auto;
    flex-wrap: nowrap;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    gap: 40px;
  }

  &.open {
    max-height: 500px;
    padding-bottom: 10px;
    transition: max-height 0.5s ease-in;
    overflow: auto;
  }
`;

export const NavbarItemsWrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (${BREAKPOINTS.lg}) {
    width: auto;
    display: block;
  }
`;

export const BurgerIconWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${COLORS.overlay_300};
  display: block;
  padding: 6px 14px;

  svg path {
    stroke: grey;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media screen and (${BREAKPOINTS.lg}) {
    flex-direction: row;
    gap: 20px;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    gap: 25px;
  }
`;
