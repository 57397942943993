import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icons } from "assets/svgs";
import { AutocompleteInput, Loader, PrimaryButton, PrimaryInput, Select } from "common";
import { useDebounce } from "hooks";
import { submitEvidenceSelectors } from "store/submit-evidence-slice/selectors";
import { submitEvidenceActions } from "store/submit-evidence-slice/slice";
import { COUNTIES_FILTER, isLoading, LOADING_STATUSES, PTP_CONTACT_US_LINK } from "utils/constants";
import { isEmpty } from "utils/helpers";
import { v4 as uuid } from "uuid";
import { submitEvidenceStep1Schema } from "validations";

import * as S from "./styled";

const Step1 = () => {
  const {
    propertySearchStatus,
    searchedProperties,
    propertySearchData,
    showBio,
    loading,
    success,
    disableConfirmButton,
    bioDataChanged,
    step1Data,
    searchedPropertiesStatus,
  } = useSelector(submitEvidenceSelectors);

  const [searchAddressError, setSearchAddressError] = useState(null);

  const location = useLocation();

  const dispatch = useDispatch();

  const {
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(submitEvidenceStep1Schema),
  });

  const { address, first_name, last_name, client_email } = watch();

  const debouncedSearchAddress = useDebounce(address);

  const navigateContactUs = () => {
    window.open(PTP_CONTACT_US_LINK, "_blank");
  };

  const handleSearchedPropertyClick = (data) => {
    dispatch(submitEvidenceActions.setPropertyId(data.id));
    dispatch(submitEvidenceActions.setShowBio(true));
    dispatch(submitEvidenceActions.propertySearch(data.id));
    setValue("address", `${data.street_address}`);
  };

  const confirmDetails = () => {
    if (bioDataChanged) {
      dispatch(submitEvidenceActions.setLoading(true));

      setTimeout(() => {
        dispatch(submitEvidenceActions.setLoading(false));
        dispatch(submitEvidenceActions.setSuccess(true));
        dispatch(submitEvidenceActions.setConfirmDetailsClicked(true));
        dispatch(submitEvidenceActions.nextStep());
      }, 1500);
    }
  };

  const onSubmit = (data) => {
    dispatch(submitEvidenceActions.setStep1Data(data));
  };

  useEffect(() => {
    if (first_name && last_name && client_email) {
      dispatch(submitEvidenceActions.setDisableConfirmButton(false));
      dispatch(submitEvidenceActions.setConfirmDetailsClicked(false));
    } else {
      dispatch(submitEvidenceActions.setDisableConfirmButton(true));
    }

    if (first_name || last_name || client_email) {
      dispatch(submitEvidenceActions.setBioDataChanged(true));
      dispatch(submitEvidenceActions.setSuccess(false));
    } else {
      dispatch(submitEvidenceActions.setBioDataChanged(false));
    }
  }, [first_name, last_name, client_email]);

  useEffect(() => {
    if (debouncedSearchAddress && !!watch().address) {
      dispatch(submitEvidenceActions.propertySearchFromDb({ search: debouncedSearchAddress }));
    }
  }, [debouncedSearchAddress]);

  useEffect(() => {
    if (location.state === "/signup") {
      setValue("first_name", sessionStorage.getItem("first_name"));
      setValue("last_name", sessionStorage.getItem("last_name"));
      setValue("client_email", sessionStorage.getItem("email"));
    }
    if (!isEmpty(propertySearchData)) {
      const { first_name = "", last_name = "", email = "", county = "" } = propertySearchData;
      if (county === "FortBend") {
        setValue("county", "Fort Bend");
      } else {
        setValue("county", county);
      }
      setValue("first_name", first_name);
      setValue("last_name", last_name);
      setValue("client_email", email);
    } else {
      setValue("first_name", "");
      setValue("last_name", "");
      setValue("client_email", "");
    }
  }, [showBio, propertySearchData]);

  useEffect(() => {
    if (!isEmpty(step1Data)) {
      setValue("first_name", step1Data.first_name);
      setValue("last_name", step1Data.last_name);
      setValue("client_email", step1Data.client_email);
    }
  }, [showBio, propertySearchData]);

  useEffect(() => {
    if (searchedPropertiesStatus === LOADING_STATUSES.failed) {
      dispatch(submitEvidenceActions.resetStatus());
    }
  }, [searchedPropertiesStatus]);

  useEffect(() => {
    if (!searchedProperties.length) {
      setSearchAddressError("No results found");
    }
  }, [searchedProperties.length, searchedPropertiesStatus]);

  const SearchDataLayout = propertySearchData ? S.Form : S.Box;

  return (
    <S.SubmitEvidenceForm onSubmit={handleSubmit(onSubmit)}>
      <S.Title>Submit Your Own Evidence (Optional)</S.Title>
      <S.Text>
        You can help us reduce your taxes by providing information that the appraisal district might not otherwise have
        about your property. This can include information about your property's condition or neighborhood factors which
        might affect its market value. You can also submit photos to support your data. We'll use whatever you submit
        verbatim, so please be careful and thorough in your report.
      </S.Text>
      <S.Box>
        <AutocompleteInput
          autoComplete="off"
          className="autocomplete"
          placeholder="Search For Your Property"
          readOnly={isLoading(propertySearchStatus)}
          register={register("address")}
          value={address}
        >
          <S.SearchBoxContainer>
            {searchedPropertiesStatus !== LOADING_STATUSES.succeeded ? (
              <S.LoaderBox>
                <Loader size={40} />
              </S.LoaderBox>
            ) : (
              searchedProperties.slice(0, 6).map((data) => (
                <S.SearchBox key={uuid()} className="pac-item" onClick={() => handleSearchedPropertyClick(data)}>
                  <Icons.LocationIcon />
                  <S.Span className="street-address">{data.street_address}</S.Span>
                  <S.Span>{data.city} TX USA</S.Span>
                </S.SearchBox>
              ))
            )}
            {!searchedProperties.length && searchedPropertiesStatus === LOADING_STATUSES.succeeded ? (
              <S.NoResult>{searchAddressError}</S.NoResult>
            ) : null}
          </S.SearchBoxContainer>
        </AutocompleteInput>
      </S.Box>
      {showBio ? (
        <>
          <S.Animation>
            <PrimaryInput
              error={errors.first_name}
              label="First Name"
              register={register("first_name")}
              wrapperClassName="input-wrapper"
            />
            <PrimaryInput
              error={errors.last_name}
              label="Last Name"
              register={register("last_name")}
              wrapperClassName="input-wrapper"
            />
            <PrimaryInput
              error={errors.client_email}
              label="Email Address"
              register={register("client_email")}
              wrapperClassName="input-wrapper"
            />
          </S.Animation>
          <S.ConfirmYourDetails>
            <Controller
              control={control}
              name="county"
              render={({ field: { value, onChange } }) => (
                <Select
                  error={errors.county}
                  items={COUNTIES_FILTER.map((item) => item.description)}
                  label="County"
                  value={value}
                  onChange={(value) => onChange({ target: { value } })}
                />
              )}
            />
            <PrimaryButton
              className={disableConfirmButton ? "disable" : "not-disabled"}
              label={
                loading ? (
                  <Loader color="white" size={24} />
                ) : success ? (
                  <Icons.SuccessIcon />
                ) : bioDataChanged ? (
                  "Confirm your Details"
                ) : (
                  "Confirm your Details"
                )
              }
              onClick={confirmDetails}
            />
            <S.ContactUsText>
              Not Your Details? <S.ContactUs onClick={navigateContactUs}> Contact Us</S.ContactUs>
            </S.ContactUsText>
          </S.ConfirmYourDetails>
        </>
      ) : null}
      <SearchDataLayout className="flex w-full flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {isLoading(propertySearchStatus) && (
          <S.Box className="flex justify-center">
            <Loader size={60} />
          </S.Box>
        )}
      </SearchDataLayout>
    </S.SubmitEvidenceForm>
  );
};

export default Step1;
