import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "utils/constants";

import { clientApi } from "./services";

export const postCheckEmail = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/postCheckEmail`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await clientApi.checkEmail(body);
      response.data.data = { ...response.data.data, ...body };
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postCheckCode = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/postCheckCode`,
  async (body, { rejectWithValue }) => {
    try {
      const response = await clientApi.checkCode(body);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMe = createAsyncThunk(`${SLICE_NAME.clientSlice}/getMe`, async (_, { rejectWithValue }) => {
  try {
    const response = await clientApi.getMe();
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAppeals = createAsyncThunk(`${SLICE_NAME.clientSlice}/getAppeals`, async (_, { rejectWithValue }) => {
  try {
    const response = await clientApi.appeals();
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getProperties = createAsyncThunk(
  `${SLICE_NAME.clientSlice}/getProperties`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await clientApi.properties();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getInvoices = createAsyncThunk(`${SLICE_NAME.clientSlice}/getInvoices`, async (_, { rejectWithValue }) => {
  try {
    const response = await clientApi.invoices();
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
