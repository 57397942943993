import * as yup from "yup";

export const discountSchema = yup.object({
  reason: yup.string().required("Reason is required"),
  percent: yup
    .number()
    .required("Percent is required")
    .typeError("Percent must be a number")
    .integer("Percent must be an integer")
    .min(1, "Percent must be at least 1")
    .max(100, "Percent must be at most 100"),
});
