import { BREAKPOINTS } from "assets/styles/breakpoints";
import { COLORS } from "assets/styles/colors";
import { styled } from "styled-components/macro";

export const AppealsScrollBlock = styled.div`
  scroll-margin-block: 20px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 60px);
  min-height: auto;
  padding-bottom: 60px;

  &::-webkit-scrollbar {
    background-color: ${COLORS.overlay_150};
    width: 6px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 4px;
  }

  @media screen and (${BREAKPOINTS.md}) {
    width: auto;
  }
`;

export const AppealsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  border-radius: 10px;
  background: ${COLORS.white};
  color: ${COLORS.deepDark};
  border: 1px solid ${COLORS.green};
  box-shadow: 0 5px 14px 0 ${COLORS.green};
  padding: 48px 50px 100px;

  @media screen and (${BREAKPOINTS.sm}) {
    width: 100%;
  }

  @media screen and (${BREAKPOINTS.md}) {
    width: 448px;
    padding: 34px 46px 100px;
  }

  @media screen and (${BREAKPOINTS.lg}) {
    width: 536px;
    padding: 30px 44px 120px;
  }

  @media screen and (${BREAKPOINTS.xl}) {
    width: 866px;
    padding: 40px 50px 140px;
  }
`;

export const AppealsContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1vw;
  margin-bottom: 30px;
`;

export const AppealsTitle = styled.div`
  color: ${COLORS.deepDark};
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const LoaderBox = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
`;
