export const InvoicesIcon = (props) => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="document">
        <g id="vuesax/linear/document">
          <g id="document_2">
            <path
              d="M17 8.4V12.4C17 16.4 15.6 18 12.1 18H7.9C4.4 18 3 16.4 3 12.4V7.6C3 3.6 4.4 2 7.9 2H11.4"
              id="Vector"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.9199 7.99976H13.9199C11.6699 7.99976 10.9199 7.24976 10.9199 4.99976L11.4199 1.99976L16.9199 7.99976Z"
              id="Vector_2"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
