import { Link } from "react-router-dom";
import { IMAGES } from "assets/images";

import * as S from "./styled";

export const Footer = () => {
  return (
    <S.StyledFooterContainer>
      <S.FooterContainer>
        <S.FooterContent>
          <S.FooterLogoBlock>
            <Link className="navbar-brand inline-block my-4" to="/">
              <S.FooterLogo alt="logo" className="img-fluid mx-auto max-w-xs" src={IMAGES.logo} />
            </Link>
          </S.FooterLogoBlock>
          <S.Description>
            <S.FooterText>Property Tax Protest</S.FooterText>
            <S.FooterText>3824 Cedar Springs Road, MB 361</S.FooterText>
            <S.FooterText>Dallas, TX 75219</S.FooterText>
            <S.FooterText>469-449-9835</S.FooterText>
          </S.Description>
          <S.PrivacyPolicy>
            <Link className="privacy-policy" to={"/privacy-policy"}>
              Privacy Policy
            </Link>
          </S.PrivacyPolicy>
          <S.Line />
          <S.CopyRight>
            <S.FooterText>
              Copyright &copy; {new Date().getFullYear()} Property Tax Protest All rights reserved
            </S.FooterText>
          </S.CopyRight>
        </S.FooterContent>
      </S.FooterContainer>
    </S.StyledFooterContainer>
  );
};
