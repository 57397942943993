export const COLORS = {
  green: "#437f55",
  light_green: "#e8f5ee",
  primary: "#437f55",
  success: "#437f55",
  green_300: "#28A745",
  danger: "#c70013",
  red: "#f40000",
  pink: "#F8E0E2",
  dark: "#333",
  deepDark: "#000000de",
  white: "#fff",
  white_170: "#ffffff2b",
  grey: "#f5f5f5",
  darkGrey: "#cfd4d7",
  blue: "#00639d",
  light_blue: "#007BFF",
  purple: "#201C47",
  darkgrey: "#B5B5B5",
  lightgray: "#1F2937",
  dimgrey: "#696969",
  overlay_30: "#DEE2E6",
  overlay_50: "#343a40",
  overlay_060: "#0000000F",
  overlay_100: "#8181814C",
  overlay_150: "#00000026",
  overlay_200: "#00000033",
  overlay_300: "#0000004C",
};
