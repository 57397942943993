import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { COLORS } from "assets/styles/colors";
import { ErrorMessage, PrimaryButton, PrimaryInput } from "common";
import { useDebounce } from "hooks";
import html2canvas from "html2canvas";
import { signupSelectors } from "store/signup-slice/selectors";
import { signupActions } from "store/signup-slice/slice";

import * as S from "./styled";

export const Signature = () => {
  const { signatureErr, signType } = useSelector(signupSelectors);
  const [typedSign, setTypedSign] = useState("");

  const typedSignRef = useRef(null);
  const drawSignRef = useRef(null);

  const errorClassName = signatureErr ? "error" : "";

  const debouncedSign = useDebounce(typedSign, 100);

  const dispatch = useDispatch();
  const handleSignTypeClick = (type) => {
    dispatch(signupActions.deleteSignature());
    dispatch(signupActions.setSignType(type));
    setTypedSign("");
  };

  const handleSignChange = (e) => {
    setTypedSign(e.target.value);
  };

  const handleCanvasEnd = () => {
    const signature = drawSignRef.current.getTrimmedCanvas().toDataURL("image/png");
    dispatch(signupActions.setSignature({ signature }));
  };

  const handleDrawSignClear = () => {
    drawSignRef.current.clear();
    dispatch(signupActions.deleteSignature());
  };
  const getSignType = useMemo(
    () => ({
      type: (
        <S.SignatureTypeContent>
          <S.SignatureInputContent>
            Full Name:
            <PrimaryInput
              placeholder="Full Name"
              value={typedSign}
              wrapperClassName="signature-input"
              onChange={handleSignChange}
            />
          </S.SignatureInputContent>
          <S.TypedSignature>
            <S.Box ref={typedSignRef}>{typedSign}</S.Box>
          </S.TypedSignature>
        </S.SignatureTypeContent>
      ),
      draw: (
        <>
          <SignatureCanvas
            ref={drawSignRef}
            canvasProps={{ className: "sigCanvas" }}
            penColor={COLORS.deepDark}
            onEnd={handleCanvasEnd}
          />
          <PrimaryButton eventHandler={handleDrawSignClear} label="Clear" />
        </>
      ),
    }),
    [typedSign]
  );

  useEffect(() => {
    const canvasElement = typedSignRef.current;
    if (canvasElement && debouncedSign) {
      const textElementHeight = 60;
      const additionalHeight = 10;
      const totalImageHeight = textElementHeight + additionalHeight;

      const options = {
        height: totalImageHeight,
      };
      html2canvas(canvasElement, options).then((canvas) => {
        const signature = canvas.toDataURL("image/png");
        dispatch(signupActions.setSignature({ signature }));
      });
    } else {
      dispatch(signupActions.deleteSignature());
    }
  }, [debouncedSign]);

  return (
    <S.SignatureContainer>
      <S.SignatureBlockTitle>Choose one of these options:</S.SignatureBlockTitle>
      <S.SignatureHeader>
        <S.SignatureHeaderItem
          className={`${signType === "type" ? "selected" : ""} ${errorClassName} `}
          onClick={() => handleSignTypeClick("type")}
        >
          Type
        </S.SignatureHeaderItem>
        <S.SignatureHeaderItem
          className={`${signType === "draw" ? "selected" : ""} ${errorClassName} `}
          onClick={() => handleSignTypeClick("draw")}
        >
          Draw
        </S.SignatureHeaderItem>
      </S.SignatureHeader>
      <S.SignatureContent className={errorClassName}>{getSignType[signType]}</S.SignatureContent>
      {!!signatureErr && <ErrorMessage children={signatureErr} />}
    </S.SignatureContainer>
  );
};
